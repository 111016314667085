import React, { useEffect, useState } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import {
  Drawer,
  Typography,
  Divider,
  CardMedia,
  Button,
  Theme,
  IconButton,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  List,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import {
  colorsFilterVar,
  expandedFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  widthFilterVar,
} from '../../../store/reactiveVars'
import { useRouter } from 'next/router'
import ColorDrawerItem from './ColorComponents/ColorDrawerItem'
import PriceDrawerItem from './PriceComponents/PriceDrawerItem'
import DimensionsDrawerItem from './DimensionsComponents/DimensionsDrawerItem'
import { useTranslation } from 'next-i18next'
import { handleClearAllFilters } from '../../../utilityFunctions/filtersUtils'
import FrameColorDrawerItem from './ColorComponents/FrameColorDrawerItem'
import CategoryDrawerItem from './CategoryComponents/CategoryDrawerItem'
import StyleDrawerItem from './StyleComponents/StyleDrawerItem'
import ProductTypeDrawerItem from './ProductTypeComponents/ProductTypeDrawerItem'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      drawer: {
        padding: theme.spacing(2, 2, 0, 2),
        width: 450,
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      drawerTopContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
        borderBottom: '1px solid #d3d3d3',
      },
      closeIcon: {
        cursor: 'pointer',
      },
      filtersList: {},
      buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        background: theme.palette.background.paper,
        padding: theme.spacing(2, 0),
        position: 'sticky',
        bottom: 0,
        marginTop: 'auto',
      },
      button: {
        width: '40%',
        textTransform: 'none',
      },
    }),
  { name: 'MuiFilterDrawerComponent' },
)

export default function FilterDrawer({
  drawerState,
  filters,
  productsCount,
  productsLoading,
  permalink,
  filtersData,
}) {
  const { t, i18n } = useTranslation(['common'])
  const priceFilterString = t('filter-price')
  const colorFilterString = t('filter-color')
  const frameColorFilterString = t('filter-frame-color')
  const dimensionFilterString = t('filter-dimension')
  const filtersString = t('filters-drawer-header')
  const viewButtonString = t('view-items-cta-button')
  const clearButtonString = t('clear-filter-cta-button')
  const loadingButtonString = t('loading-text')

  const categoryFilterString = t('filter-category')
  const styleFilterString = t('filter-style')
  const productTypeFilterString = t('filter-product-type')

  const classes = useStyles() as any
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const { drawerOpen, setDrawerOpen } = drawerState
  const {
    colorsData,
    frameColorsData,
    priceData,
    dimensionsData,
    categoriesData,
    stylesData,
  } = filtersData

  // ================= HANDLE EVENTS FUNCTIONS
  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev)
  }

  const handleDrawerItemOpen = (item: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    expandedFilterVar(isExpanded ? item : false)
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={drawerOpen}
        transitionDuration={{
          enter: 500,
          exit: 300,
        }}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        <div className={classes.drawerTopContent}>
          <Typography variant="h6">{filtersString}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => setDrawerOpen(false)}
          />
        </div>

        <List component="nav" className={classes.filtersList}>
          {filters.map((filter, i) => {
            switch (filter) {
              case categoryFilterString:
                return (
                  <CategoryDrawerItem
                    key={i}
                    categoryData={categoriesData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              case styleFilterString:
                return (
                  <StyleDrawerItem
                    key={i}
                    stylesData={stylesData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              case productTypeFilterString:
                return (
                  <ProductTypeDrawerItem
                    key={i}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              case colorFilterString:
                return (
                  <ColorDrawerItem
                    key={i}
                    colorsData={colorsData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              case frameColorFilterString:
                return (
                  <FrameColorDrawerItem
                    key={i}
                    colorsData={frameColorsData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              case priceFilterString:
                return (
                  <PriceDrawerItem
                    key={i}
                    priceData={priceData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )
              case dimensionFilterString:
                return (
                  <DimensionsDrawerItem
                    key={i}
                    dimensionsData={dimensionsData}
                    permalink={permalink}
                    handleDrawerItemOpen={handleDrawerItemOpen}
                  />
                )

              default:
                throw new Error(
                  "Error in FilterDrawer... shouldn't be able to open with no filter options",
                )
            }
          })}
        </List>

        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={handleClearAllFilters}
          >
            {clearButtonString}
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => handleDrawerOpen()}
          >
            {productsLoading
              ? `${loadingButtonString}...`
              : `${viewButtonString} (${productsCount})`}
          </Button>
        </div>
      </Drawer>
    </>
  )
}
