import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import React from 'react'

// TODO -- Change css to react themes.pallet.primary.main (or whichever is the color)
// const greenColor = "rgb(0, 163, 108)";
// const greenColor = '#c1a578' //original
// const greenColor = "#2b2b2b";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        color: '#ffffff',
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'block',
        marginTop: '10px',
        position: 'relative',
        lineHeight: 0,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          padding: 0,
          marginTop: '3px',
          fontSize: '11px',
          // lineHeight: "1.6",
        },

        '&::after': {
          content: '""',
          position: 'absolute',
          right: '-8px',
          bottom: '0',
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 8px 12px 0',
          borderColor: `transparent transparent ${theme.palette.secondary.main} transparent`,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          right: '-8px',
          top: ' 0',
          width: ' 0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '12px 8px 0 0',
          borderColor: `${theme.palette.secondary.main} transparent transparent transparent`,
        },
      },

      wrapperLeft: {
        color: '#ffffff',
        lineHeight: 0,
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'block',
        marginTop: '10px',
        position: 'relative',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          padding: 1,
          marginTop: '3px',
          fontSize: '11px',
          // lineHeight: "1.6",
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          left: '-8px',
          top: '0',
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 8px 12px 0',
          borderColor: `transparent ${theme.palette.secondary.main} transparent transparent`,
        },

        '&::after': {
          content: '""',
          position: 'absolute',
          left: '-8px',
          bottom: ' 0',
          width: ' 0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 0 12px 8px',
          borderColor: `transparent transparent ${theme.palette.secondary.main} transparent`,
        },
      },

      smallModify: {
        padding: 1,
        marginTop: '5px',
        fontSize: '12px',
        // lineHeight: "1.6",
      },

      carIcon: {
        height: '30px',
        width: '35px',
        color: '#fff',
        padding: 0,
        margin: 0,
        display: 'inline-block',
        filter:
          'invert(100%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%)',

        [theme.breakpoints.down('sm')]: {
          height: '20px',
          width: '20px',
        },
      },
    }),
  { name: 'MuiFastDeliveryBadge' },
)

const FastDeliveryBadge = ({ left = false, small = false }) => {
  const classes = useStyles() as any

  return (
    <div
      className={`${left ? classes.wrapperLeft : classes.wrapper}  ${
        small ? classes.smallModify : ''
      }  `}
    >
      <img className={classes.carIcon} src="/shipping-icon-png.svg"></img>
    </div>
  )
}

export default FastDeliveryBadge
