import { createContext, useContext } from "react";

const CategoryContext = createContext(null);

export function CategoryContextWrapper({ data, locale, children }) {
  return (
    <CategoryContext.Provider value={{ data, locale }}>
      {children}
    </CategoryContext.Provider>
  );
}

export function useCategoryContext() {
  return useContext(CategoryContext);
}
