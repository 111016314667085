import React, { useEffect } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import {
  categoriesFilterVar,
  colorsFilterVar,
  frameColorsFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  productTypeFilterVar,
  stylesFilterVar,
  widthFilterVar,
} from '../../../../store/reactiveVars'
import {
  GET_FRAME_COLOR_BY_ID,
} from '../../../../operations/queries'
import { Skeleton } from '@mui/material'
import {
  getCategoriesQueryVariable,
  getDimensionsQueryVariable,
  getProductTypeQueryVariable,
  getStylesQueryVariable,
} from '../../../../operations/queries/variablesConstructors'
import { useRouter } from 'next/router'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'

export default function FrameColorDrawerItemCheckbox({ colorID, permalink }) {
  const { locale } = useRouter()
  const widthFilter = useReactiveVar(widthFilterVar)
  const heightFilter = useReactiveVar(heightFilterVar)
  const lengthFilter = useReactiveVar(lengthFilterVar)
  // const colorsFilter = useReactiveVar(colorsFilterVar)

  const frameColorsFilter = useReactiveVar(frameColorsFilterVar)

  const priceFilter = useReactiveVar(priceFilterVar)
  // const [checked, setChecked] = React.useState(false);
  const [fetchColor, { data, loading, error }] = useLazyQuery(
    GET_FRAME_COLOR_BY_ID,
  )
  const [colorProfileIndex, setColorProfileIndex] = React.useState(null)

  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)

  // =========== colors
  const styleOptions = getStylesQueryVariable(stylesFilter)
  // =========== styles
  const categoriesOptions = getCategoriesQueryVariable(categoriesFilter)

  const productTypeFilter = useReactiveVar(productTypeFilterVar)
  // =========== product types
  const productTypeOptions = getProductTypeQueryVariable(productTypeFilter)

  //* =================================================================
  //* ==== getting constructed variable through helper functions ======
  //* =================================================================
  const dimensionOptions = getDimensionsQueryVariable(
    widthFilter,
    heightFilter,
    lengthFilter,
  )

  //* ===================================================================================================
  //* ======= set the checked state to true on first render to the reactive var if it will contain this ID
  //* ===================================================================================================
  useEffect(() => {
    fetchColor({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        colorID,
        permalink,
        styles: styleOptions,
        categories: categoriesOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    // if (colorsFilter.includes(colorID)) {
    //   setChecked(true);
    // } else {
    //   setChecked(false);
    // }

    return
  }, [])

  useEffect(() => {
    //* ====================================================================
    //* we want to run the check and get this item's profile
    //* ====================================================================
    if (!loading && data) {
      const colorProfileIndex: number = getProfileIndexFromLanguage(
        data.findUniqueColor.color_profiles,
        locale,
      )

      setColorProfileIndex(colorProfileIndex)
    }

    return
  }, [loading, data])

  //* =========================================================================
  //* =========== handling loading state and errors
  //* =========================================================================
  if (loading || colorProfileIndex === null || colorProfileIndex === undefined)
    return (
      <Skeleton
        variant="rectangular"
        height={25}
        style={{ margin: '0.8rem 0' }}
      />
    )
  if (error) return <p>Something went wrong...</p>

  //* =========================================================================
  //* ============================== handling the selection of a color filter
  //* =========================================================================
  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      frameColorsFilterVar([...frameColorsFilter, parseInt(event.target.name)])
    } else if (
      event.target.checked === false &&
      frameColorsFilter.includes(colorID)
    ) {
      const filteredColors = frameColorsFilter.filter(
        (ID) => ID !== parseInt(event.target.name),
      )

      frameColorsFilterVar([...filteredColors])
    }

    // setChecked(event.target.checked);
  }

  return (
    <>
      {data &&
        data.findManyProductContainerCount > 0 &&
        data.findUniqueColor.color_profiles[colorProfileIndex] && (
          <FormControlLabel
            key={data.findUniqueColor.id}
            control={
              <Checkbox
                checked={frameColorsFilter.includes(data.findUniqueColor.id)}
                onChange={handleColorChange}
                name={`${data.findUniqueColor.id}`}
              />
            }
            label={`${data.findUniqueColor.color_profiles[colorProfileIndex].name} (${data.findManyProductContainerCount})`}
          />
        )}
    </>
  )
}
