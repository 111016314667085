import React, { useEffect } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import {
  colorsFilterVar,
  categoriesFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  widthFilterVar,
  stylesFilterVar,
  productTypeFilterVar,
} from '../../../../store/reactiveVars'
import { Skeleton } from '@mui/material'
import {
  getCategoriesQueryVariable,
  getDimensionsQueryVariable,
  getMainColorQueryVariable,
  getProductTypeQueryVariable,
  getStylesQueryVariable,
} from '../../../../operations/queries/variablesConstructors'
import { useRouter } from 'next/router'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'
import { GET_CATEGORY_BY_ID } from '../../../../operations/queries/filterCategoryQueries'

export default function CategoryDrawerItemCheckbox({
  categoryID,
  permalink,
  category,
  ...props
}) {
  const { locale } = useRouter()
  const widthFilter = useReactiveVar(widthFilterVar)
  const heightFilter = useReactiveVar(heightFilterVar)
  const lengthFilter = useReactiveVar(lengthFilterVar)
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const priceFilter = useReactiveVar(priceFilterVar)

  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)
  // =========== categories
  const categoriesOptions = getCategoriesQueryVariable(categoriesFilter)

  // =========== colors
  const colorOptions = getMainColorQueryVariable(colorsFilter)

  // =========== styles
  const styleOptions = getStylesQueryVariable(stylesFilter)

  const productTypeFilter = useReactiveVar(productTypeFilterVar)
  // =========== product types
  const productTypeOptions = getProductTypeQueryVariable(productTypeFilter)

  const [fetchCategory, { data, loading, error }] = useLazyQuery(
    GET_CATEGORY_BY_ID,
  )
  const [categoryProfileIndex, setCategoryProfileIndex] = React.useState(null)

  //* =================================================================
  //* ==== getting constructed variable through helper functions ======
  //* =================================================================
  const dimensionOptions = getDimensionsQueryVariable(
    widthFilter,
    heightFilter,
    lengthFilter,
  )

  //* ===================================================================================================
  //* ======= set the checked state to true on first render to the reactive var if it will contain this ID
  //* ===================================================================================================
  useEffect(() => {
    fetchCategory({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        categoryID,
        // permalink,
        colors: colorOptions,
        styles: styleOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    // if (colorsFilter.includes(colorID)) {
    //   setChecked(true);
    // } else {
    //   setChecked(false);
    // }

    return
  }, [])

  useEffect(() => {
    //* ====================================================================
    //* we want to run the check and get this item's profile
    //* ====================================================================
    if (!loading && data) {
      const categoryProfileIndex: number = getProfileIndexFromLanguage(
        data.findUniqueCategory.category_profiles,
        locale,
      )

      setCategoryProfileIndex(categoryProfileIndex)
    }

    return
  }, [loading, data])

  //* =========================================================================
  //* =========== handling loading state and errors
  //* =========================================================================
  if (
    loading ||
    categoryProfileIndex === null ||
    categoryProfileIndex === undefined
  )
    return (
      <Skeleton
        variant="rectangular"
        height={25}
        style={{ margin: '0.8rem 0' }}
      />
    )
  if (error) return <p>Something went wrong...</p>

  //* =========================================================================
  //* ============================== handling the selection of a color filter
  //* =========================================================================
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      // -- ALSO ALLOW ONLY ONE CATEGORY TO BE SELECTED
      // categoriesFilterVar([...categoriesFilter, parseInt(event.target.name)])
      categoriesFilterVar([parseInt(event.target.name)])
    } else if (
      event.target.checked === false &&
      categoriesFilter.includes(categoryID)
    ) {
      const filteredCategories = categoriesFilter.filter(
        (ID) => ID !== parseInt(event.target.name),
      )
      categoriesFilterVar([...filteredCategories])
    }

    // setChecked(event.target.checked);
  }

  const prefix = ' - '
  const fullPrefix = prefix.repeat(category.depth)

  return (
    <>
      {data &&
        data.findManyProductContainerCount > 0 &&
        data.findUniqueCategory.category_profiles[categoryProfileIndex] && (
          <FormControlLabel
            key={data.findUniqueCategory.id}
            control={
              <Checkbox
                checked={categoriesFilter.includes(data.findUniqueCategory.id)}
                onChange={handleCategoryChange}
                name={`${data.findUniqueCategory.id}`}
              />
            }
            label={`${fullPrefix} ${data.findUniqueCategory.category_profiles[categoryProfileIndex].name} (${data.findManyProductContainerCount})`}
          />
        )}
    </>
  )
}
