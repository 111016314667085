import {
  Card,
  CardMedia,
  Container,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      sliderContainer: {
        position: 'relative',
        // height: '500px',
        maxHeight: '600px',
        // overflow: 'hidden',
        // paddingTop: theme.spacing(6),
        // paddingBottom: theme.spacing(6),
        // marginTop: theme.spacing(5),
        // [theme.breakpoints.down('md')]: {
        //   marginTop: theme.spacing(1),
        //   padding: theme.spacing(0, 0, 0, 2),
        // },

        '&::after': {
          content: '""',
          width: '100%',
          height: '15%',
          position: 'absolute',
          right: '0',
          bottom: '0',
          background:
            'linear-gradient(180deg, transparent 0%, rgba(247,249,250,1) 100%)',
          zIndex: 2,
        },
      },

      sliderContainerEnd: {
        '&::after': {
          display: 'none',
        },
      },

      card: {
        // display: 'inline',
        position: 'relative',
        opacity: 0.6,
      },

      cardSelected: {
        opacity: 1,
        border: '5px solid',
        borderColor: theme.palette.secondary.main,
      },

      nextArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        top: '-2rem',
        right: '0',
        left: 'auto',
        marginTop: '-20px',

        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        transform: 'rotate(90deg)',
        webkitTransform: 'rotate(90deg)',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
        },
      },
      prevArrow: {
        position: 'absolute',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        top: '-2rem',
        right: '3rem',
        left: 'auto',
        marginTop: '-20px',

        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        background: '#e7e9ea',
        transform: 'rotate(-90deg)',
        webkitTransform: 'rotate(-90deg)',

        '&::before': {
          fontSize: '1rem',
          fontWeight: 700,
          content: '"next"',
          fontFamily: 'swiper-icons',
          color: '#000000',
        },
      },

      fabricImage: {
        minHeight: '100px',
        minWidth: '100px',
      },
      fabricCodeBox: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: 'rgb(0 0 0 / 26%)',
        color: '#fff',
        textAlign: 'center',
      },
    }),
  { name: 'MuiFabricSliderComponent' },
)

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const FabricSlider = ({
  autoplay = false,
  className = null,
  items,
  selectedItem,
  setSelectedItem,
  ...rest
}: ProductSliderProps): JSX.Element => {
  const classes = useStyles() as any
  // const [currentSlide, setCurrentSlide] = useState(0)
  const [isLastSlide, setIsLastSlide] = useState(false)

  let duplicateItems = [...items] as any
  let sortedItems = [...duplicateItems].sort((a, b) => a.order - b.order)

  const groupedItems = [] as any
  for (const item of sortedItems) {
    let groupOfThee = [] as any
    for (let i = 0; i < 3; i++) {
      const currentNumber = groupedItems.length * 3 + i
      if (currentNumber < sortedItems.length) {
        groupOfThee.push(sortedItems[currentNumber])
      }
    }
    if (groupOfThee.length > 0) {
      groupedItems.push(groupOfThee)
    }
  }

  const numberOfSlides = groupedItems.length > 4 ? 4 : groupedItems.length

  useEffect(() => {
    if (groupedItems.length === numberOfSlides) {
      setIsLastSlide(true)
    }
  })

  const settings = {
    dots: false,
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: numberOfSlides,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,
    beforeChange: (current, next) => {
      if (next === groupedItems.length - numberOfSlides) {
        setIsLastSlide(true)
      } else {
        setIsLastSlide(false)
      }
    },
  }

  // console.log('groupedItems :>> ', groupedItems)

  return (
    <Container
      maxWidth="xs"
      className={clsx(
        isLastSlide && classes.sliderContainerEnd,
        classes.sliderContainer,
      )}
    >
      <Slider key={items.length} {...settings}>
        {groupedItems.map((groupOfThree, i) => {
          return (
            <SwiperSlide
              key={i}
              tag="li"
              style={{ padding: '10px', height: 'auto' }}
            >
              <Grid container justifyContent="flex-start" spacing={1}>
                {groupOfThree.map((item, i) => {
                  let selected = false
                  if (selectedItem && selectedItem.id === item.id) {
                    selected = true
                  }
                  return (
                    <Grid key={i} item lg={4}>
                      <ItemCard
                        item={item}
                        classes={classes}
                        selected={selected}
                        onItemChange={setSelectedItem}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </SwiperSlide>
          )
        })}
      </Slider>
    </Container>
  )
}

// const ItemCard = ({ item, classes, selected, onItemChange }) => {
//   // ----
//   const { locale } = useRouter()
//   const handleChange = (e) => {
//     // Trying to change selected item
//     onItemChange(item)
//   }

//   const fabricImage = item?.image?.src_md ?? item?.image?.src

//   return (
//     <Card
//       elevation={0}
//       square={true}
//       className={clsx(selected && classes.cardSelected, classes.card)}
//       onClick={(e) => handleChange(e)}
//     >
//       <CardMedia
//         component="img"
//         image={fabricImage}
//         alt={item.code}
//         className={classes.fabricImage}
//       />
//     </Card>
//   )
// }

const ItemCard = ({ item, classes, selected, onItemChange }) => {
  // ----
  // const { locale } = useRouter()
  const handleChange = (e) => {
    // Trying to change selected item
    onItemChange(item)
  }

  const { t, i18n } = useTranslation(['common'])
  const fabricCodeText = t('fabric-code-text')
  // const fabricCodeText = 'Fabric code'

  const fabricImage = item?.image?.src_md ?? item?.image?.src

  return (
    <Card
      elevation={0}
      square={true}
      className={clsx(selected && classes.cardSelected, classes.card)}
      onClick={(e) => handleChange(e)}
    >
      <CardMedia
        component="img"
        image={fabricImage}
        alt={item.code}
        className={classes.fabricImage}
      />
      <div className={classes.fabricCodeBox}>
        <Typography variant="caption">
          {`${fabricCodeText} `}
          <strong>{item.code}</strong>
        </Typography>
      </div>
    </Card>
  )
}

const SampleNextArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.nextArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick, classes } = props
  return (
    <div
      className={clsx(className, classes.prevArrow)}
      style={{ ...style }}
      onClick={onClick}
    />
  )
}

export default FabricSlider
