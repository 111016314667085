import { gql, useQuery } from '@apollo/client'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import DynamicCategoryProductCard from '../CategoryPageElements/DynamicCategoryProductCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      h1: {
        padding: theme.spacing(5, 0),
      },
      divider: {
        marginBottom: theme.spacing(4),
      },
      gridContainer: {
        marginTop: theme.spacing(4),
      },
      loadMoreBtn: {
        marginTop: theme.spacing(6),
        width: '10rem',
      },
    }),
  { name: 'MuiSearchProductListComponent' },
)

//TODO: Fix this query: checkout the one in category product list to use the same pagination
const GET_SEARCH_PRODUCTS = gql`
  query GET_SEARCH_PRODUCTS($searchQuery: String, $language: Language) {
    findManyProductContainer(
      where: {
        visible: { equals: true }
        single_product: {
          product_profiles: {
            some: {
              AND: [
                { name: { mode: insensitive, contains: $searchQuery } }
                { language: { equals: $language } }
              ]
            }
          }
        }
      }
    ) {
      id
    }
  }
`

export default function SearchProductList({ searchQuery }) {
  const classes = useStyles() as any
  const take = 20
  const { data, loading, error, fetchMore } = useQuery(GET_SEARCH_PRODUCTS, {
    variables: {
      searchQuery,
    },
  })

  const { t } = useTranslation(['common'])
  const loadMoreText = t('load-more')

  const [lastProductID, setLastProductID] = useState(null)

  // useEffect(() => {
  //   // console.log("useEffect triggered...");
  //   data?.findManyProductContainers.forEach((product, index) => {
  //     if (index === data?.findManyProductContainers.length - 1) {
  //       setLastProductID(product.id);
  //     } else {
  //       return;
  //     }
  //   });
  //   return;
  // }, [data]);

  if (loading) return <CircularProgress />

  if (data?.findManyProductContainer.length === 0)
    return (
      <Typography
        variant="h5"
        component="p"
        align="center"
        className={classes.h1}
      >
        {`Sorry, there are no products matching '${searchQuery}'`}
      </Typography>
    )
  if (error)
    return (
      <Typography variant="h5" component="p">
        Something went wrong...
      </Typography>
    )

  return (
    <>
      <Typography
        component="h1"
        variant="h3"
        align="center"
        gutterBottom
        className={classes.h1}
      >{`Search result for '${searchQuery}'`}</Typography>
      <Divider className={classes.divider} />

      {/* <FilterComponent productsCount={data?.productContainers.length} /> */}

      <Grid container spacing={2} className={classes.gridContainer}>
        {data?.findManyProductContainer.map((product) => (
          <Grid item xs={6} sm={6} md={4} lg={4} xl={3} key={product?.id}>
            {/* <CategoryProductCard productContainerID={product?.id} /> */}
            <DynamicCategoryProductCard productContainerID={product?.id} />
          </Grid>
        ))}
      </Grid>
      {/* //TODO: ADD CHECK IF HAS NEXT PAGE */}
      <Button
        variant="contained"
        onClick={() => {
          fetchMore({
            variables: {
              take,
              skip: 1,
              ID: lastProductID,
            },
          })
        }}
        className={classes.loadMoreBtn}
      >
        {loadMoreText}
      </Button>
    </>
  )
}
