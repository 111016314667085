import { useQuery, useReactiveVar } from '@apollo/client'
import { CardMedia, Divider, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { GET_PRODUCT_IN_CART } from '../../../operations/queries/headerQueries'
import { cartItemsVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { parseSofaCombination } from '../../../utilityFunctions/parseSofaCombination'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) => ({
    productBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
      margin: theme.spacing(2, 0),
    },
    media: {
      height: '4rem',
      width: '2.5rem',
      backgroundSize: 'contain',
      margin: '0 auto',
      alignSelf: 'center',
    },
    pricesContainer: {},
    discountedPrice: {
      textDecoration: 'line-through',
    },
  }),
  { name: 'MuiProductSideBoxItemComponent' },
)

export default function ProductSideBoxItem({ cartItem, mobile = false }) {
  if (cartItem.product_type === 'SIMPLE_PRODUCT') {
    return (
      <ProductSideBoxItemSingleProduct cartItem={cartItem} mobile={mobile} />
    )
  } else if (cartItem.product_type === 'ADVANCED_PRODUCT') {
    return (
      <ProductSideBoxItemAdvancedProduct cartItem={cartItem} mobile={mobile} />
    )
  } else {
    // unhandlend entry
    return <p>Unhandled product type</p>
  }
}

const ProductSideBoxItemSingleProduct = ({ cartItem, mobile = false }) => {
  const classes = useStyles() as any
  // Since this component won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)
  //TODO: use these to should the discounted price state variables
  // const [discountedPrice, setDiscountedPrice] = useState(null)
  // const [discounted, setDiscounted] = useState(false)
  //* =================================================================================
  //* ==================  query to get latest cartItems info ======================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_CART, {
    variables: {
      itemID: cartItem.id,
    },
    //On completed we will have the data and we want to update
    //all the static data in the reactive var and localstorage
    onCompleted: (data) => {
      // localDataUpdate(
      //   data,
      //   cartItem,
      //   cartItems,
      //   cartItemsVar,
      //   localStorageKeys.cartItems,
      //   setDiscounted,
      //   setDiscountedPrice,
      //   locale,
      // )
    },
    onError: (error) => {
      console.log(
        "Something went wrong.. We couldn't fetch the checkout sidebox item",
      )
    },
  })

  const discounted = cartItem?.discounted
  const discountedPrice = cartItem?.discountedPrice

  //TODO: make better UI for loading state
  if (loading) return <Skeleton height="10rem" component="span" width="100%" />

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer.single_product?.product_profiles,
    locale,
  )

  //* ============================================================
  //* Here we know if the profile exists or not, if it does
  //* this component can be displayed in the current locale
  //* else this item will be removed from client data by the
  //* above useEffect
  //* =============================================================
  if (productProfileIndex !== null && productProfileIndex !== undefined) {
    const id = data.findUniqueProductContainer.id
    const image = data.findUniqueProductContainer.single_product.images[0].src
    const name =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .name
    const price = data.findUniqueProductContainer.single_product.price

    return (
      <React.Fragment key={id}>
        {mobile && <Divider />}
        <div className={classes.productBox}>
          <CardMedia image={image} className={classes.media} />
          {/* <Image
                  src={image}
                  objectFit="contain"
                  priority
                  alt=""
                  layout="intrinsic"
                  width={35}
                  height={64}
                /> */}
          <div>
            <Typography variant="body2" component="h3" gutterBottom>
              {name}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {`Qty: ${cartItem.quantity}`}
            </Typography>
          </div>

          <div className={classes.pricesContainer}>
            <Typography
              align="right"
              variant="body1"
              gutterBottom
              className={clsx(discounted && classes.discountedPrice)}
            >
              <ProductPrice price={price} />
            </Typography>
            <Typography variant="body2" align="right">
              {discounted && <ProductPrice price={discountedPrice} />}
            </Typography>
          </div>
        </div>
        {!mobile && <Divider />}
      </React.Fragment>
    )
  } else {
    return null
  }
}

const ProductSideBoxItemAdvancedProduct = ({ cartItem, mobile = false }) => {
  const classes = useStyles() as any
  // Since this component won't be statically built
  //I can use useRouter() to get the locale
  const { locale } = useRouter()

  // === UNHANDLED when swiching between lanugages (best to reset cart)
  // We allow to increment
  const followProductStock = true
  // Maximum number to increment
  const productQuantity = 1000

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const dispatchInformation = cartItem.dispatch_information

  //--- ADVANCED SOFA DATA -----
  // const selectedFabric = cartItem.advanced_product_data.selected_fabric
  // // We neeed to JSON Parse all object in array, because incoming data comes from local storage Stringified...
  // const selectedSofaCombinations = parseSofaCombination(
  //   JSON.parse(cartItem.advanced_product_data.selected_sofa_combinations),
  // )

  return (
    <React.Fragment key={id}>
      {mobile && <Divider />}
      <div className={classes.productBox}>
        <CardMedia image={image} className={classes.media} />

        <div>
          <Typography variant="body2" component="h3" gutterBottom>
            {name}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {`Qty: ${cartItem.quantity}`}
          </Typography>
        </div>

        <div className={classes.pricesContainer}>
          <Typography
            align="right"
            variant="body1"
            gutterBottom
            className={clsx(discounted && classes.discountedPrice)}
          >
            <ProductPrice price={price} />
          </Typography>
          <Typography variant="body2" align="right">
            {discounted && <ProductPrice price={discountedPrice} />}
          </Typography>
        </div>
      </div>
      {!mobile && <Divider />}
    </React.Fragment>
  )
}
