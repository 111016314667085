import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import {
  Alert,
  Button,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { Skeleton } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useShopContext } from '../../../context'
import {
  GET_PRODUCT_DISPATCH_INFO,
  GET_SHOPSETTINGS_SHIPPING_DETAILS,
} from '../../../operations/queries'
import { CartItem, OrderItem, OrderItemStatus } from '../../../store/models'
import { orderVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  calculateShippingMethodPrice,
  priceFormatter,
} from '../../../utilityFunctions/priceUtils'
import { convertDaysToWeeks } from '../Utils/DeliveryInformationBox'
import { removeKey } from '../../../utilityFunctions/removeKey'

const useStyles = makeStyles(
  (theme: Theme) => ({
    estimatedDelivery: {
      display: 'block',
      margin: theme.spacing(3, 0),
      padding: theme.spacing(1),
      backgroundColor: '#FFF9D4',
      width: '100%',
    },
    dispatchSkeleton: {
      display: 'block',
      margin: theme.spacing(2, 0),
      backgroundColor: '#FFF9D4',
      width: '100%',
    },
    deliveryOptionContainer: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(2),
      // height: "8rem",
    },
    deliveryOptionSkeleton: {
      marginBottom: theme.spacing(2),
      backgroundColor: '#f6f6f4',
    },
    productBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 4fr',
      margin: '2rem 0',
    },
    media: {
      height: '4rem',
      minWidth: '2.5rem',
      backgroundSize: 'contain',
      margin: '0 auto',
    },
    formControlBox: {
      backgroundColor: '#f6f6f4',
      width: '100%',
      display: 'flex',
      gap: theme.spacing(3),
      padding: theme.spacing(2, 1),
      margin: 0,
    },
    button: {
      width: '40%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
    },
    error: {
      color: '#d9534f',
    },
  }),
  { name: 'MuiShippingFormComponent' },
)

interface ShippingForm {
  cartItem: CartItem
  handleNext: any
  activeCartItemState: any
  isLastCartItem: boolean
  cartItemPosition: number
  numberOfItems: number
}

export default function ShippingForm({
  cartItem,
  handleNext,
  activeCartItemState,
  isLastCartItem,
  cartItemPosition,
  numberOfItems,
}: ShippingForm): JSX.Element {
  //-----
  const { t } = useTranslation(['common'])
  const formTwoHeader = t('form-two-header')
  const estimatedDispatchString = t('estimated-dispatch')
  const errorLoadingShippingMethodsString = t(
    'error-message-load-shipping-methods',
  )
  const chooseOneOptionString = t('please-choose-option')
  const nextStepButtonString = t('next-step-button')
  const itemQtyString = t('item-quantity-shipping-form')

  const deliveryAlertText = t(
    'delivery-does-not-include-transportation-in-house',
  )

  const classes = useStyles() as any
  const { locale } = useRouter()
  const { SHOP_ID } = useShopContext()
  //this state will hold the data for the dispath information
  //and will be populated after checking for existence of data on three layers
  //ProductContainer, ProductContainer.manufacturer and ShopSettings.
  const [dispatchData, setDispatchData] = useState(null)
  //this state will hold data for shipping methods
  //it will be populated after checking for existence of data on two layers
  //ProductContainer, ProductContainer.Categories and ShopSettings.
  //TODO: IMPORTANT!! ask airidas if we should always show the default shop shipping method (free shipping method for example) or if it will be automatically connected to all products?
  const [shippingMethods, setShippingMethods] = useState(null)
  //* =====================================================================================
  //* get the product's shipping methods, packing information etc. from the database
  //*  we have them in the cartItem, but we want the latest in case of admin changes.
  //* =====================================================================================

  // console.log('cartItem :>> ', cartItem)

  const {
    data: productContainerData,
    loading: productContainerLoading,
    error: productContainerError,
    called: productContainerCalled,
  } = useQuery(GET_PRODUCT_DISPATCH_INFO, {
    variables: {
      cartItemID: cartItem.product_container_id,
    },
    fetchPolicy: 'network-only',
  })
  //* ===================================================================
  //* get the default shop shipping methods and dispatch information
  //* to use as fallback in case product container doesn't have that data
  //* ===================================================================
  const [
    fetchShopSettings,
    {
      data: shopSettingsData,
      loading: shopSettingsLoading,
      error: shopSettingsError,
      called: shopSettingsCalled,
    },
  ] = useLazyQuery(GET_SHOPSETTINGS_SHIPPING_DETAILS, {
    variables: {
      SHOP_ID,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // We have to check if the state vars are null before setting them
      // in case only one of the two is the cause of the triggering of this lazyQuery
      // (for example the productContainer had dispatchData, but not shippingMethods
      // we only want to set shippingMethods, not both)
      if (data.findFirstShopSetting.default_dispatch_information) {
      }
      !dispatchData &&
        setDispatchData(data.findFirstShopSetting.default_dispatch_information)
      !shippingMethods &&
        setShippingMethods([data.findFirstShopSetting.default_shipping_method])
    },
  })
  //* ================
  //* React-hook-form
  //* ================
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm()
  const order = useReactiveVar(orderVar)
  const { activeCartItem, setActiveCartItem } = activeCartItemState

  // Here we want to make sure that if this is the first item the user
  // is choosing shipping for, we set the total_shipping_price to 0
  // in case he went all the way to the end to payment form and came back
  useEffect(() => {
    if (activeCartItem === 0) {
      orderVar({
        ...order,
        total_shipping_price: null,
      })
    }

    return
  }, [])

  //* ====================================================================================
  //* ============================== HANDLE FALLBACKS ====================================
  //* ====================================================================================
  useEffect(() => {
    // We check if loading is false, so we know we have the needed data
    if (!productContainerLoading && productContainerData) {
      // =============================================================
      // =============== DISPATCH INFO SWITCH ========================
      // =============================================================
      //This switch handles the case where product container's dispatch info to null
      // - If it's null, we check if the ProductCotnaienr.manufacturer holds the needed data
      //   and if it doesn't, we set the dispatchData state to the settings (which should always be available)
      // - If it's not default we simply jump to the default case
      switch (
        productContainerData.findUniqueProductContainer.dispatch_information
      ) {
        case null:
          if (
            productContainerData.findUniqueProductContainer.manufacturer
              .default_dispatch_information
          ) {
            setDispatchData(
              productContainerData.findUniqueProductContainer.manufacturer
                .default_dispatch_information,
            )
          } else {
            //checking if the lazyQuery was already called, makes us avoid calling it twice
            //in the case where the product doesn't have both dispatch info and shipping methods.
            shopSettingsCalled && !shopSettingsLoading
              ? setDispatchData(
                  shopSettingsData.findFirstShopSetting
                    .default_dispatch_information,
                )
              : fetchShopSettings()
          }
          break
        default:
          setDispatchData(
            productContainerData.findUniqueProductContainer
              .dispatch_information,
          )
          break
      }

      // =============================================================
      // ================== SHIPPING_METHODS SWITCH ==================
      // =============================================================
      // - If the length of shipping_methods on productContainer is 0,
      //   the we fallback to the shop settings default shipping methods
      // - else we the switch will run default case.
      // switch (
      //   (productContainerData?.findUniqueProductContainer?.single_product
      //     ?.shipping_methods?.length === 0 ||
      //     productContainerData?.findUniqueProductContainer?.advanced_product
      //       ?.shipping_methods?.length === 0) ??
      //   0
      // ) {
      //   case 0:
      //     let shippingMethodsWithDuplicates = []
      //     // loop through the categories with filter method with the condition that
      //     // the category. is different from any other category.id in the categories array
      //     productContainerData.findUniqueProductContainer.categories.forEach(
      //       (category) => {
      //         if (category.shipping_method) {
      //           shippingMethodsWithDuplicates.push(category.shipping_method)
      //         } else {
      //           return
      //         }
      //       },
      //     )

      //     //If this condition is met, it means that the loop above found
      //     //some shipping methods in the categories, so we can make sure it has no duplicates
      //     //and push its value to shippingMethods state variable
      //     if (shippingMethodsWithDuplicates.length !== 0) {
      //       let filteredShippingMethods = shippingMethodsWithDuplicates
      //         .filter(
      //           (shippingMethod, index, self) =>
      //             index ===
      //             self.findIndex(
      //               (selfItem) => selfItem.id === shippingMethod.id,
      //             ),
      //         )
      //         .sort((a, b) => a.price_for_box - b.price_for_box)

      //       setShippingMethods([...filteredShippingMethods])
      //     } else {
      //       //checking if the lazyQuery was already called, makes us avoid calling it twice
      //       //in the case where the product doesn't have both dispatch info and shipping methods.
      //       shopSettingsCalled && !shopSettingsLoading
      //         ? setShippingMethods([
      //             ...shopSettingsData.findFirstShopSetting.shipping_methods,
      //           ])
      //         : fetchShopSettings()
      //     }
      //     break
      //   default:
      //     if (productContainerData?.findUniqueProductContainer?.single_product) {
      //       // console.log('Setting shipping methods for single product')
      //       setShippingMethods(
      //         productContainerData.findUniqueProductContainer.single_product.shipping_methods.sort(
      //           (a, b) => a.price_for_box - b.price_for_box,
      //         ),
      //       )
      //     } else if (productContainerData?.findUniqueProductContainer?.advanced_product) {
      //       // console.log('Setting shipping methods for advanced product')
      //       setShippingMethods(
      //         productContainerData.findUniqueProductContainer.advanced_product.shipping_methods.sort(
      //           (a, b) => a.price_for_box - b.price_for_box,
      //         ),
      //       )
      //     } else {
      //       //---
      //       console.log(
      //         'Hitting last else (In shippingForm.tsx -- unhandled case ADVANCED_PRODUCT_VARIANT)...',
      //       )
      //     }
      // }

      if (
        productContainerData?.findUniqueProductContainer?.single_product
          ?.shipping_methods?.length === 0 ||
        productContainerData?.findUniqueProductContainer?.advanced_product
          ?.shipping_methods?.length === 0
      ) {
        let shippingMethodsWithDuplicates = []
        // loop through the categories with filter method with the condition that
        // the category. is different from any other category.id in the categories array
        productContainerData.findUniqueProductContainer.categories.forEach(
          (category) => {
            if (category.shipping_method) {
              shippingMethodsWithDuplicates.push(category.shipping_method)
            } else {
              return
            }
          },
        )

        //If this condition is met, it means that the loop above found
        //some shipping methods in the categories, so we can make sure it has no duplicates
        //and push its value to shippingMethods state variable
        if (shippingMethodsWithDuplicates.length !== 0) {
          let filteredShippingMethods = shippingMethodsWithDuplicates
            .filter(
              (shippingMethod, index, self) =>
                index ===
                self.findIndex((selfItem) => selfItem.id === shippingMethod.id),
            )
            .sort((a, b) => a.price_for_box - b.price_for_box)

          setShippingMethods([...filteredShippingMethods])
        } else {
          //checking if the lazyQuery was already called, makes us avoid calling it twice
          //in the case where the product doesn't have both dispatch info and shipping methods.
          shopSettingsCalled && !shopSettingsLoading
            ? setShippingMethods([
                ...shopSettingsData.findFirstShopSetting.shipping_methods,
              ])
            : fetchShopSettings()
        }
      } else {
        if (productContainerData?.findUniqueProductContainer?.single_product) {
          // console.log('Setting shipping methods for single product')
          setShippingMethods(
            productContainerData.findUniqueProductContainer.single_product.shipping_methods.sort(
              (a, b) => a.price_for_box - b.price_for_box,
            ),
          )
        } else if (
          productContainerData?.findUniqueProductContainer?.advanced_product
        ) {
          // console.log('Setting shipping methods for advanced product')
          setShippingMethods(
            productContainerData.findUniqueProductContainer.advanced_product.shipping_methods.sort(
              (a, b) => a.price_for_box - b.price_for_box,
            ),
          )
        } else {
          //---
          console.log(
            'Hitting last else (In shippingForm.tsx -- unhandled case ADVANCED_PRODUCT_VARIANT)...',
          )
        }
      }
    }

    return
  }, [productContainerLoading])

  //* ==============================================================================================================================
  //* ================================================ HANDLE SUBMIT ===============================================================
  //* ==============================================================================================================================
  const onSubmit = (formData) => {
    // Radio only stores a string value, I need to parse it to an integer here
    const shipping_methodID = parseInt(
      formData[`shipping_method:${cartItem.id}`],
    )
    formData[`shipping_method:${cartItem.id}`] = shipping_methodID

    // create a copy of the order_items array contained in the order reactive Var
    // this is would usually spread an empty array, unless the user is coming back before completing order
    let order_items = [...order.order_items]

    // Check if order_items already has this item ID. If yes, remove it
    // so that we can add a new object in case the user went back and changed shipping method
    if (order_items.length > 0) {
      order_items = order_items.filter(
        (order_item) => order_item.product_container.id !== cartItem.id,
      )
    }

    // use momentjs to get the current date and time and add the total_delivery_time(weeks or days??????) from the query
    let expected_delivery_date = dispatchData
      ? moment().add(dispatchData.total_delivery_time, 'days').format()
      : null

    //We need more data than what react-hook-form has, so we need to take that data
    //from the list of shipping methods in the state varible set after all the fallback checking
    // We have the possible shipping methods the user chose from in the state variabale
    // filter through them and find the one that has the same ID as shipping_methodID
    // use then the calculateShippingMethodPrice() utility function
    const selectedShippingMethod = shippingMethods.find(
      (shippingMethod) => shippingMethod.id === shipping_methodID,
    )

    const shipping_price = calculateShippingMethodPrice(
      selectedShippingMethod.price_for_box,
      productContainerData.findUniqueProductContainer?.single_product
        ?.packing_information_list ?? [],
      cartItem.quantity,
    )

    // ==== We need to remove __typename properties from within order_itemConstructor
    // TODO make a normal type for this object (so we could remove TS ignores..)
    // We get them with advanced_product_data
    // Remove __typename keys from the query.
    // (The object below is made, so it is accepted by a custom type in the backend).
    //-------------------------------------------------
    let advancedData = cartItem.advanced_product_data as any
    //-------------------------------------------------

    // const modifiedAdditionalComponents = advancedData.selected_additional_components
    //   ? removeKey(
    //       removeKey(advancedData.selected_additional_components, '__typename'),
    //       'additional_component_profiles',
    //     )
    //   : null

    const modifiedAdditionalComponents =
      advancedData?.selected_additional_components.map(
        (additionalComponent) => {
          return {
            id: additionalComponent.id,
            // additionalComponentGroupId:
            //   additionalComponent.additionalComponentGroupId,
            // color: additionalComponent.color,
            // enabled: additionalComponent.enabled,
            // component_sku: additionalComponent.component_sku,
            // extra_price: additionalComponent.extra_price,
            // image: additionalComponent.image,
          }
        },
      ) ?? null

    console.log('advancedData :>> ', advancedData)

    const combinationFabrics = advancedData?.selected_fabric?.combinationFabrics
    //----
    let modifiedAdvancedProductData = {
      advanced_product_type: advancedData?.advanced_product_type,
      fabric: advancedData?.selected_fabric?.fabricObject?.id
        ? { id: advancedData.selected_fabric.fabricObject.id }
        : null,
      fabric_code: advancedData?.selected_fabric?.fabricObject?.code ?? null,
      fabric_group: advancedData?.selected_fabric?.fabricGroupObject?.id
        ? {
            id: advancedData.selected_fabric.fabricGroupObject.id,
          }
        : null,
      selected_sofa_combinations:
        advancedData?.selected_sofa_combinations ?? null,

      additional_components: modifiedAdditionalComponents,
      fabricCombination: combinationFabrics
        ? {
            id: advancedData.selected_fabric_combination.id,
            fabrics: Object.keys(combinationFabrics).map((key) => ({
              fabricId: combinationFabrics[key]?.fabricObject?.id,
              fabric_groupId: combinationFabrics[key]?.fabricGroupObject?.id,
              optionId: Number(key),
            })),
          }
        : null,
    }

    // console.log('modifiedAdvancedProductData :>> ', modifiedAdvancedProductData)

    // Construct an object shaped like the mutation variable and push it to the order_items
    // the object is now defined in the src/store/models file as OrderItem
    const order_itemConstructor: OrderItem = {
      id: cartItem.id,
      // price: cartItem.discountedPrice ?? cartItem.price,
      price: cartItem.discountedPrice ?? cartItem.price,
      shipping_price,
      quantity: cartItem.quantity,
      expected_delivery_date,
      shipping_method: {
        id: shipping_methodID,
      },
      status: OrderItemStatus.AWAITING_PAYMENT,
      sku: cartItem.sku,
      product_container: {
        id: cartItem.product_container_id,
      },

      //--- Addding advanced product items
      product_type: cartItem.product_type,
      advanced_product_data: modifiedAdvancedProductData,
    }

    //Once we reconstructed the item we filtered out before
    //We can push it to the shallow copy of the order.order_items

    const order_itemsIds = order_items.map((item) => item.id)

    if (order_itemsIds.indexOf(order_itemConstructor.id) > -1) {
      // We have this item already
    } else {
      // -- push
      order_items.push(order_itemConstructor)
    }

    //We pass order_items array we created previously
    //Since this form will always start from the item with index[0]
    //And every time this component renders we check if item is the first one
    //(so that we can reset all the values to the initial values)
    //order.total_shipping_price should always initialize to null
    //so it's ok to sum it, since null can be summed as 0
    orderVar({
      ...order,
      order_items,
      total_shipping_price: order.total_shipping_price + shipping_price,
    })

    // handle the two cases when you click next (either go to next product or go to next step)
    if (isLastCartItem) {
      handleNext()
    } else {
      setActiveCartItem((prev) => prev + 1)
    }
  }

  const string1 = t('date-string-1')
  const string2 = t('date-string-2')

  const deliveryTimeString = dispatchData
    ? convertDaysToWeeks(
        dispatchData.total_delivery_time,
        locale,
        string1,
        string2,
      )
    : ''

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5" gutterBottom>
          {
            //TODO: let the user know how many selections has to go through eg. Delivery Options(1 of 3)
            `${formTwoHeader} (${cartItemPosition} of ${numberOfItems})`
          }
        </Typography>
        <Divider />
        <div className={classes.productBox}>
          <CardMedia image={cartItem.image} className={classes.media} />
          <div>
            <Typography variant="body2" component="h3" gutterBottom>
              {cartItem.name}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {`${itemQtyString} ${cartItem.quantity}`}
            </Typography>
          </div>
        </div>
        {/* //* if there is any error in the query, we still want to show the product, but show an error message */}
        {productContainerError && (
          <Typography variant="h6" color="error">
            {errorLoadingShippingMethodsString}
          </Typography>
        )}
        {!dispatchData ? (
          <Skeleton height={50} className={classes.dispatchSkeleton} />
        ) : (
          <Typography variant="caption" className={classes.estimatedDelivery}>
            {`${estimatedDispatchString} ${deliveryTimeString}`}
          </Typography>
        )}
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <Controller
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <RadioGroup aria-label="shipping method">
                {shippingMethods ? (
                  shippingMethods.map((shippingMethod) => {
                    //if the shipping method doesn't have a profile matching
                    //the current locale, we don't want to show it
                    const shippingMethodProfileIndex: number =
                      getProfileIndexFromLanguage(
                        shippingMethod.shipping_method_profiles,
                        locale,
                      )

                    return (
                      <div
                        className={classes.deliveryOptionContainer}
                        key={shippingMethod.id}
                      >
                        <FormControlLabel
                          className={classes.formControlBox}
                          label={
                            <>
                              <Typography variant="body2">
                                {
                                  shippingMethod.shipping_method_profiles[
                                    shippingMethodProfileIndex
                                  ].title
                                }
                              </Typography>
                              <Typography variant="body2" display="block">
                                {priceFormatter(
                                  calculateShippingMethodPrice(
                                    shippingMethod.price_for_box,
                                    productContainerData
                                      .findUniqueProductContainer
                                      ?.single_product
                                      ?.packing_information_list ?? [],
                                    cartItem.quantity,
                                  ),
                                )}
                              </Typography>
                            </>
                          }
                          control={
                            <Radio
                              inputRef={ref}
                              value={shippingMethod.id.toString()}
                              onChange={onChange}
                            />
                          }
                        />
                      </div>
                    )
                  })
                ) : (
                  <Skeleton
                    height={90}
                    variant="rectangular"
                    className={classes.deliveryOptionSkeleton}
                  />
                )}
              </RadioGroup>
            )}
            // This will make the name unique so that useForm will store a different instance for each item
            name={`shipping_method:${cartItem.id}`}
            defaultValue=""
            control={control}
            rules={{ required: chooseOneOptionString }}
          />
          {errors[`shipping_method:${cartItem.id}`] && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors[`shipping_method:${cartItem.id}`].message}
            </Typography>
          )}
        </FormControl>
        <Button
          variant="contained"
          disabled={
            productContainerError !== null &&
            productContainerError !== undefined
          }
          type="submit"
          className={classes.button}
        >
          {nextStepButtonString}
        </Button>
        {/* // TODO ----- Remove or move to settings eventually (uznesimas nera */}
        <Alert sx={{ marginTop: 2 }} severity="info">
          {deliveryAlertText}
        </Alert>
      </form>
    </>
  )
}

//* ============================================================
//* ======================== FRAGMENTS =========================
//* ============================================================
ShippingForm.fragments = {
  shippingDetails: gql`
    fragment ShippingDetailsFragment on ShopSettings {
      default_dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
      shipping_methods {
        id
        shipping_method_profiles {
          id
          title
          language
        }
        is_enabled
        price_for_box
      }
    }
  `,
}
