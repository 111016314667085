import { ReactiveVar } from '@apollo/client'
import { removeFromCart } from '../operations/clientMutations/cartClient'
import { removeFromRecentlyViewed } from '../operations/clientMutations/recentlyViewedClient'
import { removeFromWishlist } from '../operations/clientMutations/wishlistClient'
import { CartItem, RecentlyViewedItem, WishlistItem } from '../store/models'
import { checkDiscountValidity } from './discountValidityCheck'
import { getProfileIndexFromLanguage } from './getProfile'

//* product_type enum
export enum localStorageKeys {
  cartItems = 'cartItems',
  wishlistItems = 'wishlistItems',
  recentlyViewed = 'recentlyViewed',
}

type ItemType = CartItem | WishlistItem | RecentlyViewedItem

//typeScript doesn't know which type we might get here
//so we narrow it for him with a typeguard that checks if the
//passed value has a quantity property
//if it does it's a cartItem for sure, else is either wishlist item
//or recently viewed (they have the same fields)
//source (https://medium.com/ovrsea/checking-the-type-of-an-object-in-typescript-the-type-guards-24d98d9119b0)
function determineItem(toBeDetermined: ItemType): toBeDetermined is CartItem {
  if ((toBeDetermined as CartItem).quantity) {
    return true
  }
  return false
}

// ! DEPRECATED, bad logic, we will need to replace this.
//* =======================================================
//* =======================================================
//This will fire once we have the data from the lazyQuery
//once completed I want to remove this cartItem from the
//cartItemsVar and put it back updated
export const localDataUpdate = (
  data,
  item: CartItem | WishlistItem | RecentlyViewedItem,
  reactiveVarValue: CartItem[] | WishlistItem[] | RecentlyViewedItem[],
  reactiveVar: ReactiveVar<CartItem[] | RecentlyViewedItem[] | WishlistItem[]>,
  localStorageKey: localStorageKeys,
  setDiscounted: React.SetStateAction<any>,
  setDiscountedPrice: React.SetStateAction<any>,
  locale: string,
) => {
  return null

  // //We need to find the correct profileIndex
  // const productProfileIndex: number = getProfileIndexFromLanguage(
  //   data.productContainer?.single_product.product_profiles,
  //   locale,
  // )

  // // This functions handles the case where the profile for this type was not found
  // // If the profileIndex is returned as null, it usually means that the user changed
  // // locale and if he previously had client data in localStorage that doesn't match the
  // // current locale, we want to delete that data to avoid any error.

  // // e.g.:
  // //       Locale is /en, you add a product to cart, switch to /lv and that product
  // //       does not have an lv profile, we want to remove that item from wishlist/cart/recently viewed
  // if (productProfileIndex === null || productProfileIndex === undefined) {
  //   removeFromRecentlyViewed(item.id)
  //   removeFromWishlist(item.id)
  //   removeFromCart(item.id)

  //   return
  // }

  // //find the index of this item in  the reactiveVar
  // //update the reactive var (array), by spreading old value using splice
  // //and an object of type CartItem which values should be from the data.productContainer
  // //reset localStorage in the same way
  // const copyOfReactiveVarValue = [...reactiveVarValue]
  // let indexOfItem = reactiveVarValue.findIndex(
  //   (reactiveVarItem) => reactiveVarItem.id === item.id,
  // )

  // //I check if the there is a discount on the server data
  // //if there is I set discounted and discountedPrice
  // //else I leave the false and null
  // const { discounted, discountedPrice } = checkDiscountValidity(
  //   data.findUniqueProductContainer.discount,
  //   data.findUniqueProductContainer.single_product.price,
  // )

  // setDiscounted(discounted)
  // setDiscountedPrice(discountedPrice)

  // //use the typeguard to define the shape of the new object to pass to the reactive var
  // if (determineItem(item)) {
  //   const newCartItem = {
  //     id: data.findUniqueProductContainer.id,
  //     name:
  //       data.findUniqueProductContainer.single_product?.product_profiles[
  //         productProfileIndex
  //       ]?.name,
  //     price: data.findUniqueProductContainer.single_product.price,
  //     sku: data.findUniqueProductContainer.single_product.sku,
  //     quantity: item.quantity,
  //     image: data.findUniqueProductContainer.single_product.images[0].src,
  //     permalink:
  //       data.findUniqueProductContainer.single_product?.product_profiles[
  //         productProfileIndex
  //       ].meta_information.permalink,
  //     product_type: data.findUniqueProductContainer.type,
  //     dispatchInformation: data.findUniqueProductContainer.dispatch_information,
  //     discounted,
  //     discountedPrice,
  //     product_container_id: data.findUniqueProductContainer.id,
  //   } as CartItem

  //   copyOfReactiveVarValue.splice(indexOfItem, 1, newCartItem)

  //   //I set the reactiveVar and the local storage to the updated reactiveVarValue
  //   reactiveVar(copyOfReactiveVarValue)
  //   localStorage.setItem(
  //     localStorageKey,
  //     JSON.stringify(copyOfReactiveVarValue),
  //   )
  // } else {
  //   const newItem = {
  //     id: data.findUniqueProductContainer.id,
  //     name:
  //       data.findUniqueProductContainer.single_product?.product_profiles[
  //         productProfileIndex
  //       ]?.name,
  //     price: data.findUniqueProductContainer.single_product.price,
  //     sku: data.findUniqueProductContainer.single_product.sku,
  //     image: data.findUniqueProductContainer.single_product.images[0].src,
  //     permalink:
  //       data.findUniqueProductContainer.single_product?.product_profiles[
  //         productProfileIndex
  //       ].meta_information.permalink,
  //     product_type: data.findUniqueProductContainer.type,
  //     dispatchInformation: data.findUniqueProductContainer.dispatch_information,
  //     discounted,
  //     discountedPrice,
  //     product_container_id: data.findUniqueProductContainer.id,
  //   } as WishlistItem | RecentlyViewedItem

  //   reactiveVarValue.splice(indexOfItem, 1, newItem)

  //   //I set the reactiveVar and the local storage to the updated reactiveVarValue
  //   reactiveVar(reactiveVarValue)
  //   localStorage.setItem(localStorageKey, JSON.stringify(reactiveVarValue))
  // }
}
