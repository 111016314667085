import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      row: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        marginBottom: theme.spacing(2),
        backgroundColor: '#ffffff',
        alignItems: 'center',
        // [theme.breakpoints.down('lg')]: {
        //   marginBottom: theme.spacing(4),
        // },
        //border: '1px solid #000', // Add this line
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
      rowContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      section: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: 940,
      },
      componentImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      imageContainer: {
        float: 'left',
        margin: '0 15px 0 0',
        width: '54px',
        height: '54px',
      },
      contentContainer: {
        display: 'flex',
        alignItems: 'center' /* Align children vertically */,
        justifyContent: 'center' /* Align children horizontally */,
        //height: '100%' /* Set height of the container */,
        //border: '1px solid #ccc' /* Optional: Add border for visualization */,
      },
    }),
  {
    name: 'MuiAdditionalComponentGroupWithDrawerComponent',
  },
)
