import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { SEARCH_DROPDOWN_PRODUCT_CARD } from '../../../operations/queries'
import { recentlyViewedVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        // width: "9rem",
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(2, 0),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      media: {
        height: '11rem',
        backgroundSize: 'contain',

        [theme.breakpoints.down('lg')]: {
          height: '13rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        // textAlign: "center",
      },
    }),
  { name: 'MuiSearchDropdownCardComponent' },
)

export default function RecentlyViewedSearchDropdownCard({
  productID,
  recentlyViewedItem,
  setState,
  withPrice = false,
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()
  const recentlyViewedItems = useReactiveVar(recentlyViewedVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)
  const { data, loading, error } = useQuery(SEARCH_DROPDOWN_PRODUCT_CARD, {
    variables: {
      productID,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //On completed we will have the data and we want to update
      //all the static data in the reactive var and localstorage
      localDataUpdate(
        data,
        recentlyViewedItem,
        recentlyViewedItems,
        recentlyViewedVar,
        localStorageKeys.recentlyViewed,
        setDiscounted,
        setDiscountedPrice,
        locale,
      )
    },
    onError: (error) => {
      console.log('There was a problem fetching this cart page item...')
    },
  })
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')

  if (loading) return <Skeleton height={100} />
  if (error)
    return <div>Something went wrong {console.log('error', error.message)}</div>

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer?.single_product?.product_profiles,
    locale,
  )

  if (
    data.findUniqueProductContainer?.single_product?.product_profiles[productProfileIndex]
  ) {
    const productPrice = data.findUniqueProductContainer.single_product.price
    const productImage = data.findUniqueProductContainer.single_product.images[0].src
    const productName =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .name
    const permalink =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .meta_information.permalink

    return (
      <Grid item xs={6} sm={4}>
        <Card
          elevation={0}
          square={true}
          className={classes.card}
          onClick={() => {
            setState(false)
          }}
        >
          <div className={classes.cardActionAreaContainer}>
            <CardActionArea>
              <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
                <CardMedia className={classes.media} image={productImage} />
              </Link>
            </CardActionArea>
          </div>
          <CardContent className={classes.cardContent}>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <span className="cursorPointer">
                <Typography
                  gutterBottom
                  variant="caption"
                  component="h2"
                  className={classes.productName}
                >
                  {productName}
                </Typography>
              </span>
            </Link>
            {withPrice && (
              <Typography variant="body2">
                <ProductPrice price={productPrice} />
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    )
  } else {
    return null
  }
}
