declare global {
  interface Window {
    dataLayer: any[]
  }
}

// Currently not used
export const gtmPageView = (url) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'pageview',
    skip: url,
  })
}

export const triggerCustomEvent = (eventName, eventPayload) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      ...eventPayload,
    })
  }
}
