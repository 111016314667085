import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  colors,
  useMediaQuery,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  Avatar,
  Typography,
  Grid,
  Divider,
} from '@mui/material'
import { Section, SectionAlternate, Image } from '../Utils'
import BlogPostCard from './BlogPostCard'
import moment from 'moment'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useBlogContext } from '../../../context/blogContext'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'

const useStyles = makeStyles(
  (theme) => ({
    pagePaddingTop: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionAlternate: {
      '& .section-alternate__content': {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(5),
          paddingBottom: theme.spacing(5),
        },
      },
    },
    cardProduct: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: theme.spacing(1),
      '& .card-product__content': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    summary: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 6,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    image: {
      objectFit: 'cover',
      borderRadius: theme.spacing(0, 0, 20, 0),
    },
    blogContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    leftFooterContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    button: {
      marginTop: theme.spacing(6),
      width: '10rem',
    },
    answerCount: {
      padding: theme.spacing(1 / 2, 1),
      borderRadius: theme.spacing(1),
      background: theme.palette.secondary.light,
      color: 'white',
      fontWeight: 300,
    },
    readMoreButton: {
      minWidth: '5rem',
      maxWidth: '7rem',
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'MuiResultComponent' },
)

const Result = ({
  className,
  blogPosts,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const blogSlug = t('blog-slug')
  const readMoreCtaButton = t('blog-post-read-more-cta-button')
  const classes = useStyles() as any
  // const { loading, error, data } = useQuery(GET_BLOG_POSTS_DATA);
  const data = blogPosts.data
  const { locale } = useBlogContext()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const isMdLg = useMediaQuery(theme.breakpoints.between('md', 'xl'), {
    defaultMatches: true,
  })

  const isMdSm = useMediaQuery(theme.breakpoints.between(800, 'lg'), {
    defaultMatches: true,
  })

  const BlogMediaContent = (props: ImageProps) => (
    <Image
      {...props}
      className={classes.image}
      lazyProps={{ width: '100%', height: '100%' }}
    />
  )

  const BlogContent = (props: any) => (
    <div className={classes.blogContent}>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        {props.title}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.summary}
      >
        {props.summary}
      </Typography>
      <div style={{ flexGrow: 1 }} />

      {/* <div style={{ flexGrow: 1 }} /> */}
      <Divider className={classes.divider} />
      <div className={classes.list}>
        <div className={classes.leftFooterContent}>
          {props.author ? (
            <Avatar className={classes.avatar}>{`${props.author.name.charAt(
              0,
            )}${props.author.surname.charAt(0)}`}</Avatar>
          ) : null}
          <div>
            {props.author && (
              <Typography variant="body2" color="textPrimary">
                {`${props.author.name} ${props.author.surname}`}
              </Typography>
            )}
            <Typography variant="overline" color="textSecondary">
              {props.date}
            </Typography>
          </div>
        </div>
        <Link href={`/${blogSlug}/${props.permalink}`}>
          <Button variant="contained" className={classes.readMoreButton}>
            {readMoreCtaButton}
          </Button>
        </Link>
      </div>
    </div>
  )

  return (
    <div className={className} {...rest}>
      <SectionAlternate className={classes.sectionAlternate}>
        <Grid container spacing={isMd ? 4 : 2}>
          {data.findManyBlogPost.map((post: any) => {
            const blogPostProfileIndex: number = getProfileIndexFromLanguage(
              post.blog_post_profiles,
              locale,
            )

            if (
              post.active === true &&
              post.blog_post_profiles[blogPostProfileIndex]
            ) {
              return (
                <React.Fragment key={post.id}>
                  <Link
                    href={`/${blogSlug}/${post.blog_post_profiles[blogPostProfileIndex].meta_information.permalink}`}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={isMdSm ? 6 : 12}
                      md={isMdLg ? 6 : 6}
                      lg={4}
                      xl={3}
                      data-aos="fade-up"
                    >
                      <BlogPostCard
                        withShadow
                        liftUp
                        className={classes.cardProduct}
                        mediaContent={
                          <BlogMediaContent
                            image={post.featured_image}
                            alt={
                              post.blog_post_profiles[blogPostProfileIndex]
                                .title
                            }
                          />
                        }
                        cardContent={
                          <BlogContent
                            title={
                              post.blog_post_profiles[blogPostProfileIndex]
                                .title
                            }
                            summary={
                              post.blog_post_profiles[blogPostProfileIndex]
                                .summary
                            }
                            permalink={
                              post.blog_post_profiles[blogPostProfileIndex]
                                .meta_information.permalink
                            }
                            author={post.author}
                            date={moment
                              .utc(post.createdAt)
                              .local()
                              .format('YYYY-MM-DD')}
                          />
                        }
                      />
                    </Grid>
                  </Link>
                </React.Fragment>
              )
            } else {
              return null
            }
          })}
          {/* //TODO: IPORTANT fix this when dealing with pagination */}
          {/* <Grid item xs={12} container justifyContent="center">
            <Button variant="contained" className={classes.button}>
              Load more
            </Button>
          </Grid> */}
        </Grid>
      </SectionAlternate>
    </div>
  )
}

export default Result
