import moment from "moment";
import { CartItem, Order } from "../store/models";
import { calculatePriceWithoutVat } from "./priceUtils";

// Coupon logic
// 1) Is it expired?
// 2) Was a limit reached?
// 3) Does it have specific products?
// 4) Can it be applied on top of other discounts?
// 5) fixed or percentage discount??

const couponValidityCheck = (
  coupon,
  orderVar,
  order: Order,
  cartItems: CartItem[],
  snackbar,
  setSnackbar,
  shopSettings,
  translatedData
) => {
  const {
    generalCouponMessage,
    couponIsExpiredErrorMessage,
    nonCumulativeCouponWhenAddingCoupon,
  } = translatedData;
  const { current_prices_with_vat, vat_percentage } = shopSettings;

  const isFixedPriceCoupon = coupon?.fixed_price_coupon;
  const couponExpiryDate = coupon?.expiry_date;
  const couponLimit = coupon?.limit;
  const couponUsedCount = coupon?.count_used;
  const applicableOnOtherDiscounts =
    coupon?.apply_on_top_of_other_discounts;
  const productsAllowed = coupon?.products_that_are_allowed;
  const fixedDiscount = coupon?.fixed_price_discount;
  const percentageDiscount = coupon?.percentage_discount;
  // If there is data then we want to do:
  //1) check if it's not expired
  //2) check if there is a limit
  //3) check if the count_used is over the limit

  //1)2)3)
  if (
    !coupon ||
    moment(couponExpiryDate).isBefore(moment()) ||
    (couponLimit && couponLimit === couponUsedCount)
  ) {
    //* =============================================================================
    //* ==========================THE COUPON IS EXPIRED==============================
    //* =============================================================================
    setSnackbar({
      ...snackbar,
      open: true,
      message: couponIsExpiredErrorMessage,
    });

    orderVar({
      ...order,
      coupon: null,
      discount_applied: null,
    });

    return;
  }
  //* =============================================================================
  //* ==========================THE COUPON IS VALID!!==============================
  //* =============================================================================

  let productsThatTheDiscountWillBeAppliedTo = [] as CartItem[];
  // 3333) Does it have specific products?
  if (productsAllowed && productsAllowed.length > 0) {
    // We have specific products
    // Discount only applies on these...
    const matchingProducts = [];
    let cartItemsCopy = [...cartItems];
    //here we need to find the products and calculate the order.discount_applied
    productsAllowed.forEach((productAllowed) => {
      const cartItem = cartItemsCopy.find(
        (cartItem) => cartItem.id === productAllowed.id
      );

      //if the cartItem copy !== undefined
      //we check what type of coupon this is
      //then we calculate the discount applied to it
      //then push the discount calculated to discount_applied
      if (cartItem) {
        //push to the matchingProducts array, so that we can check it later
        //If it's empty then we didn't find any matching product, let the user know
        matchingProducts.push(cartItem);
      }
    });
    productsThatTheDiscountWillBeAppliedTo = [...matchingProducts];
  } else {
    // No specific products, discount applies on the whole cart.
    productsThatTheDiscountWillBeAppliedTo = [...cartItems];
  }
  //4) check if applicable on other discounts (if yes check if there are products with discounts)
  let productsThatWillBeDiscounted = [] as CartItem[];
  if (applicableOnOtherDiscounts) {
    // We ignore if applicable
    productsThatWillBeDiscounted = [...productsThatTheDiscountWillBeAppliedTo];
  } else {
    // If not applicable, we check each cart item and we only push the ones that have no discount
    productsThatTheDiscountWillBeAppliedTo.forEach((item) => {
      if (item.discounted) {
        // skip
      } else {
        // We push the item
        productsThatWillBeDiscounted.push(item);
      }
    });
  }

  // ==== All the products that will be discounted --> productsThatWillBeDiscounted

  let subtotalOfAllItems = 0;
  for (const item of productsThatWillBeDiscounted) {
    if (item.discounted) {
      const finalItemPrice =
        calculatePriceWithoutVat(item.discountedPrice, vat_percentage) *
        item.quantity;
      subtotalOfAllItems += finalItemPrice;
    } else {
      const finalItemPrice =
        calculatePriceWithoutVat(item.price, vat_percentage) * item.quantity;
      subtotalOfAllItems += finalItemPrice;
    }
  }

  let discount_applied = 0;
  if (isFixedPriceCoupon) {
    // Subtract the fixed price from availbale products.
    if (subtotalOfAllItems > fixedDiscount) {
      discount_applied += fixedDiscount;
    } else {
      discount_applied += subtotalOfAllItems;
    }
  } else {
    // This means its percentage discount and we need to loop all the products...
    discount_applied = subtotalOfAllItems * (percentageDiscount / 100);

    // TODO Loop productsThatWillBeDiscounted array and add additional variable, to show that the discount was applied specifically to these products
  }

  orderVar({
    ...order,
    ...{ coupon },
    discount_applied,
  });
};

export default couponValidityCheck;
