import { useQuery } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import { imageFallback } from '../../../../../constants/Images'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      cardSelected: {
        position: 'relative',
        border: `5px solid ${theme.palette.secondary.main}`,
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(1, 1),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      combinationMedia: {
        height: '16rem',
        backgroundSize: 'fit',
        [theme.breakpoints.down('lg')]: {
          height: '16rem',
        },
        [theme.breakpoints.down('md')]: {
          height: '8rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        // textAlign: "center",
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      selected: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
      },

      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
      },
    }),
  { name: 'MuiFabricCombinationDrawerCardComponent' },
)

export default function FabricCombinationDrawerCard({
  // additionalComponent,
  combination = null,
  selected = false,
  onClick,
}) {
  const classes = useStyles() as any
  const { t, i18n } = useTranslation(['common'])
  const { locale } = useAppContext()

  const profile = getProfileFromLanguage(
    combination.fabricCombinationProfiles,
    locale,
  )

  const colors =
    t('colors') + ': ' + combination.fabricCombinationOptions.length

  return (
    <Card
      elevation={0}
      square={true}
      className={selected ? classes.cardSelected : classes.card}
      onClick={() => {
        onClick(combination)
      }}
    >
      <div className={classes.cardActionAreaContainer}>
        <CardActionArea>
          <CardMedia
            className={classes.combinationMedia}
            image={combination.image?.src_md ?? imageFallback}
          />
          {selected && (
            <CheckCircleIcon color="success" className={classes.selected} />
          )}
        </CardActionArea>
      </div>
      <CardContent className={classes.cardContent}>
        <span className="cursorPointer">
          <Typography
            gutterBottom
            variant="body2"
            component="h3"
            className={classes.productName}
          >
            {profile?.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="p"
            className={classes.productName}
          >
            {colors}
          </Typography>

          {/* <Typography
                // fontSize={12}
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discoutedPrice)}
              >
                <ProductPrice price={sumPrice} />
              </Typography>
              {discounted && (
                <Typography variant="subtitle1" component="p">
                  {' '}
                  {discountedPrice && <ProductPrice price={discountedPrice} />}
                </Typography> */}
          {/* <Typography
            gutterBottom
            variant="caption"
            className={classes.productName}
          >
            {componentProfile.description}
          </Typography> */}
        </span>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.pricesContainer}>
            <ProductPrice price={additionalComponent.extra_price} />
          </div>
        </div> */}
      </CardContent>
    </Card>
  )
}
