import { gql } from "@apollo/client";

export const GET_MIN_MAX_DIMENSIONS = gql`
  query GET_MIN_MAX_DIMENSIONS(
    $permalink: String
    $colors: [SingleProductWhereInput]
    $price: ProductContainerWhereInput
    $language: Language
  ) {
    SingleProductMinWidth(
      where: {
        product_container: {is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: {is:{
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }}
      }
    )
    SingleProductMaxWidth(
      where: {
        product_container: {is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: { is: {
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }
      }}
    )

    # ============================================================================================================
    #            ================================== HEIGHT =======================================
    # ============================================================================================================
    SingleProductMinHeight(
      where: {
        product_container: { is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: { is: {
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }
      }}
    )
    SingleProductMaxHeight(
      where: {
        product_container: { is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: {is: {
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }
      }}
    )

    # ============================================================================================================
    #            ================================== LENGTH =======================================
    # ============================================================================================================
    SingleProductMinLength(
      where: {
        product_container: { is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: {is: {
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }
      }}
    )

    SingleProductMaxLength(
      where: {
        product_container: {is: {
          AND: [
            {
              OR: [
                {
                  categories: {
                    some: {
                      category_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  tags: {
                    some: {
                      tag_profiles: {
                        some: {
                          AND: [
                            {
                              meta_information: { is: {
                                permalink: { equals: $permalink }
                              }}
                            }
                            { language: { equals: $language } }
                          ]
                        }
                      }
                    }
                  }
                }
                {
                  style: { is: {
                    style_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }}
                }
                {
                  collection: { is: {
                    collection_profiles: {
                      some: {
                        AND: [
                          {
                            meta_information: { is: {
                                permalink: { equals: $permalink }
                             }}
                          }
                          { language: { equals: $language } }
                        ]
                      }
                    }
                  }
                }}
              ]
            }
            {
              single_product: {is: {
                AND: [
                  { OR: $colors }
                  {
                    product_profiles: {
                      some: { language: { equals: $language } }
                    }
                  }
                ]
              }}
            }
            $price
          ]
        }
      }}
    )
  }
`;
