import { Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'

import React from 'react'

import { TitleOutlined } from '@mui/icons-material'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      sectionTitle: {
        marginTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        marginBottom: '20px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        [theme.breakpoints.down('lg')]: {
          marginTop: '10px',
        },
      },
    }),
  { name: 'MuiSectionTitle' },
)

export default function SectionTitle({ title }) {
  const classes = useStyles() as any
  return (
    <Grid item xs={12} lg={12} className={classes.sectionTitle}>
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
    </Grid>
  )
}
