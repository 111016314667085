import { gql } from '@apollo/client'

export const GET_COLOR_BY_ID = gql`
  query GET_COLOR_BY_ID(
    $colorID: Int!
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findUniqueColor(where: { id: $colorID }) {
      id
      color_profiles {
        id
        name
        language
      }
    }
    findManyProductContainerCount(
      where: {
        AND: [
          { OR: $styles }
          { OR: $categories }
          { OR: $productTypes }
          {
            single_product: {is:{
              AND: [
                { main_color: {is:{ id: { equals: $colorID } } }}
                { dimensions: {is:{ AND: $dimensions } }}
                {
                  product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }
              ]
            }}
          }
          $price
        ]
      }
    )
  }
`

export const GET_FRAME_COLOR_BY_ID = gql`
  query GET_FRAME_COLOR_BY_ID(
    $colorID: Int!
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findUniqueColor(where: { id: $colorID }) {
      id
      color_profiles {
        id
        name
        language
      }
    }
    findManyProductContainerCount(
      where: {
        AND: [
          { OR: $styles }
          { OR: $categories }
          { OR: $productTypes }
          {
            single_product: {is: {
              AND: [
                { frame_color: { is: { id: { equals: $colorID } } }}
                { dimensions: {is: { AND: $dimensions }} }
                {
                  product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }
              ]
            }}
          }
          $price
        ]
      }
    )
  }
`

export const GET_FILTERED_COLORS = gql`
  query GET_FILTERED_COLORS(
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findManyColor(
      where: {
        AND: [
          {
            # ----> Looks irrelevant
            product_containers: {
              some: {
                AND: [
                  { OR: $styles }
                  { OR: $categories }
                  { OR: $productTypes }
                  {
                    single_product: {is:{
                      AND: [
                        { main_colorId: { not: null } }
                        { dimensions: {is: { AND: $dimensions }} }
                        {
                          product_profiles: {
                            some: { language: { equals: $language } }
                          }
                        }
                      ]
                    }}
                  }
                  $price
                ]
              }
            }
          }
        ]
      }
    ) {
      id
    }
  }
`

export const GET_FILTERED_FRAME_COLORS = gql`
  query GET_FILTERED_FRAME_COLORS(
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findManyColor(
      where: {
        AND: [
          {
            product_containers:  {
              some: {
                AND: [
                  { OR: $styles }
                  { OR: $categories }
                  { OR: $productTypes }
                  {
                    single_product: { is: {
                      AND: [
                        { frame_colorId: { not: null } }
                        { dimensions: {is: { AND: $dimensions } }}
                        {
                          product_profiles: {
                            some: { language: { equals: $language } }
                          }
                        }
                      ]
                    }}
                  }
                  $price
                ]
              }
            }
          }
        ]
      }
    ) {
      id
    }
  }
`
