// We take in all dispatch informations and return the total delivery time

export const dispatchInformationDate = (
  disaptchInformation,
  defaultDispatchInformation,
  manufacturerDefaultDispatch
) => {
  //-----------
  let time = 0;
  // Check if dispatchInformation exist for a specific product
  if (disaptchInformation) {
    // --- Dispatch information on Product exists..
    time = disaptchInformation.total_delivery_time;
  } else if (manufacturerDefaultDispatch) {
    // If not return store default from settings
    time = manufacturerDefaultDispatch.total_delivery_time;
  } else {
    // If not return store default from settings
    time = defaultDispatchInformation.total_delivery_time;
  }

  return time;
};

// Returns total time
// manufacturing time
// and delviery time - shipping_time
export const dispatchInformationDateExtended = (
  disaptchInformation,
  defaultDispatchInformation,
  manufacturerDefaultDispatch
) => {
  //-----------
  let total_delivery_time = 0;
  let manufacturing_time = 0;
  let shipping_time = 0;
  // Check if dispatchInformation exist for a specific product
  if (disaptchInformation) {
    // --- Dispatch information on Product exists..
    total_delivery_time = disaptchInformation.total_delivery_time;
    manufacturing_time = disaptchInformation.manufacturing_time;
    shipping_time = disaptchInformation.shipping_time;
  } else if (manufacturerDefaultDispatch) {
    // If not return store default from settings
    total_delivery_time = manufacturerDefaultDispatch.total_delivery_time;
    manufacturing_time = manufacturerDefaultDispatch.manufacturing_time;
    shipping_time = manufacturerDefaultDispatch.shipping_time;
  } else {
    // If not return store default from settings
    total_delivery_time = defaultDispatchInformation.total_delivery_time;
    manufacturing_time = defaultDispatchInformation.manufacturing_time;
    shipping_time = defaultDispatchInformation.shipping_time;
  }

  const returnObject = {
    total_delivery_time,
    manufacturing_time,
    shipping_time,
  };

  return returnObject;
};
