import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import clsx from 'clsx'
import { QuantitySelectorProps } from '../../../../types/QuantitySelector'

const useStyles = makeStyles(
  (theme) => ({
    quantitySelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',
      // width: theme.spacing(12),
      // height: theme.spacing(4.5),
      height: theme.spacing(10),
      width: theme.spacing(4.5),
      padding: theme.spacing(0.5, 1),
      border: '1px solid #d3d3d3',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '1rem',
    },
    remove: {
      textDecoration: 'underline',
    },
  }),
  { name: 'MuiQuantitySelectorVerticalComponent' },
)

const QuantitySelectorVertical = ({
  className,
  quantity,
  setQuantity,
  ...props
}: any): JSX.Element => {
  const classes = useStyles() as any

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }
  const incrementQuantity = () => {
    setQuantity(quantity + 1)
  }

  return (
    <div className={clsx(classes.quantitySelector, className)}>
      <RemoveIcon
        className={classes.icon}
        color={quantity === 1 ? `disabled` : `inherit`}
        onClick={() => decrementQuantity()}
      />
      <Typography variant="subtitle1">{quantity}</Typography>
      <AddIcon className={classes.icon} onClick={() => incrementQuantity()} />
    </div>
  )
}

export default QuantitySelectorVertical
