import { gql } from "@apollo/client";

export const GET_BLOG_POSTS_DATA = gql`
  query GET_BLOG_POSTS_DATA {
    findManyBlogPost {
      id
      createdAt
      updatedAt
      blog_post_profiles {
        id
        title
        summary
        language
        meta_information {
          id
          permalink
        }
      }
      active
      featured_image {
        id
        src
      }
      author {
        id
        name
      }
    }
  }
`;
