import { createContext, useContext } from 'react'

const EmbedContext = createContext(null)

const parseQueryParams = (queryParams) => {
  const parsed = {}

  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      const element = queryParams[key]
      if (element === 'true') {
        parsed[key] = true
      } else if (element === 'false') {
        parsed[key] = false
      } else {
        parsed[key] = element
      }
    }
  }

  return parsed
}

export function EmbedConfiguratorContext({ router, children }) {
  const isEmbed = router.state?.pathname === '/product-embed/[product]'
  return (
    <EmbedContext.Provider
      value={{ isEmbed, queryParams: parseQueryParams(router.state?.query) }}
    >
      {children}
    </EmbedContext.Provider>
  )
}

export function useEmbedContext() {
  return useContext(EmbedContext)
}
