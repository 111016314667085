import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useAppContext } from '../../../../../context/appContext'
import { checkDiscountValidity } from '../../../../../utilityFunctions/discountValidityCheck'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import { ProductPrice } from '../../../../UtilityComponents/price'
import { getCurrentPriceCategory } from '../../../../../utilityFunctions/priceUtils'

const useStylesSelectedFabric = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        marginBottom: '10px',
        maxWidth: '500px',
        minHeight: '120px',
        minWidth: '450px',
        [theme.breakpoints.down('md')]: {
          maxWidth: '380px',
          minWidth: '340px',
        },
      },
      cardBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
        width: '100%',
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',

        '&:last-child': {
          paddingBottom: 0,
        },
      },
      property: {
        width: '40%',
      },
      value: {
        width: '60%',
        textAlign: 'left',
      },
      title: {
        marginLeft: '10px',
        borderBottom: '1px solid #e2e1e0',
      },
      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
        justifyContent: 'left',
        width: '50%',
      },

      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },
    }),
  { name: 'MuiSofaPreviewSelectedAdditionalComponentFabric' },
)
// We make a block for each sofa combination, so they are separate as products
const PreviewSelectedAdditionalComponent = ({
  selectedAdditionalComponent,
  discount,
  elevation = 1,
  selectedFabric,
  ...props
}) => {
  const classes = useStylesSelectedFabric() as any
  const appContext = useAppContext()
  const locale = appContext ? appContext.locale : 'en'

  const componentGroupName = selectedAdditionalComponent.groupName

  const image = selectedAdditionalComponent.image
    ? selectedAdditionalComponent.image.src_md ??
      selectedAdditionalComponent.image.src
    : null
  const color = selectedAdditionalComponent.color
    ? selectedAdditionalComponent.color.background ??
      selectedAdditionalComponent.color.hex
    : null

  const componentProfile = getProfileFromLanguage(
    selectedAdditionalComponent.additional_component_profiles,
    locale,
  )

  const componentName = componentProfile?.name ?? 'Not translated'
  const componentMaterialName = componentProfile?.material_name ?? ''

  const selectedAdvancedProductFabric = selectedFabric

  let componentExtraPrice
  if (
    selectedAdditionalComponent.use_fabric_prices_for_components &&
    selectedAdditionalComponent.price_fabric_category &&
    selectedAdditionalComponent.price_fabric_category.length &&
    selectedAdvancedProductFabric?.fabricGroupObject?.form_price_fabric_category
  ) {
    let componentExtraPrice = getCurrentPriceCategory(
      selectedAdvancedProductFabric,
      selectedAdditionalComponent.price_fabric_category,
    )
  } else {
    componentExtraPrice = selectedAdditionalComponent.extra_price
  }
  //====== END PRICE CALCULATTIONS  (above) ======

  const { discounted, discountedPrice } = checkDiscountValidity(
    discount,
    componentExtraPrice,
  )

  // For order items
  if (isNaN(componentExtraPrice)) {
    componentExtraPrice = null
  }

  const { t, i18n } = useTranslation(['common'])

  const materialString = t('material')
  const extraPriceString = t('extra-price')
  const nameString = t('name')

  const noImageNoColor = !image && !color ? true : false

  return (
    <Card
      elevation={elevation}
      className={classes.card}
      sx={{ display: 'flex', ...props.style }}
    >
      {image && (
        <CardMedia
          component="img"
          height="140"
          image={image}
          sx={{ width: 120 }}
          // alt="green iguana"
        />
      )}
      {!image && color && (
        <div
          style={{
            minWidth: '120px',
            background: color,
          }}
        ></div>
      )}

      <Box className={classes.cardBox}>
        <Box className={classes.title}>
          <Typography variant="subtitle1" component="p">
            {componentGroupName}
          </Typography>
        </Box>
        <CardContent className={classes.row}>
          <Typography
            variant="body1"
            component="p"
            className={classes.property}
            sx={noImageNoColor ? { width: '55% !important' } : {}}
          >
            {`${nameString}:`}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.value}
            sx={noImageNoColor ? { width: '45% !important' } : {}}
          >
            {componentName}
          </Typography>
        </CardContent>
        {componentMaterialName && (
          <CardContent className={classes.row}>
            <Typography
              variant="body1"
              component="p"
              className={classes.property}
              sx={noImageNoColor ? { width: '55% !important' } : {}}
            >
              {materialString}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.value}
              sx={noImageNoColor ? { width: '45% !important' } : {}}
            >
              {componentMaterialName}
            </Typography>
          </CardContent>
        )}

        {componentExtraPrice != null && (
          <CardContent className={classes.row}>
            <Typography
              variant="body1"
              component="p"
              className={classes.property}
              sx={noImageNoColor ? { width: '60% !important' } : {}}
            >
              {extraPriceString}
            </Typography>

            <div
              className={classes.pricesContainer}
              style={noImageNoColor ? { width: '40% !important' } : {}}
            >
              <Typography
                // fontSize={12}
                variant={discounted ? 'body1' : 'body2'}
                component="p"
                className={clsx(discounted && classes.discoutedPrice)}
                sx={noImageNoColor ? { width: '100% !important' } : {}}
              >
                <ProductPrice price={componentExtraPrice} />
              </Typography>
              {discounted && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={noImageNoColor ? { width: '100% !important' } : {}}
                >
                  {' '}
                  {discountedPrice != null ? (
                    <ProductPrice price={discountedPrice} />
                  ) : null}
                </Typography>
              )}
            </div>
          </CardContent>
        )}
      </Box>
    </Card>
  )
}

export default PreviewSelectedAdditionalComponent
