import { createContext, useContext } from "react";

const BlogContext = createContext(null);

export function BlogContextWrapper({ data, locale, children }) {
  return (
    <BlogContext.Provider value={{ data, locale }}>
      {children}
    </BlogContext.Provider>
  );
}

export function useBlogContext() {
  return useContext(BlogContext);
}
