import { gql } from '@apollo/client'

//* ======================================================================
//* ========================== SHIPPING FORM =============================
//* ======================================================================
export const GET_PRODUCT_DISPATCH_INFO = gql`
  query GET_PRODUCT_DISPATCH_INFO($cartItemID: Int) {
    findUniqueProductContainer(where: { id: $cartItemID }) {
      id
      dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
      manufacturer {
        id
        default_dispatch_information {
          id
          manufacturing_time
          shipping_time
          total_delivery_time
        }
      }
      categories {
        id
        shipping_method {
          id
          price_for_box
          shipping_method_profiles {
            id
            title
            language
          }
        }
      }
      single_product {
        id
        packing_information_list {
          id
          name
          number_of_products_that_fit_in_box
        }
        shipping_methods {
          id
          price_for_box
          shipping_method_profiles {
            id
            title
            language
          }
        }
      }

      advanced_product {
        id
        shipping_methods {
          id
          price_for_box
          shipping_method_profiles {
            id
            title
            language
          }
        }
      }
    }
  }
`

//TODO: IMPORTANT remove all the shopSettings queries, we have them in the shopsettingsVar
export const GET_NEOPAY_WIDGET_URL = gql`
  query GET_NEOPAY_WIDGET_URL(
    $orderId: String!
    $TLD: String!
    $main_website_url: String!
  ) {
    neopayWidget(
      order: { id: $orderId }
      TLD: $TLD
      main_website_url: $main_website_url
    ) {
      widgetUrl
    }
  }
`

//TODO: IMPORTANT remove all the shopSettings queries, we have them in the shopsettingsVar
export const GET_SHOPSETTINGS_VAT_CODE = gql`
  query GET_SHOPSETTINGS_VAT_CODE($SHOP_ID: Int) {
    findFirstShopSetting(where: { id: { equals: $SHOP_ID } }) {
      id
      default_manufacturer {
        id
        vat_code
      }
    }
  }
`

export const GET_SHOPSETTINGS_SHIPPING_DETAILS = gql`
  query GET_SHOPSETTINGS_SHIPPING_DETAILS($SHOP_ID: Int) {
    findFirstShopSetting(where: { id: { equals: $SHOP_ID } }) {
      id
      default_dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
      shipping_methods {
        id
        shipping_method_profiles {
          id
          title
          language
        }
        is_enabled
        price_for_box
      }
      default_shipping_method {
        id
        price_for_box
        shipping_method_profiles {
          id
          title
          language
        }
      }
    }
  }
`

export const GET_SHOPSETTINGS_PAYMENT_METHODS = gql`
  query GET_SHOPSETTINGS_PAYMENT_METHODS($SHOP_ID: Int) {
    findFirstShopSetting(where: { id: { equals: $SHOP_ID } }) {
      id
      enable_vat
      vat_percentage
      current_prices_with_vat
      default_payment_method {
        id
        is_enabled
        enabled_languages
        payment_method_profiles {
          id
          title
          description
          language
        }
        image {
          id
          src
          src_lg
          src_md
          src_xs
        }
      }
      payment_methods {
        id
        is_enabled
        enabled_languages
        payment_method_profiles {
          id
          title
          description
          instructions
          language
        }
        image {
          id
          src
          src_lg
          src_md
          src_xs
        }
      }
    }
  }
`

export const SHOP_SETTINGS = gql`
  query SHOP_SETTINGS($SHOP_ID: Int) {
    findFirstShopSetting(where: { id: { equals: $SHOP_ID } }) {
      id
      currency
      default_manufacturer {
        id
        is_manufacturer_public
        company_name
        company_address
        company_code
        vat_code
        company_email
        company_phone
        country
        country_code
        website
        bank_name
        bank_account_number
        bic_swift
        coordinates {
          id
          lat
          lng
        }
        coordinatesId
        logo_imageId
        logo_image {
          id
          src
          is_hosted_externally
        }
        manufacturer_profiles {
          id
          name
          description
          language
          meta_information {
            id
            permalink
            meta_title
            meta_description
          }
        }
        manufacturer_contacts {
          id
          name
          surname
          phone_number
          email
          primary
        }
        default_dispatch_informationId
        default_dispatch_information {
          id
          manufacturing_time
          shipping_time
          total_delivery_time
        }
        package_dimensions_list {
          id
          name
          is_one_of_defaults
          width
          height
          length
          weight
          number_of_products_that_fit_in_box
        }
      }
    }
  }
`

// // THIS WILL BE USED IN VERSION 2
// export const GET_SHIPPING_PACKAGE_INFO = gql`;
//   query GET_SHIPPING_PACKAGE_INFO(
//     $shippingMethodID: Int
//     $packageInformationID: Int
//   ) {
//     shippingMethods(where: { id: { equals: $shippingMethodID } }) {
//       id
//       price_for_box
//     }
//     packageDimensions(where: { id: { equals: $packageInformationID } }) {
//       id
//       number_of_products_that_fit_in_box
//     }
//   }
// `;
