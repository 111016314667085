import { useReactiveVar } from '@apollo/client'
import { Button, Divider, InputBase, InputLabel, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { v5 as uuidv5 } from 'uuid'
import { useShopContext } from '../../../context'
import { AddressFormData } from '../../../store/models'
import { orderVar } from '../../../store/reactiveVars'
import { useAppContext } from '../../../context/appContext'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      pageTitle: {
        fontSize: 32,
        fontWeight: 500,
      },
      gridContainer: {
        margin: theme.spacing(2, 0, 1, 0),
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
        },
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(1.3, 1),
      },
      inputLabel: {
        marginBottom: theme.spacing(0.5),
      },
      radioGroup: {
        display: 'flex',
        flexDirection: 'row',
      },
      button: {
        width: '40%',
        [theme.breakpoints.down('lg')]: {
          width: '100%',
        },
      },
      error: {
        color: '#d9534f',
      },
    }),
  { name: 'MuiDeliveryAddressFormComponent' },
)

export default function DeliveryAddressForm({ handleNextSubStep }) {
  const { t } = useTranslation(['common'])
  const fieldRequiredError = t('field-is-required')
  const nextStepButtonString = t('next-step-button')
  const formOneStepTwoHeader = t('form-one-step-two-header')
  const addressInputLabel = t('address-input-label')
  const address2InputLabel = t('address2-input-label')
  const cityInputLabel = t('city-input-label')
  const stateInputLabel = t('state-input-label')
  const zipInputLabel = t('zip-input-label')
  const countryInputLabel = t('country-input-label')
  const classes = useStyles() as any
  const shopContext = useShopContext()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useForm()
  const order = useReactiveVar(orderVar)

  ///====
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  const disableInputFields = b2bToken ? true : false

  useEffect(() => {
    if (b2bToken) {
      setValue('address1', b2bToken.b2b_company_address.address_1 ?? '-')
      setValue('address2', b2bToken.b2b_company_address.address_2 ?? '-')
      setValue('city', b2bToken.b2b_company_address.city ?? '-')
      setValue('country', b2bToken.b2b_company_address.country ?? '-')
      setValue('postal_code', b2bToken.b2b_company_address.postal_code ?? '-')
      setValue('state', b2bToken.b2b_company_address.state_region ?? '-')
    }
  }, [])

  //* =================================================================
  //* ====================== HANDLE ONSUBMIT ==========================
  //* =================================================================
  const onSubmit = (data) => {
    if (data.address1 === undefined && b2bToken) {
      //-- We have the b2b case...

      handleNextSubStep()
    } else {
      const billingData = {
        billing_address1: data.address1,
        billing_address2: data.address2,
        billing_city: data.city,
        billing_country: data.country,
        billing_postal_code: data.postal_code,
      }

      //* Spread an object containing all the data key value pairs and the new unique_generated
      orderVar({ ...order, ...data, ...billingData })
      handleNextSubStep()
    }
  }

  // console.log('order :>> ', order)

  //TODO: IMPORTANT change the country to a list of defined countries, add state/region to the whole flow and add a textarea for "comment"
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        {formOneStepTwoHeader}
      </Typography>
      <Divider />
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {addressInputLabel}
              {errors.address1 ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line1"
            inputProps={{
              ...register('address1', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'address1' in order && order.address1 ? order.address1 : ''
            }
            disabled={disableInputFields}
          />
          {errors.address1 && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.address1.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {address2InputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line2"
            inputProps={{
              ...register('address2'),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'address2' in order && order.address2 ? order.address2 : ''
            }
            disabled={disableInputFields}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {cityInputLabel}
              {errors.city ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            id="city"
            name="city"
            fullWidth
            autoComplete="shipping address-level2"
            inputProps={{
              ...register('city', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'city' in order && order.city ? order.city : ''}
            disabled={disableInputFields}
          />
          {errors.city && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.city.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {stateInputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            inputProps={{ ...register('state') }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'state' in order && order.state ? order.state : ''}
            disabled={disableInputFields}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {zipInputLabel}
              {errors.postal_code ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping postal-code"
            inputProps={{
              ...register('postal_code', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'postal_code' in order && order.postal_code
                ? order.postal_code
                : ''
            }
            disabled={disableInputFields}
          />
          {errors.postal_code && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.postal_code.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {countryInputLabel}
              {errors.country ? <span className={classes.error}>*</span> : `*`}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping country"
            inputProps={{
              ...register('country', { required: fieldRequiredError }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'country' in order && order.country ? order.country : ''
            }
            disabled={disableInputFields}
          />
          {errors.country && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.country.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Button variant="contained" type="submit" className={classes.button}>
        {nextStepButtonString}
      </Button>
    </form>
  )
}
