import { Grid, Paper, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'swiper/css/bundle'

import {
  fabricsCombinationDrawerStateVar,
  selectedAdvancedProductFabricCombinationVar,
} from '../../../../../store/reactiveVars'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../../../context/appContext'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import { useStyles } from './groupWithDrawerStyles'

const FabricsCombinationSelectWithDrawer = ({
  fabricCombinations,
}): JSX.Element => {
  const classes = useStyles() as any
  //=== Crunch data here...
  const { locale } = useAppContext()

  const selectedAdvancedProductFabricCombination = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )

  const { t, i18n } = useTranslation(['common'])
  const fabricText = 'Audinių derinys'
  const fabricGroupText = t('fabric-group')
  const fabricCodeText = t('fabric-code')

  const image =
    selectedAdvancedProductFabricCombination?.fabricCombination?.image?.src_md

  const groupProfile = getProfileFromLanguage(
    selectedAdvancedProductFabricCombination?.fabricCombination
      ?.fabricCombinationProfiles,
    locale,
  )

  return (
    <Grid id="fabricsSelect" item xs={12} lg={12}>
      <section className={classes.section}>
        <div
          className={classes.row}
          onClick={() => {
            fabricsCombinationDrawerStateVar({
              open: true,
              data: fabricCombinations,
            })
          }}
        >
          <div className={classes.rowContentContainer}>
            <div className={classes.imageContainer}>
              {image && (
                <img
                  src={image}
                  loading="lazy"
                  className={classes.componentImage}
                />
              )}
            </div>
            <div className={classes.contentContainer}>
              <Typography
                component="p"
                variant="caption"
                display="block"
                style={{ fontSize: '1.2em' }}
              >
                {groupProfile?.name}
              </Typography>
              {/* <Typography component="p" variant="body2" display="block">
                {groupProfile?.name}
              </Typography> */}
            </div>
          </div>
          <ChevronRightIcon className={classes.chevronRight} />
        </div>
      </section>
    </Grid>
  )
}

export default FabricsCombinationSelectWithDrawer
