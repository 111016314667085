import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import Skeleton from '@mui/material/Skeleton'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { parse } from 'next-useragent'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  imageFallback,
  imageLoadingPlaceholder,
} from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../operations/clientMutations/wishlistClient'
import { GET_PRODUCT_CONTAINER } from '../../../operations/queries'
import {
  recentlyViewedVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import { dispatchInformationDate } from '../../../utilityFunctions/dispatchInformationDate'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import DiscountBadge from '../Utils/DiscountBadge'
import FastDeliveryBadge from '../Utils/FastDeliveryBadge'
import NewProductBadge from '../Utils/NewProductBadge'
import LayersIcon from '@mui/icons-material/Layers'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
      },
      rootInCarousel: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      cardContent: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2, 0),
      },
      cardActions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1, 0),
        },
      },
      cardActionAreaContainer: {
        position: 'relative',
        height: '32rem',
        [theme.breakpoints.between('xs', 649)]: {
          height: '14rem',
        },
        [theme.breakpoints.between(650, 'lg')]: {
          height: '20rem',
        },
        [theme.breakpoints.between('lg', 1500)]: {
          height: '23rem',
        },
      },
      wishlistIconContainer: {
        position: 'absolute',
        padding: theme.spacing(2),
        right: 0,
        top: 0,
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1),
        },
      },
      cardActionsButtons: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      colorsContainer: {
        display: 'flex',
      },
      colorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        padding: theme.spacing(0.6, 0.5),
        cursor: 'pointer',
      },
      colorActive: {
        // borderBottom: "2px solid black",
        '&::after': {
          content: "''",
          display: 'block',
          width: '8px',
          height: '2px',
          margin: '4px auto 0px',
          backgroundColor: 'black',
          transition: 'background-color 0.3s ease 0s',
        },
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
      },
      icon: {
        fontSize: '1.3rem',
        marginLeft: '1.3rem',
      },
      favoriteIcon: {
        color: 'rgb(241, 88, 86)',
        fontSize: '1.3rem',
      },
      namePriceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
          flexDirection: 'column',
        },
      },
      productName: {
        [theme.breakpoints.down('lg')]: {
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': 1,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
        },
      },
      imageAnchorTagWrapper: {
        height: '100%',
        position: 'relative',
        display: 'block',
      },

      pricesContainer: {
        display: 'flex',
        gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
      },

      badgeBox: {
        position: 'absolute',
        top: 5,
        left: 0,
        zIndex: 2,
      },

      advancedInfoBox: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        zIndex: 2,
      },
    }),
  { name: 'MuiDynamicCategoryProductCardComponent' },
)

interface ActiveProduct {
  id: number
  price: number
  productProfile: Record<string, any>
  images: any[]
  groupedByColorProducts?: Record<string, any>[]

  createdAt: any
  manufacturer: any
  dispatch_information: any
  discount: any
}

// const DynamicCategoryProductCard = ({
//   productContainerID,
//   inCarousel = false,
// }: {
//   productContainerID: number
//   inCarousel?: boolean
// }) => {

//   if (productContainer.type === 'SIMPLE_PRODUCT') {
//     return (
//       <CategorySimpleProductCard
//         productContainer={productContainer}
//         locale={locale}
//         inCarousel={inCarousel}
//       />
//     )
//   } else if (productContainer.type === 'ADVANCED_PRODUCT') {
//     return (
//       <CategoryAdvancedProductCard
//         productContainer={productContainer}
//         locale={locale}
//         inCarousel={inCarousel}
//       />
//     )
//   } else {
//     return <p>unhandled product card...</p>
//   }

// }

const DynamicCategoryProductCard = ({
  productContainerID,
  inCarousel = false,
  ...props
}: {
  productContainerID: number
  inCarousel?: boolean
}) => {
  const classes = useStyles() as any
  const { locale } = useRouter()

  // const recentlyViewed = useReactiveVar(recentlyViewedVar)
  // TODO move somewhere elese...
  const removeFromRecentlyViewed = (id) => {
    //--- Remove by productContainerID
    const newValue = recentlyViewedVar().filter((item) => item.id != id)
    // recentlyViewedVar(newValue)
    localStorage.setItem('recentlyViewed', JSON.stringify(newValue))
  }

  const { loading, error, data } = useQuery(GET_PRODUCT_CONTAINER, {
    variables: { productContainerID: productContainerID, language: locale },
  })

  if (loading)
    return (
      <div style={{ width: '100%' }}>
        <Skeleton variant="rectangular" height={400} />
        <Skeleton />
        <Skeleton width="60%" />
      </div>
    )
  if (error || !data) return <p>Something went wrong...</p>

  // ========= ADVANCED / SIMPLE / PRODUCT ===========

  // console.log('data :>> ', data)
  const productType = data.findManyProductContainer[0]?.type ?? null
  const productData = data.findManyProductContainer[0] ?? null

  if (productType) {
    if (productType === 'ADVANCED_PRODUCT') {
      return (
        <DynamicCategoryProductCardAdvancedProduct
          productData={productData}
          inCarousel={inCarousel}
        />
      )
    } else if (productType === 'SIMPLE_PRODUCT') {
      return (
        <DynamicCategoryProductCardSimpleProduct
          productData={productData}
          inCarousel={inCarousel}
        />
      )
    } else {
      return <p> Unhandled product type</p>
    }
  } else {
    // Also initiate remove froom Recently viewed
    removeFromRecentlyViewed(productContainerID)

    return <p>Product not found</p>
    // return null
  }
}

const DynamicCategoryProductCardSimpleProduct = ({
  productData,
  inCarousel = false,
}) => {
  const classes = useStyles() as any
  const { locale } = useRouter()
  const [isInWishlist, setisInWishlist] = useState(false)
  const [activeProduct, setActiveProduct] = useState(null as ActiveProduct)
  const [groupedByColorProducts, setGroupedByColorProducts] = useState([])
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  const userAgent = appContext ? appContext.userAgent : null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false
  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // const ua = useUserAgent(window ? window.navigator.userAgent : null) // ()Not working on buils
  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  //* =======================================================================
  //* ======== get the wishlist items from the cache at first render ========
  //* =======================================================================
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === productData?.id) {
        setisInWishlist(true)
      }
    })

    return
  }, [wishlistItems, productData])

  useEffect(() => {
    if (productData) {
      const productProfileIndex: number = getProfileIndexFromLanguage(
        productData.single_product.product_profiles,
        locale,
      )

      //once we have data we set the active product
      setActiveProduct({
        id: productData.id,
        price: productData.single_product.price,
        productProfile:
          productData.single_product.product_profiles[productProfileIndex],
        images: productData.single_product.images,
        groupedByColorProducts: productData.single_product.grouped_by_color,

        createdAt: productData.createdAt,
        manufacturer: productData.manufacturer,
        dispatch_information: productData.dispatch_information,
        discount: productData.discount,
      })

      let groupedByColorProducts = []
      //I have to construct an array of groupedByColor products
      //Loop through them and make sure that I return only the ones with a profile
      //matching the current locale.
      for (let groupedByColorProduct of productData.single_product
        .grouped_by_color) {
        const groupedProductProfileIndex: number = getProfileIndexFromLanguage(
          productData.single_product.product_profiles,
          locale,
        )

        if (
          groupedByColorProduct.product_profiles[groupedProductProfileIndex]
        ) {
          groupedByColorProducts.push({
            ...groupedByColorProduct,
            productProfile:
              groupedByColorProduct.product_profiles[
              groupedProductProfileIndex
              ],
          })
        }
      }

      setGroupedByColorProducts([...groupedByColorProducts])
    }

    return
  }, [productData, locale])

  if (activeProduct) {
    // Order images by display order
    // Convert to list
    const productImages = activeProduct.images
      .slice()
      .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

    // change src for SAFARI as it does not support webp...

    const mainProductImage = isSafari
      ? productImages[0]?.src ?? imageFallback
      : productImages[0]?.src_xs ??
      productImages[0]?.src_md ??
      productImages[0]?.src ??
      imageFallback

    // =============================================
    // ================ BADGES =====================
    //? =============================================
    // ============ DISCOUNTS ======================
    const { discounted, discountedPrice } = checkDiscountValidity(
      activeProduct.discount,
      activeProduct.price,
    )
    const discountPercentage = activeProduct.discount
      ? '-' + activeProduct.discount.discount + '%'
      : 0

    // ========== DISPATCH INFORMATION ===============
    // === We need to get dispatch data from SHOP Settings
    const shopSettings = appContext ? appContext.shopSettings : null
    const defaultDispatchInformation = appContext
      ? appContext.shopSettings.default_dispatch_information
      : null
    // === We need to get dispatch data from MANUFACTURER
    const manufacturerDefaultDispatch = activeProduct.manufacturer
      ? activeProduct.manufacturer.default_dispatch_information
      : null

    const dispatchTime = dispatchInformationDate(
      activeProduct.dispatch_information,
      defaultDispatchInformation,
      manufacturerDefaultDispatch,
    )
    // We turn on FastDelivery Badge when dispatch time is less than 10 days.
    const fastDelivery = dispatchTime <= 10 ? true : false
    // ============ NEW BADGE ========================
    const newProduct = checkIfProductIsNew(activeProduct.createdAt)

    //* ====================================================================
    //* Here we're sure the data has come (we are after the "loading" check)
    //* but still make sure that there is a product
    //* we want to run the check and get this item's profile
    //* ====================================================================
    const productProfileIndex: number = getProfileIndexFromLanguage(
      productData.single_product.product_profiles,
      locale,
    )

    //* ============================================================
    //* Here we know if the profile exists or not, if it does
    //* this component can be displayed in the current locale
    //* else this item will be removed from client data by the
    //* above useEffect
    //* =============================================================
    if (productProfileIndex !== null && productProfileIndex !== undefined) {
      return (
        // <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
        <Card
          elevation={0}
          square={true}
          className={clsx(inCarousel ? classes.rootInCarousel : classes.root)}
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <div className={classes.badgeBox}>
            {discounted && <DiscountBadge content={discountPercentage} />}
            {fastDelivery && <FastDeliveryBadge />}
            {newProduct && <NewProductBadge />}
          </div>

          <div className={classes.cardActionAreaContainer}>
            <CardActionArea style={{ height: '100%' }}>
              {/* // TODO ----> We should include lazy loading in this new component. */}
              <Link
                href={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
                prefetch={false}
              >
                <a className={classes.imageAnchorTagWrapper}>
                  <Image
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={imageLoadingPlaceholder}
                    objectFit="contain"
                    src={mainProductImage}
                    alt={
                      productData.single_product.product_profiles[
                        productProfileIndex
                      ]?.name
                    }
                    priority={true}
                  />
                </a>
              </Link>
            </CardActionArea>
            <div className={classes.wishlistIconContainer}>
              {!isInWishlist ? (
                <FavoriteBorderIcon
                  className={classes.icon}
                  aria-label="add to favorites"
                  onClick={() => {
                    addToWishlist({
                      id: productData.id,
                      name:
                        productData.single_product.product_profiles[
                          productProfileIndex
                        ]?.name,
                      price: productData.single_product.price,
                      sku: productData.single_product.sku,
                      image: productData.single_product.images[0].src,
                      permalink:
                        productData.single_product.product_profiles[
                          productProfileIndex
                        ].meta_information.permalink,
                      product_type: productData.type,
                      dispatchInformation: productData.dispatch_information,
                      discount: productData.discount,
                    })
                    wishlistDrawerVar(true)
                    setisInWishlist(true)
                  }}
                />
              ) : (
                <FavoriteIcon
                  className={classes.favoriteIcon}
                  aria-label="remove from favorites"
                  onClick={() => {
                    removeFromWishlist(productData.id)
                    setisInWishlist(false)
                  }}
                />
              )}
            </div>
          </div>
          <CardContent className={classes.cardContent}>
            <div className={classes.namePriceContainer}>
              <Link
                href={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
                prefetch={false}
              >
                <span className="cursorPointer">
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="h2"
                    className={classes.productName}
                  >
                    {activeProduct.productProfile.name}
                  </Typography>
                </span>
              </Link>
              {/* <Typography variant="body2" component="p">
                <ProductPrice price={activeProduct.price} />
              </Typography> */}
              <div className={classes.pricesContainer}>
                <Typography
                  variant={discounted ? 'body1' : 'body2'}
                  component="p"
                  className={clsx(discounted && classes.discoutedPrice)}
                >
                  <ProductPrice price={activeProduct.price} />
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {discountedPrice && <ProductPrice price={discountedPrice} />}
                </Typography>
              </div>
            </div>
            <CardActions disableSpacing className={classes.cardActions}>
              {groupedByColorProducts.length !== 0 && (
                <div className={classes.colorsContainer}>
                  <div
                    className={clsx(
                      activeProduct.id === productData.id &&
                      classes.colorActive,
                      classes.colorContainer,
                    )}
                    onMouseEnter={() =>
                      setActiveProduct({
                        id: productData.id,
                        price: productData.single_product.price,
                        productProfile:
                          productData.single_product.product_profiles[
                          productProfileIndex
                          ],
                        images: productData.single_product.images,
                        groupedByColorProducts:
                          productData.single_product.grouped_by_color,

                        createdAt: productData.createdAt,
                        manufacturer: productData.manufacturer,
                        discount: productData.discount,
                        dispatch_information: productData.dispatch_information,
                      })
                    }
                  >
                    <span
                      className={classes.color}
                      style={{
                        backgroundColor: `${productData.single_product.main_color?.hex}`,
                      }}
                    />
                  </div>
                  {groupedByColorProducts.map((groupedProduct) => {
                    return (
                      <Link
                        href={`/${productSlug}/${groupedProduct.productProfile.meta_information.permalink}`}
                        key={groupedProduct.id}
                        prefetch={false}
                      >
                        <div
                          className={clsx(
                            activeProduct.id ===
                            groupedProduct.product_containerId &&
                            classes.colorActive,
                            classes.colorContainer,
                          )}
                          onMouseEnter={() =>
                            setActiveProduct({
                              id: groupedProduct.product_containerId,
                              price: groupedProduct.price,
                              productProfile: groupedProduct.productProfile,
                              images: groupedProduct.images,

                              createdAt: groupedProduct.createdAt,
                              manufacturer: groupedProduct.manufacturer,
                              discount: groupedProduct.discount,
                              dispatch_information:
                                groupedProduct.dispatch_information,
                            })
                          }
                          onMouseLeave={() =>
                            setActiveProduct({
                              id: productData.id,
                              price: productData.single_product.price,
                              productProfile:
                                productData.single_product.product_profiles[
                                productProfileIndex
                                ],
                              images: productData.single_product.images,
                              groupedByColorProducts:
                                productData.single_product.grouped_by_color,
                              createdAt: productData.createdAt,
                              manufacturer: productData.manufacturer,
                              discount: productData.discount,
                              dispatch_information:
                                productData.dispatch_information,
                            })
                          }
                        >
                          <span
                            className={classes.color}
                            style={{
                              backgroundColor: `${groupedProduct.main_color?.hex}`,
                            }}
                          />
                        </div>
                      </Link>
                    )
                  })}
                </div>
              )}
            </CardActions>
          </CardContent>
        </Card>
        // </Grid>
      )
    }
    // ==================
    // Here the page this component was loaded in had a productContainer
    // matching the current locale, but the productContainer didn't have
    // a profile matching this language so we return null.
    // ==================
    else {
      return null
    }
  }
  // ==================
  // Here the page this component was loaded in had no productContaiener
  // matching the current locale, so we return null.
  // ==================
  else {
    return null
  }
}

const DynamicCategoryProductCardAdvancedProduct = ({
  productData,
  inCarousel = false,
}) => {
  const classes = useStyles() as any
  const { locale } = useRouter()
  const [activeProduct, setActiveProduct] = useState(null as ActiveProduct)
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const configurableProductText = t('configurable-product')

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  const userAgent = appContext ? appContext.userAgent : null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false
  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // const ua = useUserAgent(window ? window.navigator.userAgent : null) // ()Not working on buils
  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  //* =======================================================================
  //* ======== get the wishlist items from the cache at first render ========
  //* =======================================================================
  const [isInWishlist, setisInWishlist] = useState(false)
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === productData?.id) {
        setisInWishlist(true)
      }
    })

    return
  }, [wishlistItems, productData])

  useEffect(() => {
    if (productData) {
      const productProfileIndex: number = getProfileIndexFromLanguage(
        productData.advanced_product.advanced_product_profiles,
        locale,
      )
      //once we have data we set the active product
      setActiveProduct({
        id: productData.id,
        price: productData.advanced_product.price_from,
        productProfile:
          productData.advanced_product.advanced_product_profiles[
          productProfileIndex
          ],
        images: productData.advanced_product.images,
        createdAt: productData.createdAt,
        manufacturer: productData.manufacturer,
        dispatch_information: productData.dispatch_information,
        discount: productData.discount,
      })
    }
    return
  }, [productData, locale])

  if (activeProduct) {
    // Order images by display order
    // Convert to list
    const productImages = activeProduct.images
      .slice()
      .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

    // change src for SAFARI as it does not support webp...

    const mainProductImage = isSafari
      ? productImages[0]?.src ?? imageFallback
      : productImages[0]?.src_xs ??
      productImages[0]?.src_md ??
      productImages[0]?.src ??
      imageFallback

    // =============================================
    // ================ BADGES =====================
    //? =============================================
    // ============ DISCOUNTS ======================
    const { discounted, discountedPrice } = checkDiscountValidity(
      activeProduct.discount,
      activeProduct.price,
    )
    const discountPercentage = activeProduct.discount
      ? '-' + activeProduct.discount.discount + '%'
      : 0

    // ========== DISPATCH INFORMATION ===============
    // === We need to get dispatch data from SHOP Settings
    const shopSettings = appContext ? appContext.shopSettings : null
    const defaultDispatchInformation = appContext
      ? appContext.shopSettings.default_dispatch_information
      : null
    // === We need to get dispatch data from MANUFACTURER
    const manufacturerDefaultDispatch = activeProduct.manufacturer
      ? activeProduct.manufacturer.default_dispatch_information
      : null

    const dispatchTime = dispatchInformationDate(
      activeProduct.dispatch_information,
      defaultDispatchInformation,
      manufacturerDefaultDispatch,
    )
    // We turn on FastDelivery Badge when dispatch time is less than 10 days.
    const fastDelivery = dispatchTime <= 10 ? true : false
    // ============ NEW BADGE ========================
    const newProduct = checkIfProductIsNew(activeProduct.createdAt)

    //* ====================================================================
    //* Here we're sure the data has come (we are after the "loading" check)
    //* but still make sure that there is a product
    //* we want to run the check and get this item's profile
    //* ====================================================================
    const productProfileIndex: number = getProfileIndexFromLanguage(
      productData.advanced_product.advanced_product_profiles,
      locale,
    )

    //* ============================================================
    //* Here we know if the profile exists or not, if it does
    //* this component can be displayed in the current locale
    //* else this item will be removed from client data by the
    //* above useEffect
    //* =============================================================
    if (productProfileIndex !== null && productProfileIndex !== undefined) {
      return (
        // <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
        <Card
          elevation={0}
          square={true}
          className={clsx(inCarousel ? classes.rootInCarousel : classes.root)}
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          <div className={classes.badgeBox}>
            {discounted && <DiscountBadge content={discountPercentage} />}
            {fastDelivery && <FastDeliveryBadge />}
            {newProduct && <NewProductBadge />}
          </div>

          <div className={classes.cardActionAreaContainer}>
            <CardActionArea style={{ height: '100%' }}>
              {/* // TODO ----> We should include lazy loading in this new component. */}
              <Link
                href={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
                prefetch={false}
              >
                <a className={classes.imageAnchorTagWrapper}>
                  <Image
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={imageLoadingPlaceholder}
                    objectFit="contain"
                    src={mainProductImage}
                    alt={
                      productData.advanced_product.advanced_product_profiles[
                        productProfileIndex
                      ]?.name
                    }
                    priority={true}
                  />
                </a>
              </Link>
              <div className={classes.advancedInfoBox}>
                <Tooltip title={configurableProductText} placement="top">
                  <LayersOutlinedIcon sx={{ fontSize: 30 }} />
                </Tooltip>
              </div>
            </CardActionArea>
            <div className={classes.wishlistIconContainer}>
              {!isInWishlist ? (
                <FavoriteBorderIcon
                  className={classes.icon}
                  aria-label="add to favorites"
                  onClick={() => {
                    addToWishlist({
                      id: productData.id,
                      name:
                        productData.advanced_product.advanced_product_profiles[
                          productProfileIndex
                        ]?.name,
                      price: productData.advanced_product.price,
                      sku: productData.advanced_product.sku,
                      image: productData.advanced_product.images[0].src,
                      permalink:
                        productData.advanced_product.advanced_product_profiles[
                          productProfileIndex
                        ].meta_information.permalink,
                      product_type: productData.type,
                      dispatchInformation: productData.dispatch_information,
                      discount: productData.discount,
                    })
                    wishlistDrawerVar(true)
                    setisInWishlist(true)
                  }}
                />
              ) : (
                <FavoriteIcon
                  className={classes.favoriteIcon}
                  aria-label="remove from favorites"
                  onClick={() => {
                    removeFromWishlist(productData.id)
                    setisInWishlist(false)
                  }}
                />
              )}
            </div>
          </div>
          <CardContent className={classes.cardContent}>
            <div className={classes.namePriceContainer}>
              <Link
                href={`/${productSlug}/${activeProduct.productProfile.meta_information.permalink}`}
                prefetch={false}
              >
                <span className="cursorPointer">
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="h2"
                    className={classes.productName}
                  >
                    {activeProduct.productProfile.name}
                  </Typography>
                </span>
              </Link>
              {/* <Typography variant="body2" component="p">
              <ProductPrice price={activeProduct.price} />
              </Typography> */}
              <div className={classes.pricesContainer}>
                <Typography
                  variant={discounted ? 'body1' : 'body2'}
                  component="p"
                  className={clsx(discounted && classes.discoutedPrice)}
                >
                  <ProductPrice price={activeProduct.price} />
                </Typography>
                <Typography variant="subtitle1" component="p">
                  {discountedPrice && <ProductPrice price={discountedPrice} />}
                </Typography>
              </div>
            </div>
            <CardActions
              disableSpacing
              className={classes.cardActions}
            ></CardActions>
          </CardContent>
        </Card>
        // </Grid>
      )
    }
    // ==================
    // Here the page this component was loaded in had a productContainer
    // matching the current locale, but the productContainer didn't have
    // a profile matching this language so we return null.
    // ==================
    else {
      return null
    }
  }
  // ==================
  // Here the page this component was loaded in had no productContaiener
  // matching the current locale, so we return null.
  // ==================
  else {
    return null
  }
}

export default DynamicCategoryProductCard
