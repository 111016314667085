import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    md: 600,
    lg: 1175,
    xl: 1250,
  },
});

// Generate CSS to be injected into the head
export const mediaStyle = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
