// import { cache } from "../../../store/cache";
import { RecentlyViewedItem } from "../../../store/models";
import { recentlyViewedVar } from "../../../store/reactiveVars";
import createRecentlyViewedItem from "../../../utilityFunctions/createRecentlyViewedItem";

//! ADD TO RECENTLY VIEWED MUTATION
export const addToRecentlyViewed = (constructor) => {
  const newRecentlyViewedItem: RecentlyViewedItem = createRecentlyViewedItem(
    constructor.id,
    constructor.name,
    constructor.price,
    constructor.image,
    constructor.permalink,
    constructor.product_type,
    constructor.dispatchInformation,
    constructor.discount
  );

  const recentlyViewed: RecentlyViewedItem[] = JSON.parse(
    localStorage.getItem("recentlyViewed")
  );

  if (recentlyViewed) {
    let isSame = false;
    let isSameIndex;

    recentlyViewed.map((item, i) => {
      if (item.id === newRecentlyViewedItem.id) {
        isSame = true;
        isSameIndex = i;
        return;
      }
    });

    if (isSame) {
      recentlyViewed.splice(isSameIndex, 1);
      recentlyViewed.unshift(newRecentlyViewedItem);
    } else {
      recentlyViewed.unshift(newRecentlyViewedItem);
    }
    // check the length of recentlyViewed and make sure it's always less than 15
    if (recentlyViewed.length > 15) {
      recentlyViewed.pop();
    }

    localStorage.setItem("recentlyViewed", JSON.stringify([...recentlyViewed]));
    recentlyViewedVar([...recentlyViewed]);
  } else {
    localStorage.setItem(
      "recentlyViewed",
      JSON.stringify([newRecentlyViewedItem])
    );
    recentlyViewedVar([newRecentlyViewedItem]);
  }
};

//! REMOVE FROM LOCAL STORAGE RECENTLYVIEWED
export const removeFromRecentlyViewed = (recentlyViewedID) => {
  const recentlyViewed: RecentlyViewedItem[] = JSON.parse(
    localStorage.getItem("recentlyViewed")
  );

  recentlyViewed.forEach((recentlyViewedItem, i) => {
    if (recentlyViewedItem.id === recentlyViewedID) {
      recentlyViewed.splice(i, 1);
    }
  });

  localStorage.setItem("recentlyViewed", JSON.stringify([...recentlyViewed]));
  recentlyViewedVar([...recentlyViewed]);
};
