import { useReactiveVar } from '@apollo/client'
import { Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { variantsDrawerStateVar } from '../../../store/reactiveVars'
import { Content } from '../BlogPostPageElements'
import ColorDrawerCard from './ColorDrawerCard'
import FrameColorDrawerCard from './FrameColorDrawerCard'
import SizeDrawerCard from './SizeDrawerCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      drawer: {
        width: 450,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topDrawerContent: {
        margin: theme.spacing(2, 0, 3, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      cartContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
      },
      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiColorsDrawerComponent' },
)

const drawerForms = {
  color: COLOR_FORM,
  frame_color: FRAME_COLOR_FORM,
  size: SIZE_FORM,
  returns_information: RETURNS_INFORMATION_FORM,
}

export default function VariantsDrawer({
  groupedByColorProducts,
  groupedBySizeProducts,
  groupedByFrameColor,
  deliveryInformation = null,
}) {
  const { t } = useTranslation(['common'])
  const colorVariationsHeader = t('color-variations-header')
  const frameColorVariationsHeader = t('frame-color-variations-header')
  const sizeVariationsHeader = t('size-variations-header')
  const classes = useStyles() as any
  const variantsDrawerState = useReactiveVar(variantsDrawerStateVar)

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={variantsDrawerState.open}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() =>
          variantsDrawerStateVar({
            open: false,
            data: null,
            component: '',
          })
        }
      >
        {variantsDrawerState.component === 'COLOR_FORM'
          ? drawerForms.color(groupedByColorProducts, classes, {
              colorVariationsHeader,
            })
          : variantsDrawerState.component === 'FRAME_COLOR_FORM'
          ? drawerForms.frame_color(groupedByFrameColor, classes, {
              frameColorVariationsHeader,
            })
          : variantsDrawerState.component === 'SIZE_FORM'
          ? drawerForms.size(groupedBySizeProducts, classes, {
              sizeVariationsHeader,
            })
          : variantsDrawerState.component === 'RETURNS_INFORMATION_FORM'
          ? drawerForms.returns_information(variantsDrawerState, classes)
          : null}
      </Drawer>
    </>
  )
}

//* ========== COLORS
function COLOR_FORM(groupedByColorProducts, classes, translatedData) {
  const { colorVariationsHeader } = translatedData

  return (
    <>
      <div className={classes.topDrawerContent}>
        <Typography variant="h6">{colorVariationsHeader}</Typography>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() =>
            variantsDrawerStateVar({
              open: false,
              data: null,
              component: '',
            })
          }
        />
      </div>

      <Grid container spacing={1} style={{ padding: 1 }}>
        {groupedByColorProducts.map((product, index) => {
          if (index === 0) {
            return (
              <Grid item xs={6} key={index}>
                <ColorDrawerCard
                  // This is some syntax bug but original id is passed as ID, however it should be product container ID from the top
                  productID={product.id}
                  selected
                />
              </Grid>
            )
          }
          return (
            <Grid item xs={6} key={index}>
              <ColorDrawerCard productID={product.product_containerId} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

//* ========== FRAME COLORS
function FRAME_COLOR_FORM(groupedByFrameColor, classes, translatedData) {
  const { frameColorVariationsHeader } = translatedData

  return (
    <>
      <div className={classes.topDrawerContent}>
        <Typography variant="h6">{frameColorVariationsHeader}</Typography>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() =>
            variantsDrawerStateVar({
              open: false,
              data: null,
              component: '',
            })
          }
        />
      </div>

      <Grid container spacing={1} style={{ padding: 1 }}>
        {groupedByFrameColor.map((product, index) => {
          if (index === 0) {
            return (
              <Grid item xs={6} key={index}>
                <FrameColorDrawerCard productID={product.id} selected />
              </Grid>
            )
          }
          return (
            <Grid item xs={6} key={index}>
              <FrameColorDrawerCard productID={product.product_containerId} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

//* ========== SIZES
function SIZE_FORM(groupedBySizeProducts, classes, translatedData) {
  const { sizeVariationsHeader } = translatedData

  return (
    <>
      <div className={classes.topDrawerContent}>
        <Typography variant="h6">{sizeVariationsHeader}</Typography>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() =>
            variantsDrawerStateVar({
              open: false,
              data: null,
              component: '',
            })
          }
        />
      </div>

      <Grid container spacing={1} style={{ padding: 1 }}>
        {groupedBySizeProducts.map((product, index) => {
          if (index === 0) {
            return (
              <Grid item xs={6} key={index}>
                <SizeDrawerCard productID={product.id} selected />
              </Grid>
            )
          }
          return (
            <Grid item xs={6} key={index}>
              <SizeDrawerCard productID={product.product_containerId} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

//* ========== SIZES
function RETURNS_INFORMATION_FORM(variantsDrawerState, classes) {
  // Data comes from DRAFT JS Object
  // We need to parse and display data accordingly
  // console.log("variantsDrawerState.data ->", variantsDrawerState.data);

  const content = variantsDrawerState.data
    ? JSON.parse(variantsDrawerState.data)
    : null

  return (
    <>
      <div className={classes.topDrawerContent}>
        <Typography variant="h6"></Typography>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() =>
            variantsDrawerStateVar({
              open: false,
              data: null,
              component: '',
            })
          }
        />
      </div>
      {content ? (
        <Content content={content} />
      ) : (
        <p>Policy is not translated to your language.</p>
      )}
    </>
  )
}
