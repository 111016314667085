import {
  Breadcrumbs,
  Container,
  Divider,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import {
  CategoryProductList,
  ChildCategoriesSlider,
} from '../DesktopElements/CategoryPageElements'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      h1: {
        padding: theme.spacing(3, 0),
        textTransform: 'capitalize',
      },
      divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      capitalize: {
        textTransform: 'capitalize',
      },
    }),
  { name: 'MuiCategoryPageComponent' },
)

const CategoryPage = ({
  permalink,
  fullPermalink,
  categoryName,
  categoryDescription,
  childCategoriesData,
  staticData,
  productsCount,
  numberOfPages,
  pageNumber,
  categoryId,
}) => {
  const classes = useStyles() as any
  const { t } = useTranslation(['common'])
  const categorySlug = t('category-slug')

  // ---- Didnt work here for some reason....
  // const { data: productsMinMaxPriceStatic } = useQuery(
  //   GET_MIN_MAX_PRICE_STATIC,
  //   {
  //     variables: { language: locale, permalink: permalink },
  //   }
  // );
  // const {
  //   loading,
  //   error,
  //   data: productsMinMaxDimensions,
  // } = useQuery(GET_MIN_MAX_DIMENSIONS_STATIC, {
  //   variables: { language: locale, permalink: permalink },
  // });

  // console.log("productsMinMaxPriceStatic ->", productsMinMaxPriceStatic);
  // console.log("productsMinMaxDimensions :>> ", productsMinMaxDimensions);
  // if (!productsMinMaxPriceStatic && !productsMinMaxDimensions) return null;

  return (
    <>
      <Container maxWidth="xl">
        <Breadcrumbs aria-label="breadcrumb" style={{ padding: '1rem 0' }}>
          <Link prefetch={false} href="/">
            <Typography
              variant="caption"
              component="a"
              className={classes.capitalize}
            >
              {t('home-bread-crumb')}
            </Typography>
          </Link>
          <Typography variant="caption" className={classes.capitalize}>
            {categoryName}
          </Typography>
        </Breadcrumbs>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          gutterBottom
          className={classes.h1}
        >{`${categoryName}`}</Typography>

        {/* // TODO: display slider only if category has childCategories */}
        <ChildCategoriesSlider
          permalink={permalink}
          fullPermalink={fullPermalink}
          data={childCategoriesData}
        />

        <Divider className={classes.divider} />

        <CategoryProductList
          staticData={staticData}
          productsCount={productsCount}
          permalink={permalink}
          numberOfPages={numberOfPages}
          pageNumber={pageNumber}
          pageSlug={categorySlug}
          fullPermalink={fullPermalink}
          categoryId={categoryId}
        />

        <Divider className={classes.divider} />

        <Typography
          component="h3"
          variant="body1"
          align="center"
          gutterBottom
        >{`${categoryDescription}`}</Typography>
      </Container>
    </>
  )
}

export default CategoryPage
