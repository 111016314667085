import { useLazyQuery, useReactiveVar } from '@apollo/client'
import {
  Button,
  Container,
  Divider,
  Grid,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Media } from '../../../lib/artsyFresnel/Media'
import { useShopContext } from '../../context'
import { GET_MATCHING_COUPON } from '../../operations/queries/cartPageQueries'
import {
  cartItemsVar,
  orderVar,
  shopSettingsVar,
} from '../../store/reactiveVars'
import couponValidityCheck from '../../utilityFunctions/couponValidityCheck'
import getAndUpdateCheckoutPrices from '../../utilityFunctions/getAndUpdateCheckoutPrices'
import {
  calculateVatAmount,
  priceFormatter,
} from '../../utilityFunctions/priceUtils'
// * ELEMENTS
import { CartPageItem } from '../DesktopElements/CartPageElements'
import { InfoBoxes } from '../DesktopElements/CheckoutElements'
import { FlexBox, useSnackbarContext } from '../DesktopElements/Utils'
import { ResponsiveCartPageItem } from '../ResponsiveElements/ResponsiveCartPageElements'
import { ProductPrice } from '../UtilityComponents/price'
import { SendCartToEmailButton } from '../DesktopElements/Utils/SendCartToEmailButton'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      // marginTop: "3rem",
    },
    tableHead: {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    caption: {
      display: 'inline-block',
      opacity: 0.5,
      paddingTop: theme.spacing(2),
      textTransform: 'uppercase',
    },
    h1: {
      padding: theme.spacing(3, 0, 2, 0),
      [theme.breakpoints.between('sm', 'lg')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '1.5rem',
      },
    },
    productHeader: {
      width: '30rem',
      [theme.breakpoints.down('lg')]: {
        width: 'fit-content',
      },
    },
    checkoutButton: {
      display: 'block',
      width: '45%',
      marginLeft: 'auto',
      [theme.breakpoints.down('lg')]: {
        marginLeft: 0,
        width: '100%',
      },
    },
    orderTotalContainer: {
      width: '45%',
      marginLeft: 'auto',
      padding: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: theme.spacing(1, 0),
        margin: 0,
      },
    },
    cartInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mobileCartInfoContainer: {
      paddingTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    addCouponButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    inputBaseRoot: {
      backgroundColor: theme.palette.background.paper,
      width: '130%',
      [theme.breakpoints.up('md')]: {
        width: '170%',
      },
    },
    input: {
      border: '1px solid #d3d3d3',
      padding: theme.spacing(1.3, 1),
    },
    redeemBtn: {
      backgroundColor: theme.palette.background.paper,
    },
    couponContainer: {
      marginTop: theme.spacing(2),
    },
    removeCoupon: {
      cursor: 'pointer',
      fontWeight: 500,
      textDecoration: 'underline',
    },
  }),
  { name: 'MuiCartPageComponent' },
)

export default function CartPage() {
  const { t, i18n } = useTranslation(['common'])
  const generalCouponMessage = t('general-coupon-error-message')
  const couponIsExpiredErrorMessage = t('coupon-is-expired-error-message')
  const nonCumulativeCouponWhenAddingCoupon = t(
    'non-cumulative-coupon-when-adding-coupon',
  )
  const checkoutSlug = t('checkout-slug')
  const backToShoppingCta = t('back-to-shopping-cta')
  const cartPageSubtotal = t('cart-page-subtotal')
  const pageHeader = t('page-header')
  const tableHeaderOne = t('table-header-one')
  const tableHeaderTwo = t('table-header-two')
  const tableHeaderThree = t('table-header-three')
  const tableHeaderFour = t('table-header-four')
  const itemSingular = t('item-singular')
  const itemPlural = t('item-plural')
  const excludingDeliveryString = t('excluding-delivery')
  const couponCtaContent = t('coupon-cta-content')
  const couponCtaButton = t('coupon-cta-button')
  const goToCheckoutCta = t('go-to-checkout-cta')
  const cartPageTotalString = t('cart-page-total')
  const discountString = t('discount')
  const errorMessageCalculatingVAT = t('error-message-calculating-vat')
  const couponInputPlaceholder = t('coupon-input-placeholder')
  const couponInputSubmit = t('coupon-input-submit')
  const fieldRequiredError = t('field-is-required')
  const couponRemoveCtaButton = t('coupon-remove-cta-button')
  const couponAppliedString = t('coupon-applied')
  const VATString = t('VAT')
  const classes = useStyles() as any
  const { SHOP_ID } = useShopContext()
  const cartItems = useReactiveVar(cartItemsVar)
  const order = useReactiveVar(orderVar)
  const shopSettings = useReactiveVar(shopSettingsVar)
  const [redeemingCoupon, setRedeemingCoupon] = useState(false)
  const { snackbar, setSnackbar } = useSnackbarContext()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm()

  //* ==========================================================================
  //* ================== lazy query to get the entered coupon ==================
  //* ==========================================================================
  const [fetchCoupon, { refetch, called }] = useLazyQuery(GET_MATCHING_COUPON, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      couponValidityCheck(
        data.findUniqueCoupon,
        orderVar,
        order,
        cartItems,
        snackbar,
        setSnackbar,
        shopSettings,
        {
          generalCouponMessage,
          couponIsExpiredErrorMessage,
          nonCumulativeCouponWhenAddingCoupon,
        },
      )
      setRedeemingCoupon(false)
    },
    onError: () => {
      setRedeemingCoupon(false)

      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Something went wrong, please try adding the coupon again',
      })
    },
  })

  //I'm using this useEffect to trigger another onCompleted and a couponValidityCheck
  //In the couponValidityCheck we make some important calculation for the checkout
  //I would use refetch(), but it doesn't make the onCompleted method fire again
  // In the onCompleted method we make most of the checking
  useEffect(() => {
    if (order.coupon) {
      const coupon_code = order.coupon.coupon_code

      fetchCoupon({
        variables: {
          coupon_code,
        },
      })
    }

    getAndUpdateCheckoutPrices(shopSettings, cartItems, order, orderVar)

    return
  }, [order.coupon?.coupon_code, cartItems, shopSettings])

  //* ========================================================================
  //* ========================= ON COUPON SUBMIT =============================
  //* ========================================================================
  const onSubmit = (data) => {
    // trigger lazy query to get the coupon code
    // where coupon_code equals order.coupon_code
    fetchCoupon({
      variables: {
        coupon_code: data.coupon_code,
      },
    })
  }

  //* =================================================================
  //* ====================HANDLE EVENTS FUNCTIONS======================
  //* =================================================================
  const handleRemoveCoupon = () => {
    orderVar({
      ...order,
      coupon: null,
      discount_applied: null,
    })
  }

  return (
    <>
      {/* //* ============================================ */}
      {/* //* ================== MOBILE ================== */}
      {/* //* ============================================ */}
      <Media lessThan="lg">
        <Link prefetch={false} href="/">
          <Typography
            variant="caption"
            component="a"
            className={classes.caption}
          >
            {backToShoppingCta}
          </Typography>
        </Link>
        <Typography variant="h4" component="h1" className={classes.h1}>
          {pageHeader}
        </Typography>
        <Divider />
        <Grid container>
          {cartItems.map((cartItem) => {
            return (
              <ResponsiveCartPageItem cartItem={cartItem} key={cartItem.id} />
            )
          })}
        </Grid>

        {/* SUBTOTAL  */}
        <div className={classes.mobileCartInfoContainer}>
          <Typography variant="body2">
            {cartPageSubtotal}
            {cartItems.length === 1 ? (
              <Typography variant="caption">{` (1 ${itemSingular})`}</Typography>
            ) : (
              <Typography variant="caption">
                {` (${cartItems.length} ${itemPlural})`}
              </Typography>
            )}
          </Typography>
          <Typography variant="body2">
            {/* {priceFormatter(subtotalCartPrice(cartItems, shopSettings))} */}

            <ProductPrice price={order.sub_total_price} />
          </Typography>
        </div>

        {/* VAT  */}
        <div className={classes.mobileCartInfoContainer}>
          <Typography variant="body2">
            {`${VATString}(${shopSettings.vat_percentage}%) `}
          </Typography>
          <Typography variant="body2">
            <ProductPrice
              price={calculateVatAmount(
                order.sub_total_price,
                shopSettings.vat_percentage,
              )}
            />
          </Typography>
        </div>

        {/* TOTAL  */}
        <div className={classes.mobileCartInfoContainer}>
          <Typography variant="body2">{`${cartPageTotalString} `}</Typography>
          <Typography variant="body2">
            <ProductPrice price={order.total_price} />
          </Typography>
        </div>

        <Typography
          display="block"
          align="right"
          variant="caption"
          gutterBottom
        >
          {`(${excludingDeliveryString})`}
        </Typography>

        {/* //TODO: IMPORTANT check if this handles the case where the coupon is a percentage coupon and not a fixed amount */}
        {order.coupon && (
          <div className={classes.mobileCartInfoContainer}>
            <Typography variant="body2">{discountString}</Typography>
            <Typography variant="body2">
              {/* {`-${priceFormatter(order.discount_applied)}`} */}

              {'-'}
              <ProductPrice price={order.discount_applied} />
            </Typography>
          </div>
        )}

        <div className={classes.couponContainer}>
          {!redeemingCoupon && !order.coupon ? (
            <Typography variant="caption" component="span">
              {couponCtaContent}
              {
                <Typography
                  variant="caption"
                  component="span"
                  display="inline"
                  onClick={() => setRedeemingCoupon(true)}
                  className={classes.addCouponButton}
                >
                  {` ${couponCtaButton}`}
                </Typography>
              }
            </Typography>
          ) : redeemingCoupon && !order.coupon ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexBox gap="0.5rem" style={{ margin: '1rem 0' }}>
                <InputBase
                  classes={{
                    root: classes.inputBaseRoot,
                    input: classes.input,
                  }}
                  placeholder={couponInputPlaceholder}
                  inputProps={{
                    ...register('coupon_code', {
                      required: fieldRequiredError,
                    }),
                  }}
                  defaultValue=""
                />
                <Button
                  variant="outlined"
                  type="submit"
                  className={classes.redeemBtn}
                >
                  {couponInputSubmit}
                </Button>
              </FlexBox>
            </form>
          ) : !redeemingCoupon && order.coupon ? (
            <Typography variant="caption">
              {couponAppliedString}
              {
                <>
                  <Typography variant="body2" display="inline">
                    {`${order.coupon.coupon_code} `}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="inline"
                    className={classes.removeCoupon}
                    onClick={handleRemoveCoupon}
                  >
                    {`${couponRemoveCtaButton}`}
                  </Typography>
                </>
              }
            </Typography>
          ) : null}
        </div>

        <Divider />
        <Link prefetch={false} href={`/${checkoutSlug}`}>
          <Button
            classes={{
              root: classes.checkoutButton,
            }}
            variant="contained"
          >
            {goToCheckoutCta}
          </Button>
        </Link>
        {process.env.NEXT_PUBLIC_SHOPPING_CART_SEND_AS_PDF === 'true' && (
          <div style={{ marginTop: '20px' }}>
            <SendCartToEmailButton
              cartItems={cartItems}
              classes={{
                root: classes.checkoutButton,
              }}
            />
          </div>
        )}
      </Media>

      {/* ======================================= */}
      {/* =============== DESKTOP =============== */}
      {/* ======================================= */}

      <Media greaterThan="md">
        <Container maxWidth="lg">
          <Link prefetch={false} href="/">
            <Typography
              variant="caption"
              component="a"
              className={classes.caption}
            >
              {backToShoppingCta}
            </Typography>
          </Link>
          <Typography variant="h4" component="h1" className={classes.h1}>
            {pageHeader}
          </Typography>
          <Divider />
          <Table aria-label="simple table" className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.productHeader}>
                  {tableHeaderOne}
                </TableCell>
                <TableCell size="small" align="center">
                  {tableHeaderTwo}
                </TableCell>
                <TableCell size="small" align="center">
                  {tableHeaderThree}
                </TableCell>
                <TableCell size="small" align="center">
                  {tableHeaderFour}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map((cartItem) => {
                return <CartPageItem cartItem={cartItem} key={cartItem.id} />
              })}
            </TableBody>
          </Table>
          <div className={classes.orderTotalContainer}>
            <>
              {/* SUBTOTAL  */}
              <div className={classes.cartInfoContainer}>
                <Typography variant="body2">
                  {cartPageSubtotal}
                  {cartItems.length === 1 ? (
                    <Typography variant="caption">{` (1 ${itemSingular})`}</Typography>
                  ) : (
                    <Typography variant="caption">
                      {` (${cartItems.length} ${itemPlural})`}
                    </Typography>
                  )}
                </Typography>
                <Typography variant="body2">
                  <ProductPrice price={order.sub_total_price} />
                </Typography>
              </div>

              {/* VAT  */}
              <div className={classes.cartInfoContainer}>
                <Typography variant="body2">
                  {`${VATString}(${shopSettings.vat_percentage}%) `}
                </Typography>
                <Typography variant="body2">
                  <ProductPrice
                    price={calculateVatAmount(
                      order.sub_total_price,
                      shopSettings.vat_percentage,
                    )}
                  />
                </Typography>
              </div>

              {/* TOTAL  */}
              <div className={classes.cartInfoContainer}>
                <Typography variant="body2">
                  {`${cartPageTotalString} `}
                </Typography>
                <Typography variant="body2">
                  <ProductPrice price={order.total_price} />
                </Typography>
              </div>

              <Typography display="block" align="right" variant="caption">
                {`(${excludingDeliveryString})`}
              </Typography>

              {order.coupon && (
                <div className={classes.cartInfoContainer}>
                  <Typography variant="body2">{discountString}</Typography>
                  <Typography variant="body2">
                    {/* {`-${priceFormatter(order.discount_applied)}`} */}

                    {'-'}
                    <ProductPrice price={order.discount_applied} />
                  </Typography>
                </div>
              )}
            </>

            <div className={classes.couponContainer}>
              {!redeemingCoupon && !order.coupon ? (
                <Typography variant="caption" component="span">
                  {couponCtaContent}
                  {
                    <Typography
                      variant="caption"
                      component="span"
                      display="inline"
                      onClick={() => setRedeemingCoupon(true)}
                      className={classes.addCouponButton}
                    >
                      {` ${couponCtaButton}`}
                    </Typography>
                  }
                </Typography>
              ) : redeemingCoupon && !order.coupon ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FlexBox gap="0.5rem" style={{ marginTop: '2rem' }}>
                    <InputBase
                      classes={{
                        root: classes.inputBaseRoot,
                        input: classes.input,
                      }}
                      placeholder={couponInputPlaceholder}
                      inputProps={{
                        ...register('coupon_code', {
                          required: fieldRequiredError,
                        }),
                      }}
                      defaultValue=""
                    />
                    <Button
                      variant="outlined"
                      type="submit"
                      className={classes.redeemBtn}
                    >
                      {couponInputSubmit}
                    </Button>
                  </FlexBox>
                </form>
              ) : !redeemingCoupon && order.coupon ? (
                <Typography variant="caption">
                  {couponAppliedString}
                  {
                    <>
                      <Typography variant="body2" display="inline">
                        {` ${order.coupon.coupon_code} `}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="inline"
                        className={classes.removeCoupon}
                        onClick={handleRemoveCoupon}
                      >
                        {`(${couponRemoveCtaButton})`}
                      </Typography>
                    </>
                  }
                </Typography>
              ) : null}
            </div>
          </div>
          <Link prefetch={false} href={`/${checkoutSlug}`}>
            <Button
              classes={{
                root: classes.checkoutButton,
              }}
              variant="contained"
            >
              {goToCheckoutCta}
            </Button>
          </Link>
          {process.env.NEXT_PUBLIC_SHOPPING_CART_SEND_AS_PDF === 'true' && (
            <div style={{ marginTop: '20px' }}>
              <SendCartToEmailButton
                cartItems={cartItems}
                classes={{
                  root: classes.checkoutButton,
                }}
              />
            </div>
          )}
        </Container>
      </Media>
      <Container maxWidth="lg">
        <InfoBoxes />
      </Container>
    </>
  )
}
