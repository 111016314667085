import { useAppContext } from '../../context/appContext'
import { priceFormatter } from '../../utilityFunctions/priceUtils'

export const ProductPrice = ({ price }) => {
  //---
  //-- MIDDLEWARE FOR THE PRICE COMPONENT
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken
  const pricesDisabled = appContext.pricesDisabled

  const priceMultiplier = appContext.priceMultiplier
  //   console.log('b2bToken :>> ', b2bToken)
  //   console.log('shopSettings :>> ', shopSettings)

  //-- We add price_multiplier by locale
  let modifiedPrice = price * priceMultiplier

  //-- We add b2bdiscount
  if (b2bToken) {
    if (b2bToken.b2b_customer_discount && b2bToken.b2b_customer_discount > 0) {
      //-- We have a b2b discount in percentage from 1 to 99 percent. So we apply this on the price.
      const appliedDiscount = b2bToken.b2b_customer_discount
      modifiedPrice = price - price * (appliedDiscount / 100)
    }
    //--- WE NEED TO REMOVE VAT FOR B2B CUSTOMERS ALSO....
  }

  //----
  return (
    <>
      {pricesDisabled ? (
        <span></span>
      ) : (
        <span>{priceFormatter(modifiedPrice)}</span>
      )}
    </>
  )
}
