import { colors, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { useBlogPostContext } from '../../../context/blogPostContext'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { ProductPrice } from '../../UtilityComponents/price'
import { BlogPostCard } from '../BlogPageElements'
import { Image } from '../Utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(3, 2),
      border: `1px solid ${colors.grey[200]}`,
      borderRadius: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
    gridItem: {
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${colors.grey[200]}`,
      '&:last-child': {
        marginBottom: 0,
        borderBottom: 0,
        paddingBottom: 0,
      },
    },
    cardProduct: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      boxShadow: 'none',
      borderRadius: 0,
      '& .card-product__content': {
        padding: 0,
        paddingLeft: theme.spacing(2),
      },
      '& .card-product__media': {
        height: 90,
        width: 90,
        '& img': {
          height: 90,
          width: 90,
        },
      },
    },
    image: {
      objectFit: 'cover',
    },
    productContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '100%',
      cursor: 'default',
    },
    blogTitle: {
      // fontWeight: 700,
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tag: {
      margin: theme.spacing(0, 1 / 2, 1 / 2, 0),
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
      },
    },
  }),
  { name: 'MuiSidebarRelatedProductsComponent' },
)

const SidebarRelatedProducts = ({
  relatedProducts,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { locale } = useBlogPostContext()
  const classes = useStyles() as any
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const relatedProductsString = t('blog-post-related-products')

  const BlogMediaContent = ({ image, permalink, ...props }) => (
    <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
      <span className="cursorPointer">
        <Image
          image={{
            src: image.src,
          }}
          {...props}
          className={classes.image}
          lazyProps={{ width: '100%', height: '100%' }}
        />
      </span>
    </Link>
  )

  const BlogContent = ({ permalink, name, price }) => (
    <div className={classes.productContent}>
      <Link
        prefetch={false}
        href={`/${productSlug}/${permalink}`}
        locale={locale}
      >
        <Typography
          variant="body2"
          component="a"
          className={classes.blogTitle}
          gutterBottom
        >
          {name}
        </Typography>
      </Link>
      <Typography variant="caption" component="span">
        <i>
          {' '}
          <ProductPrice price={price} />
        </i>
      </Typography>
    </div>
  )

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography
        variant="h6"
        color="textPrimary"
        component="h6"
        gutterBottom
        className={classes.sectionTitle}
      >
        {relatedProductsString}
      </Typography>
      <Grid container spacing={0}>
        {relatedProducts.map((product: any) => {
          //* =========================================================
          //* =========== GET THE CORRECT PRODUCT PROFILE ============
          //* =========================================================
          const productProfileIndex: number = getProfileIndexFromLanguage(
            product.single_product.product_profiles,
            locale,
          )

          if (
            productProfileIndex !== null &&
            productProfileIndex !== undefined
          ) {
            return (
              <Grid
                item
                xs={12}
                key={product.id}
                data-aos="fade-up"
                className={classes.gridItem}
              >
                <BlogPostCard
                  className={classes.cardProduct}
                  mediaContent={
                    <BlogMediaContent
                      image={product.single_product.images[0]}
                      alt={
                        product.single_product.product_profiles[
                          productProfileIndex
                        ].name
                      }
                      permalink={
                        product.single_product.product_profiles[
                          productProfileIndex
                        ].meta_information.permalink
                      }
                    />
                  }
                  cardContent={
                    <BlogContent
                      name={
                        product.single_product.product_profiles[
                          productProfileIndex
                        ].name
                      }
                      price={product.single_product.price}
                      permalink={
                        product.single_product.product_profiles[
                          productProfileIndex
                        ].meta_information.permalink
                      }
                    />
                  }
                />
              </Grid>
            )
          } else {
            return null
          }
        })}
      </Grid>
    </div>
  )
}

export default SidebarRelatedProducts
