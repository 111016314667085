import { createContext, useContext } from "react";

const BlogPostContext = createContext(null);

export function BlogPostContextWrapper({ data, locale, children }) {
  return (
    <BlogPostContext.Provider value={{ data, locale }}>
      {children}
    </BlogPostContext.Provider>
  );
}

export function useBlogPostContext() {
  return useContext(BlogPostContext);
}
