// import { cartStateVar, wishlistDrawerVar } from "../../../store/reactiveVars";
// * TOPHEADER ELEMENTS
// import CartDrawer from "./CartDrawer";
// import WishlistDrawer from "./WishlistDrawer";
import { useReactiveVar } from '@apollo/client'
import { Badge, CardMedia, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import MenuIcon from '@mui/icons-material/Menu'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import Link from 'next/link'
import React from 'react'
import {
  cartItemsVar,
  cartStateVar,
  responsiveMenuVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import {
  CartDrawer,
  WishlistDrawer,
} from '../../DesktopElements/HeaderElements'
import { LanguageSwitch, SearchInput } from '../../DesktopElements/Utils'
import ResponsiveMenu from './ResponsiveMenu'
import { imageFallback } from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        padding: theme.spacing(2),
        height: 'fit-content',
        backgroundColor: theme.palette.background.paper,
      },
      responsiveMedia: {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        minHeight: '45px',
      },
      iconsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
          marginLeft: 'auto',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          marginLeft: 'auto',
          justifyContent: 'flex-end',
        },
      },
      icon: {
        [theme.breakpoints.only('xs')]: {
          height: '1.2rem',
          width: '1.2rem',
        },
      },
      searchBarContainer: {
        marginTop: theme.spacing(2),
      },
      iconWrapper: {
        [theme.breakpoints.between('sm', 'lg')]: {
          marginLeft: theme.spacing(2),
        },
      },
    }),
  { name: 'MuiResponsiveTopHeader' },
)

export default function ResponsiveTopHeader(props) {
  const classes = useStyles() as any
  const { locale, data } = props

  const cartItems = useReactiveVar(cartItemsVar)
  const wishlistItems = useReactiveVar(wishlistItemsVar)

  const appContext = useAppContext()
  const shopSettings = appContext ? appContext.shopSettings : null

  const siteLogoSrc = shopSettings
    ? shopSettings.default_manufacturer?.logo_image
      ? shopSettings.default_manufacturer.logo_image.src_xs ??
        shopSettings.default_manufacturer.logo_image.src
      : imageFallback
    : imageFallback

  if (!data) return null

  return (
    <>
      <Grid container className={classes.appBar}>
        <Grid
          item
          xs={4}
          style={{ display: 'flex', flexWrap: 'nowrap', gap: '13px' }}
        >
          <span className="cursorPointer">
            <MenuIcon onClick={() => responsiveMenuVar(true)} />
          </span>
          <div style={{ marginTop: '-2px' }}>
            <LanguageSwitch />
          </div>
        </Grid>

        <Grid item xs={4}>
          <Link prefetch={false} href="/">
            <CardMedia
              image={siteLogoSrc}
              className={classes.responsiveMedia}
            />
          </Link>
        </Grid>
        <Grid item xs={2} className={classes.iconsContainer}>
          <a className={classes.iconWrapper}>
            <Badge
              variant="standard"
              badgeContent={wishlistItems.length}
              color="secondary"
              onClick={() => wishlistDrawerVar(true)}
            >
              <FavoriteBorderIcon
                // className={classes.icon}
                classes={{ root: classes.icon }}
              />
            </Badge>
          </a>
          <a className={classes.iconWrapper}>
            <Badge
              variant="standard"
              badgeContent={cartItems.length}
              color="secondary"
              onClick={() => {
                cartStateVar(true)
              }}
            >
              <ShoppingBasketOutlinedIcon
                // className={classes.icon}
                classes={{ root: classes.icon }}
              />
            </Badge>
          </a>
        </Grid>
        <Grid item xs={12} className={classes.searchBarContainer}>
          <SearchInput mobile />
        </Grid>
      </Grid>

      <ResponsiveMenu categoriesData={data} locale={locale} />
      <CartDrawer />
      <WishlistDrawer />
    </>
  )
}
