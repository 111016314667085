import {
  Collapse,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import PhoneIcon from '@mui/icons-material/Phone'
import ReplayIcon from '@mui/icons-material/Replay'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { useShopContext } from '../../../context'

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      margin: theme.spacing(8, 0),
      [theme.breakpoints.down(959)]: {
        margin: theme.spacing(3, 0),
        gap: theme.spacing(2),
      },
      '&.MuiGrid-spacing-xs-3': {
        width: '100%',
      },
    },
    box: {
      maxWidth: '25rem',
      backgroundColor: 'white',
      minHeight: '10rem',
      margin: 'auto',
      [theme.breakpoints.down(959)]: {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      margin: 'auto 2rem',
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'column',
      // height: "8rem",
      justifyContent: 'space-evenly',

      // margin: "0 1rem",
    },
    listItemText: {
      padding: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    accordionSummaryDescriptiom: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    collapse: {
      maxWidth: '25rem',
      backgroundColor: 'white',
      padding: '1rem',
      margin: 'auto',
      [theme.breakpoints.down(959)]: {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
      },
    },
  }),
  { name: 'MuiInfoBoxesComponent' },
)

export default function InfoBoxes() {
  const { t } = useTranslation(['common'])
  const boxOneHeader = t('box-one-header')
  const boxOneSubHeader = t('box-one-subHeader')
  const boxOneContent = t('box-one-content')
  const boxTwoHeader = t('box-two-header')
  const boxTwoSubHeader = t('box-two-subHeader')
  const boxTwoContentOne = t('box-two-content-one')
  const boxTwoContentTwo = t('box-two-content-two')
  const boxTwoContentThree = t('box-two-content-three')
  const boxThreeHeader = t('box-three-header')
  const boxThreeSubHeader = t('box-three-subHeader')
  const boxThreeContentOne = t('box-three-content-one')
  const boxThreeContentTwo = t('box-three-content-two')
  const boxThreeContentThree = t('box-three-content-three')
  const boxThreeContentFour = t('box-three-content-four')
  const [shippingBoxOpen, setShippingBoxOpen] = useState(false)
  const [returnsBoxOpen, setReturnsBoxOpen] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const handleShippingBox = (panel) => {
    setShippingBoxOpen(!shippingBoxOpen)
  }

  const handleReturnsBox = (panel) => {
    setReturnsBoxOpen(!returnsBoxOpen)
  }

  const { shopPhoneNumber } = useShopContext()
  // console.log('shopPhoneNumber (context) :>> ', shopPhoneNumber)

  const classes = useStyles() as any
  return (
    <>
      <Grid container className={classes.container} spacing={matches ? 3 : 0}>
        {/* //* ========================================================================================== */}
        {/* //* ================================== SUPPORT BOX =========================================== */}
        {/* //* ========================================================================================== */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <ListItem className={classes.box}>
            <ListItemIcon>
              <PhoneIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <div className={classes.accordionSummaryContent}>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    display="block"
                    gutterBottom
                  >
                    {boxOneHeader}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    display="block"
                    className={classes.accordionSummaryDescriptiom}
                  >
                    {/* {boxOneSubHeader} */}

                    <a href={`tel:${shopPhoneNumber}`}>{shopPhoneNumber}</a>
                  </Typography>
                </div>
              }
              className={classes.listItemText}
            />
          </ListItem>
        </Grid>

        {/* //* ========================================================================================== */}
        {/* //* ================================== SHIPPING BOX ========================================== */}
        {/* //* ========================================================================================== */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <ListItem button onClick={handleShippingBox} className={classes.box}>
            <ListItemIcon>
              <LocalShippingOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <div className={classes.accordionSummaryContent}>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    display="block"
                    gutterBottom
                  >
                    {boxTwoHeader}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    display="block"
                    className={classes.accordionSummaryDescriptiom}
                  >
                    {boxTwoSubHeader}
                  </Typography>
                </div>
              }
              className={classes.listItemText}
            />
            {shippingBoxOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={shippingBoxOpen}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <Typography variant="body1" component="p" paragraph>
              {boxTwoContentOne}
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              {boxTwoContentTwo}
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              {boxTwoContentThree}
            </Typography>
          </Collapse>
        </Grid>

        {/* //* ========================================================================================== */}
        {/* //* ================================== RETURNS BOX =========================================== */}
        {/* //* ========================================================================================== */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <ListItem button onClick={handleReturnsBox} className={classes.box}>
            <ListItemIcon>
              <ReplayIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <div className={classes.accordionSummaryContent}>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    display="block"
                    gutterBottom
                  >
                    {boxThreeHeader}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    display="block"
                    className={classes.accordionSummaryDescriptiom}
                  >
                    {boxThreeSubHeader}
                  </Typography>
                </div>
              }
              className={classes.listItemText}
            />
            {returnsBoxOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={returnsBoxOpen}
            timeout="auto"
            unmountOnExit
            className={classes.collapse}
          >
            <Typography variant="body1" component="p" paragraph>
              {boxThreeContentOne}
            </Typography>

            <Typography variant="subtitle1" component="p" paragraph>
              {boxThreeContentTwo}
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              {boxThreeContentThree}
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              {boxThreeContentFour}
            </Typography>
          </Collapse>
        </Grid>
      </Grid>
    </>
  )
}
