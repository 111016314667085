import {
  Alert,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'
import { Contact } from '../DesktopElements/ContactElements'
import {
  Form,
  Hero,
  HeroShaped,
  Section,
  SectionAlternate,
  SectionHeader,
  Image,
} from '../DesktopElements/Utils'
import InfoIcon from '@mui/icons-material/Info'

const useStyles = makeStyles((theme: Theme) => ({
  contactSection: {
    marginLeft: 'auto',
  },

  contactsContainer: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },

  image: {
    maxWidth: 420,
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      margin: 'auto',
    },
  },

  contactDetailsContainer: {
    padding: 50,
    margin: 10,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 5,
    },
  },
}))

const ContactPage = ({ contactUsPageData, locale, ...props }): JSX.Element => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const contactInfoHeader = t('contact-info-header')
  const contactInfoSubHeader = t('contact-info-subHeader')

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  // console.log('contactUsPageData :>> ', contactUsPageData)

  const pageData = contactUsPageData?.data?.findFirstShopSetting ?? null

  const contactUsProfileIndex: number = getProfileIndexFromLanguage(
    pageData?.contact_us_page?.contact_us_page_profiles,
    locale,
  )

  if (
    !pageData ||
    contactUsProfileIndex < 0 ||
    contactUsProfileIndex === null
  ) {
    return (
      <div className={classes.root}>
        <Hero
          clipped
          image={'/AboutHero.jpg'}
          withHeader
          title={'Information is being prepared.'}
          // subtitle={heroSubHeader}
          style={{ maxHeight: '200px' }}
          alignLeft={true}
        />
        <Section id="ourStory">
          <p>Information is being prepared...</p>
        </Section>
      </div>
    )
  }

  const contactUsProfile =
    pageData.contact_us_page.contact_us_page_profiles[contactUsProfileIndex] ??
    null

  const bottomImage = contactUsProfile?.bottomImage
    ? contactUsProfile.bottomImage.src_md ??
    contactUsProfile.bottomImage.src ??
    null
    : null

  const heroImage = pageData.contact_us_page.main_image
    ? pageData.contact_us_page.main_image.src_lg ??
    pageData.contact_us_page.main_image.src ??
    null
    : null

  const heroHeader = contactUsProfile.name ?? ''
  const heroSubHeader = contactUsProfile.pageTopText ?? ''
  const contactBlocks = contactUsProfile.contact_blocks ?? []
  const showForm = pageData.contact_us_page.show_contact_form

  const contactBottomTitle = contactUsProfile.contactBottomTitle ?? null
  const contactBottomText = contactUsProfile.contactBottomText ?? null
  const contactBottomImage = contactUsProfile.contactBottomImage
    ? contactUsProfile.contactBottomImage.src_md ??
    contactUsProfile.contactBottomImage.src ??
    null
    : null

  return (
    <div>
      <Hero
        // clipped
        image={heroImage ?? '/AboutHero.jpg'}
        withHeader
        title={heroHeader}
        subtitle={heroSubHeader}
        style={{ maxHeight: '200px' }}
        alignLeft={true}
      />

      {contactBlocks.map((contactBlock, i) => {
        // console.log('contactBlock :>> ', contactBlock)
        return (
          <Section key={i} fullWidth disablePadding>
            <Contact
              data={contactBlock}
              switchSides={contactBlock.switchSides}
            />
          </Section>
        )
      })}

      <Grid
        container
        // maxWidth={'lg'}
        spacing={2}
        className={classes.contactDetailsContainer}
      >
        <Grid item xs={12} md={6}>
          <Container className={classes.contactsContainer}>
            <SectionHeader
              title={contactBottomTitle}
              // subtitle={'lalalal'}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              data-aos="fade-up"
              align="left"
            />

            <ListItem disableGutters data-aos="fade-up">
              <ListItemText
                primary=""
                secondary={
                  <>
                    {contactBottomText.split('\n').map((line, i) => (
                      <Typography key={i} variant="subtitle1" component="p">
                        {line}
                      </Typography>
                    ))}
                  </>
                }
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  color: 'textSecondary',
                }}
                secondaryTypographyProps={{
                  variant: 'subtitle1',
                  color: 'textPrimary',
                }}
              />
            </ListItem>
          </Container>
        </Grid>

        <Grid
          item
          container
          justifyContent={'space-between'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          {contactBottomImage && (
            <Image
              image={{ src: contactBottomImage }}
              alt={''}
              className={classes.image}
            />
          )}
        </Grid>
      </Grid>
      {showForm && (
        <SectionAlternate>
          <Form locale={locale} />
        </SectionAlternate>
      )}
      <Divider />
    </div>
  )
}

export default ContactPage
