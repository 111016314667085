// Takes in the product createdAt and returnd true or false
// if the date is older than 14 === false
export const checkIfProductIsNew = (createdAt) => {
  const createdDate = new Date(createdAt)
  const dateNow = new Date()
  const differenceInTime = dateNow.getTime() - createdDate.getTime()
  const differenceInDays = differenceInTime / (1000 * 3600 * 24)
  //? -------------> NEW PRODUCT IF CREATED WITHIN 14 days..
  // TODO change to 14
  const newProduct = differenceInDays < 30 ? true : false

  return newProduct
}
