const isCompanyInSameCountry = (
  defaultManufacturerVATCode,
  buyingCompanyVATCode,
) => {
  console.log('defaultManufacturerVATCode :>> ', defaultManufacturerVATCode)
  console.log('buyingCompanyVATCode :>> ', buyingCompanyVATCode)

  //This will be passed to the regex.test
  const buyingVATprefix = buyingCompanyVATCode.substring(0, 2).toUpperCase()
  //This will be used as model to test against
  const defaultVATprefix = defaultManufacturerVATCode
    .substring(0, 2)
    .toUpperCase()

  // const regex = /^LT$|^lt$/;
  // check here if the company is lithuanian or not with regex
  // and let's put a boolean in order reactive var for easy check in other components
  //TODO: for system
  //1)query for the default manufacturer in this shop
  //2)take his VAT code and substring the first two letters
  //3)take the capitalized and lower case version of those two letters
  //4)put them in the bottom RegExp as variables
  //This will make it univarsal for shops in any country
  const regex = new RegExp(`^${defaultVATprefix}$`)
  let isCompanyInSameCountry = regex.test(buyingVATprefix)

  const testVatSyntax = new RegExp(
    '^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$',
  )
  const isInputValidVATnumber = testVatSyntax.test(buyingCompanyVATCode)

  console.log('isInputValidVATnumber :>> ', isInputValidVATnumber)

  if (!isInputValidVATnumber) {
    // Not a vlid VAT
    isCompanyInSameCountry = true
  }
  // --- empty string case..
  if (buyingVATprefix.replace(/\s/g, '') === '') {
    // If its an empty string... Apply VAT.
    isCompanyInSameCountry = true
  }

  // console.log('isCompanyInSameCountry :>> ', isCompanyInSameCountry)

  return isCompanyInSameCountry
}

export default isCompanyInSameCountry
