export const sortImages = (images) => {
  const imagesCopy = [...images];

  imagesCopy.sort(({ display_order: a }, { display_order: b }) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });

  return imagesCopy;
};
