import { useReactiveVar } from '@apollo/client'
import { Typography, Slider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { colorsFilterVar, priceFilterVar } from '../../../../store/reactiveVars'
import { priceFormatter } from '../../../../utilityFunctions/priceUtils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      sliderThumb: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginTop: '-7px',
        backgroundColor: theme.palette.background.paper,
        border: '0.5px solid #000',
      },
      minMaxPrice: {
        fontWeight: 500,
      },
    }),
  { name: 'MuiFilterPriceRangeComponent' },
)

export default function FilterPriceRange({ priceData }) {
  const classes = useStyles() as any
  const priceFilter = useReactiveVar(priceFilterVar)
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const [value, setValue] = useState([])
  const { loading, min, max } = priceData

  //* =====================================================================================================
  //* handling different cases on component mount (first render - coming from an already filtered category)
  //* =====================================================================================================
  useEffect(() => {
    // console.log('loading :>> ', loading)
    // console.log('min :>> ', min)
    // console.log('max :>> ', max)

    if (priceFilter.minPrice > min && priceFilter.maxPrice < max) {
      setValue([priceFilter.minPrice, priceFilter.maxPrice])
    } else if (!priceFilter.minPrice && !priceFilter.maxPrice) {
      setValue([min, max])
    } else if (priceFilter.minPrice < min || priceFilter.maxPrice < max) {
      setValue([min, priceFilter.maxPrice])
    } else if (priceFilter.minPrice > min || priceFilter.maxPrice > max) {
      setValue([priceFilter.minPrice, max])
    } else if (priceFilter.minPrice < min || priceFilter.maxPrice > max) {
      setValue([min, max])
    }
    // //* This is not needed since handleResetFilters utility handles everything
    // else if (priceFilter.minPrice === null || priceFilter.maxPrice === null) {
    //   setValue([min, max]);
    // }

    return () => {
      setValue([])
    }
  }, [])

  //* =========================================================================
  //* ======= Handling change while sliding and on mouse up (committed) =======
  //* =========================================================================
  const handleChange = (event: any, newValue: number[]) => {
    setValue(newValue)
  }

  // here we wait for the user to stop dragging (mouseUp event ) to set the reactive var.
  const handleCommittedChange = (event: any, newValue: number[]) => {
    const minPrice = Math.min(...newValue)
    const maxPrice = Math.max(...newValue)

    // Handle case when the users resets the price and it matches the whole category, so that we can show static data with intelligent sort instead of full category from dynamic data with adiacent variations.
    if (minPrice === min && maxPrice === max) {
      priceFilterVar({ minPrice: null, maxPrice: null })
      return
    }

    console.log('Setting new priceFilterVAR')

    priceFilterVar({ minPrice, maxPrice })
  }

  //* ==============================================================================================
  //* =============== handling loading state, so that we return the component only once we have data
  //* ==============================================================================================
  if (loading) return <div>Loading...</div>

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography gutterBottom variant="caption" component="p">
            Min:{' '}
            <Typography
              variant="caption"
              component="span"
              className={classes.minMaxPrice}
            >
              {priceFormatter(value[0])}
            </Typography>
          </Typography>
          <Typography gutterBottom variant="caption" component="p">
            Max:{' '}
            <Typography
              variant="caption"
              component="span"
              className={classes.minMaxPrice}
            >
              {priceFormatter(value[1])}
            </Typography>
          </Typography>
        </div>
        <Slider
          value={value}
          disabled={
            //* =====================================================================================================================================
            //* if they're not null and they're the same it means that there's either only one product or all products have the same price. so better disable it until they turn on different filters that return different min and max.
            //* =====================================================================================================================================
            min === max ? true : false
          }
          classes={{
            thumb: classes.sliderThumb,
          }}
          onChangeCommitted={handleCommittedChange}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="price-slider"
          min={min}
          max={max}
        />
      </div>
    </>
  )
}
