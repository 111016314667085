import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
  InputLabel,
  InputBase,
} from '@mui/material'
import { SectionHeader, Image } from '../Utils'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(3, 2),
      borderRadius: theme.spacing(2),
      background: theme.palette.alternate.dark,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
    cover: {
      width: 200,
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      marginBottom: theme.spacing(3),
    },
    // form: {
    //   "& .MuiTextField-root": {
    //     background: theme.palette.background.paper,
    //   },
    //   "& .MuiOutlinedInput-input": {
    //     background: theme.palette.background.paper,
    //   },
    // },
    inputTitle: {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
    },
    input: {
      border: '1px solid #d3d3d3',
      padding: theme.spacing(1.3, 1),
      backgroundColor: theme.palette.background.paper,
    },
    inputLabel: {
      marginBottom: theme.spacing(0.5),
    },
  }),
  { name: 'MuiSidebarNewsletterComponent' },
)

//TODO: register this form with react-hook-form
const Form = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const sidebarNewsletterTitle = t('sidebar-newsletter-title')
  const sidebarNewsletterSubtitle = t('sidebar-newsletter-subtitle')
  const sidebarNewsletterInputLabelOne = t('sidebar-newsletter-input-label-one')
  const sidebarNewsletterInputPlaceholderOne = t(
    'sidebar-newsletter-input-placeholder-one',
  )
  const sidebarNewsletterInputLabelTwo = t('sidebar-newsletter-input-label-two')
  const sidebarNewsletterInputPlaceholderTwo = t(
    'sidebar-newsletter-input-placeholder-two',
  )
  const sidebarNewsletterCtaButton = t('sidebar-newsletter-cta-button')
  const sidebarNewsletterContent = t('sidebar-newsletter-content')
  const classes = useStyles() as any
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.cover}>
        <Image
          image={{
            src: 'https://assets.maccarianagency.com/the-front/illustrations/want-to-work.svg',
          }}
        />
      </div>
      <SectionHeader
        title={sidebarNewsletterTitle}
        subtitle={sidebarNewsletterSubtitle}
        titleProps={{
          variant: 'h4',
          color: 'textPrimary',
        }}
        subtitleProps={{
          variant: 'body1',
          color: 'textPrimary',
        }}
        data-aos="fade-up"
        align="left"
      />
      <div>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} data-aos="fade-up">
            <InputLabel className={classes.inputLabel}>
              <Typography variant="subtitle1" component="p">
                {sidebarNewsletterInputLabelOne}
              </Typography>
            </InputLabel>
            <InputBase
              name="fullname"
              fullWidth
              placeholder={sidebarNewsletterInputPlaceholderOne}
              autoComplete="family-name"
              inputProps={{
                'aria-label': sidebarNewsletterInputPlaceholderOne,
              }}
              classes={{
                input: classes.input,
              }}
              // inputRef={register}
            />
          </Grid>
          <Grid item xs={12} data-aos="fade-up">
            <InputLabel className={classes.inputLabel}>
              <Typography variant="subtitle1" component="p">
                {sidebarNewsletterInputLabelTwo}
              </Typography>
            </InputLabel>
            <InputBase
              name="email"
              fullWidth
              placeholder={sidebarNewsletterInputPlaceholderTwo}
              autoComplete="email"
              inputProps={{
                'aria-label': sidebarNewsletterInputPlaceholderTwo,
              }}
              classes={{
                input: classes.input,
              }}
              // inputRef={register}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button variant="contained" type="submit" size="large">
              {sidebarNewsletterCtaButton}
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Typography variant="caption" color="textSecondary">
              {sidebarNewsletterContent}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Form
