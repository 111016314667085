import { gql } from '@apollo/client'

export const GET_CATEGORY_BY_ID = gql`
  query GET_CATEGORY_BY_ID(
    $categoryID: Int!
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $colors: [SingleProductWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findUniqueCategory(where: { id: $categoryID }) {
      id
      category_profiles {
        id
        name
        language
      }
    }
    findManyProductContainerCount(
      where: {
        AND: [
          { categories: { some: { id: { equals: $categoryID } } } }
          { AND: $styles }
          { OR: $productTypes }
          {
            OR: [
              {
                single_product: {is: {
                  OR: [
                    { OR: $colors }
                    { dimensions: {is:{ AND: $dimensions } }}
                    {
                      product_profiles: {
                        some: { language: { equals: $language } }
                      }
                    }
                  ]
                }}
              }
              {
                advanced_product: {is: {
                  AND: [
                    {
                      advanced_product_profiles: {
                        some: { language: { equals: $language } }
                      }
                    }
                  ]
                }}
              }
            ]
          }
          $price
        ]
      }
    )
  }
`

export const GET_FILTERED_CATEGORIES = gql`
  query GET_FILTERED_CATEGORIES(
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $colors: [SingleProductWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findManyCategory(
      where: {
        AND: [
          {
            # ----> Looks irrelevant
            product_containers: {
              some: {
                AND: [
                  { AND: $styles }
                  { OR: $productTypes }
                  {
                    OR: [
                      {
                        single_product: { is: {
                          AND: [
                            { OR: $colors }
                            { dimensions: {is:{ AND: $dimensions } }}
                            {
                              product_profiles: {
                                some: { language: { equals: $language } }
                              }
                            }
                          ]
                        }}
                      }
                      {
                        advanced_product: { is: {
                          AND: [
                            # { dimensions: { AND: $dimensions } }
                            {
                              advanced_product_profiles: {
                                some: { language: { equals: $language } }
                              }
                            }
                          ]
                        }}
                      }
                    ]
                  }
                  $price
                ]
              }
            }
          }
        ]
      }
    ) {
      id
      parent_categoryId
      menu_order
    }
  }
`
