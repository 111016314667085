import { gql } from '@apollo/client'

export const GET_MIN_MAX_FILTERED_PRICE = gql`
  query GET_MIN_MAX_FILTERED_PRICE(
    $colors: [SingleProductWhereInput]
    $dimensions: [DimensionWhereInput]
    $language: Language # $permalink: String
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput] # $categories: [ProductContainerWhereInput]
  ) {
    SingleProductMinPrice(
      categories: $categories
      styles: $styles
      where: {
        AND: [
          # {
          #   product_container: {
          #     categories: {
          #       some: {
          #         category_profiles: {
          #           some: {
          #             AND: [
          #               {
          #                 meta_information: {
          #                   permalink: { equals: $permalink }
          #                 }
          #               }
          #               { language: { equals: $language } }
          #             ]
          #           }
          #         }
          #       }
          #     }
          #   }
          # }

          { OR: [] }
          {
            # ADD AN OBJECT WITH OR: [] FOR EACH FILTER WE WANT TO ADD
            OR: $colors
          }
          { product_profiles: { some: { language: { equals: $language } } } }
          { dimensions:{ is:  { AND: $dimensions }} }
          { OR: [] }
          { OR: [] }
        ]
      }
    )

    SingleProductMaxPrice(
      categories: $categories
      styles: $styles
      where: {
        AND: [
          {}
          {
            #             ADD AN OBJECT WITH OR: [] FOR EACH FILTER WE WANT TO ADD
            OR: $colors
          }
          { product_profiles: { some: { language: { equals: $language } } } }
          { dimensions: { is:{ AND: $dimensions }} }
          { OR: [] }
          { OR: [] }
        ]
      }
    )
  }
`
