import React, { createContext, useContext, useState } from 'react'
import { AlertColor, Button, Snackbar } from '@mui/material'
import { Alert } from '@mui/material'

interface SnackbarState {
  message: string
  severity: AlertColor
  open: boolean
  autoHideDuration: number
}

const SnackbarContext = createContext(null)

export function SnackbarComponent({ children }) {
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: 'error',
    open: false,
    autoHideDuration: 5000,
  } as SnackbarState)

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    })
  }

  return (
    <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
      <>{children}</>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        autoHideDuration={snackbar.autoHideDuration}
      >
        <Alert variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export function useSnackbarContext() {
  return useContext(SnackbarContext)
}
