//-----
// This component is related to the tracking model and invokes a dialog
// This dialog saves customer information and attaches a customer to a tracking session in the backend.

import React, { useState } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import TrackingUserModal from './TrackingUserModal'
import { useTranslation } from 'react-i18next'

type TrackingModuleButtonType =
  | 'consultation-button'
  | 'when-new-stock-button'
  | 'order-a-fabric-or-material-sample-button'
  | 'save-wishlist-button'

interface TrackingModuleButtonProps {
  type: TrackingModuleButtonType
  variant: 'contained' | 'outlined'
}

const TRACKING_MODULE_ENABLED = process.env.NEXT_PUBLIC_TRACKING_MODULE_ENABLED

export const TrackingModuleButton: React.FC<TrackingModuleButtonProps> = ({
  type,
  variant = 'contained',
}) => {
  //---
  const isTrackingModuleEnabled =
    TRACKING_MODULE_ENABLED === 'true' ? true : false
  //--- Translations
  const { t } = useTranslation(['common'])
  const freeConsultationButtonString = t('free-consultation-button')
  const whenNewStockButtonString = t('when-new-stock-button')
  const orderFabricSampleButtonString = t(
    'order-a-fabric-or-material-sample-button',
  )

  const saveWishlistButtonString = t('save-wishlist-button')

  //   let variant: 'contained' | 'outlined' = 'contained'
  let label = freeConsultationButtonString //default label
  if (type === 'consultation-button') {
    label = freeConsultationButtonString
  }
  if (type === 'when-new-stock-button') {
    label = whenNewStockButtonString
  }
  if (type === 'order-a-fabric-or-material-sample-button') {
    label = orderFabricSampleButtonString
  }
  if (type === 'save-wishlist-button') {
    label = saveWishlistButtonString
  }
  // State to control the modal's open/close state
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true)
  }

  // Rest of your component code
  return (
    <div style={{ width: '100%' }}>
      {isTrackingModuleEnabled && (
        <>
          <Button variant={variant} color="primary" onClick={openModal}>
            {label}
          </Button>

          {/* Render the UserModal component with open and onClose props */}
          <TrackingUserModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            type={type}
          />
        </>
      )}
    </div>
  )
}
