import { gql } from '@apollo/client'

export const GET_PRODUCT_IN_CART = gql`
  query GET_PRODUCT_IN_CART($itemID: Int) {
    findUniqueProductContainer(where: { id: $itemID }) {
      id
      type
      discount {
        id
        discount
        active
        startDate
        expiryDate
      }
      single_product {
        id
        price
        sku
        follow_product_stock
        quantity
        minimum_order_quantity
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
        images {
          id
          src
          display_order
        }
      }
      dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
    }
  }
`


export const GET_PRODUCT_IN_WISHLIST = gql`
query GET_PRODUCT_IN_WISHLIST($itemID: Int) {
  findUniqueProductContainer(where: { id: $itemID }) {
    id
    type
    discount {
      id
      discount
      active
      startDate
      expiryDate
    }
    single_product {
      id
      price
      quantity
      minimum_order_quantity
      product_profiles {
        name
        language
        meta_information {
          id
          permalink
        }
      }
      images {
        id
        src
        display_order
      }
    }
    advanced_product {
      id
      price_from
      minimum_order_quantity
      advanced_product_profiles {
        name
        language
        meta_information {
          id
          permalink
        }
      }
      images {
        id
        src
        display_order
      }
    }
    dispatch_information {
      id
      manufacturing_time
      shipping_time
      total_delivery_time
    }
  }
}
`