import { gql } from '@apollo/client'

export const GET_BLOG_POST_PATHS = gql`
  query GET_BLOG_POST_PATHS($language: Language) {
    findManyBlogPostProfile(where: { language: { equals: $language } }) {
      id
      meta_information {
        id
        permalink
      }
    }
  }
`

export const GET_BLOG_PAGE_CONTENT = gql`
  query GET_BLOG_PAGE_CONTENT($permalink: String) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      permalink
      meta_title
      meta_description
      blog_post_profile {
        id
        blog_post {
          id
          active
          createdAt
          updatedAt
          author {
            id
            name
            surname
          }
          featured_image {
            id
            src
          }
          related_products {
            id
          }
        }
        title
        summary
        language
        content
      }
    }
  }
`

export const GET_NEXT_3_BLOG_POSTS = gql`
  query GET_NEXT_3_BLOG_POSTS($permalink: String) {
    findManyBlogPost(
      where: {
        blog_post_profiles: {
          some: {
            meta_information: { is: { permalink: { not: { equals: $permalink } } }}
          }
        }
      }
      take: 3
    ) {
      id
      createdAt
      blog_post_profiles {
        id
        summary
        title
        language
        meta_information {
          id
          permalink
        }
      }
      author {
        id
        name
        surname
      }
      featured_image {
        id
        src
      }
    }
  }
`

export const GET_SIDEBAR_PRODUCTS_BEST_SVP = gql`
  query GET_SIDEBAR_PRODUCTS_BEST_SVP($language: Language) {
    findManyProductContainer(
      take: 5
      orderBy: { SVP: desc }
      where: {
        single_product: { is: {
          product_profiles: { some: { language: { equals: $language } } }
        }}
      }
    ) {
      id
      single_product {
        id
        price
        images {
          id
          src
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }
    }
  }
`
