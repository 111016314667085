import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'next-i18next'

const useStylesDetailsRowDimensions = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {},
      dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
      },
      property: {
        width: '50%',
      },
      value: {
        width: '50%',
        textAlign: 'center',
      },
    }),
  { name: 'MuiSofaConfigurationDetailsDimensions' },
)

const DimensionsBlock = ({
  dimensions,
  insideSingleProduct = false,
  splitIntoCollumns = false,
  ...props
}) => {
  //---
  const classes = useStylesDetailsRowDimensions() as any
  const { t } = useTranslation(['common'])
  const yesString = t('yes')
  const noString = t('no')
  const dimensionsTitle = t('product-info-box-dimensions-title')
  const noProductInfoFallback = t('no-product-info-fallback')
  const widthString = t('product-width')
  const heightString = t('product-height')
  const lengthString = t('product-length')
  const seatHeightString = t('product-seat_height')
  const seatDepthString = t('product-seat_depth')
  const armsRestWidthString = t('product-arm-rest-width')
  const backRestWidthString = t('product-back-rest-width')
  const metricString = ' cm'

  const weightString = t('product-weight')
  const seatWidthString = t('product-seat_width')
  //-- To translate
  const radiusString = t('product-radius')
  const armrestHeightString = t('product-armrest-height')
  const mattressWidthString = t('product-mattress-width')
  const mattressLengthString = t('product-mattress-length')
  const mattressHeightString = t('product-mattress-height')
  const headboardHeightString = t('product-headboard-height')
  const headboardWidthString = t('product-headboard-width')
  const tableExtendedLenghString = t('product-table-extended-lengh')
  const shadeHeightString = t('product-shade-height')
  const shadeRadiusString = t('product-shade-radius')
  const lyingPartHeightString = t('product-lying-part-height')
  const legHeightString = t('product-leg-height')
  const mattressOverlayHeightString = t('product-mattress-overlay-height')
  const bedboxHeightString = t('product-bedbox-height')
  const bedboxWidthString = t('product-bedbox-width')
  const bedboxLengthString = t('product-bedbox-length')
  const frameThicknessString = t('product-frame-thickness')
  const tableTopThicknessString = t('product-table-top-thickness')
  const tableLegWidthString = t('product-table-leg-width')
  const fabricUsageString = t('product-fabric-usage')
  const cornerPartLengthString = t('product-corner-part-length')

  let additionalRowStyles = {}
  let variantRow: 'body1' | 'body2' = 'body1'
  let variantValue: 'body2' | 'body1' = 'body2'

  if (insideSingleProduct) {
    variantRow = 'body2'
    variantValue = 'body1'
    additionalRowStyles = {
      marginBottom: '1.1rem',
    }
  }

  if (splitIntoCollumns) {
    additionalRowStyles = {
      ...additionalRowStyles,
      width: '45%',
    }
  }

  return (
    <div>
      {!insideSingleProduct && (
        <Typography
          variant="h6"
          component="h4"
          style={{ margin: '0 0 10px 0' }}
        >
          {dimensionsTitle}
        </Typography>
      )}
      {/* LEFT DATA CONTAINER */}
      <div
        className={classes.dataContainer}
        style={{
          flexDirection: splitIntoCollumns ? 'row' : 'column',
          flexWrap: splitIntoCollumns ? 'wrap' : 'nowrap',
        }}
      >
        {dimensions.width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {widthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.width}
              {metricString}
            </Typography>
          </div>
        )}

        {dimensions.length && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {lengthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.length} {metricString}
            </Typography>
          </div>
        )}

        {dimensions.height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {heightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.height} {metricString}
            </Typography>
          </div>
        )}

        {dimensions.radius && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {radiusString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.radius}
              {metricString}
            </Typography>
          </div>
        )}

        {dimensions.weight && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {weightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.weight}
              {metricString}
            </Typography>
          </div>
        )}

        {/* ======================== CHAIR DIMENSIONS  ======================== */}
        {dimensions?.seat_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {seatHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.seat_height} {metricString}
            </Typography>
          </div>
        )}

        {dimensions?.seat_depth && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {seatDepthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.seat_depth}
              {metricString}
            </Typography>
          </div>
        )}

        {dimensions?.arms_rest_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {armsRestWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.arms_rest_width} {metricString}
            </Typography>
          </div>
        )}
        {dimensions?.back_rest_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {backRestWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.back_rest_width} {metricString}
            </Typography>
          </div>
        )}

        {dimensions?.armrest_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {armrestHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.armrest_height} {metricString}
            </Typography>
          </div>
        )}

        {dimensions?.seat_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {seatWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.seat_width} {metricString}
            </Typography>
          </div>
        )}

        {/* ======================== BED DIMENSIONS ======================== */}

        {dimensions.headboard_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {headboardHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.headboard_height}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.headboard_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {headboardWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.headboard_width}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.mattress_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {mattressWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.mattress_width}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.mattress_length && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {mattressLengthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.mattress_length}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.mattress_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {mattressHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.mattress_height}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.mattress_overlay_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {mattressOverlayHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.mattress_overlay_height}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.lying_part_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {lyingPartHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.lying_part_height}
              {metricString}
            </Typography>
          </div>
        )}

        {dimensions.bedbox_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {bedboxWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.bedbox_width}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.bedbox_length && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {bedboxLengthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.bedbox_length}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.bedbox_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {bedboxHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.bedbox_height}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.frame_thickness && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {frameThicknessString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.frame_thickness}
              {metricString}
            </Typography>
          </div>
        )}
        {/* ======================== TABLE DIMENSIONS  ======================== */}
        {dimensions.table_extended_lengh && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {tableExtendedLenghString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.table_extended_lengh}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.table_top_thickness && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {tableTopThicknessString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.table_top_thickness}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.table_leg_width && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {tableLegWidthString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.table_leg_width}
              {metricString}
            </Typography>
          </div>
        )}

        {/* ==========  LIGHTS DIMENSIONS ======================== */}
        {dimensions.shade_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {shadeHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.shade_height}
              {metricString}
            </Typography>
          </div>
        )}
        {dimensions.shade_radius && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {shadeRadiusString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.shade_radius}
              {metricString}
            </Typography>
          </div>
        )}
        {/* ===== MISC =======  */}
        {dimensions.leg_height && (
          <div className={classes.row} style={additionalRowStyles}>
            <Typography
              variant={variantRow}
              component="p"
              className={classes.property}
            >
              {legHeightString}
            </Typography>
            <Typography
              variant={variantValue}
              component="p"
              className={classes.value}
            >
              {dimensions.leg_height}
              {metricString}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default DimensionsBlock
