import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  GET_FILTERED_COLORS,
  GET_FILTERED_FRAME_COLORS,
  GET_MIN_MAX_FILTERED_PRICE,
  GET_PERMALINK_CATEGORIES_STYLES,
} from '../../operations/queries'
import { GET_FILTERED_CATEGORIES } from '../../operations/queries/filterCategoryQueries'
import { GET_MIN_MAX_DIMENSIONS } from '../../operations/queries/filterDimensionsQueries'
import { GET_FILTERED_STYLES } from '../../operations/queries/filterStyleQueries'
import {
  getCategoriesQueryVariable,
  getDimensionsQueryVariable,
  getMainColorQueryVariable,
  getProductTypeQueryVariable,
  getStylesQueryVariable,
} from '../../operations/queries/variablesConstructors'
import {
  categoriesFilterVar,
  colorsFilterVar,
  expandedFilterVar,
  frameColorsFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  productTypeFilterVar,
  sortByVar,
  stylesFilterVar,
  widthFilterVar,
} from '../../store/reactiveVars'
import { flattenCategories, sortCategories } from '../categoryUtils'

export const useFiltersData = (
  permalink,
  dimensionsMinMaxStatic,
  locale,
  translatedData,
) => {
  //* ================================================
  //* ==================i18n data=====================
  //* ================================================
  const {
    priceFilterString,
    colorFilterString,
    frameColorFilterString,
    dimensionFilterString,
    categoryFilterString,
    styleFilterString,
    productTypeFilterString,
  } = translatedData

  // console.log('permalink :>> ', permalink)

  //* ================================================
  //* ==============Reactive vars init================
  //* ================================================
  const priceFilter = useReactiveVar(priceFilterVar)
  const widthFilter = useReactiveVar(widthFilterVar)
  const heightFilter = useReactiveVar(heightFilterVar)
  const lengthFilter = useReactiveVar(lengthFilterVar)
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)
  const productTypeFilter = useReactiveVar(productTypeFilterVar)

  const orderByFilter = useReactiveVar(sortByVar)

  //* =================================================================
  //* ==== getting constructed variable through helper functions ======
  //* =================================================================
  // =========== colors
  const colorOptions = getMainColorQueryVariable(colorsFilter)

  // =========== styles
  const styleOptions = getStylesQueryVariable(stylesFilter)

  // =========== categories
  const categoriesOptions = getCategoriesQueryVariable(categoriesFilter)

  // =========== product types
  const productTypeOptions = getProductTypeQueryVariable(productTypeFilter)

  // ========== dimensions
  const dimensionOptions = getDimensionsQueryVariable(
    widthFilter,
    heightFilter,
    lengthFilter,
  )

  //* ===============================================================
  //* =================== PERMALINK LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchPermalink,
    { data: permalinkData, loading: permalinkLoading, called: permalinkCalled },
  ] = useLazyQuery(GET_PERMALINK_CATEGORIES_STYLES)

  //* ===============================================================
  //* =================== COLORS ACCORDION LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchColors,
    { data: colorsData, loading: colorsLoading, called: colorsCalled },
  ] = useLazyQuery(GET_FILTERED_COLORS)

  //* ===============================================================
  //* =================== FRAME COLORS ACCORDION LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchFrameColors,
    {
      data: frameColorsData,
      loading: frameColorsLoading,
      called: frameColorsCalled,
    },
  ] = useLazyQuery(GET_FILTERED_FRAME_COLORS)

  //* ==========================================================
  //* =================== PRICE RANGE LAZY QUERY ===============
  //* ==========================================================

  const [
    fetchPrice,
    { data: priceData, loading: priceLoading, called: priceCalled },
  ] = useLazyQuery(GET_MIN_MAX_FILTERED_PRICE)

  //* ===============================================================
  //* =============== DIMENSIONS ACCORDION LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchDimensions,
    {
      data: dimensionsData,
      loading: dimensionsLoading,
      called: dimensionsCalled,
    },
  ] = useLazyQuery(GET_MIN_MAX_DIMENSIONS)

  //* ===============================================================
  //* =================== CATEGORIES ACCORDION LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchCategories,
    {
      data: categoriesData,
      loading: categoriesLoading,
      called: categoriesCalled,
    },
  ] = useLazyQuery(GET_FILTERED_CATEGORIES)

  //* ===============================================================
  //* =================== CATEGORIES ACCORDION LAZY QUERY ===============
  //* ===============================================================
  const [
    fetchStyles,
    { data: stylesData, loading: stylesLoading, called: stylesCalled, error },
  ] = useLazyQuery(GET_FILTERED_STYLES)


  //* =============================================================================================
  //* ============ running queries depending on  reactive vars  ===============
  //* =============================================================================================
  // I'm using different useEffect hooks because I want to fetch the filtersData depending on different variables.
  // And if I put all of the fetch functions in one useEffect, they will always re-render.
  // An example of the issue that this might create is: you change the price range and it will re-render closing itself.
  // While if we have different useEffects, only the ones depending on the change of priceFilterVar will re-render.

  // ====================
  // TRIGGER PERMALINK QUERY
  // ====================
  useEffect(() => {
    fetchPermalink({
      variables: {
        language: locale,
        permalink,
      },
    })
    return
  }, [
    priceFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
  ])

  const setCategoriesOrStyles = () => {
    //----

    if (permalinkData) {
      //-- Handle permalink DATA
      // 1) select either style or Category
      console.log('permalinkData :>> ', permalinkData)
      if (permalinkData.findManyCategory.length) {
        //---
        for (const catID of permalinkData.findManyCategory) {
          //--- Perform a category select only if a category is NOT BEING Selected
          if (categoriesFilter.length === 0) {
            // const categoryIDS = categoriesFilter.map((item) => item?.id)
            if (catID?.id && categoriesFilter.includes(catID?.id)) {
              // Do nothing
            } else {
              categoriesFilterVar([...categoriesFilter, catID?.id])
            }
          }
        }
      }
      if (permalinkData.findManyStyle.length) {
        //---
        // const styleIDS = stylesFilter.map((item) => item?.id)
        for (const styleID of permalinkData.findManyStyle) {
          if (stylesFilter.length === 0) {
            if (styleID?.id && stylesFilter.includes(styleID?.id)) {
              // Do nothing
            } else {
              stylesFilterVar([...stylesFilter, styleID?.id])
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    //---
    //Set category when orderBy filter is changed...
    // console.log('orderByFilter :>> ', orderByFilter)
    if (orderByFilter && orderByFilter.value != '') {
      //---
      setCategoriesOrStyles()
    }
  }, [orderByFilter])

  // TODO Need to fix performance at some point...
  useEffect(() => {
    //-- Initiate setCategoriesOrStyles
    if (
      colorsFilter.length !== 0 ||
      categoriesOptions.length !== 0 ||
      stylesFilter.length !== 0 ||
      productTypeOptions.length !== 0 ||
      // (priceFilter.minPrice != null &&
      //   priceFilter.maxPrice != null &&
      //   priceFilter.minPrice !== priceData?.SingleProductMinPrice &&
      //   priceFilter.maxPrice !== priceData?.SingleProductMaxPrice) ||
      (widthFilter.minWidth != null && widthFilter.maxWidth != null) ||
      (heightFilter.minHeight != null && heightFilter.maxHeight != null) ||
      (lengthFilter.minLength != null && lengthFilter.maxLength != null)
    ) {
      //-- IF any of the filter are set
      // console.log('setting Categories or styles :>> ')
      setCategoriesOrStyles()
    } else {
      // console.log('NOT SETTING CATEGORIES')
    }

    // console.log('priceFilter :>> ', priceFilter)
    // console.log('priceData :>> ', priceData)
    // console.log('widthFilter :>> ', widthFilter)
    // console.log('heightFilter :>> ', heightFilter)
    // console.log('lengthFilter :>> ', lengthFilter)
    // console.log('categoriesFilter :>> ', categoriesFilter)
    // console.log('productTypeFilter :>> ', productTypeFilter)
    // console.log('stylesFilter :>> ', stylesFilter)
    // console.log('colorsFilter :>> ', colorsFilter)
  }, [
    // priceFilter, // DO NOT REACT TO A PRICE CHANGE!
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
    colorsFilter,
    permalinkData,
  ])

  useEffect(() => {
    //---
    // console.log('Detected priceFilter change')
    if (
      colorsFilter.length !== 0 ||
      categoriesOptions.length !== 0 ||
      stylesFilter.length !== 0 ||
      productTypeOptions.length !== 0 ||
      (priceFilter.minPrice != null && priceFilter.maxPrice != null) ||
      (widthFilter.minWidth != null && widthFilter.maxWidth != null) ||
      (heightFilter.minHeight != null && heightFilter.maxHeight != null) ||
      (lengthFilter.minLength != null && lengthFilter.maxLength != null)
    ) {
      //-- IF any of the filter are set
      // console.log('setting Categories or styles :>> ')
      setCategoriesOrStyles()
    } else {
      // console.log('NOT SETTING CATEGORIES')
    }
    // console.log('priceFilter :>> ', priceFilter)
    //----
  }, [priceFilter])

  // ====================
  // TRIGGER COLORS QUERY
  // ====================
  useEffect(() => {
    fetchColors({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        permalink,
        categories: categoriesOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    return
  }, [
    priceFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
  ])

  // ====================
  // TRIGGER FRAME COLORS QUERY
  // ====================
  useEffect(() => {
    fetchFrameColors({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        permalink,
        categories: categoriesOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : //====== OLD PRICE FILTERS
            // ? {
            //     single_product: {
            //       AND: [
            //         { price: { gte: priceFilter.minPrice } },
            //         { price: { lte: priceFilter.maxPrice } },
            //       ],
            //     },
            //   }
            {},
      },
    })

    return
  }, [
    priceFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
  ])

  // ====================
  // TRIGGER PRICE QUERY
  // ====================
  useEffect(() => {
    fetchPrice({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        permalink,
        colors: colorOptions,
        categories: categoriesOptions,
        styles: styleOptions,
      },
      fetchPolicy: 'network-only',
    })

    return
  }, [
    colorsFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
  ])

  // ========================
  // TRIGGER DIMENSIONS QUERY
  // =======================
  useEffect(() => {
    fetchDimensions({
      variables: {
        language: locale,
        permalink,
        colors: colorOptions,
        categories: categoriesOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    return
  }, [
    priceFilter,
    colorsFilter,
    locale,
    categoriesFilter,
    stylesFilter,
    productTypeFilter,
  ])

  // ====================
  // TRIGGER CATEGORIES QUERY
  // ====================
  useEffect(() => {
    fetchCategories({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        permalink,
        colors: colorOptions,
        styles: styleOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    return
  }, [
    priceFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    stylesFilter,
    colorsFilter,
    productTypeFilter,
  ])

  // ====================
  // TRIGGER STYLES QUERY
  // ====================
  useEffect(() => {
    fetchStyles({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        permalink,
        colors: colorOptions,
        categories: categoriesOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
    })

    return
  }, [
    priceFilter,
    widthFilter,
    heightFilter,
    lengthFilter,
    categoriesFilter,
    colorsFilter,
    productTypeFilter,
  ])

  //* ======================================================================================================================
  //* ========== creating a filters array for the filters' chips and a filtersData object with the result of all the queries
  //* ======================================================================================================================
  // TODO: this has price by default, but there might be a situation where a system user only wants to showcase products without adding price. Maybe change it to check if priceData.min/max is not null.
  // --- This array determines the order of filters...
  const filters = [priceFilterString]

  let flatCategories
  if (categoriesData && categoriesData.findManyCategory.length > 0) {
    filters.push(categoryFilterString)

    let allCategories = [
      ...JSON.parse(JSON.stringify(categoriesData.findManyCategory)),
    ]
    const sortedByMenuOrder = allCategories.sort(
      (a, b) => a.menu_order - b.menu_order,
    )
    const sortedCategories = sortCategories(sortedByMenuOrder)
    flatCategories = flattenCategories(sortedCategories)
  }
  if (stylesData?.findManyStyle?.length > 0) {
    filters.push(styleFilterString)
  }

  if (colorsData && colorsData.findManyColor.length > 0) {
    filters.push(colorFilterString)
  }

  if (frameColorsData && frameColorsData.findManyColor.length > 0) {
    filters.push(frameColorFilterString)
  }

  // looping through the data object retrieved from static query in [pagenumber] and if there is at least one value different from 0, we want to display it as an available filter, so we push it to the filters array.
  if (dimensionsMinMaxStatic) {
    for (const [key, value] of Object.entries(dimensionsMinMaxStatic)) {
      if (value !== 0) {
        filters.push(dimensionFilterString)
        break
      }
    }
  }

  //--- Product type
  filters.push(productTypeFilterString)

  const filtersData = {
    colorsData: {
      colors: colorsData?.findManyColor,
      loading: colorsLoading,
    },
    frameColorsData: {
      colors: frameColorsData?.findManyColor,
      loading: frameColorsLoading,
    },
    priceData: {
      min: priceData?.SingleProductMinPrice,
      max: priceData?.SingleProductMaxPrice,
      loading: priceLoading,
    },
    dimensionsData: {
      minWidth: dimensionsData?.SingleProductMinWidth,
      maxWidth: dimensionsData?.SingleProductMaxWidth,
      minHeight: dimensionsData?.SingleProductMinHeight,
      maxHeight: dimensionsData?.SingleProductMaxHeight,
      minLength: dimensionsData?.SingleProductMinLength,
      maxLength: dimensionsData?.SingleProductMaxLength,
      loading: dimensionsLoading,
    },

    categoriesData: {
      categories: flatCategories,
      loading: categoriesLoading,
    },

    stylesData: {
      styles: stylesData?.findManyStyle,
      loading: stylesLoading,
    },
  }

  //* ===============================================================================================
  //* ==================== return the object with filters array and filters data ====================
  //* ===============================================================================================

  return {
    filtersData,
    filters,
  }
}

//* =============================================
export const handleClearAllFilters = () => {
  // console.log('handleClearAllFilters initiated')
  categoriesFilterVar([])
  colorsFilterVar([])
  frameColorsFilterVar([])
  stylesFilterVar([])
  productTypeFilterVar([])
  priceFilterVar({ minPrice: null, maxPrice: null })
  widthFilterVar({ minWidth: null, maxWidth: null })
  heightFilterVar({ minHeight: null, maxHeight: null })
  lengthFilterVar({ minLength: null, maxLength: null })
  sortByVar({
    queryVar: null,
    value: '',
  })
  expandedFilterVar(false)
}
