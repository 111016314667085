import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { parse } from 'next-useragent'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import SwiperCore, {
  Autoplay,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import {
  imageFallback,
  imageLoadingPlaceholder,
} from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import { sortImages } from '../../../utilityFunctions/sortImages'

const useStyles = makeStyles(
  (theme: Theme) => ({
    media: {
      height: '100%',
    },
    slider: {
      position: 'relative',
      height: '28.2vw',
      '--swiper-navigation-color': 'black',
      '& .swiper-button-prev': {
        left: 10,
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      '& .swiper-button-prev::after': {
        fontSize: '1.5rem',
      },
      '& .swiper-button-next': {
        right: 10,

        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      '& .swiper-button-next::after': {
        fontSize: '1.5rem',
      },
    },
  }),
  { name: 'MuiSimpleSliderComponent' },
)

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay, Lazy])

const SimpleSlider = ({
  className,
  images,
  ...rest
}: SimpleSliderProps): JSX.Element => {
  const classes = useStyles() as any
  const sortedImages = sortImages(images)

  // change src for SAFARI as it does not support webp...
  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  const userAgent = appContext ? appContext.userAgent : null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false
  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // const ua = useUserAgent(window ? window.navigator.userAgent : null) // (Not working on Build)

  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  return (
    <Swiper
      tag="section"
      wrapperTag="ul"
      // lazy={{ loadPrevNext: true }}
      speed={700}
      effect="fade"
      autoplay={{ delay: 10000, disableOnInteraction: false }}
      loop={true}
      className={clsx(className, classes.slider)}
      navigation
      touchStartPreventDefault={false}
    >
      {sortedImages.map((banner, i) => {
        const mainImage = isSafari
          ? banner.image.src ?? imageFallback
          : banner.image.src_lg ?? banner.image.src ?? imageFallback

        return (
          <SwiperSlide key={banner.id} tag="li">
            {/* <CardMedia image={image.src} className={classes.media} /> */}
            <div
              style={{
                cursor: banner.link != '' ? 'pointer' : 'cursor',
                position: 'relative',
                height: '100%',
              }}
              onClick={(e) => {
                if (banner.link != '') {
                  document.location.href = `${banner.link}`
                }
              }}
            >
              <Image
                layout="fill"
                placeholder="blur"
                blurDataURL={imageLoadingPlaceholder}
                priority
                // alt="Home page banner that displays latest offers and our designs"
                objectFit="contain"
                src={mainImage}
              />
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default SimpleSlider
