import { ReactiveVar } from "@apollo/client";
import React from "react";
import { Order, CartItem } from "../store/models";
import {
  calculatePriceWithoutVat,
  calculatePriceWithVat,
  subtotalCartPrice,
} from "./priceUtils";

const getAndUpdateCheckoutPrices = (
  shopSettings,
  cartItems: CartItem[],
  order: Order,
  orderVar: ReactiveVar<Order>
) => {
  // let sub_total_price_read_only = subtotalCartPrice(cartItems, shopSettings);
  const copyOfOrder = { ...order };
  let sub_total_discounted = null;
  const { current_prices_with_vat, vat_percentage } = shopSettings;
  const {
    total_shipping_price,
    discount_applied,
    buying_as_company,
    is_lithuanian_company,
  } = copyOfOrder;
  let total_price_without_VAT = null,
    total_price = null,
    sub_total_price = subtotalCartPrice(cartItems, shopSettings);

  // If there is a coupon we assign order.sub_total_price (which is the price with the coupon already calculated)
  if (order.coupon && order.discount_applied) {
    sub_total_discounted = sub_total_price - order.discount_applied;
  }

  //This will only be used for calculations done below, we will always pass "sub_total_price" to the orderVar in the end
  const sub_total_for_calculations = sub_total_discounted ?? sub_total_price;
  //1) depends on order.buying_as_company
  //2) if buying as company, is the company lithuanian
  //3) depends on current_prices_with_vat
  switch (buying_as_company) {
    case true:
      //* ===========================================================================
      //* ========================== BUYING AS A COMPANY ============================
      //* ===========================================================================
      //We have to check if it's a lithuanian company before proceeding
      // ===============================================================
      // ==============SWITCH TO CHECK IF LITHUANIAN COMPANY============
      // ===============================================================
      switch (is_lithuanian_company) {
        case false:
          //since it's not a lithuanian company, VAT will not be applied
          //and since the prices already have no VAT I won't have to calculate anything
          total_price_without_VAT =
            sub_total_for_calculations + total_shipping_price;
          total_price = sub_total_for_calculations + total_shipping_price;
          break;

        default:
          //since it's a lithuanian company, VAT will be applied
          //so i have to calculate the total_price and add VAT to it

          total_price_without_VAT =
            sub_total_for_calculations + total_shipping_price;
          total_price =
            calculatePriceWithVat(sub_total_for_calculations, vat_percentage) +
            total_shipping_price;
      }
      break;

    //* ===========================================================================
    //* ========================= NOT buying as a company =========================
    //* ===========================================================================
    default:
      //here he's not buying as company (so he'll pay VAT)
      //and the subtotal (as always) is not with VAT
      //so we have to add VAT to the total_price
      total_price_without_VAT =
        sub_total_for_calculations + total_shipping_price;
      total_price =
        calculatePriceWithVat(sub_total_for_calculations, vat_percentage) +
        total_shipping_price;
  }

  orderVar({
    ...copyOfOrder,
    sub_total_price,
    total_price_without_VAT,
    total_price,
  });
};

export default getAndUpdateCheckoutPrices;
