import { useQuery } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { imageFallback } from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import { GET_VARIANTS_DRAWER_PRODUCT } from '../../../operations/queries'
import { variantsDrawerStateVar } from '../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import { dispatchInformationDate } from '../../../utilityFunctions/dispatchInformationDate'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import DiscountBadge from '../Utils/DiscountBadge'
import FastDeliveryBadge from '../Utils/FastDeliveryBadge'
import NewProductBadge from '../Utils/NewProductBadge'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(2, 0),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      media: {
        height: '11rem',
        backgroundSize: 'contain',

        [theme.breakpoints.down('lg')]: {
          height: '13rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        // textAlign: "center",
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
      },
      selected: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
      },

      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
      },
    }),
  { name: 'MuiSizeDrawerCardComponent' },
)

export default function SizeDrawerCard({ productID, selected = false }) {
  const classes = useStyles() as any
  const { locale } = useRouter()
  const { loading, error, data } = useQuery(GET_VARIANTS_DRAWER_PRODUCT, {
    variables: {
      productID,
    },
  })
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()

  if (loading) return <div>Loading...</div> // TODO Change to a normal loader - <Skeleton />
  if (error) return <div>{error.message}</div>

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  const { discounted, discountedPrice } = checkDiscountValidity(
    data.findUniqueProductContainer.discount,
    data.findUniqueProductContainer.single_product.price,
  )
  const discountPercentage = data.findUniqueProductContainer.discount
    ? '-' + data.findUniqueProductContainer.discount.discount + '%'
    : 0

  // ========== DISPATCH INFORMATION ===============

  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = data.findUniqueProductContainer.manufacturer
    ? data.findUniqueProductContainer.manufacturer.default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    data.findUniqueProductContainer.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )
  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(data.findUniqueProductContainer.createdAt)

  // const image = data.findUniqueProductContainer.single_product.images[0].src;
  // Order images by display order
  // Convert to list
  const productImages = data.findUniqueProductContainer.single_product.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
  const image =
    productImages[0]?.src_xs ??
    productImages[0]?.src_md ??
    productImages[0]?.src ??
    imageFallback

  //* ====================================================================
  //* Here we're sure the data has come (we are after the "loading" check)
  //* but still make sure that there is a product
  //* we want to run the check and get this item's profile
  //* ====================================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer.single_product?.product_profiles,
    locale,
  )

  const permalink =
    data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
      .meta_information.permalink

  const name =
    data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
      .name
  const price = data.findUniqueProductContainer.single_product.price
  const sizeName =
    data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
      .size_name

  return (
    <Card
      elevation={0}
      square={true}
      className={classes.card}
      onClick={() => {
        variantsDrawerStateVar({
          open: false,
          data: null,
          component: '',
        })
      }}
    >
      {/* BADGES */}
      <div className={classes.badgeBox}>
        {discounted && <DiscountBadge content={discountPercentage} small />}
        {fastDelivery && <FastDeliveryBadge small />}
        {newProduct && <NewProductBadge small />}
      </div>

      <div className={classes.cardActionAreaContainer}>
        {selected ? (
          <CardActionArea>
            <CardMedia className={classes.media} image={image} />
            <CheckCircleIcon className={classes.selected} />
          </CardActionArea>
        ) : (
          <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
            <CardActionArea>
              <CardMedia className={classes.media} image={image} />
            </CardActionArea>
          </Link>
        )}
      </div>
      <CardContent className={classes.cardContent}>
        <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
          <span className="cursorPointer">
            <Typography
              gutterBottom
              variant="caption"
              component="h2"
              className={classes.productName}
            >
              {name}
            </Typography>
          </span>
        </Link>
        <Typography component="p" variant="body1">
          {sizeName}
        </Typography>
        <div className={classes.pricesContainer}>
          <Typography
            variant={discounted ? 'body1' : 'body2'}
            component="p"
            className={clsx(discounted && classes.discoutedPrice)}
          >
            <ProductPrice price={price} />
          </Typography>
          <Typography variant="subtitle1" component="p">
            {' '}
            {discountedPrice && <ProductPrice price={discountedPrice} />}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
