import { useReactiveVar } from '@apollo/client'
import { Avatar, Chip, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import TuneIcon from '@mui/icons-material/Tune'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { Media } from '../../../../lib/artsyFresnel/Media'
import {
  categoriesFilterVar,
  colorsFilterVar,
  expandedFilterVar,
  frameColorsFilterVar,
  priceFilterVar,
  productTypeFilterVar,
  stylesFilterVar,
} from '../../../store/reactiveVars'
import { useFiltersData } from '../../../utilityFunctions/filtersUtils'
import { FilterDrawer, SortByComponent } from '../FilterComponentElements'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
          marginBottom: theme.spacing(1),
        },
      },
      filtersContainer: {
        display: 'flex',
      },
      filter: {
        marginRight: theme.spacing(1),
      },
      sortByContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      productsCount: {
        marginRight: theme.spacing(3),
      },
      mobileFilterContainer: {
        display: 'flex',
      },
      tuneIcon: {
        marginRight: theme.spacing(1),
      },
      chipRoot: {
        background: theme.palette.background.footer,
        '&.MuiChip-avatar': {
          color: theme.palette.background.default,
        },
      },
    }),
  { name: 'MuiFilterComponent' },
)

export default function FilterComponent({
  productsCount,
  productsLoading,
  permalink,
  productsMinMaxPriceStatic,
  productsMinMaxDimensions,
}) {
  const { t, i18n } = useTranslation(['common'])
  const priceFilterString = t('filter-price')
  const mobileFiltersButton = t('mobile-filters-button')
  const colorFilterString = t('filter-color')
  const frameColorFilterString = t('filter-frame-color')
  const dimensionFilterString = t('filter-dimension')
  const moreFiltersString = t('more-filters-chip')
  const numberOfProductsString = t('products')

  const categoryFilterString = t('filter-category')
  const styleFilterString = t('filter-style')
  const productTypeFilterString = t('filter-product-type')

  const classes = useStyles() as any
  const { locale } = useRouter()
  const priceFilter = useReactiveVar(priceFilterVar)
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const frameColorsFilter = useReactiveVar(frameColorsFilterVar)

  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)
  const productTypeFilter = useReactiveVar(productTypeFilterVar)

  // const { data: priceMinMaxStaticData } = productsMinMaxPriceStatic;
  // const { data: dimensionsMinMaxStatic } = productsMinMaxDimensions;
  const priceMinMaxStaticData = productsMinMaxPriceStatic
  const dimensionsMinMaxStatic = productsMinMaxDimensions
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { filters, filtersData } = useFiltersData(
    permalink,
    dimensionsMinMaxStatic,
    locale,
    {
      categoryFilterString,
      styleFilterString,
      priceFilterString,
      colorFilterString,
      frameColorFilterString,
      dimensionFilterString,
      productTypeFilterString,
    },
  )

  // console.log('permalink :>> ', permalink)

  const handleDrawerOpen = (filter = '') => {
    setDrawerOpen(true)
    expandedFilterVar(filter)
  }

  return (
    <>
      <Media greaterThan="md">
        <div className={classes.container}>
          <div className={classes.filtersContainer}>
            {/* //* ================================================================================ */}
            {/* //* =========== HANDLING THE CASE WHERE THE FILTERS ARE MORE THAN 6 ================ */}
            {/* //* ================================================================================ */}
            {filters.map((filter, i) => {
              if (i < 7) {
                switch (filter) {
                  case categoryFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        avatar={
                          // if colorsFilter has at least one element, we want to let the use know that he has one color filter applied
                          categoriesFilter.length > 0 ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              {categoriesFilter.length}
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )
                  case styleFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        avatar={
                          // if colorsFilter has at least one element, we want to let the use know that he has one color filter applied
                          stylesFilter.length > 0 ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              {stylesFilter.length}
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )

                  case colorFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        avatar={
                          // if colorsFilter has at least one element, we want to let the use know that he has one color filter applied
                          colorsFilter.length > 0 ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              {colorsFilter.length}
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )

                  case frameColorFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        avatar={
                          // if colorsFilter has at least one element, we want to let the use know that he has one color filter applied
                          frameColorsFilter.length > 0 ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              {frameColorsFilter.length}
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )
                  case priceFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        // if priceFilter values are not null and different from the whole category's min mix, it means we have a filter applied and we want to let the user know
                        avatar={
                          priceFilter.minPrice != null &&
                          priceFilter.maxPrice != null &&
                          (priceMinMaxStaticData?.SingleProductMinPrice !==
                            priceFilter.minPrice ||
                            priceMinMaxStaticData?.SingleProductMaxPrice !==
                              priceFilter.maxPrice) ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              1
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )
                  case dimensionFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        // if any of the dimensions reactive variables has values (which means the user changed the slider value) and those values are different from the static min max, then we want to let the user know he has a dimensions filter applied
                        avatar={null}
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )

                  case productTypeFilterString:
                    return (
                      <Chip
                        key={i}
                        className={classes.filter}
                        avatar={
                          // if colorsFilter has at least one element, we want to let the use know that he has one color filter applied
                          productTypeFilter.length > 0 ? (
                            <Avatar
                              classes={{
                                root: classes.chipRoot,
                              }}
                            >
                              {productTypeFilter.length}
                            </Avatar>
                          ) : null
                        }
                        label={filter}
                        clickable
                        onClick={() => handleDrawerOpen(filter)}
                      />
                    )
                  default:
                    console.log(
                      `There are no available filters for this category`,
                    )
                    break
                }
              }
            })}
            {filters.length > 7 && (
              <Chip
                className={classes.filter}
                label={moreFiltersString}
                clickable
                onClick={() => handleDrawerOpen()}
              />
            )}
          </div>
          <div className={classes.sortByContainer}>
            <Typography
              variant="caption"
              className={classes.productsCount}
            >{`${productsCount} ${numberOfProductsString}`}</Typography>
            <SortByComponent />
          </div>
        </div>
      </Media>

      {/* //* ================================================================================ */}
      {/* //* ============================ TABLET AND MOBILE VIEW ============================ */}
      {/* //* ================================================================================ */}
      <Media lessThan="lg">
        <div className={classes.container}>
          <div
            className={classes.mobileFilterContainer}
            onClick={() => handleDrawerOpen()}
          >
            <TuneIcon className={classes.tuneIcon} />
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.filter}
            >
              {mobileFiltersButton}
            </Typography>
          </div>

          <div className={classes.sortByContainer}>
            <SortByComponent />
          </div>
        </div>
        <Typography
          variant="caption"
          className={classes.productsCount}
          display="block"
          align="center"
        >{`${productsCount} ${numberOfProductsString}`}</Typography>
      </Media>
      <FilterDrawer
        productsLoading={productsLoading}
        productsCount={productsCount}
        drawerState={{ drawerOpen, setDrawerOpen }}
        filters={filters}
        permalink={permalink}
        filtersData={filtersData}
      />
    </>
  )
}
