import { useReactiveVar } from '@apollo/client'
import { Box, CardMedia, Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import {
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import FabricsSelectWithDrawer from './FabricsSelectWithDrawer'
import FabricsCombinationSelectWithDrawer from './FabricsCombinationSelectWithDrawer'
import SectionTitle from '../SectionTitle'

export default function FabricsWithCombinationsSelect({
  fabricGroups,
  fabricCombinations,
}) {
  const { t } = useTranslation(['common'])
  const chooseFabricText = t('fabric-configuration-text')

  const selectedAdvancedProductFabricCombination = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )
  const { locale } = useAppContext()

  if (!selectedAdvancedProductFabricCombination.fabricCombination) {
    selectedAdvancedProductFabricCombinationVar({
      fabricCombination: fabricCombinations[0],
    })
  }

  const options =
    selectedAdvancedProductFabricCombination?.fabricCombination
      ?.fabricCombinationOptions

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  useEffect(() => {
    if (!selectedAdvancedProductFabric.combinationFabrics) {
      return
    }

    // when fabric colors count changes to lower than before, it needs to remove the last ones
    let newCombination = {}

    const values = Object.values(
      selectedAdvancedProductFabric.combinationFabrics,
    )

    options.map((option, index) => {
      const selectedValue = (values[index] || values[0]) as object
      newCombination[option.id] = {
        ...selectedValue,
      }
      //}
    })

    if (
      JSON.stringify(selectedAdvancedProductFabric.combinationFabrics) !==
      JSON.stringify(newCombination)
    ) {
      console.log('selectedAdvancedProductFabricVar::::', {
        ...selectedAdvancedProductFabric,
        combinationFabrics: newCombination,
      })

      selectedAdvancedProductFabricVar({
        ...selectedAdvancedProductFabric,
        combinationFabrics: newCombination,
      })
    }
  }, [selectedAdvancedProductFabricCombination])

  return (
    <Grid id="fabricSelectDrawer" item xs={12} lg={12}>
      <SectionTitle title={chooseFabricText} />
      <Grid
        container
        item
        xs={12}
        lg={12}
        style={{ paddingLeft: '24px', paddingRight: '24px' }}
      >
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            sx={{ maxHeight: 400, objectFit: 'contain', paddingBottom: 1 }}
            image={
              selectedAdvancedProductFabricCombination.fabricCombination?.image
                ?.src_md
            }
            // alt={productName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {fabricCombinations.length > 1 ? (
            <FabricsCombinationSelectWithDrawer
              fabricCombinations={fabricCombinations}
            />
          ) : null}

          {options?.map((option, index) => {
            const name = getProfileFromLanguage(
              option.fabricCombinationOptionProfiles,
              locale,
            )?.name
            return (
              <FabricsSelectWithDrawer
                key={index}
                title={name}
                fabricGroups={fabricGroups}
                option={option}
              />
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
