import { gql } from '@apollo/client'

// export const PARENT_CATEGORY_DATA = gql`
//   fragment ParentCategoryData on Category {
//     id
//     category_profiles {
//       id
//       name
//       language
//       meta_information {
//         id
//         permalink
//       }
//     }
//   }
// `;

// // This defines how deep we can go, if ever a limit is reached - increase the number of steps.
// export const PARENT_CATEGORIES_RECURSIVE = gql`
//   ${PARENT_CATEGORY_DATA}
//   fragment ParentCategoriesRecursive on Category {
//     parent_category {
//       ...ParentCategoryData
//       parent_category {
//         ...ParentCategoryData
//         parent_category {
//           ...ParentCategoryData
//           parent_category {
//             ...ParentCategoryData
//             parent_category {
//               ...ParentCategoryData
//               parent_category {
//                 ...ParentCategoryData
//                 parent_category {
//                   ...ParentCategoryData
//                   parent_category {
//                     ...ParentCategoryData
//                     parent_category {
//                       ...ParentCategoryData
//                       parent_category {
//                         ...ParentCategoryData
//                         parent_category {
//                           ...ParentCategoryData
//                           parent_category {
//                             ...ParentCategoryData
//                             parent_category {
//                               ...ParentCategoryData
//                               parent_category {
//                                 ...ParentCategoryData
//                                 parent_category {
//                                   ...ParentCategoryData
//                                 }
//                               }
//                             }
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// ${PARENT_CATEGORY_DATA}
// ${PARENT_CATEGORIES_RECURSIVE}

export const SUB_CATEGORY_DATA = gql`
  fragment SubCategoryData on Category {
    id
    show_in_menu
    menu_order
    image {
      id
      src
      src_lg
      src_md
      src_xs
    }
    image_icon {
      id
      src
      src_lg
      src_md
      src_xs
      src_thumbnail
    }
    category_profiles {
      id
      name
      language
      meta_information {
        id
        permalink
      }
    }
    # ...ParentCategoryData
    # ...ParentCategoriesRecursive
  }
`

// This defines how deep we can go, if ever a limit is reached - increase the number of steps.
export const CATEGORIES_RECURSIVE = gql`
  ${SUB_CATEGORY_DATA}
  fragment CategoriesRecursive on Category {
    child_categories {
      ...SubCategoryData
      child_categories {
        ...SubCategoryData
        child_categories {
          ...SubCategoryData
          child_categories {
            ...SubCategoryData
            child_categories {
              ...SubCategoryData
              child_categories {
                ...SubCategoryData
                child_categories {
                  ...SubCategoryData
                  child_categories {
                    ...SubCategoryData
                    child_categories {
                      ...SubCategoryData
                      child_categories {
                        ...SubCategoryData
                        child_categories {
                          ...SubCategoryData
                          child_categories {
                            ...SubCategoryData
                            child_categories {
                              ...SubCategoryData
                              child_categories {
                                ...SubCategoryData
                                child_categories {
                                  ...SubCategoryData
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
//=== OLD AND INNEFICIENT
export const GET_PARENT_CATEGORIES = gql`
  ${SUB_CATEGORY_DATA}
  ${CATEGORIES_RECURSIVE}
  query GET_PARENT_CATEGORIES($language: Language) {
    findManyCategory(
      take: 15
      orderBy: { menu_order: asc }
      where: {
        show_in_menu: { equals: true }
        product_containers: {
          some: {
            OR: [
              {
                single_product: { is: {
                  product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }}
              }
              {
                advanced_product: { is: {
                  advanced_product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }}
              }
            ]
          }
        }
        category_profiles: { some: { language: { equals: $language } } }
        parent_categoryId: { equals: null }
      }
    ) {
      id
      show_in_menu
      image {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      image_icon {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      category_profiles {
        id
        name
        language
        meta_information {
          id
          permalink
        }
      }
      ...SubCategoryData
      ...CategoriesRecursive
    }

    # We also want styles in heder and in footer
    findManyStyle(
      orderBy: { createdAt: desc }
      take: 6
      where: {
        product_containers: {
          some: {
          single_product: { is: {
            product_profiles: { some: { language: { equals: $language } } }
          }}
          }
        }
        style_profiles: { some: { language: { equals: $language } } }
      }
    ) {
      id
      main_image {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      style_profiles {
        id
        name
        language
        meta_information {
          id
          permalink
        }
      }
    }

    findManyBlogPost(
      orderBy: { createdAt: desc }
      take: 6
      where: {
        AND: [
          { blog_post_profiles: { some: { language: { equals: $language } } } }
          { active: { equals: true } }
        ]
      }
    ) {
      id
      blog_post_profiles {
        id
        language
        createdAt
        title
        meta_information {
          permalink
        }
      }
    }
  }
`

export const GET_PARENT_CATEGORIES_NON_RECURSIVE = gql`
  query GET_PARENT_CATEGORIES($language: Language) {
    findManyCategory(
      # take: 15
      orderBy: { menu_order: asc }
      where: {
        show_in_menu: { equals: true }
        product_containers: {
          some: {
            OR: [
              {
                single_product: { is: {
                  product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }}
              }
              {
                advanced_product: {is:{
                  advanced_product_profiles: {
                    some: { language: { equals: $language } }
                  }
                }}
              }
            ]
          }
        }
        category_profiles: { some: { language: { equals: $language } } }
        # parent_categoryId: { equals: null }
      }
    ) {
      id
      show_in_menu
      parent_categoryId
      menu_order
      image {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      image_icon {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      category_profiles {
        id
        name
        language
        meta_information {
          id
          permalink
        }
      }
    }

    # We also want styles in heder and in footer
    findManyStyle(
      orderBy: { createdAt: desc }
      take: 6
      where: {
        product_containers: {
          some: {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
        }
        style_profiles: { some: { language: { equals: $language } } }
      }
    ) {
      id
      main_image {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
      }
      style_profiles {
        id
        name
        language
        meta_information {
          id
          permalink
        }
      }
    }

    findManyBlogPost(
      orderBy: { createdAt: desc }
      take: 6
      where: {
        AND: [
          { blog_post_profiles: { some: { language: { equals: $language } } } }
          { active: { equals: true } }
        ]
      }
    ) {
      id
      blog_post_profiles {
        id
        language
        createdAt
        title
        meta_information {
          permalink
        }
      }
    }
  }
`

export const GET_CHILD_CATEGORIES = gql`
  query GET_CHILD_CATEGORIES($permalink: String!) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      category_profile {
        id
        language
        category {
          id
          child_categories {
            id
            image_icon {
              id
              src
            }
            category_profiles {
              id
              name
              language
              meta_information {
                id
                permalink
              }
            }
          }
        }
      }
    }
  }
`
