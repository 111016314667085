import {
  colors,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Icon, Image, SectionHeader } from '../Utils'

const useStyles = makeStyles(
  (theme) => ({
    textWhite: {
      color: 'white',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    formControl: {
      maxWidth: 400,
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
      '& .MuiInputBase-root': {
        color: 'white',
      },
      '& .MuiInputAdornment-root i': {
        color: 'white !important',
      },
    },
    image: {
      maxWidth: 400,
    },
  }),
  { name: 'MuiNewsletterWithImageComponent' },
)

const NewsletterWithImage = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles() as any

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} container>
          <Image
            image={{
              src:
                'https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg',
            }}
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} sm={6} container alignItems="center">
          <div>
            <SectionHeader
              title={
                <span className={classes.textWhite}>
                  Subscribe To Our Newsletter
                </span>
              }
              subtitle={
                <span className={classes.textWhite}>
                  {`Don't lose a chance to be among the firsts to know about our
                  upcoming news and updates.`}
                </span>
              }
              fadeUp
              align="left"
            />
            <div className={classes.inputContainer}>
              <FormControl
                fullWidth
                variant="outlined"
                data-aos="fade-up"
                className={classes.formControl}
              >
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon
                        fontIconClass="fas fa-paper-plane"
                        fontIconColor={colors.indigo[900]}
                      />
                    </InputAdornment>
                  }
                  placeholder="Enter your email"
                />
              </FormControl>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default NewsletterWithImage
