import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Divider } from '@mui/material'
import { Section } from '.'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    hero: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
    heroLeftSide: {
      padding: theme.spacing(5, 8),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3, 8),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2),
      },
    },
    heroRightSide: {
      maxWidth: '50%',
      flex: '0 0 50%',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flex: '0 0 100%',
        height: '300px',
      },
    },

    heroLeftSideImage: {
      maxWidth: '50%',
      flex: '0 0 50%',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flex: '0 0 100%',
        height: '300px',
      },
    },

    heroCover: {
      position: 'relative',
      width: '50vw',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    heroImageContainer: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    heroImage: {
      position: 'absolute',
      left: '0%',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        shapeOutside: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
        clipPath: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
      },
    },

    heroImageLeft: {
      position: 'absolute',
      left: '0%',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        shapeOutside: 'polygon(0% 0%, 100% 0, 90% 100%, 0% 100%)',
        clipPath: 'polygon(0% 0%, 100% 0, 90% 100%, 0% 100%)',
      },
    },
  }),
  { name: 'MuiHeroShapedComponent' },
)

//  * Component to display the shaped hero
//  *
//  * @param {Object} props

const HeroShaped = ({
  leftSide,
  rightSide,
  className,
  switchSides = false,
  ...rest
}: HeroShapedProps): JSX.Element => {
  const classes = useStyles() as any

  if (switchSides) {
    return (
      <div className={clsx(classes.root, 'hero-shaped', className)} {...rest}>
        <div className={clsx('hero-shaped__wrapper', classes.hero)}>
          <div
            className={clsx(
              'hero-shaped__left-side',
              classes.heroLeftSideImage,
            )}
          >
            <div className={clsx('hero-shaped__cover', classes.heroCover)}>
              <div
                className={clsx(
                  'hero-shaped__image-container',
                  classes.heroImageContainer,
                )}
              >
                <div
                  className={clsx(
                    'hero-shaped-left__image',
                    classes.heroImageLeft,
                  )}
                >
                  {rightSide}
                </div>
              </div>
            </div>
          </div>

          <Section
            className={clsx('hero-shaped__right-side', classes.heroRightSide)}
          >
            {leftSide}
          </Section>
        </div>
        <Divider />
      </div>
    )
  } else {
    return (
      <div className={clsx(classes.root, 'hero-shaped', className)} {...rest}>
        <div className={clsx('hero-shaped__wrapper', classes.hero)}>
          <Section
            className={clsx('hero-shaped__left-side', classes.heroLeftSide)}
          >
            {leftSide}
          </Section>
          <div
            className={clsx('hero-shaped__right-side', classes.heroRightSide)}
          >
            <div className={clsx('hero-shaped__cover', classes.heroCover)}>
              <div
                className={clsx(
                  'hero-shaped__image-container',
                  classes.heroImageContainer,
                )}
              >
                <div className={clsx('hero-shaped__image', classes.heroImage)}>
                  {rightSide}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    )
  }
}

export default HeroShaped
