import { useReactiveVar, useLazyQuery, useQuery } from '@apollo/client'
import {
  Collapse,
  List,
  ListItem,
  FormControl,
  FormGroup,
  Theme,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  expandedFilterVar,
  priceFilterVar,
} from '../../../../store/reactiveVars'
import CategoryDrawerItemCheckbox from './CategoryDrawerItemCheckbox'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterDrawerItemTitle from '../FilterDrawerItemTitle'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      nested: {
        paddingLeft: theme.spacing(4),
      },
      MuiAccordionroot: {
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      MuiAccordionSummaryRoot: {
        marginBottom: -1,
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
        },
      },
      MuiAccordionSummaryContent: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    }),
  { name: 'MuiColorDrawerItemComponent' },
)

export default function CategoryDrawerItem({
  permalink,
  categoryData,
  handleDrawerItemOpen,
}) {
  const { t, i18n } = useTranslation(['common'])
  // const colorFilterString = t('filter-color')
  const classes = useStyles() as any
  const expandedFilter = useReactiveVar(expandedFilterVar)
  const categoryFilterString = t('filter-category')

  if (categoryData.loading)
    return (
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="ColorsAccordion"
          id="ColorsAccordion"
        >
          <Typography component="h6" variant="body2">
            {categoryFilterString}
          </Typography>
        </AccordionSummary>
      </Accordion>
    )

  return (
    <>
      {
        <>
          <Accordion
            expanded={expandedFilter === categoryFilterString}
            onChange={(e, isExpanded) =>
              handleDrawerItemOpen(categoryFilterString)(e, isExpanded)
            }
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            classes={{
              root: classes.MuiAccordionroot,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ColorsAccordion"
              id="ColorsAccordion"
              classes={{
                root: classes.MuiAccordionSummaryRoot,
                content: classes.MuiAccordionSummaryContent,
              }}
            >
              <Typography component="h6" variant="body2">
                {categoryFilterString}
              </Typography>
            </AccordionSummary>
            <List component="div" disablePadding>
              <ListItem className={classes.nested}>
                <FormControl component="fieldset">
                  <FormGroup>
                    {categoryData?.categories.map((category) => {
                      return (
                        <CategoryDrawerItemCheckbox
                          key={category.id}
                          categoryID={category.id}
                          permalink={permalink}
                          category={category}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </ListItem>
            </List>
          </Accordion>
        </>
      }
    </>
  )
}
