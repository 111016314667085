import { gql } from '@apollo/client'
import { PRODUCT_FRAGMENT } from './categoryCatchAllQueries'

export const GET_FILTERED_PRODUCTS = gql`
  query GET_FILTERED_PRODUCTS(
    # $permalink: String
    # --------
    $colors: [SingleProductWhereInput]
    $frameColors: [SingleProductWhereInput]
    $language: Language
    $dimensions: [DimensionWhereInput]
    $price: ProductContainerWhereInput
    $cursor: ProductContainerWhereUniqueInput
    $orderBy: [ProductContainerOrderByWithRelationInput!]
    $categories: [ProductContainerWhereInput]
    $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
    $customers: ProductContainerWhereInput
    $skip: Int
  ) {
    findManyProductContainer(
      take: 32
      #after: $lastProductID
      cursor: $cursor
      skip: $skip
      orderBy: $orderBy
      where: {
        AND: [
          $customers
          { visible: { equals: true } }
          { OR: $productTypes }
          { OR: $styles }
          { OR: $categories }
          $price
          {
            OR: [
              {
                single_product: {
                  is: {
                    AND: [
                      { dimensions: { is: { AND: $dimensions } } }
                      { OR: $colors }
                      { OR: $frameColors }
                      {
                        product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                    ]
                  }
                }
              }
              {
                advanced_product: {
                  is: {
                    OR: [
                      # { dimensions: { OR: $dimensions } }
                      {
                        advanced_product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      }
    ) {
      id
    }
    findManyProductContainerCount(
      where: {
        AND: [
          $customers
          { visible: { equals: true } }
          # {
          #   OR: [
          #     {
          #       categories: {
          #         some: {
          #           category_profiles: {
          #             some: {
          #               AND: [
          #                 {
          #                   meta_information: {
          #                     permalink: { equals: $permalink }
          #                   }
          #                 }
          #                 { language: { equals: $language } }
          #               ]
          #             }
          #           }
          #         }
          #       }
          #     }
          #     {
          #       tags: {
          #         some: {
          #           tag_profiles: {
          #             some: {
          #               AND: [
          #                 {
          #                   meta_information: {
          #                     permalink: { equals: $permalink }
          #                   }
          #                 }
          #                 { language: { equals: $language } }
          #               ]
          #             }
          #           }
          #         }
          #       }
          #     }
          #     {
          #       style: {
          #         style_profiles: {
          #           some: {
          #             AND: [
          #               {
          #                 meta_information: {
          #                   permalink: { equals: $permalink }
          #                 }
          #               }
          #               { language: { equals: $language } }
          #             ]
          #           }
          #         }
          #       }
          #     }
          #     {
          #       collection: {
          #         collection_profiles: {
          #           some: {
          #             AND: [
          #               {
          #                 meta_information: {
          #                   permalink: { equals: $permalink }
          #                 }
          #               }
          #               { language: { equals: $language } }
          #             ]
          #           }
          #         }
          #       }
          #     }
          #   ]
          # }

          { OR: $productTypes }
          { OR: $styles }
          { OR: $categories }
          $price
          {
            OR: [
              {
                single_product: {
                  is: {
                    AND: [
                      { dimensions: { is: { AND: $dimensions } } }
                      { OR: $frameColors }
                      { OR: $colors }
                      {
                        product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                    ]
                  }
                }
              }
              {
                advanced_product: {
                  is: {
                    OR: [
                      # { dimensions: { OR: $dimensions } }
                      {
                        advanced_product_profiles: {
                          some: { language: { equals: $language } }
                        }
                      }
                    ]
                  }
                }
              }
            ]
          }
          { OR: [] }
          { OR: [] }
        ]
      }
    )
  }
`

export const GET_PRODUCT_CONTAINERS = gql`
  ${PRODUCT_FRAGMENT}
  query GET_PRODUCT_CONTAINERS(
    $take: Int
    $where: ProductContainerWhereInput
    $orderBy: ProductContainerOrderByWithRelationInput
    $loadMore: Boolean!
    $cursor: ProductContainerWhereUniqueInput
    $skip: Int
  ) {
    sortedProductContainersData(
      skip: $skip
      take: $take
      cursor: $cursor
      orderBy: $orderBy
      where: $where
    ) {
      sortedProductContainers {
        id
        ...PRODUCT @skip(if: $loadMore)
      }
      lastProductId
      hasNextPage
    }
  }
`
