import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import React from 'react'
import { ProductGallery, ProductPanel } from '.'
import { Media } from '../../../../lib/artsyFresnel/Media'
import { MobileProductPageSlider } from '../../ResponsiveElements/ResponsiveProductPageElements'

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      maxWidth: 1920,
      margin: '0 auto',
    },
    productPanelContainer: {
      display: 'flex',
      position: 'relative',
    },
    productPanel: {},
  }),
  { name: 'MuiTopProductPageComponent' },
)

export default function TopProductPage({
  productPermalink,
  product,
  isSafari,
}) {
  const classes = useStyles() as any

  return (
    <div className={classes.container}>
      <Media greaterThan="md">
        <Grid container justifyContent="space-evenly" spacing={1}>
          <Grid item xs={12} sm={7} md={9} lg={9}>
            <ProductGallery
              productImages={product?.single_product.images}
              product={{
                dimensions: {
                  ...product.single_product.dimensions,
                },
                product_details: {
                  ...product.single_product.product_details,
                },
                packaging_information: [
                  ...product.single_product.packing_information_list,
                ],
              }}
              isSafari={isSafari}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={3}
            lg={3}
            className={classes.productPanelContainer}
          >
            <div className={classes.productPanel}>
              <ProductPanel
                product={product}
                productPermalink={productPermalink}
              />
            </div>
          </Grid>
        </Grid>
      </Media>
      <Media lessThan="lg">
        <Grid container>
          <Grid item xs={12}>
            <MobileProductPageSlider
              images={product?.single_product.images}
              product={product}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductPanel
              product={product}
              productPermalink={productPermalink}
            />
          </Grid>
        </Grid>
      </Media>
    </div>
  )
}
