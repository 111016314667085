import { Breadcrumbs, Divider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { Result } from '../DesktopElements/BlogPageElements'

export const breadcrumb = [
  {
    href: '#',
    title: 'Blog',
    isActive: false,
  },
  {
    title: 'Blog Search',
    isActive: true,
  },
]

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
    },
    sectionBreadcrumb: {
      '& .section-alternate__content': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  }),
  { name: 'MuiBlogPageComponent' },
)

const BlogPage = ({ blogPosts }): JSX.Element => {
  const { t } = useTranslation(['common'])
  const homeBreadcrumb = t('home-bread-crumb')
  const blogString = t('blog')
  const classes = useStyles() as any

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ padding: '1rem 0.2rem' }}>
        <Link prefetch={false} href="/">
          <Typography
            variant="caption"
            component="a"
            className={classes.capitalize}
          >
            {homeBreadcrumb}
          </Typography>
        </Link>
        <Typography variant="caption" className={classes.capitalize}>
          {blogString}
        </Typography>
      </Breadcrumbs>
      <Result blogPosts={blogPosts} />
      {/* <Section>
        <Newsletter />
      </Section> */}
      <Divider />
    </div>
  )
}

export default BlogPage
