import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Container } from '@mui/material'

import createStyles from '@mui/styles/createStyles'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     margin: "0 auto",
//     padding: theme.spacing(6, 2),
//     [theme.breakpoints.up("sm")]: {
//       padding: theme.spacing(8, 8),
//     },
//     [theme.breakpoints.up("md")]: {
//       padding: theme.spacing(12, 8),
//     },
//   },
//   fullWidth: {
//     maxWidth: "100%",
//   },
//   contained: {
//     maxWidth: "1280px",
//   },
//   disablePadding: {
//     padding: 0,
//   },
//   narrow: {
//     maxWidth: 800,
//   },
// }));

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(6, 2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(8, 8),
        },
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(12, 8),
        },
      },
      fullWidth: {
        maxWidth: '100%',
      },
      contained: {
        // maxWidth: "1280px",
        maxWidth: '1175px',
      },
      disablePadding: {
        padding: 0,
      },
      narrow: {
        maxWidth: 800,
      },
    }),
  { name: 'MuiSectionComponent' },
)

/**
 * Component to display the sections
 *
 * @param {Object} props
 */
const Section = ({
  children,
  fullWidth = false,
  narrow,
  disablePadding,
  className,
  ...rest
}: SectionProps): JSX.Element => {
  const classes = useStyles() as any

  return (
    <section
      className={clsx(
        'section',
        classes.root,
        fullWidth ? classes.fullWidth : classes.contained,
        narrow ? classes.narrow : {},
        disablePadding ? classes.disablePadding : {},
        className,
      )}
      {...rest}
    >
      {children}
    </section>
  )
}

export default Section
