import { gql } from '@apollo/client'

export const GET_CATEGORY_DATA = gql`
  query GET_CATEGORY_DATA($permalink: String) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      meta_title
      meta_description
      permalink
      category_profile {
        id
        name
        description
        language
        category {
          id
          image {
            id
            src
            src_lg
            src_md
            src_xs
          }
          # product_containers {
          #   id
          #   single_product {
          #     id
          #     price
          #     images {
          #       id
          #       src
          #       display_order
          #     }
          #     product_profiles {
          #       id
          #       name
          #       language
          #       meta_information {
          #         id
          #         permalink
          #       }
          #     }
          #   }
          # }
        }
      }
    }
  }
`

export const GET_STYLE_DATA = gql`
  query GET_STYLE_DATA($permalink: String) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      meta_title
      meta_description
      permalink
      style_profile {
        id
        name
        description
        language
        style {
          id
          main_image {
            id
            src
            src_lg
            src_md
            src_xs
          }
        }
      }
    }
  }
`

export const GET_COLLECTION_DATA = gql`
  query GET_COLLECTION_DATA($permalink: String) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      meta_title
      meta_description
      permalink
      collection_profile {
        id
        name
        description
        language
        collection {
          id
          main_image {
            id
            src
            src_lg
            src_md
            src_xs
          }
        }
      }
    }
  }
`

export const GET_TAG_DATA = gql`
  query GET_TAG_DATA($permalink: String) {
    findUniqueMetaInformation(where: { permalink: $permalink }) {
      id
      meta_title
      meta_description
      permalink
      tag_profile {
        id
        name
        description
        language
        tag {
          id
          main_image {
            id
            src
            src_lg
            src_md
            src_xs
          }
        }
      }
    }
  }
`

export const GET_PRODUCTS_IN_CATEGORY = gql`
  query GET_PRODUCTS_IN_CATEGORY(
    $categoryID: Int!
    $take: Int!
    $clientProductsInReserve: [Int!]
    $previouslyInsertedProducts: [Int!]
  ) {
    findProductContainersWithIntelligentSort(
      where: { categories: { some: { id: { equals: $categoryID } } } }
      take: $take
      clientProductsInReserve: $clientProductsInReserve
      previouslyInsertedProducts: $previouslyInsertedProducts
    ) {
      productResponse
      productsInReserve
      insertedProducts
      count
      totalPages
      currentPage
      moreProductsAvailable
    }
  }
`

export const GET_PRODUCTS_COUNT = gql`
  query GET_PRODUCTS_COUNT($permalink: String, $language: Language) {
    findManyProductContainerCount(
      where: {
        categories: {
          some: {
            category_profiles: {
              some: {
                AND: [
                  { meta_information: { is:{ permalink: { equals: $permalink } } }}
                  { language: { equals: $language } }
                ]
              }
            }
          }
        }
        single_product: {
          product_profiles: { some: { language: { equals: $language } } }
        }
      }
    )
  }
`

export const PRODUCT_FRAGMENT = gql`
  fragment PRODUCT on ProductContainer {
    id
    type
    createdAt
    product_position_in_store
    discount {
      id
      discount
      active
      startDate
      expiryDate
    }
    # === FOR SCHEMA REFERENCE ===
    primary_category {
      schema_href
    }
    #categories {
    #  schema_href
    #}

    # --- Moved to be dynamically loading ----
    # dispatch_information {
    #   id
    #   manufacturing_time
    #   shipping_time
    #   total_delivery_time
    # }
    # manufacturer {
    #   id
    #   is_manufacturer_public
    #   default_dispatch_information {
    #     id
    #     shipping_time
    #     manufacturing_time
    #     total_delivery_time
    #   }
    # }
    single_product {
      id
      price
      sku
      product_profiles {
        id
        name
        size_name
        language
        meta_information {
          id
          meta_title
          meta_description
          permalink
        }
      }
      # shipping_methods {
      #   id
      #   is_enabled
      #   price_for_box
      # }
      # packing_information_list {
      #   id
      #   number_of_products_that_fit_in_box
      # }
      # grouped products (When hovering over card color we display content of these product )
      # Below is SSR version (getServerSideProps)
      # grouped_by_color {
      #   id
      #   product_containerId
      # }
      # This is used with getStaticProps
      grouped_by_color {
        id
        price
        product_containerId
        product_container {
          createdAt
          discount {
            id
            discount
            active
            startDate
            expiryDate
          }
          dispatch_information {
            id
            manufacturing_time
            shipping_time
            total_delivery_time
          }
          manufacturer {
            id
            is_manufacturer_public
            default_dispatch_information {
              id
              shipping_time
              manufacturing_time
              total_delivery_time
            }
          }
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
        images {
          id
          src
          src_lg
          src_md
          src_xs
          src_thumbnail
          display_order
        }
        main_color {
          id
          hex
          background
        }
      }

      grouped_by_size {
        id
        product_profiles {
          id
          language
        }
      }
      images {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
        display_order
      }
      main_color {
        id
        hex
        background
      }
    }

    advanced_product {
      id
      price_from

      images {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
        display_order
      }

      advanced_product_profiles {
        id
        name
        language
        meta_information {
          id
          meta_title
          meta_description
          permalink
        }
      }
    }
  }
`

// ZAKARIAS VERSION
// (We are shaping this to fix load speeds)
export const PRODUCT_FRAGMENT_FULL = gql`
  fragment PRODUCT_FULL on ProductContainer {
    id
    type
    createdAt
    product_position_in_store
    type
    discount {
      id
      discount
      active
      startDate
      expiryDate
    }
    dispatch_information {
      id
      manufacturing_time
      shipping_time
      total_delivery_time
    }
    manufacturer {
      id
      is_manufacturer_public
      default_dispatch_information {
        id
        shipping_time
        manufacturing_time
        total_delivery_time
      }
    }
    single_product {
      id
      price
      sku
      product_profiles {
        id
        name
        size_name
        language
        meta_information {
          id
          meta_title
          meta_description
          permalink
        }
      }
      shipping_methods {
        id
        is_enabled
        price_for_box
      }
      packing_information_list {
        id
        number_of_products_that_fit_in_box
      }
      # grouped products (When hovering over card color we display content of these product )
      grouped_by_color {
        id
        price
        product_containerId
        product_container {
          createdAt
          discount {
            id
            discount
            active
            startDate
            expiryDate
          }
          dispatch_information {
            id
            manufacturing_time
            shipping_time
            total_delivery_time
          }
          manufacturer {
            id
            is_manufacturer_public
            default_dispatch_information {
              id
              shipping_time
              manufacturing_time
              total_delivery_time
            }
          }
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
        images {
          id
          src
          src_lg
          src_md
          src_xs
          src_thumbnail
          display_order
        }
        main_color {
          id
          hex
          background
        }
      }
      grouped_by_size {
        id
        product_profiles {
          id
          language
        }
      }
      images {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
        display_order
      }
      main_color {
        id
        hex
        background
      }
    }

    advanced_product {
      id
      price_from

      images {
        id
        src
        src_lg
        src_md
        src_xs
        src_thumbnail
        display_order
      }

      advanced_product_profiles {
        id
        name
        language
        meta_information {
          id
          meta_title
          meta_description
          permalink
        }
      }
    }
  }
`

export const GET_PRODUCT_CONTAINER_ADDITIONAL_DATA = gql`
  query GET_PRODUCT_CONTAINER_ADDITIONAL_DATA(
    $productContainerID: Int
    $language: Language
  ) {
    findManyProductContainer(
      where: {
        id: { equals: $productContainerID }
        single_product: { is: {
          product_profiles: { some: { language: { equals: $language } } }
        }}
      }
    ) {
      id
      dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
      manufacturer {
        id
        is_manufacturer_public
        default_dispatch_information {
          id
          shipping_time
          manufacturing_time
          total_delivery_time
        }
      }
    }
  }
`

export const GET_PRODUCT_CONTAINER = gql`
  ${PRODUCT_FRAGMENT_FULL}
  query GET_PRODUCT_CONTAINER($productContainerID: Int, $language: Language) {
    findManyProductContainer(
      where: {
        id: { equals: $productContainerID }
        OR: [
          {
            single_product: { is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: { is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
    ) {
      ...PRODUCT_FULL
    }
  }
`

export const GET_PRODUCT_CONTAINER_FOR_REVALIDATION = gql`
  query GET_PRODUCT_CONTAINER_FOR_REVALIDATION($productContainerID: Int) {
    findUniqueProductContainer(where: { id: $productContainerID }) {
      id
      discount {
        id
        discount
        active
        startDate
        expiryDate
      }
      # === To consider later
      # dispatch_information {
      #   id
      #   manufacturing_time
      #   shipping_time
      #   total_delivery_time
      # }
      single_product {
        price
        quantity
        follow_product_stock
        minimum_order_quantity
      }
    }
  }
`

export const GET_MIN_MAX_PRICE_STATIC = gql`
  query GET_MIN_MAX_COUNT_STATIC($permalink: String, $language: Language) {
    SingleProductMinPrice(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information:{ is: {
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is: {
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is: {
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is: {
              style: { is: {
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: { is: { permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: { is: {
              collection: { is: {
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: { is: { permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }
          }}
        ]
      }
    )

    SingleProductMaxPrice(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: { is: {
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: { is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: { is:{
              collection:{ is: {
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )
  }
`

export const GET_MIN_MAX_DIMENSIONS_STATIC = gql`
  query GET_MIN_MAX_DIMENSIONS_STATIC($permalink: String, $language: Language) {
    # ============================================================================================================
    #            ================================== WIDTH =======================================
    # ============================================================================================================
    SingleProductMinWidth(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: { is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: { is: {
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: { is: {
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: { is: {
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: { is: {
              style: { is: {
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: { is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: { is:{
              collection:{is: {
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )
    SingleProductMaxWidth(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information:{is: {
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: {is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: {is:{
              collection: {is:{
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )

    # ============================================================================================================
    #            ================================== HEIGHT =======================================
    # ============================================================================================================
    SingleProductMinHeight(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: {is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: {is:{
              collection: {is:{
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information:{is: { permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )
    SingleProductMaxHeight(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: {is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information:{ is: { permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: {is:{
              collection: {is:{
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )

    # ============================================================================================================
    #            ================================== LENGTH =======================================
    # ============================================================================================================
    SingleProductMinLength(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: {is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: {is:{
              collection: {is:{
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )
    SingleProductMaxLength(
      where: {
        product_profiles: { some: { language: { equals: $language } } }
        OR: [
          {
            product_container: {is:{
              categories: {
                some: {
                  category_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              tags: {
                some: {
                  tag_profiles: {
                    some: {
                      AND: [
                        {
                          meta_information: {is:{
                            permalink: { equals: $permalink }
                          }}
                        }
                        { language: { equals: $language } }
                      ]
                    }
                  }
                }
              }
            }}
          }
          {
            product_container: {is:{
              style: {is:{
                style_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
          {
            product_container: {is:{
              collection: {is:{
                collection_profiles: {
                  some: {
                    AND: [
                      {
                        meta_information: {is:{ permalink: { equals: $permalink } }}
                      }
                      { language: { equals: $language } }
                    ]
                  }
                }
              }}
            }}
          }
        ]
      }
    )
  }
`

// ....... Failed try, becuse graphql only allows cursor, since we dont have the id of last product in prevoous page, we need to handle this in the backend...
// export const GET_SORTED_BY_POSITION_PRODUCTS = gql`
//   ${PRODUCT_FRAGMENT}
//   query GET_SORTED_BY_POSITION_PRODUCTS(
//     $permalink: String!
//     $language: Language
//     $skip: Int!
//     $take: Int!
//   ) {
//     findManyProductContainer(
//       # categoryPermalink: $permalink
//       orderBy:{
//       SVP:"desc"
//       },
//       where: {
//         AND: [
//           {
//             categories: {
//               some: {
//                 category_profiles: {
//                   some: {
//                     meta_information: { permalink: { equals: $permalink } }
//                   }
//                 }
//               }
//             }
//           }
//           {
//             OR: [
//               {
//                 single_product: {
//                   product_profiles: {
//                     some: { language: { equals: $language } }
//                   }
//                 }
//               }
//               {
//                 advanced_product: {
//                   advanced_product_profiles: {
//                     some: { language: { equals: $language } }
//                   }
//                 }
//               }
//             ]
//           }
//         ]
//       }
//       skip: $skip
//       take: $take
//     ) {
//       numberOfPages
//       productsCount
//       sortedProductContainers {
//         ...PRODUCT
//       }
//     }
//   }
// `

export const GET_SORTED_BY_POSITION_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query GET_SORTED_BY_POSITION_PRODUCTS(
    $permalink: String!
    $language: Language
    $page: Int!
    $take: Int!
  ) {
    sortedByCategoryPositionProductContainers(
      categoryPermalink: $permalink
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: {is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
      page: $page
      take: $take
    ) {
      numberOfPages
      productsCount
      sortedProductContainers {
        ...PRODUCT
      }
    }
  }
`

export const GET_SORTED_BY_POSITION_PRODUCTS_STYLE = gql`
  ${PRODUCT_FRAGMENT}
  query GET_SORTED_BY_POSITION_PRODUCTS_STYLE(
    $permalink: String!
    $language: Language
    $page: Int!
    $take: Int!
  ) {
    sortedByStylePositionProductContainers(
      stylePermalink: $permalink
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: {is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
      page: $page
      take: $take
    ) {
      numberOfPages
      productsCount
      sortedProductContainers {
        ...PRODUCT
      }
    }
  }
`

export const GET_SORTED_BY_POSITION_PRODUCTS_COLLECTION = gql`
  ${PRODUCT_FRAGMENT}
  query GET_SORTED_BY_POSITION_PRODUCTS_COLLECTION(
    $permalink: String!
    $language: Language
    $page: Int!
    $take: Int!
  ) {
    sortedByCollectionPositionProductContainers(
      collectionPermalink: $permalink
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: {is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
      page: $page
      take: $take
    ) {
      numberOfPages
      productsCount
      sortedProductContainers {
        ...PRODUCT
      }
    }
  }
`

export const GET_SORTED_BY_POSITION_PRODUCTS_SEARCH_TERM = gql`
  ${PRODUCT_FRAGMENT}
  query GET_SORTED_BY_POSITION_PRODUCTS_SEARCH_TERM(
    $permalink: String!
    $language: Language!
    $take: Int!
    $page: Int!
  ) {
    sortedBySearchTermPositionProductContainers(
      searchTerm: $permalink
      language: $language
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: {is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
      take: $take
      page: $page
    ) {
      numberOfPages
      productsCount
      sortedProductContainers {
        ...PRODUCT
      }
    }
  }
`

export const GET_SORTED_BY_POSITION_PRODUCTS_TAG = gql`
  ${PRODUCT_FRAGMENT}
  query GET_SORTED_BY_POSITION_PRODUCTS_TAG(
    $permalink: String!
    $language: Language
    $page: Int!
    $take: Int!
  ) {
    sortedByTagPositionProductContainers(
      tagPermalink: $permalink
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: {is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
      page: $page
      take: $take
    ) {
      numberOfPages
      productsCount
      sortedProductContainers {
        ...PRODUCT
      }
    }
  }
`

export const GET_PRODUCT_CONTAINER_GROUPED_BY_COLOR = gql`
  query GET_PRODUCT_CONTAINER_GROUPED_BY_COLOR($productContainerIds: [Int]!) {
    findManyProductContainer(where: { id: { in: $productContainerIds } }) {
      id
      createdAt
      discount {
        id
        discount
        active
        startDate
        expiryDate
      }
      dispatch_information {
        id
        manufacturing_time
        shipping_time
        total_delivery_time
      }
      manufacturer {
        id
        is_manufacturer_public
        default_dispatch_information {
          id
          shipping_time
          manufacturing_time
          total_delivery_time
        }
      }

      single_product {
        price
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
        images {
          id
          src
          src_lg
          src_md
          src_xs
          src_thumbnail
          display_order
        }
        main_color {
          id
          hex
          background
        }
      }
    }
  }
`

export const GET_PERMALINK_CATEGORIES_STYLES = gql`
  query GET_SORTED_BY_POSITION_PRODUCTS_TAG(
    $permalink: String!
    $language: Language
  ) {
    findManyCategory(
      where: {
        category_profiles: {
          some: {
            AND: [
              { meta_information: {is: { permalink: { equals: $permalink } }} }
              { language: { equals: $language } }
            ]
          }
        }
      }
    ) {
      id
    }

    findManyStyle(
      where: {
        style_profiles: {
          some: {
            AND: [
              { meta_information: {is:{ permalink: { equals: $permalink } } }}
              { language: { equals: $language } }
            ]
          }
        }
      }
    ) {
      id
    }
  }
`
