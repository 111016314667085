import { Container, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useState } from 'react'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import CategoriesDropdown from './CategoriesDropdown'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '5rem',
        display: 'flex',
        // justifyContent: "space-evenly",
        position: 'relative',
      },
      borderBottomOnScroll: {
        width: '100%',
        borderBottom: '0.5px solid #d3d3d3',
      },
      category: {
        color: '#000',
        padding: '1.6rem 0.5rem',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.5',
        },
      },
    }),
  { name: 'MuiCategorieNavigationComponent' },
)

export default function CategoriesNavigation(props) {
  const classes = useStyles() as any
  const { locale, data } = props
  // const { locale } = useRouter();
  const [open, setOpen] = useState(false)
  const [childCategories, setChildCategories] = useState([])
  const [parentData, setParentData] = useState({
    permalink: null,
    image: null,
    name: null,
    styles: null,
  })
  const { t } = useTranslation(['common'])
  const categorySlug = t('category-slug')

  // console.log("in caegories menu data ->", propsData);

  // console.log("child data ->", data);
  // const sortedCategories = data.sort

  //staticFix
  const propsData = data?.data ?? null

  if (!propsData) return null
  return (
    <div
      className={clsx(
        !props.borderBottomOnScroll && classes.borderBottomOnScroll,
      )}
    >
      <Container maxWidth="xl">
        <Grid container spacing={1} className={classes.root}>
          {propsData.findManyCategory.map((category, i) => {
            //* =========================================================
            //* =========== GET THE CORRECT CATEGORY PROFILE ============
            //* =========================================================
            const categoryProfileIndex: number = getProfileIndexFromLanguage(
              category.category_profiles,
              locale,
            )

            return (
              typeof categoryProfileIndex === 'number' && (
                <React.Fragment key={category.id}>
                  <Link
                    prefetch={false}
                    href={`/${categorySlug}/${category.category_profiles[categoryProfileIndex]?.meta_information.permalink}/1`}
                    key={category.id}
                  >
                    <a className="cursorPointer">
                      <Grid
                        item
                        onMouseEnter={(e) => {
                          setOpen(true)
                          setChildCategories(
                            category.child_categories.filter(
                              (item) => item.show_in_menu === true,
                            ),
                          )
                          setParentData({
                            permalink:
                              category.category_profiles[categoryProfileIndex]
                                ?.meta_information.permalink,
                            image:
                              category.image?.src ??
                              '/fallbacks/thumbnail_08.png',
                            name:
                              category.category_profiles[categoryProfileIndex]
                                ?.name,
                            styles: propsData.findManyStyle,
                          })
                        }}
                        onMouseLeave={(e) => {
                          setOpen((prev) => !prev)
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {
                            category.category_profiles[categoryProfileIndex]
                              ?.name
                          }
                        </Typography>
                      </Grid>
                    </a>
                  </Link>
                </React.Fragment>
              )
            )
          })}
        </Grid>
        {childCategories.length > 0 && open && (
          <CategoriesDropdown
            openState={{ open, setOpen }}
            childCategoriesData={childCategories}
            parentData={parentData}
            locale={locale}
          />
        )}
      </Container>
    </div>
  )
}
