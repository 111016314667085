import React, { Props, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Lazy,
} from 'swiper'
import 'swiper/css/bundle'
import { Container, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      slider: {
        width: '100%',
        '--swiper-navigation-color': 'black',
        '&.swiper-container': {
          overflowX: 'clip',
          overflowY: 'visible',
        },
        '& .swiper-button-prev': {
          top: '-2rem',
          right: '3.5rem',
          left: 'auto',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '100%',
          background: '#e7e9ea',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
        '& .swiper-button-prev::after': {
          fontSize: '1rem',
          fontWeight: 700,
        },
        '& .swiper-button-next': {
          top: '-2rem',
          right: '0',
          left: 'auto',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '100%',
          background: '#e7e9ea',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
        '& .swiper-button-next::after': {
          fontSize: '1rem',
          fontWeight: 700,
        },
        [theme.breakpoints.up('lg')]: {
          '&::after': {
            content: '""',
            width: '20%',
            height: '100%',
            position: 'absolute',
            right: '0',
            top: '0',
            background:
              'linear-gradient(90deg, transparent 0%, rgba(247,249,250,1) 100%)',
            zIndex: 2,
          },
        },
      },
      sliderAfterDisplayNone: {
        '&::after': {
          display: 'none',
        },
      },
      nextArrow: {
        position: 'absolute',
        zIndex: 3,
        right: 0,
        top: '-3.5rem',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      prevArrow: {
        position: 'absolute',
        zIndex: 3,
        right: '5rem',
        top: '-3.5rem',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
    }),
  { name: 'MuiCustomSliderComponent' },
)

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const CustomSlider = ({
  swiperProps,
  className,
  children,
  ...rest
}): JSX.Element => {
  const classes = useStyles() as any
  const [isEnd, setIsEnd] = useState(false)

  return (
    <Swiper
      {...swiperProps}
      className={clsx(
        isEnd && classes.sliderAfterDisplayNone,
        className,
        classes.slider,
      )}
      onSlideChange={(swiper) => {
        if (swiper.isEnd !== isEnd) {
          setIsEnd(swiper.isEnd)
        }
      }}
    >
      {children}
    </Swiper>
  )
}

export default CustomSlider
