import { Language } from "../store/models";

//* ====================================================================================
//* =============UTILITY FUNCTION TO FIND MATCHING PROFILE GIVEN LANGUAGE===============
//* ====================================================================================
export function getProfileIndexFromLanguage(profiles, locale: string) {
  if (!profiles || profiles.length === 0) return null


  const index = profiles.findIndex((profile) => {
    return profile.language === locale;
  });

  return index === -1 ? null : index;
}

export function getProfileFromLanguage(profiles, locale: string) {
  if (!profiles || profiles.length === 0) return null


  const profile = profiles.find((profile) => {
    return profile.language === locale;
  });

  return profile;
}
