import { useReactiveVar } from '@apollo/client'
import { CardMedia, Drawer, IconButton, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import RoomIcon from '@mui/icons-material/Room'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useState } from 'react'
import {
  parentFullPermalinkVar,
  responsiveMenuVar,
} from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import ResponsiveSubMenu from './ResponsiveSubMenu'
import { useShopContext } from '../../../context'
import { LoginComponent } from '../../DesktopElements/HeaderElements/HeaderTopBanner'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      menu: {
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
          position: 'relative',
        },
      },
      menuTopContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
      },
      closeIcon: {
        cursor: 'pointer',
      },
      categoriesContainer: {
        overflowY: 'auto',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        height: '4rem',
        borderTop: '1px solid #e4e4e4',
      },
      media: {
        height: '100%',
        backgroundSize: 'contain',
        width: '2.5rem',
        marginRight: '1rem',
      },
      leftRowContent: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      rightRowContent: {
        height: '100%',
        width: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      menuFooter: {
        // background: '#efeee5',
        background: theme.palette.secondary.light,
        padding: theme.spacing(3, 2, 2, 2),
        // TODO: uncomment this for production --> marginTop: theme.spacing(5),
        marginTop: 'auto',
      },
      footerRow: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5, 0),
      },
      footerIcon: {
        marginRight: theme.spacing(3),
      },
    }),
  { name: 'MuiResponsiveMenuComponent' },
)

export default function ResponsiveMenu({ categoriesData, locale }) {
  const { t, i18n } = useTranslation(['common'])
  const categorySlug = t('category-slug')
  const contactSlug = t('contact-slug')
  const contactPageName = t('contact-page')

  const classes = useStyles() as any
  const menuState = useReactiveVar(responsiveMenuVar)
  const [subMenuState, setSubMenuState] = useState(false)
  // const subMenuState = useReactiveVar(responsiveSubMenuVar);
  const [target, setTarget] = useState({
    category: null,
    category_profile: null,
  })

  // ==== FULL MENU PERMALINK (to perserve hierachy of the url for child categories) ====
  const parentFullPermalink = useReactiveVar(parentFullPermalinkVar)

  const { shopPhoneNumber } = useShopContext()

  // console.log(
  //   "target.parent_full.permalink :>> ",
  //   target.parent_full_permalink
  // );

  // useEffect(() => {
  //   if (!subMenuState) {
  //     //--- we closed submenu (Pressed back)
  //     console.log("Submenu closed..");
  //     setTarget({
  //       category: null,
  //       category_profile: null,
  //       parent_full_permalink: "",
  //     });
  //   }
  // }, [subMenuState]);

  //staticprops fix
  // console.log('categoriesData :>> ', categoriesData) // For some reason (different to get server side props this comes with additional .data)
  const catData = categoriesData?.data ?? null

  return (
    <>
      <Drawer
        classes={{
          paper: classes.menu,
        }}
        anchor="left"
        open={menuState}
        transitionDuration={{
          enter: 800,
          exit: 800,
        }}
        onClose={() => {
          setSubMenuState(false)
          responsiveMenuVar(false)
          parentFullPermalinkVar('')
        }}
      >
        <div className={classes.menuTopContent}>
          <LoginComponent mobile={true} />

          {/* <Typography
            variant="subtitle1"
            style={{ textTransform: 'uppercase' }}
          >
            
           
          </Typography> */}
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => responsiveMenuVar(false)}
          />
        </div>

        {catData?.findManyCategory.map((category) => {
          const categoryProfileIndex: number = getProfileIndexFromLanguage(
            category.category_profiles,
            locale,
          )

          if (category.category_profiles[categoryProfileIndex]) {
            //check if the category has children. If it does we want to open the sub menu
            //where the user can click on "see all", to go to the whole parent category
            if (category.child_categories.length > 0) {
              return (
                <div
                  key={category.id}
                  className={classes.row}
                  onClick={(e) => {
                    setSubMenuState(true)
                    setTarget({
                      category,
                      category_profile:
                        category.category_profiles[categoryProfileIndex],
                    })

                    parentFullPermalinkVar(
                      parentFullPermalink +
                      '/' +
                      category.category_profiles[categoryProfileIndex]
                        .meta_information.permalink,
                    )
                  }}
                >
                  <div className={classes.leftRowContent}>
                    <CardMedia
                      image={
                        category.image_icon?.src ??
                        category.image?.src ??
                        `/fallbacks/imageNotFound.png`
                      }
                      className={classes.media}
                    />
                    <Typography
                      variant="body1"
                      component="h2"
                      key={category.id}
                    >
                      {category.category_profiles[categoryProfileIndex]?.name}
                    </Typography>
                  </div>
                  <IconButton size="large">
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              )
            } else {
              return (
                <Link
                  prefetch={false}
                  href={`/${categorySlug}${parentFullPermalink}/${category.category_profiles[categoryProfileIndex]?.meta_information.permalink}/1`}
                  key={category.id}
                >
                  <a>
                    <div
                      className={classes.row}
                      onClick={() => {
                        setSubMenuState(false)
                        responsiveMenuVar(false)
                      }}
                    >
                      <div className={classes.leftRowContent}>
                        <CardMedia
                          image={
                            category.image_icon?.src ??
                            category.image?.src ??
                            `/fallbacks/imageNotFound.png`
                          }
                          className={classes.media}
                        />
                        <Typography
                          variant="body1"
                          component="h2"
                          key={category.id}
                        >
                          {
                            category.category_profiles[categoryProfileIndex]
                              ?.name
                          }
                        </Typography>
                      </div>
                      <IconButton size="large">
                        <ChevronRightIcon />
                      </IconButton>
                    </div>
                  </a>
                </Link>
              )
            }
          } else {
            return null
          }
        })}

        {/* MENU FOOTER */}
        <section className={classes.menuFooter}>
          {/* <div className={classes.footerRow}>
          <PersonIcon className={classes.footerIcon} />
          <Typography variant="body2" component="a">
            Log in / Register
          </Typography>
        </div> */}
          <div className={classes.footerRow}>
            <RoomIcon className={classes.footerIcon} />
            <Link prefetch={false} href={`/${contactSlug}#contact`}>
              <a>
                <Typography
                  variant="body2"
                  component="a"
                  onClick={() => {
                    responsiveMenuVar(false)
                  }}
                >
                  {contactPageName}
                </Typography>
              </a>
            </Link>
          </div>

          {shopPhoneNumber && (
            <div className={classes.footerRow}>
              <HelpOutlineIcon className={classes.footerIcon} />

              <a href={`tel:${shopPhoneNumber}`}>
                <Typography variant="body2" component="a">
                  {shopPhoneNumber}
                </Typography>
              </a>
            </div>
          )}
        </section>

        {/* SUBMENU DRAWER  */}
        <ResponsiveSubMenu
          subMenuState={{
            open: subMenuState,
            setOpen: setSubMenuState,
          }}
          onClose={() => {
            setSubMenuState(false)
            responsiveMenuVar(false)
            parentFullPermalinkVar('')
          }}
          targetState={{ target, setTarget }}
          locale={locale}
        />
      </Drawer>
    </>
  )
}
