import { makeStyles } from '@mui/styles'
import React from 'react'

//TODO: populate with the rest of flex props

export default function FlexBox({
  flexDirection = 'row' as any,
  flexWrap = 'nowrap' as any,
  justifyContent = 'flex-start' as any,
  alignItems = 'stretch' as any,
  gap = '' as any,
  style = {},
  children,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        gap,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
