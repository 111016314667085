import { createContext, useContext } from 'react'

const ShopContext = createContext(null)

export function ShopContextWrapper({
  value,
  children,
  shopPhoneNumber,
  enabledLanguages,
  defaultLocale,
  defaultEnabledLanguage,
}) {
  return (
    <ShopContext.Provider
      value={{
        value,
        shopPhoneNumber,
        enabledLanguages,
        defaultLocale,
        defaultEnabledLanguage,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export function useShopContext() {
  return useContext(ShopContext)
}
