import { useReactiveVar } from '@apollo/client'
import { Typography, Slider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { heightFilterVar } from '../../../../store/reactiveVars'
import { dimensionsFormatter } from '../../../../utilityFunctions/dimensionsUtils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
      },
      sliderThumb: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginTop: '-7px',
        backgroundColor: theme.palette.background.paper,
        border: '0.5px solid #000',
      },
      minMaxValues: {
        fontWeight: 500,
      },
    }),
  { name: 'MuiFilterHeightRangeComponent' },
)

export default function FilterHeightRange({ min, max, loading }) {
  const classes = useStyles() as any
  const heightFilter = useReactiveVar(heightFilterVar)
  const [value, setValue] = useState([])

  const { t, i18n } = useTranslation(['common'])
  const heightString = t('product-height')

  //* =====================================================================================================
  //* handling different cases on component mount (first render - coming from an already filtered category)
  //* =====================================================================================================
  useEffect(() => {
    if (heightFilter.minHeight > min && heightFilter.maxHeight < max) {
      setValue([heightFilter.minHeight, heightFilter.maxHeight])
    } else if (!heightFilter.minHeight && !heightFilter.maxHeight) {
      setValue([min, max])
    } else if (heightFilter.minHeight < min || heightFilter.maxHeight < max) {
      setValue([min, heightFilter.maxHeight])
    } else if (heightFilter.minHeight > min || heightFilter.maxHeight > max) {
      setValue([heightFilter.minHeight, max])
    } else if (heightFilter.minHeight < min || heightFilter.maxHeight > max) {
      setValue([min, max])
    }

    return () => {
      setValue([])
    }
  }, [])

  //* =========================================================================
  //* ======= Handling change while sliding and on mouse up (committed) =======
  //* =========================================================================
  const handleChange = (event: any, newValue: number[]) => {
    setValue(newValue)
  }

  // here we wait for the user to stop dragging (mouseUp event ) to set the reactive var. Tried with lodash debouncing but still too many hits to API
  const handleCommittedChange = (event: any, newValue: number[]) => {
    const minHeight = Math.min(...newValue)
    const maxHeight = Math.max(...newValue)

    // Handle case when the users resets the price and it matches the whole category, so that we can show static data with intelligent sort instead of full category from dynamic data with adiacent variations.
    if (minHeight === min && maxHeight === max) {
      heightFilterVar({ minHeight: null, maxHeight: null })
      return
    }

    heightFilterVar({ minHeight, maxHeight })
  }

  //* ==============================================================================================
  //* =============== handling loading state, so that we return the component only once we have data
  //* ==============================================================================================
  if (loading) return <div>Loading...</div>

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body2">{heightString}</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography gutterBottom variant="caption" component="p">
            Min:{' '}
            <Typography
              variant="caption"
              component="span"
              className={classes.minMaxValues}
            >
              {dimensionsFormatter(value[0])}
            </Typography>
          </Typography>
          <Typography gutterBottom variant="caption" component="p">
            Max:{' '}
            <Typography
              variant="caption"
              component="span"
              className={classes.minMaxValues}
            >
              {dimensionsFormatter(value[1])}
            </Typography>
          </Typography>
        </div>
        <Slider
          value={value}
          disabled={
            //* =====================================================================================================================================
            //* if they're not null and they're the same it means that there's either only one product or all products have the same price. so better disable it until they turn on different filters that return different min and max.
            //* =====================================================================================================================================
            min === max ? true : false
          }
          classes={{
            thumb: classes.sliderThumb,
          }}
          onChangeCommitted={handleCommittedChange}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="height-slider"
          min={min}
          max={max}
        />
      </div>
    </>
  )
}
