import {
  ClickAwayListener,
  IconButton,
  InputBase,
  Paper,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'
// import OutsideClickHandler from "react-outside-click-handler";
import { motion } from 'framer-motion'
import _ from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useRef } from 'react'
import { SearchDropdown } from '../../Modules'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
          position: 'unset',
        },
      },
      root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: 0,
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down('lg')]: {
          position: 'relative',
        },
      },
      mobileRoot: {
        display: 'flex',
        flexDirection: 'row-reverse',
        backgroundColor: theme.palette.background.default,
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
      subMenu: {
        position: 'absolute',
        width: 900,
        left: -250,
        top: '4rem',
        zIndex: 1201,
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          left: 0,
          top: '10.9rem',
        },

        [theme.breakpoints.down('md')]: {
          width: '100%',
          left: 0,
          top: '7.7rem',
        },
      },
    }),
  { name: 'MuiSearchInputComponent' },
)

const SearchInput = ({ mobile, ...rest }: SearchInputProps): JSX.Element => {
  const { t, i18n } = useTranslation(['common'])
  const searchInputPlaceholder = t('search-inputPlaceholder')
  const searchSlug = t('search-slug')
  const classes = useStyles() as any
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const router = useRouter()
  const searchInputRef = useRef(null)
  const request = _.debounce((value) => {
    setSearchValue(value)
  }, 600)
  const debounceRequest = useCallback((value) => request(value), [])

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.3,
      },
      display: 'block',
      mouseEvents: 'auto',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.3,
      },
      transitionEnd: {
        display: 'none',
        mouseEvents: 'none',
      },
    },
  }

  //TODO: change this to react-hood-form on submit function
  const handleSearchSubmit = (inputValue, setInputValue, setOpen) => {
    if (inputValue !== '') {
      router.push(`/${searchSlug}/${inputValue}`)
      setInputValue('')
      setOpen(false)
      searchInputRef.current.blur()
    }
  }

  const handleClickAway = () => {
    if (open === true) setOpen(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.container}>
          <Paper
            elevation={0}
            // className={clsx(mobile ? classes.mobileRoot : classes.root)}
            className={classes.root}
            component="form"
            onSubmit={(e) => {
              e.preventDefault()
              handleSearchSubmit(inputValue, setInputValue, setOpen)
            }}
          >
            <InputBase
              className={classes.input}
              placeholder={`${searchInputPlaceholder}`}
              type="search"
              value={inputValue}
              inputRef={searchInputRef}
              inputProps={{ 'aria-label': searchInputPlaceholder }}
              onFocus={() => setOpen(true)}
              onChange={(e) => {
                setInputValue(e.target.value)

                e.target.value === ''
                  ? setSearchValue('')
                  : debounceRequest(e.target.value)
              }}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              type="submit"
              onClick={() =>
                handleSearchSubmit(inputValue, setInputValue, setOpen)
              }
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>

          {open && (
            <motion.div
              className={classes.subMenu}
              initial="exit"
              animate={open ? 'enter' : 'exit'}
              variants={subMenuAnimate}
            >
              <SearchDropdown
                inputValue={inputValue}
                searchValue={searchValue}
                openState={[open, setOpen]}
              />
            </motion.div>
          )}
        </div>
      </ClickAwayListener>
    </>
  )
}

export default SearchInput
