import { Typography, useMediaQuery } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import draftToHtml from 'draftjs-to-html'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { Image } from '../Utils'

const useStyles = makeStyles(
  (theme) => ({
    section: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
      },
    },
    image: {
      objectFit: 'cover',
      borderRadius: theme.spacing(1),
    },
    socialIcon: {
      borderRadius: 0,
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
      background: theme.palette.alternate.main,
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
  { name: 'MuiContentComponent' },
)

const styles = (theme) => ({
  // Erroring out for some reason...
  // listItem: {
  //   marginTop: theme.spacing(1),
  // },
})

const options = {
  overrides: {
    h1: {
      component: (props) => (
        <Typography gutterBottom variant="h2" component="h1" {...props} />
      ),
    },
    h2: {
      component: (props) => (
        <Typography gutterBottom variant="h3" component="h2" {...props} />
      ),
    },
    h3: {
      component: (props) => (
        <Typography gutterBottom variant="h4" component="h3" {...props} />
      ),
    },
    h4: {
      component: (props) => (
        <Typography gutterBottom variant="h6" component="h4" {...props} />
      ),
    },
    h5: {
      component: (props) => (
        <Typography
          gutterBottom
          variant="subtitle1"
          component="h5"
          {...props}
        />
      ),
    },
    h6: {
      component: (props) => (
        <Typography
          gutterBottom
          variant="subtitle2"
          component="h6"
          {...props}
        />
      ),
    },
    p: {
      component: (props) => {
        return <Typography variant="body1" paragraph {...props} />
      },
    },
    // a: { component: <Link href="/"></Link> },
    li: {
      component: withStyles(styles)(({ ...props }) => {
        return (
          <li>
            <Typography component="span" {...props} />
          </li>
        )
      }),
    },
    code: {
      component: (props) => {
        return (
          <Typography
            variant="body2"
            style={{
              backgroundColor: '#ebebeb',
              width: 'fit-content',
              padding: '3px 8px',
            }}
            component="span"
            paragraph
            {...props}
          />
        )
      },
    },
    img: {
      component: (props) => {
        return (
          <Image
            image={props.src ? { src: props.src } : null}
            roundedCorners
            {...props}
          />
        )
      },
    },
  },
}

const Content = ({
  content,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles() as any
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const markup = draftToHtml(content)

  return <Markdown options={options}>{markup}</Markdown>
}

export default Content
