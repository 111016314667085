import { CardMedia, Container, Grid, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PinterestIcon from '@mui/icons-material/Pinterest'
import TwitterIcon from '@mui/icons-material/Twitter'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { useAppContext } from '../../context/appContext'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'

const isFurnisystemsTagEnabled =
  process.env.NEXT_PUBLIC_FURNISYSTEMS_BANNER === 'true' ? true : false

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      box: {
        backgroundColor: theme.palette.background.footer,
      },
      gridContainer: {
        // marginTop: "10rem",
        padding: '3rem',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
          padding: '1rem',
        },
      },
      linksContainer: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5rem',
      },
      media: {
        height: '3rem',
        backgroundSize: 'contain',
        [theme.breakpoints.down('md')]: {
          marginBottom: theme.spacing(2),
        },
      },
      logoSocialContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // [theme.breakpoints.down("sm")]: {
        //   flexDirection: "row",
        // },
      },
      iconsContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        // [theme.breakpoints.down("sm")]: {
        //   flex: 1,
        // },
      },
      footerTerms: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
        borderTop: '1px solid #ececec',
        color: '#fff',
        padding: 0,
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
          marginTop: theme.spacing(2),
        },
      },
      marginRight: {
        marginRight: theme.spacing(2),
      },
      whiteTypography: {
        color: '#fff',
      },
      termsCookiesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
          flexDirection: 'column',
          marginBottom: theme.spacing(2),
        },
      },
    }),
  { name: 'MuiFooterComponent' },
)

export default function Footer({ data, locale }) {
  const { t, i18n } = useTranslation(['common'])
  const aboutSlug = t('about-slug')
  const contactSlug = t('contact-slug')
  const blogSlug = t('blog-slug')
  const categorySlug = t('category-slug')
  const styleSlug = t('style-slug')
  const termsConditionsSlug = t('terms-conditions-slug')
  const privacyPolicySlug = t('privacy-policy-slug')
  const firstFooterColumnTitle = t('first-footerColumn-title')
  const secondFooterColumnTitle = t('second-footerColumn-title')
  const thirdFooterColumnTitle = t('third-footerColumn-title')
  const fourthFooterColumnTitle = t('fourth-footerColumn-title')
  const termsConditions = t('terms-and-conditions')

  const cookiesAndPrivacyPolicy = t('cookies-and-privacy-policy')
  // const disclaimer = t('disclaimer')

  const classes = useStyles() as any

  // Page names
  const blogPageName = t('blog-page')
  const aboutPageName = t('about-page')
  const contactPageName = t('contact-page')
  const termsPageName = t('terms-conditions-page')

  // ==== GET CATEGORIES DATA DYNAMICALLY....
  // const {
  //   loading,
  //   error,
  //   data: dynamicData,
  // } = useQuery(GET_PARENT_CATEGORIES, {
  //   variables: { language: locale },
  // });

  //staticProps fix
  const catData = data?.data ?? null

  // Max 6 categirues
  const categories = catData?.findManyCategory.slice(0, 6) ?? []
  // Max 6 styles
  const styles = catData?.findManyStyle.slice(0, 6) ?? []
  // Max 6 blogs
  const blogPosts = catData?.findManyBlogPost.slice(0, 6) ?? []

  // === We need to get social links
  const appContext = useAppContext()
  const shopSettings = appContext ? appContext.shopSettings : null

  const facebook = shopSettings.facebook ?? null
  const twitter = shopSettings.twitter ?? null
  const instagram = shopSettings.instagram ?? null
  const linkedin = shopSettings.linkedin ?? null
  const pinterest = shopSettings.pinterest ?? null

  const disclaimer = shopSettings.footer_copyright_text

  const footerImage =
    shopSettings?.footer_logo_image?.src_md ??
    shopSettings?.footer_logo_image?.src ??
    null

  // console.log('footerImage :>> ', footerImage)
  // console.log('disclaimer :>> ', disclaimer)
  // console.log('isFurnisystemsTagEnabled :>> ', isFurnisystemsTagEnabled)

  return (
    <footer className={classes.box}>
      <Container maxWidth="xl">
        <Grid container spacing={4} className={classes.gridContainer}>
          {/* FIRST LIST */}
          <Grid item xs={6} sm={6} md={2} lg={2}>
            {/*  TITLE */}
            <a className="cursorPointer">
              <Typography
                className={classes.whiteTypography}
                variant="subtitle1"
              >
                {firstFooterColumnTitle}
              </Typography>
            </a>
            {/* LIST */}
            <ul className={classes.linksContainer}>
              <div className={classes.row}>
                <Link prefetch={false} href={`/${aboutSlug}`}>
                  <a className="cursorPointer">
                    <Typography
                      className={classes.whiteTypography}
                      variant="caption"
                    >
                      {aboutPageName}
                    </Typography>
                  </a>
                </Link>
              </div>

              <div className={classes.row}>
                <Link prefetch={false} href={`/${blogSlug}`}>
                  <a className="cursorPointer">
                    <Typography
                      className={classes.whiteTypography}
                      variant="caption"
                    >
                      {blogPageName}
                    </Typography>
                  </a>
                </Link>
              </div>

              <div className={classes.row}>
                <Link prefetch={false} href={`/${contactSlug}`}>
                  <a className="cursorPointer">
                    <Typography
                      className={classes.whiteTypography}
                      variant="caption"
                    >
                      {contactPageName}
                    </Typography>
                  </a>
                </Link>
              </div>
              <div className={classes.row}>
                <Link prefetch={false} href={`/${termsConditionsSlug}`}>
                  <a className="cursorPointer">
                    <Typography
                      className={classes.whiteTypography}
                      variant="caption"
                    >
                      {termsPageName}
                    </Typography>
                  </a>
                </Link>
              </div>
            </ul>
          </Grid>

          {blogPosts.length > 0 ? (
            <>
              {/* SECOND LIST */}
              <Grid item xs={6} sm={6} md={2} lg={2}>
                {/*  TITLE */}
                <Link prefetch={false} href={`/${contactSlug}`}>
                  <a className="cursorPointer">
                    <Typography
                      className={classes.whiteTypography}
                      variant="subtitle1"
                    >
                      {secondFooterColumnTitle}
                    </Typography>
                  </a>
                </Link>
                {/*  DATA CONTAINER */}
                <ul className={classes.linksContainer}>
                  {blogPosts.map((post, i) => {
                    //* =========================================================
                    //* =========== GET THE CORRECT BLOG POST PROFILE ============
                    //* =========================================================
                    const postProfileIndex: number = getProfileIndexFromLanguage(
                      post.blog_post_profiles,
                      locale,
                    )

                    return (
                      typeof postProfileIndex === 'number' && (
                        <div className={classes.row} key={post.id}>
                          <Link
                            prefetch={false}
                            href={`/${blogSlug}/${post.blog_post_profiles[postProfileIndex]?.meta_information.permalink}`}
                          >
                            <a className="cursorPointer">
                              <Typography
                                className={classes.whiteTypography}
                                variant="caption"
                              >
                                {
                                  post.blog_post_profiles[postProfileIndex]
                                    ?.title
                                }
                              </Typography>
                            </a>
                          </Link>
                        </div>
                      )
                    )
                  })}
                </ul>
              </Grid>
            </>
          ) : (
            <span></span>
          )}

          {/* THIRD LIST */}

          {categories.length > 0 ? (
            <>
              <Grid item xs={6} sm={6} md={2} lg={2}>
                {/* RIGHT TITLE */}
                <Typography
                  className={classes.whiteTypography}
                  variant="subtitle1"
                >
                  {thirdFooterColumnTitle}
                </Typography>
                {/* RIGHT DATA CONTAINER */}
                <ul className={classes.linksContainer}>
                  {categories.map((category, i) => {
                    //* =========================================================
                    //* =========== GET THE CORRECT CATEGORY PROFILE ============
                    //* =========================================================
                    const categoryProfileIndex: number = getProfileIndexFromLanguage(
                      category.category_profiles,
                      locale,
                    )

                    return (
                      typeof categoryProfileIndex === 'number' && (
                        <div className={classes.row} key={category.id}>
                          <Link
                            prefetch={false}
                            href={`/${categorySlug}/${category.category_profiles[categoryProfileIndex]?.meta_information.permalink}/1`}
                          >
                            <a className="cursorPointer">
                              <Typography
                                className={classes.whiteTypography}
                                variant="caption"
                              >
                                {
                                  category.category_profiles[
                                    categoryProfileIndex
                                  ]?.name
                                }
                              </Typography>
                            </a>
                          </Link>
                        </div>
                      )
                    )
                  })}
                </ul>
              </Grid>
            </>
          ) : (
            <span></span>
          )}

          {/* FIFTH LIST */}
          {styles.length > 0 ? (
            <>
              <Grid item xs={6} sm={6} md={2} lg={2}>
                {/* RIGHT TITLE */}
                <Typography
                  className={classes.whiteTypography}
                  variant="subtitle1"
                >
                  {fourthFooterColumnTitle}
                </Typography>
                {/* RIGHT DATA CONTAINER */}
                <ul className={classes.linksContainer}>
                  {styles.map((style, i) => {
                    //* =========================================================
                    //* =========== GET THE CORRECT STYLE PROFILE ============
                    //* =========================================================
                    const styleProfileIndex: number = getProfileIndexFromLanguage(
                      style.style_profiles,
                      locale,
                    )

                    return (
                      typeof styleProfileIndex === 'number' && (
                        <div className={classes.row} key={style.id}>
                          <Link
                            prefetch={false}
                            href={`/${styleSlug}/${style.style_profiles[styleProfileIndex]?.meta_information.permalink}/1`}
                          >
                            <a className="cursorPointer">
                              <Typography
                                className={classes.whiteTypography}
                                variant="caption"
                              >
                                {style.style_profiles[styleProfileIndex]?.name}
                              </Typography>
                            </a>
                          </Link>
                        </div>
                      )
                    )
                  })}
                </ul>
              </Grid>
            </>
          ) : (
            <span></span>
          )}

          {/* PLACEHOLDER */}
          <Grid item md={2} lg={2} />

          {/* LOGO AND SOCIAL LINKS */}
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className={classes.logoSocialContainer}
          >
            {/* LOGO */}
            {footerImage && (
              <CardMedia image={footerImage} className={classes.media} />
            )}
            <div className={classes.iconsContainer}>
              {facebook && (
                <a href={facebook} target="_blank" rel="noreferrer">
                  <FacebookIcon className={classes.whiteTypography} />
                </a>
              )}
              {instagram && (
                <a href={instagram} target="_blank" rel="noreferrer">
                  <InstagramIcon className={classes.whiteTypography} />
                </a>
              )}
              {linkedin && (
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <LinkedInIcon className={classes.whiteTypography} />
                </a>
              )}
              {twitter && (
                <a href={twitter} target="_blank" rel="noreferrer">
                  <TwitterIcon className={classes.whiteTypography} />
                </a>
              )}
              {pinterest && (
                <a href={pinterest} target="_blank" rel="noreferrer">
                  <PinterestIcon className={classes.whiteTypography} />
                </a>
              )}
            </div>
          </Grid>

          {/* FOOTER TERMS */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.footerTerms}
          >
            <div className={classes.termsCookiesContainer}>
              <Link prefetch={false} href={`/${termsConditionsSlug}`}>
                <a className="cursorPointer">
                  <Typography
                    variant="caption"
                    className={clsx(
                      classes.marginRight,
                      classes.whiteTypography,
                    )}
                  >
                    {termsConditions}
                  </Typography>
                </a>
              </Link>
              <Link prefetch={false} href={`/${privacyPolicySlug}`}>
                <a className="cursorPointer">
                  <Typography
                    className={classes.whiteTypography}
                    variant="caption"
                  >
                    {cookiesAndPrivacyPolicy}
                  </Typography>
                </a>
              </Link>
            </div>

            <div>
              <Typography className={classes.whiteTypography} variant="caption">
                {disclaimer}
              </Typography>
            </div>
          </Grid>
          <div
            style={{
              // textAlign: 'center',
              width: '100%',
              color: '#ffffff',
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {isFurnisystemsTagEnabled && (
              <>
                <Typography variant="caption" sx={{ marginRight: '10px' }}>
                  Made with{' '}
                </Typography>
                <a href="https://furnisystems.com">
                  <img
                    style={{ height: '50px' }}
                    src="/furnisystems_logo.png"
                  ></img>
                </a>
                {/* <CardMedia
              image={'/furnisystems_logo.png'}
              className={classes.media}
            /> */}
              </>
            )}
          </div>
        </Grid>
      </Container>
    </footer>
  )
}
