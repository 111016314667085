import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { ProductList } from '.'
import { Media } from '../../../lib/artsyFresnel/Media'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'
import { SimpleSlider } from '../DesktopElements/Utils'
import CustomSection from '../DesktopElements/Utils/CustomSection'
import { useAppContext } from '../../context/appContext'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      simpleSlider: {
        height: '65vh',
        [theme.breakpoints.down('lg')]: {
          height: '55vh',
          marginBottom: theme.spacing(5),
        },
      },
    }),
  { name: 'MuiHomePageModuleComponent' },
)

// const images = [
//   { src: "/homeImages/thumbnail_06.png", id: 1 },
//   { src: "/homeImages/thumbnail_07.png", id: 2 },
//   { src: "/homeImages/thumbnail_08.png", id: 3 },
// ];

export default function HomePageModule({
  data,
  locale,
  shopSettings,
  productContainersData,
}) {
  const classes = useStyles() as any
  const { t } = useTranslation(['common'])
  const styleSlug = t('style-slug')
  const collectionSlug = t('collection-slug')
  const categorySlug = t('category-slug')

  // const [styleProfileIndex, setStyleProfileIndex] = useState(null);
  let styleProfileIndex = null
  let collectionProfileIndex = null
  let categoryProfileIndex = null

  //This if statements will run on the server
  if (shopSettings?.homepage_style_section_enabled) {
    const profileIndex = getProfileIndexFromLanguage(
      shopSettings.homepage_style?.style_profiles,
      locale,
    )

    styleProfileIndex = profileIndex
  }
  if (shopSettings?.homepage_collection_section_enabled) {
    const profileIndex = getProfileIndexFromLanguage(
      shopSettings.homepage_collection?.collection_profiles,
      locale,
    )

    collectionProfileIndex = profileIndex
  }
  if (shopSettings?.homepage_category_section_enabled) {
    const profileIndex = getProfileIndexFromLanguage(
      shopSettings.homepage_category?.category_profiles,
      locale,
    )

    categoryProfileIndex = profileIndex
  }

  const homeBanners =
    shopSettings?.homepage_banners.length > 0
      ? shopSettings?.homepage_banners.filter(
          (item) => item.language === locale,
        )
      : null

  return (
    <div>
      {homeBanners?.length > 0 && (
        <SimpleSlider images={homeBanners} className={classes.simpleSlider} />
      )}

      <ProductList
        locale={locale}
        productContainersData={productContainersData}
      />

      <Media greaterThan="md">
        <div style={{ marginBottom: '100px' }}></div>
      </Media>
      <div style={{ marginBottom: '20px' }}></div>

      {/* STYLE SECTION - ALIGNED LEFT */}

      {shopSettings?.homepage_style_section_enabled &&
        styleProfileIndex !== null &&
        styleProfileIndex !== undefined && (
          <CustomSection
            alignImage="left"
            locale={locale}
            product_containers={shopSettings.homepage_style.product_containers}
            sectionName={
              shopSettings.homepage_style.style_profiles[styleProfileIndex].name
            }
            sectionDescription={
              shopSettings.homepage_style.style_profiles[styleProfileIndex]
                .description
            }
            sectionImage={shopSettings.homepage_style.main_image?.src}
            slug={styleSlug}
            permalink={
              shopSettings.homepage_style.style_profiles[styleProfileIndex]
                .meta_information.permalink
            }
          />
        )}

      {/* COLLECTION SECTION - ALIGNED RIGHT */}
      {shopSettings?.homepage_collection_section_enabled &&
        collectionProfileIndex !== null &&
        collectionProfileIndex !== undefined && (
          <CustomSection
            alignImage="right"
            locale={locale}
            product_containers={
              shopSettings.homepage_collection.product_containers
            }
            sectionName={
              shopSettings.homepage_collection.collection_profiles[
                collectionProfileIndex
              ].name
            }
            sectionDescription={
              shopSettings.homepage_collection.collection_profiles[
                collectionProfileIndex
              ].description
            }
            sectionImage={shopSettings.homepage_collection.main_image?.src}
            slug={collectionSlug}
            permalink={
              shopSettings.homepage_collection.collection_profiles[
                collectionProfileIndex
              ].meta_information.permalink
            }
          />
        )}

      {/* CATEGORY SECTION - ALIGNED RIGHT */}
      {shopSettings?.homepage_category_section_enabled &&
        categoryProfileIndex !== null &&
        categoryProfileIndex !== undefined && (
          <CustomSection
            alignImage="left"
            locale={locale}
            product_containers={
              shopSettings.homepage_category.product_containers
            }
            sectionName={
              shopSettings.homepage_category.category_profiles[
                categoryProfileIndex
              ].name
            }
            sectionDescription={
              shopSettings.homepage_category.category_profiles[
                categoryProfileIndex
              ].description
            }
            sectionImage={shopSettings.homepage_category.image?.src}
            slug={categorySlug}
            permalink={
              shopSettings.homepage_category.category_profiles[
                categoryProfileIndex
              ].meta_information.permalink
            }
          />
        )}
    </div>
  )
}
