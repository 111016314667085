import { checkDiscountValidity } from "./discountValidityCheck";

const createRecentlyViewedItem = (
  id,
  name,
  price,
  image,
  permalink,
  product_type,
  dispatchInformation,
  discount
) => {
  //I check if the there is a discount on the server data
  //if there is I set discounted and discountedPrice
  //else I leave the false and null
  const { discounted, discountedPrice } = checkDiscountValidity(
    discount,
    price
  );

  return {
    id,
    name,
    price,
    discountedPrice,
    discounted,
    image,
    permalink,
    product_type,
    dispatchInformation,
  };
};

export default createRecentlyViewedItem;
