import { Container } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { gallery } from '../../../public/aboutGalleryData'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'
import { Story, Team, WhoWeAre } from '../DesktopElements/AboutElements'
import { Contact } from '../DesktopElements/ContactElements'
import { Gallery, Hero, Section } from '../DesktopElements/Utils'

export const mapData = [
  {
    location: {
      y: 54.67368939615159,
      x: 25.259020646024727,
      address: 'Manufaktūrų g. 20, Vilnius 11342',
    },
  },
]

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
    sectionPartners: {
      boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
      '& .section-alternate__content': {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    contactSection: {
      backgroundColor: theme.palette.background.default,
    },
  }),
  { name: 'MuiAboutComponent' },
)

const AboutPage = ({
  locale,
  aboutUsPageData,
  contactUsPageData,
  ...props
}): JSX.Element => {
  //! Deprecetaded to be removed
  // const { t } = useTranslation(['common'])
  // const heroHeader = t('hero-header')
  // const heroSubHeader = t('hero-subHeader')
  const classes = useStyles() as any

  const pageData = aboutUsPageData?.data?.findFirstShopSetting ?? null

  const aboutUsProfileIndex: number = getProfileIndexFromLanguage(
    pageData?.about_us_page?.about_us_page_profiles,
    locale,
  )

  if (!pageData || aboutUsProfileIndex < 0 || aboutUsProfileIndex === null) {
    return (
      <div className={classes.root}>
        <Hero
          clipped
          image={'/AboutHero.jpg'}
          withHeader
          title={'Information is being prepared.'}
        // subtitle={heroSubHeader}
        />
        <Section id="ourStory">
          <p>Information is being prepared...</p>
        </Section>
      </div>
    )
  }

  const aboutUsProfile =
    pageData.about_us_page.about_us_page_profiles[aboutUsProfileIndex] ?? null

  const block2Image = aboutUsProfile?.blockImage
    ? aboutUsProfile.blockImage.src_md ?? aboutUsProfile.blockImage.src ?? null
    : null

  const heroImage = pageData.about_us_page.main_image
    ? pageData.about_us_page.main_image.src_lg ??
    pageData.about_us_page.main_image.src ??
    null
    : null

  const heroHeader = aboutUsProfile.name ?? ''
  const heroSubHeader = aboutUsProfile.pageTopText ?? ''
  const teamMembers = aboutUsProfile.team_members ?? []
  const teamMembersTextText = aboutUsProfile.teamMembersText ?? ''

  let showContacts = pageData.about_us_page.show_contact_blocks

  const contactPageData = contactUsPageData?.data?.findFirstShopSetting ?? null
  const contactUsProfileIndex: number = getProfileIndexFromLanguage(
    contactPageData?.contact_us_page?.contact_us_page_profiles,
    locale,
  )

  if (
    !contactPageData ||
    contactUsProfileIndex === null ||
    contactUsProfileIndex < 0
  ) {
    showContacts = false
  }

  const contactUsProfile = contactPageData
    ? contactPageData?.contact_us_page?.contact_us_page_profiles[
    contactUsProfileIndex
    ] ?? null
    : null
  const contactBlocks = contactPageData
    ? contactUsProfile.contact_blocks ?? []
    : []

  return (
    <div className={classes.root}>
      <Hero
        clipped
        image={heroImage ?? '/AboutHero.jpg'}
        withHeader
        title={heroHeader}
        subtitle={heroSubHeader}
      />
      <Section id="ourStory">
        <Story
          block1Title={aboutUsProfile.block1Title}
          block1Text={aboutUsProfile.block1Text}
          block2Image={block2Image}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <WhoWeAre
          block3Title={aboutUsProfile.block3Title}
          block3Text={aboutUsProfile.block3Text}
          block4Title={aboutUsProfile.block4Title}
          block4Text={aboutUsProfile.block4Text}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <Team teamMembers={teamMembers} teamMembersText={teamMembersTextText} />
      </Section>

      {showContacts && (
        <div style={{ marginBottom: '32px' }}>
          {contactBlocks.map((contactBlock, i) => {
            return (
              <Section
                key={i}
                fullWidth
                disablePadding
                className={classes.contactSection}
              >
                <Contact
                  data={contactBlock}
                  switchSides={contactBlock.switchSides}
                />
              </Section>
            )
          })}
        </div>
      )}

      {/* Currently disabling category - to be added in the future */}
      {/* <Section fullWidth>
        <Container maxWidth="lg">
          <Gallery data={gallery} />
        </Container>
      </Section> */}

      {/* <SectionAlternate className={classes.sectionPartners}>
            <Partners data={companies} />
          </SectionAlternate> */}
    </div>
  )
}

export default AboutPage
