import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React from 'react'
import {
  BlogPostHero,
  Content,
  SidebarNewsletter,
  SidebarRelatedProducts,
  SimilarStories,
} from '../DesktopElements/BlogPostPageElements'
import { SectionAlternate } from '../DesktopElements/Utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
    },
    sidebarNewsletter: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    contentContainer: {
      padding: theme.spacing(10, 5),
      maxWidth: 1300,
      margin: '0 auto',
      [theme.breakpoints.only('lg')]: {
        padding: theme.spacing(8, 10),
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 2),
      },
    },
  }),
  { name: 'MuiBlogPostPageComponent' },
)

const BlogPostPage = ({
  blogPostData,
  similarBlogPosts,
  sidebarSVPproducts,
}): JSX.Element => {
  const classes = useStyles() as any
  const { data } = blogPostData
  const title = data.findUniqueMetaInformation.blog_post_profile.title
  const summary = data.findUniqueMetaInformation.blog_post_profile.summary
  const image = data.findUniqueMetaInformation.blog_post_profile.blog_post.featured_image
  const content = JSON.parse(data.findUniqueMetaInformation.blog_post_profile.content)
  const author = data.findUniqueMetaInformation.blog_post_profile.author
  const date = data.findUniqueMetaInformation.blog_post_profile.blog_post.createdAt
  const relatedProducts =
    data.findUniqueMetaInformation.blog_post_profile.blog_post.relatedProducts &&
      data.findUniqueMetaInformation.blog_post_profile.blog_post.relatedProducts.length !==
      0
      ? data.findUniqueMetaInformation.blog_post_profile.blog_post.relatedProducts
      : sidebarSVPproducts.data.findManyProductContainer

  return (
    <div className={classes.root}>
      <BlogPostHero
        image={image}
        title={title}
        subtitle={summary}
        author={author}
        date={moment.utc(date).local().format('YYYY-MM-DD')}
      />
      <div className={classes.contentContainer}>
        {/* <Section fullWidth> */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={12} lg={8}>
            <Content content={content} />
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            {relatedProducts && relatedProducts.length !== 0 && (
              <SidebarRelatedProducts relatedProducts={relatedProducts} />
            )}
            <SidebarNewsletter className={classes.sidebarNewsletter} />
          </Grid>
        </Grid>
        {/* </Section> */}
      </div>
      {similarBlogPosts.data.findManyBlogPost.length !== 0 && (
        <SectionAlternate>
          <SimilarStories data={similarBlogPosts} />
        </SectionAlternate>
      )}
    </div>
  )
}

export default BlogPostPage
