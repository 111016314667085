import React, { useEffect } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import {
  colorsFilterVar,
  categoriesFilterVar,
  heightFilterVar,
  lengthFilterVar,
  priceFilterVar,
  widthFilterVar,
  stylesFilterVar,
  productTypeFilterVar,
} from '../../../../store/reactiveVars'
import { Skeleton } from '@mui/material'
import {
  getCategoriesQueryVariable,
  getDimensionsQueryVariable,
  getMainColorQueryVariable,
  getProductTypeQueryVariable,
  getStylesQueryVariable,
} from '../../../../operations/queries/variablesConstructors'
import { useRouter } from 'next/router'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'
import { GET_STYLE_BY_ID } from '../../../../operations/queries/filterStyleQueries'

export default function StyleDrawerItemCheckbox({
  styleID,
  permalink,
  ...props
}) {
  const { locale } = useRouter()
  const widthFilter = useReactiveVar(widthFilterVar)
  const heightFilter = useReactiveVar(heightFilterVar)
  const lengthFilter = useReactiveVar(lengthFilterVar)
  const colorsFilter = useReactiveVar(colorsFilterVar)
  const priceFilter = useReactiveVar(priceFilterVar)

  const categoriesFilter = useReactiveVar(categoriesFilterVar)
  const stylesFilter = useReactiveVar(stylesFilterVar)

  // =========== colors
  const colorOptions = getMainColorQueryVariable(colorsFilter)
  // =========== styles
  const categoriesOptions = getCategoriesQueryVariable(categoriesFilter)

  // =========== styles
  const styleOptions = getStylesQueryVariable(stylesFilter)

  const productTypeFilter = useReactiveVar(productTypeFilterVar)
  // =========== product types
  const productTypeOptions = getProductTypeQueryVariable(productTypeFilter)

  const [fetchStyle, { data, loading, error }] = useLazyQuery(GET_STYLE_BY_ID, {
    // fetchPolicy: 'network-only',
  })
  const [styleProfileIndex, setStyleProfileIndex] = React.useState(null)

  // console.log('data (single style item) :>> ', data)

  //* =================================================================
  //* ==== getting constructed variable through helper functions ======
  //* =================================================================
  const dimensionOptions = getDimensionsQueryVariable(
    widthFilter,
    heightFilter,
    lengthFilter,
  )

  // console.log('categoriesOptions :>> ', categoriesOptions)

  //* ===================================================================================================
  //* ======= set the checked state to true on first render to the reactive var if it will contain this ID
  //* ===================================================================================================
  useEffect(() => {
    // console.log('categoriesOptions (before fetch) :>> ', categoriesOptions)

    fetchStyle({
      variables: {
        language: locale,
        dimensions: dimensionOptions,
        styleID,
        // permalink,
        colors: colorOptions,
        categories: categoriesOptions,
        // categories: [{ categories: { some: { id: { equals: 5 } } } }],

        // styles: styleOptions,
        productTypes: productTypeOptions,
        price:
          priceFilter.minPrice && priceFilter.maxPrice
            ? {
              AND: [
                { price_reference: { gte: priceFilter.minPrice } },
                { price_reference: { lte: priceFilter.maxPrice } },
              ],
            }
            : {},
      },
      // fetchPolicy: 'network-only',
    })

    // if (colorsFilter.includes(colorID)) {
    //   setChecked(true);
    // } else {
    //   setChecked(false);
    // }

    return
  }, [])

  useEffect(() => {
    //* ====================================================================
    //* we want to run the check and get this item's profile
    //* ====================================================================
    if (!loading && data) {
      const styleProfileIndex: number = getProfileIndexFromLanguage(
        data.findUniqueStyle.style_profiles,
        locale,
      )

      setStyleProfileIndex(styleProfileIndex)
    }

    return
  }, [loading, data])

  //* =========================================================================
  //* =========== handling loading state and errors
  //* =========================================================================
  if (loading || styleProfileIndex === null || styleProfileIndex === undefined)
    return (
      <Skeleton
        variant="rectangular"
        height={25}
        style={{ margin: '0.8rem 0' }}
      />
    )
  if (error) return <p>Something went wrong...</p>

  //* =========================================================================
  //* ============================== handling the selection of a color filter
  //* =========================================================================
  const handleStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      // We need to modify so its only one style selected at a time
      // stylesFilterVar([...stylesFilter, parseInt(event.target.name)])
      stylesFilterVar([parseInt(event.target.name)])
    } else if (
      event.target.checked === false &&
      stylesFilter.includes(styleID)
    ) {
      const filteredstyles = stylesFilter.filter(
        (ID) => ID !== parseInt(event.target.name),
      )
      stylesFilterVar([...filteredstyles])
    }

    // setChecked(event.target.checked);
  }

  return (
    <>
      {data &&
        data.findManyProductContainerCount > 0 &&
        data.findUniqueStyle.style_profiles[styleProfileIndex] && (
          <FormControlLabel
            key={data.findUniqueStyle.id}
            control={
              <Checkbox
                checked={stylesFilter.includes(data.findUniqueStyle.id)}
                onChange={handleStyleChange}
                name={`${data.findUniqueStyle.id}`}
              />
            }
            label={`${data.findUniqueStyle.style_profiles[styleProfileIndex].name} (${data.findManyProductContainerCount})`}
          />
        )}
    </>
  )
}
