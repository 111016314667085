import { gql } from "@apollo/client";

// BUG - WHY Do we need this here? maybe because of NEOPAY... (should have handled this in the backend...)
export const UPDATE_ORDER_ITEMS_STATUS = gql`
  mutation UPDATE_ORDER_ITEMS_STATUS($orderItemsIds: [OrderItemWhereInput]) {
    updateManyOrderItem(
      where: { OR: $orderItemsIds }
      data: { status: { set: PENDING } }
    ) {
      count
    }
  }
`;
