import { Avatar, Box } from '@mui/material'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useShopContext } from '../../../context'
import {
  LT,
  GB,
  EE,
  LV,
  RU,
  DE,
  FR,
  NL,
  DK,
  ES,
  PT,
  PL,
  IT,
} from 'country-flag-icons/react/3x2'

const countryList = {
  // ---- NEW langs ---
  de: {
    flag: <DE />,
    name: 'German',
    code: 'DE',
  },
  dk: {
    flag: <DK />,
    name: 'Danish',
    code: 'DK',
  },
  en: {
    flag: <GB />,
    name: 'English',
    code: 'EN',
  },
  es: {
    flag: <ES />,
    name: 'Spanish',
    code: 'ES',
  },
  et: {
    flag: <EE />,
    name: 'Estonian',
    code: 'EE',
  },

  fr: {
    flag: <FR />,
    name: 'French',
    code: 'FR',
  },
  it: {
    flag: <IT />,
    name: 'Italian',
    code: 'IT',
  },

  lt: {
    flag: <LT />,
    name: 'Lithuanian',
    code: 'LT',
  },

  lv: {
    flag: <LV />,
    name: 'Latvian',
    code: 'LV',
  },

  nl: {
    flag: <NL />,
    name: 'Netherlands',
    code: 'NL',
  },
  pl: {
    flag: <PL />,
    name: 'Polish',
    code: 'PL',
  },
  pt: {
    flag: <PT />,
    name: 'Portuguese',
    code: 'PT',
  },

  ru: {
    flag: <RU />,
    name: 'Russian',
    code: 'RU',
  },
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    header: {
      color: '#fff',
    },
    small: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    languageContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: theme.spacing(1, 2.5),
    },
    select: {
      background: 'white',
    },
  }),
)

let switchData = {
  lt: {
    name: 'LT',
    flag: '/flags/flag-lithuania.png',
    domain: null,
  },
  lv: {
    name: 'LV',
    flag: '/flags/flag-latvia.png',
    domain: null,
  },
  et: {
    name: 'EE',
    flag: '/flags/flag-estonia.png',
    domain: null,
  },
  en: {
    name: 'EN',
    flag: '/flags/flag-uk.png',
    domain: null,
  },
  ru: {
    name: 'RU',
    flag: '/flags/flag-russia.png',
    domain: null,
  },
}

export default function SimplePopover({ className = {} }) {
  const classes = useStyles() as any
  const [cookie, setCookie, removeCookie] = useCookies(['NEXT_LOCALE'])
  const { locale, locales, push, pathname, query, asPath } = useRouter()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = useState(false)

  const { code: name, flag, domain } = countryList[locale]

  const {
    enabledLanguages,
    defaultLocale,
    defaultEnabledLanguage,
  } = useShopContext()

  let showLanguageSwithcher = true
  if (defaultEnabledLanguage) {
    if (!defaultEnabledLanguage.enable_language_switcher) {
      showLanguageSwithcher = false
    }
  }

  let modifiedSwitchData = {}
  // for (const eLang of enabledLanguages) {
  //   const lang = eLang.language
  //   const { name, flag, domain } = switchData[lang]
  //   modifiedSwitchData[`${lang}`] = {
  //     name,
  //     flag,
  //     domain: eLang.web_address_enabled ? eLang.web_address : null,
  //   }
  // }

  for (const eLang of enabledLanguages) {
    const lang = eLang.language
    const { code, flag, domain } = countryList[lang]
    modifiedSwitchData[`${lang}`] = {
      name: code,
      flag,
      domain: eLang.web_address_enabled ? eLang.web_address : null,
    }
  }

  // countryList

  const enabledLocales = enabledLanguages.map((item) => item.language)

  // console.log('modifiedSwitchData :>> ', modifiedSwitchData)

  const handleClose = (thisLocale) => {
    // ----
    // We need to handle a click outside language switcher...
    if (typeof thisLocale !== 'string') {
      // We have an event..
      // console.log("Event...");
    } else {
      //Next.js supports overriding the accept-language header with a NEXT_LOCALE=the-locale cookie.
      if (cookie.NEXT_LOCALE !== thisLocale) {
        removeCookie('NEXT_LOCALE')
        setCookie('NEXT_LOCALE', thisLocale, { path: '/' })
      }
    }

    setAnchorEl(null)
    setOpen(false)
  }

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const id = open ? 'languageSwitch' : undefined

  if (!showLanguageSwithcher) {
    return <div></div>
  }

  return (
    <>
      <div>
        <div
          aria-describedby={id}
          className={classes.headerContainer}
          onClick={handleClick}
        >
          {/* <Avatar alt={name} src={flag} className={classes.small} /> */}
          <Box
            sx={{
              width: '20px',
              height: '15px',
              margin: '0 5px',
            }}
          >
            {flag}
          </Box>

          <Typography className={classes.header}>{name}</Typography>
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {enabledLocales.map((thisLocale) => {
            const { name, flag, domain } = modifiedSwitchData[thisLocale]

            //Here I show all the locales but the current one, because it's already displayed above
            if (thisLocale !== locale) {
              return (
                <Link
                  prefetch={false}
                  href={domain ?? '/'}
                  locale={thisLocale}
                  key={thisLocale}
                >
                  <a>
                    <Button
                      onClick={() => handleClose(thisLocale)}
                      className={classes.languageContainer}
                    >
                      {/* <Avatar alt={name} src={flag} className={classes.small} /> */}
                      <Box
                        sx={{
                          width: '20px',
                          height: '15px',
                          margin: '0 5px',
                        }}
                      >
                        {flag}
                      </Box>
                      <Typography variant="body1">{name}</Typography>
                    </Button>
                  </a>
                </Link>
              )
            }
          })}
        </Popover>
      </div>
    </>
  )
}
