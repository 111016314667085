import { useReactiveVar } from '@apollo/client'
import { Paper, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import { useShopContext } from '../../../context'
import {
  cartItemsVar,
  orderVar,
  shopSettingsVar,
} from '../../../store/reactiveVars'
import getAndUpdateCheckoutPrices from '../../../utilityFunctions/getAndUpdateCheckoutPrices'
import {
  calculateVatAmount,
  priceFormatter,
} from '../../../utilityFunctions/priceUtils'
import { ProductPrice } from '../../UtilityComponents/price'
import ProductSideBoxItem from './ProductSideBoxItem'

const useStyles = makeStyles(
  (theme: Theme) => ({
    paper: {
      borderRadius: 0,
      height: 'fit-content',
      width: '23rem',
      padding: '0 1rem',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    boxTitle: {
      padding: '1rem',
      margin: 0,
      borderBottom: '1px solid #d3d3d3',
    },
    productBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
      margin: theme.spacing(2, 0),
    },
    media: {
      height: '4rem',
      width: '2.5rem',
      backgroundSize: 'contain',
      margin: '0 auto',
      alignSelf: 'center',
    },
    boxFooter: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      paddingTop: '0.5rem',
    },
  }),
  { name: 'MuiProductSideBoxComponent' },
)

export default function ProductsSideBox() {
  const { t } = useTranslation(['common'])
  const cartSlug = t('cart-slug')
  const productsSideBoxHeader = t('products-side-box-header')
  const productSideBoxSubtotal = t('product-side-box-subtotal')
  const productSideBoxDeliveryFee = t('product-side-box-delivery-fee')
  const productSideBoxTotalWithoutVat = t('product-side-box-total-without-vat')
  const productSideBoxTotal = t('product-side-box-total')
  const VATString = t('VAT')
  const productSideBoxWithVat = t('product-side-box-total-with-vat')
  const discountString = t('discount')
  const classes = useStyles() as any
  const cartItems = useReactiveVar(cartItemsVar)
  const order = useReactiveVar(orderVar)
  const shopSettings = useReactiveVar(shopSettingsVar)
  const { SHOP_ID } = useShopContext()

  useEffect(() => {
    getAndUpdateCheckoutPrices(shopSettings, cartItems, order, orderVar)

    return
  }, [
    order.buying_as_company,
    order.is_lithuanian_company,
    order.total_shipping_price,
    shopSettings,
  ])

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography
        align="center"
        variant="h6"
        gutterBottom
        className={classes.boxTitle}
      >
        {productsSideBoxHeader}
      </Typography>
      {cartItems.map((item) => {
        return <ProductSideBoxItem key={item.id} cartItem={item} />
      })}
      <div className={classes.boxFooter}>
        <Typography variant="body2" component="p" gutterBottom>
          {productSideBoxSubtotal}
        </Typography>
        <Typography align="right" variant="body2" component="p" gutterBottom>
          <ProductPrice price={order.sub_total_price} />
        </Typography>
      </div>

      <div className={classes.boxFooter}>
        <Typography variant="body1" component="p" gutterBottom>
          {`${VATString}(${shopSettings.vat_percentage}%)`}
        </Typography>
        <Typography align="right" variant="body1" component="p" gutterBottom>
          <ProductPrice
            price={calculateVatAmount(
              order.sub_total_price,
              shopSettings.vat_percentage,
            )}
          />
        </Typography>
      </div>

      {/* Here I check for both because  order.total_shipping_price is set
          for every item in the shippingForm, so after the first product it won't be null anymore
          but total_price is only set at paymentForm first render */}
      <div className={classes.boxFooter}>
        <Typography variant="body1" gutterBottom>
          {productSideBoxDeliveryFee}
        </Typography>
        {/* check for both because 0 is a falsy value and matches null and undefined
            I want to show the 0 value, but not the undefined or null */}
        {order.total_shipping_price === 0 || order.total_shipping_price ? (
          <Typography align="right" variant="body1" gutterBottom>
            {priceFormatter(order.total_shipping_price)}
          </Typography>
        ) : (
          <Typography align="right" variant="body1" gutterBottom>
            -
          </Typography>
        )}
      </div>

      {order.discount_applied && order.coupon ? (
        <div className={classes.boxFooter}>
          <Typography variant="body1" gutterBottom>
            {`${discountString}("${order.coupon.coupon_code}")`}
          </Typography>
          <Typography align="right" variant="body1" gutterBottom>
            {/* {`-${priceFormatter(order.discount_applied)}`} */}

            {'-'}
            <ProductPrice price={order.discount_applied} />
          </Typography>
        </div>
      ) : null}

      <div className={classes.boxFooter}>
        <Typography variant="body1" component="p" gutterBottom>
          {`${productSideBoxTotalWithoutVat}(${shopSettings.vat_percentage}%)`}
        </Typography>
        <Typography align="right" variant="body1" component="p" gutterBottom>
          <ProductPrice price={order.total_price_without_VAT} />
        </Typography>
      </div>

      <div className={classes.boxFooter}>
        <Typography variant="subtitle1" component="p" gutterBottom>
          {productSideBoxTotal}
        </Typography>
        <Typography
          align="right"
          variant="subtitle1"
          component="p"
          gutterBottom
        >
          <ProductPrice price={order.total_price} />
        </Typography>
      </div>
    </Paper>
  )
}
