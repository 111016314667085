import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, Theme } from '@mui/material/styles'
import { appWithTranslation, useTranslation } from 'next-i18next'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress' //nprogress module
import 'nprogress/nprogress.css' //styles of nprogress
import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useApollo } from '../lib/apolloClient'
import { MediaContextProvider } from '../lib/artsyFresnel/Media'
import * as fbq from '../lib/fpixel'
import { SnackbarComponent } from '../src/components/DesktopElements/Utils'
import { getTheme } from '../src/constants/theme'
import { ShopContextWrapper } from '../src/context'
import { CartItem, WishlistItem } from '../src/store/models'
import {
  cartItemsVar,
  recentlyViewedVar,
  wishlistItemsVar,
} from '../src/store/reactiveVars'
import '../src/styles/css/nprogress.css' //styles of nprogress
// CSS GLOBAL STYLES
import '../src/styles/globals.css'
import createEmotionCache from '../src/utilityFunctions/createEmotionCache'
import { CacheProvider } from '@emotion/react'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import { gtmPageView, triggerCustomEvent } from '../lib/gtm'
import { getUserIdFromCookieClient } from '../lib/getUserIdFromCookie'
import {
  retrieveUserCartItems,
  routeSaveMutation,
} from '../lib/userSessionTracking'
import { handleClearAllFilters } from '../src/utilityFunctions/filtersUtils'
import { EmbedConfiguratorContext } from '../src/context/embedConfiguratorContext'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const isProduction = process.env.NODE_ENV === 'production'
// const isProduction = true
// const facebookPixelID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL ?? null
// const facebookPageID = process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID ?? null
// const hotJarID = process.env.NEXT_PUBLIC_HOTJAR_ID ?? null
// const googleTagManagerID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER ?? null
// const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? null

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const clientSideEmotionCache = createEmotionCache()

function MyApp(props) {
  // ==== WE NEEED TO GET ANALYTICS DATA FROM SHOP SETTINGS ====

  const shopSettings =
    props?.pageProps?.shopSettingsData?.data?.findFirstShopSetting ?? null

  const defaultLocale = props?.pageProps?.defaultLocale ?? 'en'

  const enabledLanguages = shopSettings?.enabled_languages ?? []

  const isEmbed = props.router.state?.pathname === '/product-embed/[product]'

  const defaultEnabledLanguage =
    enabledLanguages.find((item) => item.language === defaultLocale) || null

  let facebookPixelID
  let facebookPageID
  let hotJarID
  let googleTagManagerID
  let GA_TRACKING_ID
  let SHOP_PHONE_NUMBER

  if (defaultEnabledLanguage) {
    facebookPixelID = defaultEnabledLanguage.facebook_pixel
    facebookPageID = defaultEnabledLanguage.facebook_page_id
    hotJarID = defaultEnabledLanguage.hotjar
    googleTagManagerID = defaultEnabledLanguage.google_tag_manager
    GA_TRACKING_ID = defaultEnabledLanguage.google_analytics
    SHOP_PHONE_NUMBER = defaultEnabledLanguage.shop_phone_number
  }

  // ============================
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const apolloClient = useApollo(pageProps)
  const SHOP_ID = JSON.parse(process.env.NEXT_PUBLIC_SHOP_ID)

  const { t, i18n } = useTranslation(['common'])
  const cookieNoticeMainText = t('cookie-notice-main-text')
  const cookieNoticeButtonText = t('cookie-notice-button-text')

  const router = useRouter()

  const font = shopSettings?.font ?? 'Roboto'
  let themeOverride
  try {
    themeOverride = JSON.parse(shopSettings?.theme_override)
  } catch (error) {
    themeOverride = {}
  }

  const typography = themeOverride?.typography ?? {}

  const theme = getTheme({
    ...themeOverride,
    typography: {
      ...typography,
      fontFamily: [font, 'sans-serif'].join(','),
    },
  })

  const mainColor = theme.palette.secondary.main // The brown one

  //====== INTERNAL USER TRACKING ========
  useEffect(() => {
    // Handle the route change
    function handleRouteChange() {
      // Trigger on every page change
      const cookie = getUserIdFromCookieClient()
      //------
      routeSaveMutation(cookie, router)

      //--- RESET THE CART
      retrieveUserCartItems(cookie)
    }
    // Add the event listener
    router.events.on('routeChangeComplete', handleRouteChange)
    // Clean up the listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  //==== Handel fist load.
  useEffect(() => {
    //--- On any event trigger the cookieSave
    const newCookie = getUserIdFromCookieClient()
    // console.log('Creating a cookie --> ', newCookie)
    routeSaveMutation(newCookie, router)
    //--- RESET THE CART
    retrieveUserCartItems(newCookie)
  }, [])

  // ====== FACEBOOK PIXEL EVENTS ========
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)

    if (isProduction && facebookPixelID) fbq.pageview()
    // if (isProduction && googleTagManagerID) gtmPageView(googleTagManagerID)

    const handleRouteChange = (url) => {
      if (isProduction && facebookPixelID) fbq.pageview()
      if (isProduction && googleTagManagerID)
        triggerCustomEvent('routeChange', { skip: url })
      if (isProduction && googleTagManagerID)
        triggerCustomEvent('VirtualPageView', { skip: url })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    //* ====================================
    //* Remove the server-side injected CSS.
    //* ====================================
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    //* ==============================================================================================================================
    //* Once the app renders, we take the data from LS ( local storage is only accessible on the client)
    //* and we set the cartItems wishlistItems and recentlyViewed reactive variables to that LS data. The vars are the updated in the clientMutations
    //* ==============================================================================================================================
    // const cartItems: CartItem[] = JSON.parse(localStorage.getItem('cartItems'))
    // const cartItems: CartItem[] = cartItemsVar()
    const wishlistItems: WishlistItem[] = JSON.parse(
      localStorage.getItem('wishlistItems'),
    )
    const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed'))

    // cartItems && cartItemsVar([...cartItems])
    wishlistItems && wishlistItemsVar([...wishlistItems])
    recentlyViewed && recentlyViewedVar([...recentlyViewed])

    //===== GOOGLE TAG MANAGER INITIALIZE
    if (googleTagManagerID) {
      // init GA/GTM
      TagManager.initialize({
        gtmId: googleTagManagerID,
      })
    }
    //-----
  }, [])

  //===== FIXING SCROLL BEHAVIOUR
  //--- We disable scrolling UP when back button is clicked

  //==== THIS ONE IS WORKING ====
  useEffect(() => {
    // Apply the scroll middleware
    router.beforePopState(({ url, as }) => {
      // console.log(' router.beforePopState :>> ')
      // Check if the route starts with '/category'
      if (url.startsWith('/category')) {
        // Disable scroll behavior
        // router.push(url, undefined, { scroll: false })

        const isBackClicked = url.includes('b=true')
        router.push(
          {
            pathname: as.replace('?b=true', ''),
            query: { b: true },
          },
          undefined,
          { scroll: false },
        )

        return false
      }

      // Allow default scroll behavior for other routes
      return true
    })

    // Add any other global setup or listeners here
    return () => {
      // Clean up any global setup or listeners here
    }
  }, [router])

  //====================================
  //==== FILTER CLEARING FUNCTIONALITY!!!
  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      // console.log('Handling Route change in categoruProductList')

      const isBackClicked = router?.query?.b
      // console.log('isBackClicked :>> ', isBackClicked)
      // console.log('router :>> ', router)

      if (isBackClicked) {
        //--- Scroll the additional positio
        if (sessionStorage.getItem('categoryPageScrollPosition')) {
          // Retrieve the saved scroll position from sessionStorage
          const scrollPosition = JSON.parse(
            sessionStorage.getItem('categoryPageScrollPosition'),
          )

          console.log('scrollPosition :>> ', scrollPosition)
          // Check if the scroll position is valid (e.g., a number)
          if (!isNaN(scrollPosition)) {
            // Scroll to the saved position
            window.scrollTo(0, scrollPosition)
          }
          // Optionally, you can remove the saved scroll position from sessionStorage
          // sessionStorage.removeItem("categoryPageScrollPosition");
        }
      } else {
        //--- Back was NOT CLICKED (clear filters)
        console.log('CLEARING ALL FILTERS')
        handleClearAllFilters()
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  const modifiedHotJarID = hotJarID
    ? Number(`${hotJarID}`.replace(/\s/g, '').replace(/"/g, ''))
    : null

  //==== FAVICON
  const favicon = shopSettings?.favicon
    ? shopSettings.favicon.src + '?v=1'
    : '/favicon.ico'

  //==== THEME COLORS

  const settingsThemeMainColor = shopSettings?.theme_main_color ?? null
  const settingsThemeSecondaryColor =
    shopSettings?.theme_secondary_color ?? null
  // TODO
  let modifiedTheme = { ...theme }
  modifiedTheme.palette.secondary.main = settingsThemeMainColor
    ? settingsThemeMainColor
    : modifiedTheme.palette.secondary.main
  modifiedTheme.palette.secondary.light = settingsThemeSecondaryColor
    ? settingsThemeSecondaryColor
    : modifiedTheme.palette.secondary.light
  modifiedTheme.palette.secondary.dark = settingsThemeSecondaryColor
    ? settingsThemeSecondaryColor
    : modifiedTheme.palette.secondary.dark

  // sets background color from query parameters. used for embed configurator
  if (isEmbed) {
    const embedSettings = JSON.parse(shopSettings.embed_configurator_settings)
    if (embedSettings?.backgroundColor) {
      modifiedTheme.palette.alternate = undefined
      modifiedTheme.palette.background.default = embedSettings.backgroundColor
    }
  }

  return (
    <>
      {/* <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      /> */}

      {/* Override NPROGRESS MAIN COLOR */}
      <style jsx global>{`
        #nprogress .bar {
          /* Override the styles here */
          background: ${settingsThemeMainColor};
        }
        #nprogress .peg {
          box-shadow: 0 0 10px ${settingsThemeMainColor},
            0 0 5px ${settingsThemeMainColor};
        }

        #nprogress .spinner-icon {
          /* background: ${settingsThemeMainColor}; */
          border-top-color: ${settingsThemeMainColor};
          border-left-color: ${settingsThemeMainColor};
        }
      `}</style>

      <Head>
        <link rel="shortcut icon" href={favicon} />
        <meta name="theme-color" content={modifiedTheme.palette.primary.main} />
        <link
          href={`https://fonts.googleapis.com/css2?family=${font}:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap`}
          rel="stylesheet"
        />
      </Head>
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="minimum-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"
        name="viewport"
      />

      {isProduction && (
        <>
          {/* Global Site Code Pixel - Facebook Pixel */}
          {facebookPixelID && (
            <Script
              id="1"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${facebookPixelID});
        `,
              }}
            />
          )}
          {/* GOOGLE TAG MANAGER  */}
          {/* We are currently disabling this integration and shifting to google tag manger package */}
          {/* {googleTagManagerID && (
            <Script
              id="6"
              strategy="beforeInteractive"
              // strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${googleTagManagerID}');
          `,
              }}
            />
          )} */}

          {/* enable Google analytics script only for production */}
          {GA_TRACKING_ID && (
            <>
              <Script
                strategy="lazyOnload"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
              ></Script>

              <Script id="google-analytics" strategy="lazyOnload">
                {` window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}');`}
              </Script>
            </>
          )}

          {/* FACEBOOK CHAT */}
          {facebookPageID && (
            <Script
              id="4"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
              var chatbox = document.getElementById('fb-customer-chat');
                chatbox.setAttribute("page_id", "${facebookPageID}");
                chatbox.setAttribute("attribution", "biz_inbox");
          
                window.fbAsyncInit = function() {
                  FB.init({
                    xfbml            : true,
                    version          : 'v12.0'
                  });
                };
          
                (function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) return;
                  js = d.createElement(s); js.id = id;
                  js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                  fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
          `,
              }}
            />
          )}

          {/* HOTJAR  */}
          {hotJarID && (
            <Script
              id="5"
              strategy="lazyOnload"
              dangerouslySetInnerHTML={{
                __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${modifiedHotJarID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
              }}
            />
          )}
        </>
      )}
      {/* This sends window height to parent window. Used for embed configurator */}
      {isEmbed && (
        <Script
          id="6"
          dangerouslySetInnerHTML={{
            __html: `
            let prevHeight = document.body.clientHeight;
            function updateHeightDisplay() {
              const currentHeight = document.body.scrollHeight + 20;
              if (currentHeight !==prevHeight) {
                prevHeight = currentHeight;
                window.parent.postMessage({
                  source: 'embed_configurator',
                  height: currentHeight
                }, "*");
              }
            }
            window.addEventListener('load', updateHeightDisplay);
            window.addEventListener('resize', updateHeightDisplay);
            setInterval(updateHeightDisplay, 1000);
            `,
          }}
        />
      )}

      <CacheProvider value={emotionCache}>
        {/* <StyledEngineProvider injectFirst> */}
        <ShopContextWrapper
          value={{ SHOP_ID }}
          shopPhoneNumber={SHOP_PHONE_NUMBER}
          enabledLanguages={enabledLanguages}
          defaultLocale={defaultLocale}
          defaultEnabledLanguage={defaultEnabledLanguage}
        >
          <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={modifiedTheme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <EmbedConfiguratorContext router={props.router}>
                <MediaContextProvider>
                  {/* <LayoutWrapper {...pageProps}> */}
                  <SnackbarComponent>
                    {/* BODY SCRIPTS ====== */}

                    {/* Disabling script integration */}
                    {/* {googleTagManagerID && (
                    <noscript
                      dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                      }}
                    ></noscript>
                  )} */}

                    <Component {...pageProps} />
                  </SnackbarComponent>
                  {/* </LayoutWrapper> */}
                </MediaContextProvider>
              </EmbedConfiguratorContext>
            </ThemeProvider>
          </ApolloProvider>
        </ShopContextWrapper>
        {/* </StyledEngineProvider> */}
      </CacheProvider>

      {/** for embed configurator (cookie query param is set) there should be  no cookie consent*/}
      {!isEmbed && (
        <CookieConsent
          location="bottom"
          buttonText={cookieNoticeButtonText}
          cookieName="cookie-consent-furnibay"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            background: `${mainColor}`,
            fontSize: '14px',
            fontWeight: 600,
            color: '#fff',
          }}
          expires={150}
        >
          {cookieNoticeMainText}
        </CookieConsent>
      )}
    </>
  )
}

export default appWithTranslation(MyApp)
