export function sortCategories(categories, parentId = null) {
  const sortedCategories = []
  categories.forEach((category) => {
    if (category.parent_categoryId === parentId) {
      const sortedCategory = { ...category }
      sortedCategory.child_categories = sortCategories(categories, category.id)
      sortedCategories.push(sortedCategory)
    }
  })
  return sortedCategories
}

export function flattenCategories(categories, depth = 0) {
  let flatCategories = []
  categories.forEach((category) => {
    flatCategories.push({ ...category, depth })
    if (category.child_categories) {
      flatCategories = flatCategories.concat(
        flattenCategories(category.child_categories, depth + 1),
      )
    }
  })
  return flatCategories
}
