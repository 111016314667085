import { useState, useEffect } from 'react'

// function getWindowDimensions() {
//   if (typeof window !== 'undefined') {
//     // Client-side-only code
//     const { innerWidth: width, innerHeight: height } = window
//     return {
//       width,
//       height,
//     }
//   } else {
//     return {
//       width: 2000,
//       height: 2000,
//     }
//   }
// }

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    if (typeof window !== 'undefined') {
      //--- Again for SSR
      handleResize()

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowDimensions
}
