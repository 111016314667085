import { useReactiveVar } from '@apollo/client'
import { Divider, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { wishlistItemsVar } from '../../store/reactiveVars'
import { WishlistProductCard } from '../DesktopElements/WishlistProductListElements'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      pageTitle: {
        padding: theme.spacing(3, 0, 2, 0),
        [theme.breakpoints.between('sm', 'lg')]: {
          fontSize: '2rem',
        },
        [theme.breakpoints.only('xs')]: {
          fontSize: '1.5rem',
        },
      },
      gridContainer: {
        marginTop: theme.spacing(3),
      },
    }),
  { name: 'MuiWishlistProductListComponent' },
)

export default function WishlistProductList() {
  const classes = useStyles() as any
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  console.log("wishlistItems", wishlistItems)
  return (
    <>
      <Typography
        align="center"
        variant="h3"
        component="h1"
        className={classes.pageTitle}
      >
        Wishlist
      </Typography>
      <Divider />
      <Grid container spacing={3} className={classes.gridContainer}>
        {wishlistItems.map((wishlistItem) => {
          return (
            <WishlistProductCard
              wishlistItem={wishlistItem}
              key={wishlistItem.id}
            />
          )
        })}
      </Grid>
    </>
  )
}
