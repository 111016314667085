import React, { useState } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import SendCartToEmailModal from './SendCartToEmailModal'

interface TrackingModuleButtonProps {
  cartItems: any
  classes?: ButtonProps['classes']
}

export const SendCartToEmailButton: React.FC<TrackingModuleButtonProps> = ({
  cartItems,
  classes,
}) => {
  const { t } = useTranslation(['common'])
  const label = t('save-cart-to-email-button')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  // Rest of your component code
  return (
    <div style={{ width: '100%' }}>
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={openModal}
          classes={classes}
        >
          {label}
        </Button>

        <SendCartToEmailModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          cartItems={cartItems}
        />
      </>
    </div>
  )
}
