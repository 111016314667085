import { CardMedia } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { imageFallback } from '../../../constants/Images'
import DescriptionTab from './DesciptionTab'

const useStyles = makeStyles(
  () => ({
    imageContainer: {
      height: 'fit-content',
      backgroundColor: '#fff',
    },
    image: {
      width: '100%',
      minHeight: '75vh',
      backgroundSize: 'contain',
    },
    gridContainer: {
      padding: '3rem',
    },
    leftDataContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
    property: {
      width: '50%',
    },
    value: {
      width: '50%',
    },
  }),
  { name: 'MuiProductGalleryComponent' },
)

export default function ProductGallery({ productImages, product, isSafari }) {
  const classes = useStyles() as any

  // const appContext = useAppContext()
  // const userAgent = appContext ? appContext.userAgent : null
  // // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false;

  // const [isSafari, setIsSafari] = useState(
  //   userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  // )

  // // --- After moving to getStaticProps  we need to load userAgent locally
  // useEffect(() => {
  //   // --- trying to get user agent
  //   const ua = useUserAgent(window.navigator.userAgent)
  //   setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  // }, [])

  // Order images by display order
  // Convert to list
  const productImagesArranged = productImages
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  return (
    <div>
      {productImagesArranged.map((image) => {
        // If safari show sorc or fallback
        // Else try to show src_lg
        const imageLink = isSafari
          ? image.src ?? imageFallback
          : image.src_lg ?? image.src ?? imageFallback

        return (
          <div className={classes.imageContainer} key={image.id}>
            <CardMedia image={imageLink} className={classes.image} />
          </div>
        )
      })}
      {/* DIMENSIONS AND DETAILS */}
      <DescriptionTab product={product} />
    </div>
  )
}
