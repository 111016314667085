import { Container, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import DynamicCategoryProductCard from '../CategoryPageElements/DynamicCategoryProductCard'

import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { recentlyViewedVar } from '../../../store/reactiveVars'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      sliderContainer: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
          marginTop: theme.spacing(1),
          padding: theme.spacing(0, 0, 0, 2),
        },
      },
      slider: {
        overflowX: 'clip',
        overflowY: 'visible',

        '--swiper-navigation-color': 'black',
        '& .swiper-container': {
          overflowX: 'clip',
          overflowY: 'visible',
        },
        '& .swiper-button-prev': {
          top: '-2rem',
          right: '3.5rem',
          left: 'auto',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '100%',
          background: '#e7e9ea',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
        '& .swiper-button-prev::after': {
          fontSize: '1rem',
          fontWeight: 700,
        },
        '& .swiper-button-next': {
          top: '-2rem',
          right: '0',
          left: 'auto',
          width: '2.5rem',
          height: '2.5rem',
          borderRadius: '100%',
          background: '#e7e9ea',
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
        '& .swiper-button-next::after': {
          fontSize: '1rem',
          fontWeight: 700,
        },
        [theme.breakpoints.up('lg')]: {
          '&::after': {
            content: '""',
            width: '20%',
            height: '100%',
            position: 'absolute',
            right: '0',
            top: '0',
            background:
              'linear-gradient(90deg, transparent 0%, rgba(247,249,250,1) 100%)',
            zIndex: 2,
          },
        },
      },
      sliderAfterDisplayNone: {
        '&::after': {
          display: 'none',
        },
      },
      nextArrow: {
        position: 'absolute',
        zIndex: 3,
        right: 0,
        top: '-3.5rem',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      prevArrow: {
        position: 'absolute',
        zIndex: 3,
        right: '5rem',
        top: '-3.5rem',
        [theme.breakpoints.down('lg')]: {
          display: 'none',
        },
      },
      itemsContainer: {
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(0, 0, 0, 2),
        },
      },
    }),
  { name: 'MuiProductSliderComponent' },
)

// SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const ProductSlider = ({
  sliderTitle = null,
  titleVariant = 'h5',
  autoplay = false,
  className,
  items,
  product,
  ...rest
}: ProductSliderProps): JSX.Element => {
  const classes = useStyles() as any
  const [isEnd, setIsEnd] = useState(false)

  return (
    <Container maxWidth="xl" className={classes.sliderContainer}>
      {sliderTitle && (
        <Typography
          variant={titleVariant}
          component="h2"
          style={{ marginBottom: '10px' }}
        >
          {sliderTitle}
        </Typography>
      )}

      <Swiper
        modules={[Navigation, Pagination, Autoplay, Lazy]}
        spaceBetween={15}
        slidesPerView={1.4}
        slidesPerGroup={1}
        tag="section"
        wrapperTag="ul"
        navigation
        longSwipes={false}
        resistance={false}
        // autoplay={
        //   autoplay ? { delay: 1000, disableOnInteraction: true } : false
        // }
        autoplay={false}
        loop={false}
        touchStartPreventDefault={false}
        breakpoints={{
          // when window width is >= 600px
          600: {
            slidesPerView: 2.5,
          },
          // when window width is >= 1175px
          1175: {
            slidesPerView: 3.7,
            longSwipes: false,
            slidesPerGroup: 1,
            pagination: {
              clickable: true,
            },
          },
        }}
        className={clsx(
          isEnd && classes.sliderAfterDisplayNone,
          className,
          classes.slider,
        )}
        onSlideChange={(swiper) => {
          if (swiper.isEnd !== isEnd) {
            setIsEnd(swiper.isEnd)
          }
        }}
      >
        {items.map((item) => {
          return (
            <SwiperSlide key={item.id} tag="li">
              <DynamicCategoryProductCard productContainerID={item.id} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Container>
  )
}

export default ProductSlider
