import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Image, Section, SectionHeader } from '../Utils'
import { CardMedia } from '@mui/material'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 700,
      position: 'relative',
      overflow: 'hidden',
      minHeight: 400,
      [theme.breakpoints.down('lg')]: {
        height: 450,
      },
      // [theme.breakpoints.up("md")]: {
      //   backgroundPosition: "right -400px top",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   backgroundPosition: "right -250px top",
      // },
    },
    media: {
      // minHeight: 400,
      filter: 'brightness(60%)',
      width: '100%',
      height: '100%',
      // objectFit: "contain",
      [theme.breakpoints.down('md')]: {
        minHeight: '100%',
      },
    },
    clipped: {
      clipPath: 'polygon(0 0, 100% 0, 100% 67%, 0% 100%)',
    },
    textWhite: {
      color: 'white',
    },
    title: {
      fontWeight: 'bold',
      color: 'white',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem',
      },
    },
    subtitle: {
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
    },
    section: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    sectionLeft: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(0, -50%)',
    },
  }),
  { name: 'MuiHeroComponent' },
)

const Hero = ({
  clipped,
  image,
  withHeader,
  title,
  subtitle,
  className,
  alignLeft = false,
  ...rest
}: HeroProps): JSX.Element => {
  const classes = useStyles() as any
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Image
        className={clsx(classes.media, clipped && classes.clipped)}
        image={{ src: image }}
      />
      {withHeader && (
        <Section className={alignLeft ? classes.sectionLeft : classes.section}>
          <SectionHeader
            title={title}
            subtitle={subtitle}
            align="left"
            data-aos="fade-up"
            disableGutter
            titleProps={{
              className: clsx(classes.title, classes.textWhite),
              variant: 'h3',
            }}
            subtitleProps={{
              className: clsx(classes.subtitle, classes.textWhite),
            }}
          />
        </Section>
      )}
    </div>
  )
}

export default Hero
