import { useReactiveVar } from '@apollo/client'
import { Button, Drawer, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import {
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import WishlistItem from './WishlistItem'
import { TrackingModuleButton } from '../Utils/TrackingModuleComponent'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        width: 450,
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topWishlistContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
      },
      wishlistContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
      },
      itemsContainer: {
        padding: theme.spacing(0, 4),
        overflow: 'scroll',
      },
      // wishlistContentFooter: {
      //   padding: '20px 30px 30px 30px',
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      // },

      wishlistContentFooter: {
        padding: theme.spacing(0, 2, 2, 2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiWishlistDrawerComponent' },
)

export default function WishlistDrawer() {
  const { t } = useTranslation(['common'])
  const wishlistSlug = t('wishlist-slug')
  const wishlistDrawerHeader = t('wishlist-drawer-header')
  const goToWishlistCtaButton = t('go-to-wishlist-cta-button')
  const classes = useStyles() as any
  const wishListState = useReactiveVar(wishlistDrawerVar)
  const wishlistItems = useReactiveVar(wishlistItemsVar)

  return (
    <>
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor="right"
        open={wishListState}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() => wishlistDrawerVar(false)}
      >
        <div className={classes.topWishlistContent}>
          <Typography variant="h6">{wishlistDrawerHeader}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => wishlistDrawerVar(false)}
          />
        </div>

        <section className={classes.wishlistContent}>
          <div className={classes.itemsContainer}>
            {wishlistItems.map((wishlistItem) => (
              <WishlistItem wishlistItem={wishlistItem} key={wishlistItem.id} />
            ))}
          </div>
          <div>
            {/* //* ============= TRACKING MODULE SECTION */}

            <div className={classes.wishlistContentFooter}>
              <TrackingModuleButton
                type={'save-wishlist-button'}
                variant="outlined"
              />
            </div>

            <div className={classes.wishlistContentFooter}>
              <Link prefetch={false} href={`/${wishlistSlug}`}>
                <Button
                  variant="contained"
                  onClick={() => wishlistDrawerVar(false)}
                >
                  {goToWishlistCtaButton}
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </Drawer>
    </>
  )
}
