import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import {
  colors,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputBase,
  IconButton,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { SectionHeader, Section } from '.'
import SendIcon from '@mui/icons-material/Send'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // background: '#efeee5',
        background: theme.palette.secondary.light,
        backgroundSize: 'cover',
      },
      inputContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      formControl: {
        maxWidth: 400,
      },
      input: {
        border: `1px solid ${theme.palette.text.primary}`,
        padding: theme.spacing(0, 1),
      },
      textColor: {
        color: theme.palette.text.primary,
      },
    }),
  { name: 'MuiNewsletterComponent' },
)

const Newsletter = ({
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const newsletterHeader = t('newsletter-header')
  const newsletterSubHeader = t('newsletter-subHeader')
  const newsletterInputPlaceholder = t('newsletter-inputPlaceholder')
  const classes = useStyles() as any

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        <>
          <SectionHeader
            title={<span>{newsletterHeader}</span>}
            subtitle={<span>{newsletterSubHeader}</span>}
            fadeUp
          />
          <div className={classes.inputContainer}>
            <FormControl
              fullWidth
              variant="outlined"
              data-aos="fade-up"
              className={classes.formControl}
            >
              <InputBase
                className={classes.input}
                id="email"
                name="email"
                fullWidth
                autoComplete="email"
                inputProps={{ 'aria-label': newsletterInputPlaceholder }}
                placeholder={newsletterInputPlaceholder}
                endAdornment={
                  <IconButton size="large">
                    <SendIcon className={classes.textColor} />
                  </IconButton>
                }
              />
            </FormControl>
          </div>
        </>
      </Section>
    </div>
  )
}

export default Newsletter
