import { Box, Button, Container, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PhoneIcon from '@mui/icons-material/Phone'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { LanguageSwitch } from '../Utils'
import { useShopContext } from '../../../context'
import { useAppContext } from '../../../context/appContext'
import { E } from '../ProductPageElements/AdvancedProductElements/SofaDrawingElements/SofaElements'
import router from 'next/router'

const useStyles = makeStyles(
  (theme: Theme) => ({
    box: {
      backgroundColor: theme.palette.background.footer,
      height: '2.5rem',
      zIndex: 1201,
    },
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    whiteTypography: {
      color: '#fff',
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    leftLinksContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    rightLinksContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
    },
  }),
  { name: 'MuiHeaderTopBannerComponent' },
)

export default function HeaderTopBanner() {
  const { t, i18n } = useTranslation(['common'])
  const aboutSlug = t('about-slug')
  const blogSlug = t('blog-slug')
  const contactSlug = t('contact-slug')
  const aboutUs = t('about-us')
  const contact = t('contact')
  const blog = t('blog')
  const FAQ = t('F.A.Q.')

  const freeShipping = t('free-shipping')

  const classes = useStyles() as any

  const { shopPhoneNumber } = useShopContext()

  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  return (
    <div className={classes.box}>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.leftLinksContainer}>
          <Link prefetch={false} href={`/${aboutSlug}`}>
            <a className="cursorPointer">
              <Typography
                variant="body1"
                component="p"
                className={clsx(classes.whiteTypography, classes.marginRight)}
              >
                {aboutUs}
              </Typography>
            </a>
          </Link>
          <Link prefetch={false} href={`/${blogSlug}`}>
            <a className="cursorPointer">
              <Typography
                variant="body1"
                component="p"
                className={clsx(classes.whiteTypography, classes.marginRight)}
              >
                {blog}
              </Typography>
            </a>
          </Link>
          {/* <Link href="/">
            <a className="cursorPointer">
              <Typography
                variant="body1"
                component="p"
                className={clsx(classes.whiteTypography, classes.marginRight)}
              >
                {FAQ}
              </Typography>
            </a>
          </Link> */}
          <Link prefetch={false} href={`/${contactSlug}`}>
            <a className="cursorPointer">
              <Typography
                variant="body1"
                component="p"
                className={clsx(classes.whiteTypography, classes.marginRight)}
              >
                {contact}
              </Typography>
            </a>
          </Link>
        </div>

        {/* FREEE SHIPPING IN THE MIDDLE IN TOP HEADER */}
        {/* <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <LocalShipping
              className={clsx(classes.whiteTypography, classes.marginRight)}
            />

            <Typography variant="subtitle2">
              <span
                style={{
                  color: "#fff",
                  paddingRight: "5px",
                  marginRight: "5px",
                  // borderRight: "1px solid",
                  // fontSize: "14px",
                }}
              >
                {freeShipping}
              </span>
            </Typography>
          </div>
        </div> */}

        <div className={classes.rightLinksContainer}>
          {/* <PhoneIcon
            className={clsx(classes.whiteTypography, classes.marginRight)}
          /> */}

          {shopPhoneNumber && !b2bToken && (
            <>
              <PhoneIcon
                className={clsx(classes.whiteTypography, classes.marginRight)}
              />
              <Typography variant="subtitle2">
                <a href={`tel:${shopPhoneNumber}`} style={{ color: '#fff' }}>
                  {shopPhoneNumber}
                </a>
              </Typography>
            </>
          )}
          <LoginComponent mobile={false} />
          <LanguageSwitch />
        </div>
      </Container>
    </div>
  )
}

// TODO Move to an independet file
export const LoginComponent = ({ mobile = false }) => {
  const { t, i18n } = useTranslation(['common'])
  const loginSlug = t('login')
  const logoutString = t('logout-string')
  const loginString = t('login-string')

  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken
  const shopSettings = appContext.shopSettings
  const isB2BEnabled = shopSettings.b2b_enabled

  // console.log('b2bToken :>> ', b2bToken)

  const b2bUserName = b2bToken
    ? b2bToken.name
      ? `${b2bToken.name} ${b2bToken.surname}`
      : `${b2bToken.b2b_company_name}`
    : null

  const handleLogout = (e) => {
    //---
    e.preventDefault()
    localStorage.removeItem('furnisystems_token')
    // router.push('/')
    window.location.reload()
  }

  return (
    <>
      {isB2BEnabled && (
        <Box sx={{ margin: mobile ? 0 : '0 20px' }}>
          {b2bToken ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: mobile ? 'row-reverse' : 'row',
              }}
            >
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  flexGrow: 1,
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  margin: '0 20px',
                }}
              >
                {b2bUserName}
              </Typography>

              <Button
                variant="outlined"
                color="secondary"
                onClick={(e) => handleLogout(e)}
              >
                {logoutString}
              </Button>
            </Box>
          ) : (
            <>
              <Link prefetch={false} href={`/${loginSlug}`}>
                <a className="cursorPointer">
                  <Button variant="outlined" color="secondary">
                    {loginString}
                  </Button>
                </a>
              </Link>
            </>
          )}
        </Box>
      )}
    </>
  )
}
