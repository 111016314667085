import { Button, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      loadMoreBtn: {
        marginTop: '3rem',
        width: '10rem',
      },
    }),
  { name: 'MuiLoadMoreButtonComponent' },
)

interface Props {
  onClick: () => void
}

const LoadMoreButton: React.FC<Props> = ({ onClick, ...props }) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const loadMoreText = t('load-more')

  return (
    <>
      <Button
        variant="contained"
        onClick={() => onClick()}
        className={classes.loadMoreBtn}
        {...props}
      >
        {loadMoreText}
      </Button>
    </>
  )
}

export default LoadMoreButton
