import { CardMedia, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Media } from '../../../../lib/artsyFresnel/Media'
import { imageFallback } from '../../../constants/Images'
import { MobileProductPageSlider } from '../../ResponsiveElements/ResponsiveProductPageElements'
import AdvancedProductPanel from './AdvancedProductPanel'

import { Navigation, Pagination } from 'swiper'

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      maxWidth: 1920,
      margin: '0 auto',
    },
    productPanelContainer: {
      display: 'flex',
    },
    productPanel: {
      // height: "fit-content",
      // position: "sticky",
      // top: "8.5rem",
      // [theme.breakpoints.down("lg2")]: {
      //   top: "auto",
      //   bottom: 0,
      //   marginTop: "auto",
      // },
    },

    media: {
      height: '55vh',
      backgroundSize: 'contain',
    },
    slider: {
      '--swiper-pagination-color': 'black',

      '& .swiper-button-prev': {
        padding: '30px',
        background: '#e7e9ea',
        opacity: 0.2,
        color: '#000',
      },
      '& .swiper-button-prev:hover': {
        opacity: 0.3,
      },
      // '& .swiper-button-prev::after': {
      //   fontSize: '1rem',
      //   fontWeight: 700,
      // },
      '& .swiper-button-next': {
        padding: '30px',
        background: '#e7e9ea',
        opacity: 0.2,
        color: '#000',
      },
      '& .swiper-button-next:hover': {
        opacity: 0.3,
      },
      // '& .swiper-button-next::after': {
      //   fontSize: '1rem',
      //   fontWeight: 700,
      // },
    },
  }),
  { name: 'MuiTopProductPageComponent' },
)

export default function TopAdvancedProductPage({
  productPermalink,
  product,
  isSafari,
}) {
  const classes = useStyles() as any
  // Convert to list
  const images = product?.advanced_product?.images
  const productImagesArranged = images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  return (
    <div className={classes.container}>
      <Media greaterThan="md">
        <Grid container justifyContent="space-evenly" spacing={1}>
          <Grid item xs={12} sm={7} md={9} lg={9}>
            {/* ========   The Image SLIDER ======== */}
            <Swiper
              modules={[Navigation, Pagination]}
              tag="section"
              wrapperTag="ul"
              resistance={true}
              longSwipes={false}
              navigation={true}
              autoplay={{ delay: 9000, disableOnInteraction: true }}
              loop={images?.length === 1 ? false : true}
              pagination={images?.length === 1 ? false : true}
              className={clsx(classes.slider)}
            >
              {productImagesArranged.map((image) => {
                const imageLink = image.src_lg ?? image.src ?? imageFallback

                return (
                  <SwiperSlide
                    key={image.id}
                    tag="li"
                    style={{ backgroundColor: '#fff' }}
                  >
                    <CardMedia image={imageLink} className={classes.media} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            {/* ======== ^^^^^^ (above)  The Image SLIDER ======== */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={3}
            lg={3}
            className={classes.productPanelContainer}
          >
            <AdvancedProductPanel
              product={product}
              productPermalink={productPermalink}
            />
          </Grid>
        </Grid>
      </Media>

      <Media lessThan="lg">
        <Grid container>
          <Grid item xs={12}>
            <MobileProductPageSlider
              images={product?.advanced_product.images}
              product={product}
            />
          </Grid>
          <Grid item xs={12}>
            <AdvancedProductPanel
              product={product}
              productPermalink={productPermalink}
            />
          </Grid>
        </Grid>
      </Media>
    </div>
  )
}
