import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { CardBase, SectionHeader } from '../Utils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      cardBase: {
        boxShadow: 'none',
        background: theme.palette.background.default,
        borderRadius: theme.spacing(1),
        '& .card-base__content': {
          padding: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
          },
        },
      },
      avatar: {
        width: 110,
        height: 110,
        border: `4px solid ${theme.palette.background.default}`,
        borderRadius: '100%',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
      },
      listItem: {
        padding: 0,
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
      listItemAvatar: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
          marginBottom: theme.spacing(2),
        },
      },
      listItemText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%',
      },
      title: {
        fontWeight: 'bold',
      },
    }),
  { name: 'MuiTeamComponent' },
)

const Team = ({
  teamMembers,
  teamMembersText,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  // const { t } = useTranslation(['common'])
  // const teamHeader = t('team-header')
  // const teamSubHeader = t('team-subHeader')
  // const teamPersonOneJobTitle = t('team-person-one-job-title')
  // const teamPersontwoJobTitle = t('team-person-two-job-title')
  // const teamPersonThreeJobTitle = t('team-person-three-job-title')
  // const teamPersonFourJobTitle = t('team-person-four-job-title')
  // const teamPersonFiveJobTitle = t('team-person-five-job-title')
  // const teamPersonSixJobTitle = t('team-person-six-job-title')

  const teamHeader = teamMembersText

  const classes = useStyles() as any
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  // console.log('teamMembers :>> ', teamMembers)

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={teamHeader}
        // subtitle={teamSubHeader}
      />
      <Grid container spacing={isMd ? 2 : 1}>
        {teamMembers.map((item: any, index: number) => (
          <Grid item xs={6} sm={6} md={4} key={index} data-aos="fade-up">
            <CardBase className={classes.cardBase} liftUp>
              <ListItem disableGutters className={classes.listItem}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    {...(item.image
                      ? item.image.src
                        ? { src: item.image.src }
                        : null
                      : null)}
                    className={classes.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={item.name ?? ''}
                  secondary={item.position ?? ''}
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'h6',
                    align: isMd ? 'left' : 'center',
                  }}
                  secondaryTypographyProps={{
                    color: 'textPrimary',
                    align: isMd ? 'left' : 'center',
                  }}
                />
              </ListItem>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default Team
