import React, { useEffect, useState } from 'react'
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper'
import 'swiper/css/bundle'

import AdditionalComponentGroup from './AdditionalComponentGroup'
import AdditionalComponentGroupWithDrawer from './AdditionalComponentGroupWithDrawer'

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy])

const AdditionalComponentGroupWrapper = ({
  className = null,
  locale,
  componentGroup,
  ...rest
}): JSX.Element => {
  if (componentGroup.ui_type === 'drawer') {
    return (
      <AdditionalComponentGroupWithDrawer
        key={componentGroup.id}
        componentGroup={componentGroup}
        locale={locale}
      />
    )
  }
  return (
    <AdditionalComponentGroup
      key={componentGroup.id}
      componentGroup={componentGroup}
      locale={locale}
    />
  )
}

export default AdditionalComponentGroupWrapper
