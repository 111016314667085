import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import NoSsr from '@mui/material/NoSsr'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      extraSmall: {
        fontSize: 10,
      },
      small: {
        fontSize: 20,
      },
      medium: {
        fontSize: 30,
      },
      large: {
        fontSize: 40,
      },
    }),
  { name: 'MuiIconComponent' },
)

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = ({
  fontIconClass,
  size = 'small',
  fontIconColor,
  className,
  ...rest
}: IconProps): JSX.Element => {
  const classes = useStyles() as any

  return (
    <NoSsr>
      <i
        className={clsx('icon', fontIconClass, classes[size], className)}
        style={{ color: fontIconColor }}
        {...rest}
      />
    </NoSsr>
  )
}

export default Icon
