import {
  Alert,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import RoomIcon from '@mui/icons-material/Room'
import WorkIcon from '@mui/icons-material/Work'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { HeroShaped, SectionHeader } from '../Utils'
import Map from '../Utils/Map'

// const DEFAULT_CENTER = [54.67312661869364, 25.25888899999995];
// const DEFAULT_CENTER = [54.6778016, 25.3171581]

const Contact = ({
  data,
  className,
  rightSideContent = false,
  switchSides = false,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const mapMarkerLabel = t('map-marker-label')
  const phoneNumberContact = t('phone-number-contact')
  const emailContact = t('email-contact')
  const showroomAddress = t('showroom-address')
  const workingHours = t('working-hours')
  //----
  const contactInfoSubHeader = t('contact-info-subHeader')
  const contactInfoHeader = t('contact-info-header')
  const notWorkingText = t('holiday-not-working-text')

  const title = data.title ?? null
  const subtitle = data.subtitle ?? null
  const shopPhoneNumber = data.telephone ?? null
  const email = data.email ?? null
  const address = data.address ?? null
  const workingHoursContent = data.workingHours ?? null
  const alertContent = data.alert ?? null

  const isMapEnabled = data.enableMap ?? false
  const xCoodrinate = data.mapXCoordinate ?? 54.6784
  const yCoodrinate = data.mapYCoordinate ?? 25.2865

  const DEFAULT_CENTER = [xCoodrinate, yCoodrinate]

  return (
    <div className={className} {...rest} id="contact">
      <HeroShaped
        switchSides={switchSides}
        style={{ borderTop: '1px solid #f7f9fa' }}
        leftSide={
          <Container maxWidth="sm" style={{ padding: 0 }}>
            <SectionHeader
              title={title}
              subtitle={subtitle}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              data-aos="fade-up"
              align="left"
            />

            <List disablePadding>
              {shopPhoneNumber && (
                <ListItem disableGutters data-aos="fade-up">
                  <ListItemAvatar>
                    <PhoneIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={phoneNumberContact}
                    secondary={shopPhoneNumber}
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
              )}

              {email && (
                <ListItem disableGutters data-aos="fade-up">
                  <ListItemAvatar>
                    <EmailIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={emailContact}
                    secondary={email}
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
              )}
              {address && (
                <ListItem disableGutters data-aos="fade-up">
                  <ListItemAvatar>
                    <RoomIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={showroomAddress}
                    secondary={address}
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
              )}

              {workingHoursContent && (
                <ListItem disableGutters data-aos="fade-up">
                  <ListItemAvatar>
                    <WorkIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={workingHours}
                    secondary={
                      <>
                        {workingHoursContent.split('\n').map((line, i) => (
                          <Typography key={i} variant="subtitle1" component="p">
                            {line}
                          </Typography>
                        ))}
                      </>
                    }
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textSecondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'subtitle1',
                      color: 'textPrimary',
                    }}
                  />
                </ListItem>
              )}
            </List>
            {alertContent && <Alert severity="warning">{alertContent}</Alert>}

            {/* The Alert box below is used to display holiday not working days */}
            {/* <Alert severity="error">{notWorkingText}</Alert> */}
          </Container>
        }
        rightSide={
          <>
            {isMapEnabled && (
              <Map center={DEFAULT_CENTER} zoom={12}>
                {({ TileLayer, Marker, Popup }) => (
                  <>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={DEFAULT_CENTER}>
                      <Popup open>{mapMarkerLabel}</Popup>
                    </Marker>
                  </>
                )}
              </Map>
            )}
          </>
        }
      />
    </div>
  )
}

export default Contact
