import { useQuery } from '@apollo/client'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { imageFallback } from '../../../../../constants/Images'
import { useAppContext } from '../../../../../context/appContext'
import { GET_VARIANTS_DRAWER_PRODUCT } from '../../../../../operations/queries'
import {
  componentsDrawerStateVar,
  variantsDrawerStateVar,
} from '../../../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../../../utilityFunctions/discountValidityCheck'
import { dispatchInformationDate } from '../../../../../utilityFunctions/dispatchInformationDate'
import {
  getProfileFromLanguage,
  getProfileIndexFromLanguage,
} from '../../../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../../../utilityFunctions/priceUtils'
import DiscountBadge from '../../../Utils/DiscountBadge'
import FastDeliveryBadge from '../../../Utils/FastDeliveryBadge'
import NewProductBadge from '../../../Utils/NewProductBadge'
import { ProductPrice } from '../../../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      cardSelected: {
        position: 'relative',
        border: `5px solid ${theme.palette.secondary.main}`,
      },
      cardContent: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: "baseline",
        padding: theme.spacing(1, 1),
        // backgroundColor: theme.palette.background.default,
      },
      cardActionAreaContainer: {
        filter: 'brightness(0.97) invert(0.001)',
        backgroundColor: '#fefefe',
      },
      media: {
        height: '11rem',
        backgroundSize: 'fit',
        [theme.breakpoints.down('lg')]: {
          height: '13rem',
        },
        [theme.breakpoints.down('md')]: {
          height: '8rem',
        },
      },
      productName: {
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        // textAlign: "center",
      },
      color: {
        height: '16px',
        width: '16px',
        display: 'inline-block',
        borderRadius: '50%',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      selected: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
      },

      pricesContainer: {
        display: 'flex',
        // gap: theme.spacing(1),
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        marginRight: '10px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
      },
    }),
  { name: 'MuiAdditionalComponentsDrawerCardComponent' },
)

export default function AdditionalComponentDrawerCard({
  additionalComponent,
  selected = false,
  onClick,
}) {
  const classes = useStyles() as any
  const { t, i18n } = useTranslation(['common'])
  const { locale } = useAppContext()

  const componentProfile = getProfileFromLanguage(
    additionalComponent.additional_component_profiles,
    locale,
  )

  return (
    <Card
      elevation={0}
      square={true}
      className={selected ? classes.cardSelected : classes.card}
      onClick={() => {
        componentsDrawerStateVar({
          open: false,
          data: null,
          componentGroup: null,
        })
        onClick && onClick(additionalComponent)
      }}
    >
      <div className={classes.cardActionAreaContainer}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={additionalComponent.image?.src_xs ?? imageFallback}
          />
          {selected && (
            <CheckCircleIcon color="success" className={classes.selected} />
          )}
        </CardActionArea>
      </div>
      <CardContent className={classes.cardContent}>
        <span className="cursorPointer">
          <Typography
            gutterBottom
            variant="body1"
            component="h3"
            className={classes.productName}
          >
            {componentProfile.name}
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            className={classes.productName}
          >
            {componentProfile.description}
          </Typography>
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.pricesContainer}>
            <ProductPrice price={additionalComponent.extra_price} />
            {/* <Typography variant="subtitle1" component="p">
              {' '}
              {discountedPrice && <ProductPrice price={discountedPrice} />}
            </Typography> */}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
