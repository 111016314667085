import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import React from 'react'

const redColor = 'rgb(237,41,57)'
// const redColor = "#c1a578";
// const redColor = "#2b2b2b";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      wrapper: {
        color: '#ffffff',
        lineHeight: '2',
        backgroundColor: redColor,
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'block',
        marginTop: '10px',
        position: 'relative',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          padding: 1,
          marginTop: '3px',
          fontSize: '11px',
          lineHeight: '1.6',
        },

        '&::after': {
          content: '""',
          position: 'absolute',
          right: '-8px',
          bottom: '0',
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 8px 12px 0',
          borderColor: `transparent transparent ${redColor} transparent`,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          right: '-8px',
          top: ' 0',
          width: ' 0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '12px 8px 0 0',
          borderColor: `${redColor} transparent transparent transparent`,
        },
      },

      wrapperLeft: {
        color: '#ffffff',
        lineHeight: '2',
        backgroundColor: redColor,
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'block',
        marginTop: '10px',
        position: 'relative',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          padding: 1,
          marginTop: '3px',
          fontSize: '11px',
          lineHeight: '1.6',
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          left: '-8px',
          top: '0',
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 8px 12px 0',
          borderColor: `transparent ${redColor} transparent transparent`,
        },

        '&::after': {
          content: '""',
          position: 'absolute',
          left: '-8px',
          bottom: ' 0',
          width: ' 0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 0 12px 8px',
          borderColor: `transparent transparent ${redColor} transparent`,
        },
      },

      smallModify: {
        padding: 1,
        marginTop: '5px',
        fontSize: '12px',
        lineHeight: '1.6',
      },
    }),
  { name: 'MuiDiscountBadge' },
)

const DiscountBadge = ({ content, left = false, small = false }) => {
  const classes = useStyles() as any

  return (
    <div
      className={`${left ? classes.wrapperLeft : classes.wrapper}  ${
        small ? classes.smallModify : ''
      }  `}
    >
      <span>{content}</span>
    </div>
  )
}

export default DiscountBadge
