import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material'
import { Parallax, Section, SectionHeader, Image } from '../Utils'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 700,
      position: 'relative',
      background: 'white',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        height: 450,
      },
    },
    textWhite: {
      color: 'white',
    },
    title: {
      fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem',
      },
    },
    summary: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 10,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
        '-webkit-line-clamp': 5,
      },
    },
    section: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemAvatar: {
      marginRight: theme.spacing(2),
    },
    avatar: {
      height: 60,
      width: 60,
    },
    media: {
      filter: 'brightness(60%)',
      width: '100%',
      height: '100%',
      // objectFit: "contain",
      [theme.breakpoints.down('md')]: {
        minHeight: '100%',
      },
    },
    clipped: {
      clipPath: 'polygon(0 0, 100% 0, 100% 67%, 0% 100%)',
    },
  }),
  { name: 'MuiBlogPostHeroComponent' },
)

const BlogPostHero = ({
  className,
  image,
  title,
  subtitle,
  author,
  clipped = false,
  date,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const publishedByString = t('published-by')
  const classes = useStyles() as any
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Image
        className={clsx(classes.media, clipped && classes.clipped)}
        image={image}
      />
      <Section className={classes.section}>
        <>
          <SectionHeader
            title={title}
            subtitle={subtitle}
            align="left"
            data-aos="fade-up"
            titleProps={{
              className: clsx(classes.title, classes.textWhite),
              variant: 'h3',
            }}
            subtitleProps={{
              className: clsx(classes.summary, classes.textWhite),
            }}
          />
          <List disablePadding>
            <ListItem disableGutters>
              {author && (
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    alt={author.name}
                    className={classes.avatar}
                  >{`${author.name.charAt(0)}${author.surname.charAt(
                    0,
                  )}`}</Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  author
                    ? `${publishedByString} ${author.name} ${author.surname}`
                    : ''
                }
                secondary={`${date}`}
                primaryTypographyProps={{
                  className: classes.textWhite,
                  variant: 'subtitle1',
                }}
                secondaryTypographyProps={{
                  className: classes.textWhite,
                  variant: 'subtitle1',
                }}
              />
            </ListItem>
          </List>
        </>
      </Section>
    </div>
  )
}

export default BlogPostHero
