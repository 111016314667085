// import { cache } from "../../../store/cache";
import { getCookie } from '../../../../lib/getUserIdFromCookie'
import { wishListItemSaveMutation } from '../../../../lib/userSessionTracking'
import { WishlistItem } from '../../../store/models'
import { wishlistItemsVar } from '../../../store/reactiveVars'
import createWishlistItem from '../../../utilityFunctions/createWishlistItem'

//! ADD TO WISHLIST wishlistItemS
export const addToWishlist = (constructor) => {
  const newWishlistItem: WishlistItem = createWishlistItem(
    constructor.id,
    constructor.name,
    constructor.price,
    constructor.sku,
    constructor.image,
    constructor.permalink,
    constructor.product_type,
    constructor.dispatchInformation,
    constructor.discount,
  )

  //* =================================================================================
  //* get the wishlistItems from local storage, if there's none, wishlistItems will be === null
  //* =================================================================================
  const wishlistItems: WishlistItem[] = JSON.parse(
    localStorage.getItem('wishlistItems'),
  )

  const userCookie = getCookie('userSessionId')
  wishListItemSaveMutation(userCookie, newWishlistItem)

  if (wishlistItems) {
    let isSame = false

    //* ====================================================================================================================
    //* - If wishlistItems already has items, we loop through each item and check if its ID is equal to the ID of the incomig item.
    //* - If it is we add +1 to quantity and set local storage and the wishlistItemsVar to the updated wishlistItems array.
    //* - else if wishlistItems is null, we just set localStorage and the wishlistItemsVar to an array with the newWishlistItem only.
    //* ====================================================================================================================
    wishlistItems.forEach((wishlistItem, i) => {
      if (wishlistItem.id === newWishlistItem.id) {
        isSame = true

        return
      }
    })

    !isSame && wishlistItems.push(newWishlistItem)

    localStorage.setItem('wishlistItems', JSON.stringify([...wishlistItems]))
    wishlistItemsVar([...wishlistItems])
  } else {
    localStorage.setItem('wishlistItems', JSON.stringify([newWishlistItem]))
    wishlistItemsVar([newWishlistItem])
  }
}

//! REMOVE FROM WISHLIST
export const removeFromWishlist = (newWishlistItemID) => {
  const wishlistItems: WishlistItem[] = JSON.parse(
    localStorage.getItem('wishlistItems'),
  )

  let removedWishlistItem
  if (wishlistItems) {
    wishlistItems.forEach((wishlistItem, i) => {
      if (wishlistItem.id === newWishlistItemID) {
        wishlistItems.splice(i, 1)
        removedWishlistItem = wishlistItem
      }
    })
  } else {
    return
  }

  if (removedWishlistItem) {
    const userCookie = getCookie('userSessionId')
    wishListItemSaveMutation(userCookie, removedWishlistItem, true)
  }

  localStorage.setItem('wishlistItems', JSON.stringify([...wishlistItems]))
  wishlistItemsVar([...wishlistItems])
}
