import { gql } from '@apollo/client'

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER(
    $paymentMethodID: Int
    $phoneNumber: String!
    $shipping_country: String!
    $billing_country: String!
    $shipping_address_1: String!
    $billing_address_1: String!
    $shipping_address_2: String!
    $billing_address_2: String!
    $shipping_city: String!
    $billing_city: String!
    $shipping_postal_code: String!
    $billing_postal_code: String!
    $discount_applied: Float!
    $sub_total_price: Float!
    $total_shipping_price: Float!
    $total_price_without_VAT: Float!
    $total_price: Float!
    $email: String!
    $name: String
    $surname: String
    $order_items: [OrderItemCustomInput!]!
    $company_name: String
    $company_code: String
    $vat_code: String
    $buying_as_company: Boolean
    $zero_vat: Boolean
    $coupon_code: String
    $order_locale: Language!
    $shopId: Int
    $hostname: String!
    $price_multiplier: Int!
    $b2b_customer_discount: Int
  ) {
    createNewOrder(
      hostname: $hostname
      shopId: { id: $shopId }
      order_locale: $order_locale
      discount_applied: $discount_applied
      sub_total_price: $sub_total_price
      total_shipping_price: $total_shipping_price
      total_price_without_VAT: $total_price_without_VAT
      total_price: $total_price
      zero_vat: $zero_vat
      company_name: $company_name
      company_code: $company_code
      vat_code: $vat_code
      payment_method: { id: $paymentMethodID }
      shipping_address: {
          phone_number: $phoneNumber
          country: $shipping_country
          address_1: $shipping_address_1
          address_2: $shipping_address_2
          city: $shipping_city
          postal_code: $shipping_postal_code
      }
      billing_address: {
          phone_number: $phoneNumber
          country: $billing_country
          address_1: $billing_address_1
          address_2: $billing_address_2
          city: $billing_city
          postal_code: $billing_postal_code
        
      }
      purchased_by: {
        where: { email: $email }
        create: {
          name: $name
          surname: $surname
          email: $email
          default_phone_number: $phoneNumber
          buying_as_company: $buying_as_company
        }
      }
      coupon: { coupon_code: $coupon_code }
      order_items: $order_items

      price_multiplier: $price_multiplier
      b2b_customer_discount: $b2b_customer_discount
    ) {
      id
      project_id
    }
  }
`
