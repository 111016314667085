import { Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { SectionHeader } from '../Utils'

const WhoWeAre = ({
  block3Text,
  block3Title,
  block4Text,
  block4Title,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  // const { t } = useTranslation(['common'])
  // const paragraphTwoTitle = t('paragraph-two-title')
  // const paragraphTwoContent = t('paragraph-two-content')
  // const paragraphThreeTitle = t('paragraph-three-title')
  // const paragraphThreeContent = t('paragraph-three-content')

  const paragraphTwoTitle = block3Title
  const paragraphTwoContent = block3Text
  const paragraphThreeTitle = block4Title
  const paragraphThreeContent = block4Text

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title={paragraphTwoTitle}
            subtitle={paragraphTwoContent}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'subtitle2',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title={paragraphThreeTitle}
            subtitle={paragraphThreeContent}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'subtitle2',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default WhoWeAre
