import { CardMedia, Container, Paper, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { CustomSlider } from '.'
import { Media } from '../../../../lib/artsyFresnel/Media'
import CategoryProductCard from '../CategoryPageElements/CategoryProductCard'
import DynamicCategoryProductCard from '../CategoryPageElements/DynamicCategoryProductCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        marginTop: theme.spacing(15),
        height: 700,
        display: 'flex',
      },

      mobileTextBox: {
        marginBottom: theme.spacing(2),
        padding: '0 !important',
      },

      mobileMedia: {
        // position: "absolute",
        width: '100%',
        height: '200px',
      },

      leftSideMedia: {
        width: '45%',
        clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0% 100%)',
        [theme.breakpoints.down(1285)]: {
          width: '35%',
        },
      },
      rightSideMedia: {
        width: '45%',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)',
        [theme.breakpoints.down(1285)]: {
          width: '35%',
        },
      },
      rightSide: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '55%',
        height: '100%',
        [theme.breakpoints.down(1285)]: {
          width: '65%',
        },
      },
      customSliderContainer: {
        width: '100%',
        height: '60%',
      },
      customSlider: {
        overflowX: 'clip',
        overflowY: 'visible',
        height: '100%',
      },
      paper: {
        background: theme.palette.background.default,
        paddingRight: theme.spacing(5),
      },
      translateTextSection: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '130%',
        transform: 'translate(-20%, 10%)',
        padding: theme.spacing(3.5),
        minWidth: '900px',
        maxWidth: '1200px',
        maxHeight: '250px',
        minHeight: '200px',
        background: `rgba(251, 251, 251, 0.95)`,
        [theme.breakpoints.down(1285)]: {
          minWidth: '600px',
          maxWidth: '900px',
          minHeight: '150px',
          maxHeight: '250px',
        },
      },
      translateTextSectionRight: {
        position: 'absolute',
        top: 0,
        left: 0,
        // width: "130%",
        // transform: "translate(-30%, 10%)",
        padding: theme.spacing(3.5),
        minWidth: '900px',
        maxWidth: '1200px',
        maxHeight: '250px',
        minHeight: '200px',
        background: `rgba(251, 251, 251, 0.95)`,
        [theme.breakpoints.down(1285)]: {
          minWidth: '600px',
          maxWidth: '900px',
          minHeight: '150px',
          maxHeight: '250px',
        },
        // "&::after": {
        //   content: '""',
        //   zIndex: -1,
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        //   display: "inline-block",
        //   width: "2000px",
        //   background: `rgba(251, 251, 251, 0.95)`,
        // },
      },
      description: {
        color: 'rgb(68 67 67)',
        fontWeight: 300,
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 15,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
      divider: {
        marginBottom: theme.spacing(3),
      },

      viewAllLink: {
        // width: 'fit-content',
        display: 'inline-block',
        color: '#333',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.5',
        },
      },
      allText: {
        // fontStyle: "italic",
        textDecoration: 'underline',
      },
    }),
  { name: 'MuiCustomSectionComponent' },
)

interface CustomSectionProps {
  alignImage: 'left' | 'right'
  locale: string
  product_containers: Record<string, any>[]
  sectionName: string
  sectionDescription: string
  sectionImage: string
  dynamic?: boolean
  permalink?: string | null
  slug?: string | null
}

function CustomSection({
  alignImage,
  locale,
  product_containers,
  sectionName,
  sectionDescription,
  sectionImage,
  dynamic = false,
  permalink = null,
  slug = null,
}: CustomSectionProps) {
  const classes = useStyles() as any

  // Randomize the start of the carousel...
  let randomNumber = Math.floor(Math.random() * product_containers.length - 15)
  if (randomNumber < 0) {
    randomNumber = 0
  }
  // product_position_in_store
  const productContainerSorted = product_containers
    .slice()
    .sort((a, b) =>
      a.product_position_in_store > b.product_position_in_store
        ? 1
        : b.product_position_in_store > a.product_position_in_store
        ? -1
        : 0,
    )
    .slice(randomNumber, product_containers.length) // Apply some randomness.
    .slice(0, 15) // Get only 15 elements

  return (
    <>
      <Media greaterThan="md">
        <DesktopComponent
          classes={classes}
          productContainerSorted={productContainerSorted}
          alignImage={alignImage}
          locale={locale}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          sectionImage={sectionImage}
          dynamic={dynamic}
          permalink={permalink}
          slug={slug}
        />
      </Media>
      <Media lessThan="md">
        {/* <MobileComponent
          classes={classes}
          productContainerSorted={productContainerSorted}
          alignImage={alignImage}
          locale={locale}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          sectionImage={sectionImage}
          dynamic={dynamic}
          permalink={permalink}
          slug={slug}
        /> */}
      </Media>
    </>
  )
}

const DesktopComponent = ({
  classes,
  productContainerSorted,
  alignImage,
  locale,
  sectionName,
  sectionDescription,
  sectionImage,
  dynamic,
  permalink,
  slug,
}) => {
  const { t, i18n } = useTranslation(['common'])
  const allText = t('all')
  // const viewAllText = t('similar-articles-cta-button')

  // console.log('permalink :>> ', permalink)
  return (
    <Container maxWidth="xl" className={classes.container}>
      {alignImage === 'left' && (
        <CardMedia
          className={classes.leftSideMedia}
          image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
        />
      )}
      <div className={classes.rightSide}>
        <Paper
          elevation={0}
          className={clsx(
            alignImage === 'left' && classes.translateTextSection,
            alignImage === 'right' && classes.translateTextSectionRight,
            classes.paper,
          )}
        >
          {/* {alignImage === "right" && <Divider className={classes.divider} />} */}
          <Typography variant="h3">{sectionName}</Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.description}
          >
            {sectionDescription}
          </Typography>

          {permalink && (
            <Link prefetch={false} href={`/${slug}/${permalink}/1`} key={1000}>
              <a className={classes.viewAllLink}>
                <Typography
                  variant="body1"
                  className={classes.allText}
                >{`${allText} ${sectionName}`}</Typography>
              </a>
            </Link>
          )}
        </Paper>
        {/* Do not remove this empty div */}
        <div></div>
        <div className={classes.customSliderContainer}>
          <CustomSlider
            swiperProps={{
              spaceBetween: 15,
              slidesPerView: 1.4,
              tag: 'section',
              wrapperTag: 'ul',
              navigation: true,
              longSwipes: true,
              resistance: false,
              // autoplay: { delay: 5000, disableOnInteraction: true },
              autoplay: false,
              loop: false,
              touchStartPreventDefault: false,
              breakpoints: {
                // when window width is >= 600px
                600: {
                  slidesPerView: 2.5,
                },
                1175: {
                  slidesPerView: 2.5,
                  spaceBetween: 15,
                  longSwipes: false,
                  slidesPerGroup: 2,
                  pagination: {
                    clickable: true,
                  },
                },
              },
            }}
            className={classes.customSlider}
          >
            {productContainerSorted.map((product_container) => {
              return (
                <SwiperSlide key={product_container.id} tag="li">
                  {dynamic ? (
                    <DynamicCategoryProductCard
                      productContainerID={product_container.id}
                      inCarousel
                    />
                  ) : (
                    <CategoryProductCard
                      productContainer={product_container}
                      locale={locale}
                      inCarousel
                    />
                  )}
                </SwiperSlide>
              )
            })}
          </CustomSlider>
        </div>
      </div>
      {alignImage === 'right' && (
        <CardMedia
          className={classes.rightSideMedia}
          image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
        />
      )}
    </Container>
  )
}

const MobileComponent = ({
  classes,
  productContainerSorted,
  alignImage,
  locale,
  sectionName,
  sectionDescription,
  sectionImage,
  dynamic,
  permalink,
  slug,
}) => {
  const { t, i18n } = useTranslation(['common'])
  const allText = t('all')
  const viewAllText = t('similar-articles-cta-button')

  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <div>
        <CardMedia
          className={classes.mobileMedia}
          image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
        />

        <Container maxWidth="md">
          <Paper
            elevation={0}
            className={clsx(classes.mobileTextBox, classes.paper)}
          >
            <Typography
              variant="h3"
              style={{ margin: '20px 0 10px 0', fontSize: 20 }}
            >
              {sectionName}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.description}
            >
              {sectionDescription}
            </Typography>
            {permalink && (
              <Link
                prefetch={false}
                href={`/${slug}/${permalink}/1`}
                key={1000}
              >
                <a
                  className={classes.viewAllLink}
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <Typography
                    variant="body1"
                    className={classes.allText}
                  >{`${allText} ${sectionName}`}</Typography>
                </a>
              </Link>
            )}
          </Paper>

          <div>
            <CustomSlider
              swiperProps={{
                spaceBetween: 15,
                slidesPerView: 1.4,
                tag: 'section',
                wrapperTag: 'ul',
                navigation: true,
                longSwipes: true,
                resistance: false,
                // autoplay: { delay: 5000, disableOnInteraction: true },
                autoplay: false,
                loop: false,
                touchStartPreventDefault: false,
                breakpoints: {
                  // when window width is >= 600px
                  600: {
                    slidesPerView: 2.5,
                  },
                  1175: {
                    slidesPerView: 2.5,
                    spaceBetween: 15,
                    longSwipes: false,
                    slidesPerGroup: 2,
                    pagination: {
                      clickable: true,
                    },
                  },
                },
              }}
              className={classes.customSlider}
            >
              {productContainerSorted.map((product_container) => {
                return (
                  <SwiperSlide key={product_container.id} tag="li">
                    {dynamic ? (
                      <DynamicCategoryProductCard
                        productContainerID={product_container.id}
                        inCarousel
                      />
                    ) : (
                      <CategoryProductCard
                        productContainer={product_container}
                        locale={locale}
                        inCarousel
                      />
                    )}
                  </SwiperSlide>
                )
              })}
            </CustomSlider>
          </div>
        </Container>
      </div>
    </Container>
  )
}

export default CustomSection
