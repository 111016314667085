import React, { useState, useEffect } from 'react'
import {
  Theme,
  Typography,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  calculatePriceWithoutVat,
  calculateVatAmount,
  priceFormatter,
  subtotalCartPrice,
} from '../../../utilityFunctions/priceUtils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useQuery, useReactiveVar } from '@apollo/client'
import {
  orderVar,
  cartItemsVar,
  shopSettingsVar,
} from '../../../store/reactiveVars'
import { GET_SHOPSETTINGS_PAYMENT_METHODS } from '../../../operations/queries'
import { useShopContext } from '../../../context'
import ProductSideBoxItem from '../../DesktopElements/CheckoutElements/ProductSideBoxItem'
import getAndUpdateCheckoutPrices from '../../../utilityFunctions/getAndUpdateCheckoutPrices'
import { useTranslation } from 'next-i18next'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) => ({
    media: {
      height: '3rem',
      backgroundSize: 'contain',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    accordion: {
      marginTop: theme.spacing(3),
      border: 0,
      backgroundColor: theme.palette.background.paper,
    },
    accordionSummary: {
      padding: 0,
      '& .MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    productBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr 1fr',
      gap: theme.spacing(0.5),
      margin: '2rem 0',
    },
    boxFooter: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
      paddingTop: '0.5rem',
    },
  }),
  { name: 'MuiMobileOrderSummaryComponent' },
)

const MobileOrderSummary = () => {
  const { t } = useTranslation(['common'])
  const productsSideBoxHeader = t('products-side-box-header')
  const productSideBoxSubtotal = t('product-side-box-subtotal')
  const productSideBoxDeliveryFee = t('product-side-box-delivery-fee')
  const discountString = t('discount')
  const productSideBoxTotalWithoutVat = t('product-side-box-total-without-vat')
  const productSideBoxTotal = t('product-side-box-total')
  const VATString = t('VAT')
  const classes = useStyles() as any
  const order = useReactiveVar(orderVar)
  const cartItems = useReactiveVar(cartItemsVar)
  const shopSettings = useReactiveVar(shopSettingsVar)

  useEffect(() => {
    getAndUpdateCheckoutPrices(shopSettings, cartItems, order, orderVar)

    return
  }, [
    order.buying_as_company,
    order.is_lithuanian_company,
    order.total_shipping_price,
    shopSettings,
  ])

  return (
    <>
      <Accordion elevation={0} className={classes.accordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummary}
        >
          <Typography variant="body2" component="h2">
            {productsSideBoxHeader}
          </Typography>
          {/* <Typography align="right" variant="body2" component="p">
                        {priceFormatter(subtotalCartPrice(cartItems))}
                      </Typography> */}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.boxFooter}>
            <Typography variant="body2" component="p" gutterBottom>
              {productSideBoxSubtotal}
            </Typography>
            <Typography
              align="right"
              variant="body2"
              component="p"
              gutterBottom
            >
              <ProductPrice price={order.sub_total_price} />
            </Typography>
          </div>

          <div className={classes.boxFooter}>
            <Typography variant="body1" component="p" gutterBottom>
              {`${VATString}(${shopSettings.vat_percentage}%)`}
            </Typography>
            <Typography
              align="right"
              variant="body1"
              component="p"
              gutterBottom
            >
              <ProductPrice
                price={calculateVatAmount(
                  order.sub_total_price,
                  shopSettings.vat_percentage,
                )}
              />
            </Typography>
          </div>

          <div className={classes.boxFooter}>
            <Typography variant="body1" gutterBottom>
              {productSideBoxDeliveryFee}
            </Typography>
            {order.total_shipping_price === 0 || order.total_shipping_price ? (
              <Typography align="right" variant="body1" gutterBottom>
                {priceFormatter(order.total_shipping_price)}
              </Typography>
            ) : (
              <Typography align="right" variant="body1" gutterBottom>
                -
              </Typography>
            )}
          </div>

          {order.discount_applied && order.coupon ? (
            <div className={classes.boxFooter}>
              <Typography variant="body1" gutterBottom>
                {`${discountString}("${order.coupon.coupon_code}")`}
              </Typography>
              <Typography align="right" variant="body1" gutterBottom>
                {/* {`-${priceFormatter(order.discount_applied)}`} */}
                {'-'}
                <ProductPrice price={order.discount_applied} />
              </Typography>
            </div>
          ) : null}

          <div className={classes.boxFooter}>
            <Typography variant="body1" gutterBottom>
              {`${productSideBoxTotalWithoutVat}(${shopSettings.vat_percentage}%)`}
            </Typography>
            <Typography
              align="right"
              variant="body1"
              component="p"
              gutterBottom
            >
              <ProductPrice price={order.total_price_without_VAT} />
            </Typography>
          </div>

          <div className={classes.boxFooter}>
            <Typography variant="body2" component="p" gutterBottom>
              {productSideBoxTotal}
            </Typography>
            <Typography
              align="right"
              variant="body2"
              component="p"
              gutterBottom
            >
              <ProductPrice price={order.total_price} />
            </Typography>
          </div>
          {cartItems.map((cartItem) => {
            return (
              <ProductSideBoxItem
                key={cartItem.id}
                cartItem={cartItem}
                mobile
              />
            )
          })}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default MobileOrderSummary
