import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { useBlogPostContext } from '../../../context/blogPostContext'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { BlogPostCard } from '../BlogPageElements'
import { DescriptionCta, Image } from '../Utils'

const useStyles = makeStyles(
  (theme) => ({
    cardProduct: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: theme.spacing(1),
      '& .card-product__content': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    summary: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    image: {
      objectFit: 'cover',
      borderRadius: theme.spacing(0, 0, 20, 0),
    },
    blogTitle: {
      fontWeight: 700,
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    tag: {
      fontWeight: 700,
      margin: theme.spacing(0, 1, 1, 0),
    },
    author: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    title: {
      fontWeight: 'bold',
    },
    descriptionCta: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
      },
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    blogContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    leftFooterContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    readMoreButton: {
      minWidth: '5rem',
      maxWidth: '7rem',
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'MuiSimilarStoriesComponent' },
)

const SimilarStories = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { locale } = useBlogPostContext()
  const { t } = useTranslation(['common'])
  const blogSlug = t('blog-slug')
  const similarArticlesHeader = t('similar-articles-header')
  const similarArticlesCtaButton = t('similar-articles-cta-button')
  const readMoreCtaButton = t('blog-post-read-more-cta-button')
  const classes = useStyles() as any

  const BlogMediaContent = (props: ImageProps) => (
    <Image
      {...props}
      className={classes.image}
      lazyProps={{ width: '100%', height: '100%' }}
    />
  )

  const BlogContent = (props: any) => (
    <div className={classes.blogContent}>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        {props.title}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.summary}
      >
        {props.summary}
      </Typography>
      <div style={{ flexGrow: 1 }} />
      <Divider className={classes.divider} />
      <div className={classes.list}>
        <div className={classes.leftFooterContent}>
          {props.author ? (
            // Todo: HERE FALLBACK TO USER IMAGE??
            <Avatar className={classes.avatar}>{`${props.author.name.charAt(
              0,
            )}${props.author.surname.charAt(0)}`}</Avatar>
          ) : null}
          <div>
            {props.author && (
              <Typography variant="body2" color="textPrimary">
                {`${props.author.name} ${props.author.surname}`}
              </Typography>
            )}
            <Typography variant="overline" color="textSecondary">
              {props.date}
            </Typography>
          </div>
        </div>
        <Link prefetch={false} href={`/${blogSlug}/${props.permalink}`}>
          <Button variant="contained" className={classes.readMoreButton}>
            {readMoreCtaButton}
          </Button>
        </Link>
      </div>
    </div>
  )

  return (
    <Container maxWidth="lg">
      <div className={className} {...rest}>
        <DescriptionCta
          title={similarArticlesHeader}
          primaryCta={
            <Link prefetch={false} href={`/${blogSlug}`}>
              <Button variant="outlined" color="primary" size="large">
                {similarArticlesCtaButton}
              </Button>
            </Link>
          }
          align={'left'}
          titleProps={{
            variant: 'h4',
            color: 'textPrimary',
            className: classes.title,
          }}
          className={classes.descriptionCta}
          data-aos="fade-up"
        />
        <Grid container spacing={3}>
          {data.data.findManyBlogPost.map((post: any, index: number) => {
            const blogPostProfileIndex = getProfileIndexFromLanguage(
              post.blog_post_profiles,
              locale,
            )

            const blogPostSimilar =
              post.blog_post_profiles[blogPostProfileIndex]
            if (blogPostSimilar) {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  key={index}
                  data-aos="fade-up"
                >
                  <BlogPostCard
                    withShadow
                    liftUp
                    className={classes.cardProduct}
                    mediaContent={
                      <BlogMediaContent image={post.Image} alt={post.title} />
                    }
                    cardContent={
                      <BlogContent
                        title={
                          post.blog_post_profiles[blogPostProfileIndex]?.title
                        }
                        summary={
                          post.blog_post_profiles[blogPostProfileIndex]?.summary
                        }
                        permalink={
                          post.blog_post_profiles[blogPostProfileIndex]
                            ?.meta_information.permalink
                        }
                        author={post.author}
                        date={moment
                          .utc(post.createdAt)
                          .local()
                          .format('YYYY-MM-DD')}
                      />
                    }
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
    </Container>
  )
}

export default SimilarStories
