import { useForm } from '@formspree/react'
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { SectionHeader } from '.'
import { useMutation } from '@apollo/client'
import { SEND_EMAIL_FORM } from '../../../operations/mutations'

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 550,
    margin: `0 auto`,
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },

  alertBox: {
    margin: '30px',
  },
}))

const Form = ({
  className,
  locale,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  const contactFormHeader = t('contact-form-header')
  const contactFormSubHeader = t('contact-form-subHeader')
  const firstInputLabel = t('first-input-label')
  const firstInputPlaceholder = t('first-input-placeholder')
  const secondInputLabel = t('second-input-label')
  const secondInputPlaceholder = t('second-input-placeholder')
  const thirdInputLabel = t('third-input-label')
  const thirdInputPlaceholder = t('third-input-placeholder')
  const submitFormButton = t('submit-form-button')
  const formSuccess = t('success')

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const classes = useStyles() as any
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  // !DEPRECATED we do not use mayarlq anymore
  // const [state, handleSubmit] = useForm('mayarlqe')

  const [sendEmailForm, { data, loading, called }] = useMutation(
    SEND_EMAIL_FORM,
    {
      onCompleted: (data) => {
        // -----
        setSuccess(true)
        setError(null)
      },
      onError: (error) => {
        //----
        console.log('error ---> ', error)
        setSuccess(false)
        setError(error)
      },
    },
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log('Form event ->', e)
    const form = e.target
    let name
    let email
    let message
    for (const input of form) {
      if (input.name === 'name') {
        name = input.value
      }
      if (input.name === 'email') {
        email = input.value
      }
      if (input.name === 'message') {
        message = input.value
      }
    }
    const objectToSend = {
      name,
      email,
      message,
    }

    // console.log('objectToSend :>> ', objectToSend)
    const stingifiedObject = JSON.stringify(objectToSend)
    sendEmailForm({
      variables: {
        stingifiedObject,
        language: locale,
      },
    })

    // const name = event.target.elements.username.value
  }

  return (
    <div className={className} {...rest}>
      <form onSubmit={handleSubmit}>
        <SectionHeader
          title={contactFormHeader}
          subtitle={contactFormSubHeader}
          subtitleProps={{
            variant: 'subtitle2',
            color: 'textPrimary',
          }}
          data-aos="fade-up"
          align={isMd ? 'center' : 'left'}
        />
        <div className={classes.form}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} data-aos="fade-up">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                {firstInputLabel}
              </Typography>
              <TextField
                placeholder={firstInputPlaceholder}
                variant="outlined"
                size="medium"
                fullWidth
                type="text"
                id="name"
                name="name"
              />
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                {secondInputLabel}
              </Typography>
              <TextField
                placeholder={secondInputPlaceholder}
                variant="outlined"
                size="medium"
                fullWidth
                type="email"
                id="email"
                name="email"
              />
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                {thirdInputLabel}
              </Typography>
              <TextField
                placeholder={thirdInputPlaceholder}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                id="message"
                name="message"
              />
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="primary"
                size="large"
              >
                {submitFormButton}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.alertBox}>
            {success && (
              <Alert severity="success" style={{ width: '100%' }}>
                {formSuccess}!
              </Alert>
            )}

            {error && (
              <Alert severity="error" style={{ width: '100%' }}>
                {error}!
              </Alert>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Form
