import { useReactiveVar } from '@apollo/client'
import { Button, Divider, InputBase, InputLabel, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { v5 as uuidv5 } from 'uuid'
import { useShopContext } from '../../../context'
import { AddressFormData } from '../../../store/models'
import { orderVar } from '../../../store/reactiveVars'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      form: {
        marginTop: theme.spacing(2),
      },
      pageTitle: {
        fontSize: 32,
        fontWeight: 500,
      },
      gridContainer: {
        margin: theme.spacing(2, 0, 1, 0),
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        [theme.breakpoints.down('lg')]: {
          maxWidth: '100%',
        },
      },
      input: {
        border: '1px solid #d3d3d3',
        padding: theme.spacing(1.3, 1),
      },
      inputLabel: {
        marginBottom: theme.spacing(0.5),
      },
      radioGroup: {
        display: 'flex',
        flexDirection: 'row',
      },
      buttonsContainer: {
        display: 'flex',
        marginTop: theme.spacing(3),
        justifyContent: 'center',
        gap: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
          justifyContent: 'space-between',
        },
      },
      button: {
        width: '41.5%',
        [theme.breakpoints.down('lg')]: {
          width: '45%',
        },
      },
      error: {
        color: '#d9534f',
      },
    }),
  { name: 'MuiBillingAddressFormComponent' },
)

export default function BillingAddressForm({ changingBillingAddressState }) {
  const { t } = useTranslation(['common'])
  const fieldRequiredError = t('field-is-required')
  const billingAddressFormHeader = t('billing-address-form-header')
  const billingAddressInputLabel = t('billing-address-input-label')
  const billingAddress2InputLabel = t('billing-address2-input-label')
  const billingCityInputLabel = t('billing-city-input-label')
  const billingStateInputLabel = t('billing-state-input-label')
  const billingZipInputLabel = t('billing-zip-input-label')
  const billingCountryInputLabel = t('billing-country-input-label')
  const confirmBillingAddressUpdateCta = t('confirm-billing-address-update-cta')
  const cancelBillingAddressUpdateCta = t('cancel-billing-address-update-cta')
  const classes = useStyles() as any
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm()
  const order = useReactiveVar(orderVar)
  const {
    changingBillingAddress,
    setChangingBillingAddress,
  } = changingBillingAddressState
  const address_1 = useRef(null)

  //* =================================================================
  //* ====================== HANDLE ONSUBMIT ==========================
  //* =================================================================
  const onSubmit = (data) => {
    orderVar({ ...order, ...data })

    //setting this to false will hide the billingAddressForm and allowa the user
    // to proceed with the order
    setChangingBillingAddress(false)
  }

  useEffect(() => {
    //it might be that on mobile the form will be below the fold
    //so let's make sure the user knows that this component rendered
    // so that they can cancel or change address and then complete order
    address_1.current.scrollIntoView()
    address_1.current.focus()
    return
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Typography variant="h5" component="h2" gutterBottom>
        {billingAddressFormHeader}
      </Typography>
      <Divider />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingAddressInputLabel}
              {errors.billing_address1 ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line1"
            inputRef={address_1}
            inputProps={{
              ...register('billing_address1', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'billing_address1' in order ? order.billing_address1 : ''
            }
          />
          {errors.billing_address1 && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.billing_address1.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingAddress2InputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-line2"
            inputProps={{
              ...register('billing_address2'),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'billing_address2' in order ? order.billing_address2 : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingCityInputLabel}
              {errors.billing_city ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping address-level2"
            inputProps={{
              ...register('billing_city', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'billing_city' in order ? order.billing_city : ''}
          />
          {errors.billing_city && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.billing_city.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingStateInputLabel}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            inputProps={{ ...register('billing_state') }}
            classes={{
              input: classes.input,
            }}
            defaultValue={'billing_state' in order ? order.billing_state : ''}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingZipInputLabel}
              {errors.billing_postal_code ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping postal-code"
            inputProps={{
              ...register('billing_postal_code', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'billing_postal_code' in order ? order.billing_postal_code : ''
            }
          />
          {errors.billing_postal_code && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.billing_postal_code.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel className={classes.inputLabel}>
            <Typography variant="body1" component="p">
              {billingCountryInputLabel}
              {errors.billing_country ? (
                <span className={classes.error}>*</span>
              ) : (
                `*`
              )}
            </Typography>
          </InputLabel>
          <InputBase
            fullWidth
            autoComplete="shipping country"
            inputProps={{
              ...register('billing_country', {
                required: fieldRequiredError,
              }),
            }}
            classes={{
              input: classes.input,
            }}
            defaultValue={
              'billing_country' in order ? order.billing_country : ''
            }
          />
          {errors.billing_country && (
            <Typography
              variant="caption"
              component="span"
              className={classes.error}
            >
              {errors.billing_country.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          onClick={() => setChangingBillingAddress(false)}
          className={classes.button}
        >
          {cancelBillingAddressUpdateCta}
        </Button>
        <Button variant="contained" type="submit" className={classes.button}>
          {confirmBillingAddressUpdateCta}
        </Button>
      </div>
    </form>
  )
}
