import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#7c7c7c',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const parseTableData = (data: any) => {
  const rows = data
    .split('\n')
    .map((r, i) => ({ id: i, data: r.split(';') }))
    .map((r) => {
      if (r.data[0].startsWith('H.')) {
        const colSpan = r.data[0].split(' ')[1]?.split(':')
        return {
          ...r,
          data: colSpan ? r.data.filter((c) => c !== '') : r.data, // if header has collSpan data, then remove all empty cells
          header: r.data[0],
          colSpan,
        }
      }
      return r
    })
    .map((row) => ({
      ...row,
      data: row.data.slice(1),
    }))

  const tableStartRowIndexes = rows
    .filter((r) => r.header?.startsWith('H.0'))
    .map((r) => r.id)

  // Splitting into multiple tables: first table line is "H.0"
  const result = tableStartRowIndexes.map((startIndex, index, array) => {
    const endIndex = array[index + 1] || rows.length
    return rows.slice(startIndex, endIndex)
  })
  return result
}

export default function PriceListTable({ data }) {
  const tableData = parseTableData(data)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        {tableData.map((t, k) => {
          return (
            <>
              <TableHead key={`th_${k}`}>
                {t
                  .filter((r) => r.header)
                  .map((row, rkey) => (
                    <TableRow key={rkey}>
                      {row.data.map((c, i) => (
                        <StyledTableCell
                          align="left"
                          key={`t${k}_h_${i}`}
                          colSpan={row?.colSpan?.[i] ?? 1}
                        >
                          {c}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableHead>
              <TableBody key={`tb_${k}`}>
                {t
                  .filter((r) => !r.header)
                  .map((row, key) => (
                    <StyledTableRow key={key}>
                      {row.data.map((c, i) => (
                        <StyledTableCell key={`r_${i}`} align="left">
                          {c}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </>
          )
        })}
      </Table>
    </TableContainer>
  )
}
