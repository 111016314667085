import { useReactiveVar } from '@apollo/client'
import { Badge, CardMedia, Container } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import { imageFallback } from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import {
  cartItemsVar,
  cartStateVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { SearchInput } from '../Utils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        background: 'white',
        position: 'relative',
        zIndex: 5,
      },
      root: {
        height: '4rem',
        padding: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      box: {
        width: '30%',
        height: '100%',
        backgroundSize: 'contain',
        cursor: 'pointer',
      },
      searchInputContainer: {
        width: '25%',
      },
      media: {
        minHeight: '45px',
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'left',
      },
      iconsContainer: {
        width: '20%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      icon: {
        marginLeft: '1.5rem',
      },
    }),
  { name: 'MuiTopHeaderComponent' },
)

export default function TopHeader() {
  const { t, i18n } = useTranslation(['common'])
  const aboutSlug = t('about-slug')
  const classes = useStyles() as any
  const cartItems = useReactiveVar(cartItemsVar)
  const wishlistItems = useReactiveVar(wishlistItemsVar)

  const appContext = useAppContext()
  const shopSettings = appContext ? appContext.shopSettings : null

  const siteLogoSrc = shopSettings
    ? shopSettings.default_manufacturer?.logo_image
      ? shopSettings.default_manufacturer.logo_image.src_xs ??
        shopSettings.default_manufacturer.logo_image.src
      : imageFallback
    : imageFallback

  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <div className={classes.root}>
          <div className={classes.box}>
            <Link prefetch={false} href="/">
              <CardMedia image={siteLogoSrc} className={classes.media} />
            </Link>
          </div>
          <div className={classes.searchInputContainer}>
            <SearchInput />
          </div>
          <div className={classes.iconsContainer}>
            {/* <span className="cursorPointer">
              <PersonIcon className={classes.icon} />
            </span> */}
            <span className="cursorPointer">
              <Badge
                variant="standard"
                badgeContent={wishlistItems.length}
                color="secondary"
                onClick={() => wishlistDrawerVar(true)}
              >
                <FavoriteBorderIcon className={classes.icon} />
              </Badge>
            </span>
            <span className="cursorPointer">
              <Badge
                variant="standard"
                badgeContent={cartItems.length}
                color="secondary"
                onClick={() => {
                  cartStateVar(true)
                }}
              >
                <ShoppingBasketOutlinedIcon className={classes.icon} />
              </Badge>
            </span>
          </div>
        </div>
      </Container>
    </>
  )
}
