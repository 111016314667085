import { useReactiveVar, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionSummary,
  Collapse,
  List,
  ListItem,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { expandedFilterVar } from '../../../../store/reactiveVars'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterWidthRange from './FilterWidthRange'
import FilterHeightRange from './FilterHeightRange'
import FilterLengthRange from './FilterLengthRange'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      filtersList: {
        display: 'flex',
        flexDirection: 'column',
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
      MuiAccordionroot: {
        '&:before': {
          backgroundColor: 'white',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      MuiAccordionSummaryRoot: {
        marginBottom: -1,
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
        },
      },
      MuiAccordionSummaryContent: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    }),
  { name: 'MuiDimansionsDrawerItemComponent' },
)

export default function DimansionsDrawerItem({
  permalink,
  dimensionsData,
  handleDrawerItemOpen,
}) {
  const { t, i18n } = useTranslation(['common'])
  const dimensionFilterString = t('filter-dimension')
  const classes = useStyles() as any
  const expandedFilter = useReactiveVar(expandedFilterVar)
  const {
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    minLength,
    maxLength,
    loading,
  } = dimensionsData

  return (
    <>
      <Accordion
        expanded={expandedFilter === dimensionFilterString}
        onChange={(e, isExpanded) =>
          handleDrawerItemOpen(dimensionFilterString)(e, isExpanded)
        }
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="DimensionsAccordion"
          id="DimensionsAccordion"
          classes={{
            root: classes.MuiAccordionSummaryRoot,
            content: classes.MuiAccordionSummaryContent,
          }}
        >
          <Typography component="h6" variant="body2">
            {dimensionFilterString}
          </Typography>
        </AccordionSummary>
        <List component="ul" disablePadding>
          {minWidth !== 0 && maxWidth !== 0 && (
            <ListItem component="li" className={classes.nested}>
              <FilterWidthRange
                min={minWidth}
                max={maxWidth}
                loading={loading}
              />
            </ListItem>
          )}
          {minHeight !== 0 && maxHeight !== 0 && (
            <ListItem component="li" className={classes.nested}>
              <FilterHeightRange
                min={minHeight}
                max={maxHeight}
                loading={loading}
              />
            </ListItem>
          )}
          {minLength !== 0 && maxLength !== 0 && (
            <ListItem component="li" className={classes.nested}>
              <FilterLengthRange
                min={minLength}
                max={maxLength}
                loading={loading}
              />
            </ListItem>
          )}
        </List>
      </Accordion>
    </>
  )
}
