import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100% !important',
      height: '100% !important',
      objectFit: 'cover',
    },
    dBlock: {
      display: 'block',
    },
    borderRadius: {
      borderRadius: theme.spacing(2),
    },
  }),
  { name: 'MuiImageComponent' },
)

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const Image = ({
  image,
  alt = '...',
  lazy = true,
  lazyProps = {
    width: 'auto',
    height: 'auto',
  },
  roundedCorners,
  className,
  ...rest
}: ImageProps): JSX.Element => {
  const [fallbackImage, setFallbackImage] = useState('')
  const classes = useStyles() as any

  useEffect(() => {
    const fallbackImages = [
      '/fallbacks/furnibay-article-cover.jpeg',
      '/fallbacks/thumbnail_01.jpg',
      '/fallbacks/thumbnail_02.jpg',
      '/fallbacks/thumbnail_03.jpg',
      '/fallbacks/thumbnail_05.jpg',
      '/fallbacks/thumbnail_06.png',
      '/fallbacks/thumbnail_07.png',
      '/fallbacks/thumbnail_08.png',
    ]

    setFallbackImage(
      fallbackImages[Math.floor(Math.random() * fallbackImages.length)],
    )

    return
  }, [])

  return (
    <img
      className={clsx(
        // "image",
        classes.root,
        className,
        roundedCorners ? classes.borderRadius : null,
      )}
      alt={alt}
      src={!image ? fallbackImage : image.src}
      srcSet={!image ? fallbackImage : image.src}
      loading="lazy"
      {...rest}
    />
  )
}

export default Image
