import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  incrementQuantity,
  decrementQuantity,
} from '../../../operations/clientMutations/cartClient'
import clsx from 'clsx'
import { QuantitySelectorProps } from '../../../../types/QuantitySelector'

const useStyles = makeStyles(
  (theme) => ({
    cartItem__quantitySelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: theme.spacing(12),
      height: theme.spacing(4.5),
      padding: theme.spacing(0.5, 1),
      border: '1px solid #d3d3d3',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '1rem',
    },
    remove: {
      textDecoration: 'underline',
    },
  }),
  { name: 'MuiQuantitySelectorComponent' },
)

const QuantitySelector = ({
  cartItem,
  client,
  className,
  productQuantity,
  followProductStock,
  ...rest
}: QuantitySelectorProps): JSX.Element => {
  const classes = useStyles() as any

  // console.log('cartItem in QuantitySelector :>> ', cartItem)

  const minimum_order_quantity = cartItem.minimum_order_quantity ?? null

  // console.log('followProductStock :>> ', followProductStock)
  // console.log('productQuantity :>> ', productQuantity)
  // console.log('minimum_order_quantity :>> ', minimum_order_quantity)

  return (
    <div className={clsx(classes.cartItem__quantitySelector, className)}>
      <RemoveIcon
        className={classes.icon}
        color={cartItem.quantity === 1 ? `disabled` : `inherit`}
        onClick={() =>
          decrementQuantity({ cartItemID: cartItem.id, minimum_order_quantity })
        }
      />
      <Typography variant="subtitle1">{cartItem.quantity}</Typography>
      <AddIcon
        className={classes.icon}
        onClick={() =>
          incrementQuantity({
            cartItemID: cartItem.id,
            followProductStock,
            productQuantity,
            minimum_order_quantity,
          })
        }
      />
    </div>
  )
}

export default QuantitySelector
