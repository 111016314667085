import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ClearIcon from '@mui/icons-material/Clear'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { addToCart } from '../../../operations/clientMutations/cartClient'
import { removeFromWishlist } from '../../../operations/clientMutations/wishlistClient'
import { GET_PRODUCT_IN_WISHLIST } from '../../../operations/queries/headerQueries'
import { WishlistItem } from '../../../store/models'
import { cartStateVar, wishlistItemsVar } from '../../../store/reactiveVars'
import {
  getProfileIndexFromLanguage,
  getProfileFromLanguage,
} from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useSnackbarContext } from '../Utils'
import { ProductPrice } from '../../UtilityComponents/price'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        // margin: 5,
        // [theme.breakpoints.down("sm")]: {
        //   margin: "1rem auto",
        // },
      },
      cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2, 0),
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
          alignItems: 'baseline',
        },
      },
      cardActionAreaContainer: {
        position: 'relative',
      },
      cardActions: {
        padding: 0,
      },
      media: {
        height: '28rem',
        backgroundSize: 'contain',
        [theme.breakpoints.between('xs', 649)]: {
          height: '14rem',
        },
        [theme.breakpoints.between(650, 'lg')]: {
          height: '20rem',
        },
        [theme.breakpoints.between('lg', 1500)]: {
          height: '23rem',
        },
      },
      productName: {
        [theme.breakpoints.down('lg')]: {
          [theme.breakpoints.down('lg')]: {
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          },
        },
      },
      removeIcon: {
        position: 'absolute',
        padding: theme.spacing(2),
        right: 0,
        top: 0,
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1),
        },
      },
      hide: {
        display: 'none',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('lg')]: {
          display: 'inline-block',
        },
      },
      addToCartButton: {
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(2, 2),
          '& .MuiButton-label': {
            // fontSize: "0.7rem",
          },
        },
      },
      skeleton: {},
    }),
  { name: 'MuiWhislistProductCardComponent' },
)

//TODO add bubbles and size to wishlist product card and the discounted price
//TODO CREATE AN EMPTY STATE COMPONENT AND DISPLAY IT WHEN THERE ARE NO ITEMS

export default function WishlistProductCard({ wishlistItem }) {
  const { t, i18n } = useTranslation(['common'])
  const nonCumulativeCouponWhenAddingProduct = t(
    'non-cumulative-coupon-when-adding-product',
  )
  const productSlug = t('product-slug')
  const classes = useStyles() as any
  const { locale } = useRouter()
  const { snackbar, setSnackbar } = useSnackbarContext()
  const [hover, setHover] = useState(false)
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)

  //* =================================================================================
  //* ==================  query to get latest cartItems info ==========================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_WISHLIST, {
    variables: {
      itemID: wishlistItem.id,
    },
    //On completed we will have the data and we want to update
    //all the static data in the reactive var and localstorage
    onCompleted: (data) => {
      localDataUpdate(
        data,
        wishlistItem,
        wishlistItems,
        wishlistItemsVar,
        localStorageKeys.wishlistItems,
        setDiscounted,
        setDiscountedPrice,
        locale,
      )
    },
    onError: (error) => {
      console.log('Error in wishlistItem.tsx')
    },
  })

  //TODO: make better UI for loading state
  if (loading) return <Skeleton height="10rem" className={classes.skeleton} />

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfile =
    getProfileFromLanguage(
      data.findUniqueProductContainer.single_product?.product_profiles,
      locale,
    ) ||
    getProfileFromLanguage(
      data.findUniqueProductContainer.advanced_product
        ?.advanced_product_profiles,
      locale,
    )

  const productType =
    data.findUniqueProductContainer.type === 'ADVANCED_PRODUCT'
      ? 'advanced_product'
      : 'single_product'

  if (productProfile) {
    const id = data.findUniqueProductContainer.id
    const permalink = productProfile.meta_information.permalink
    const image = data.findUniqueProductContainer[productType].images[0].src
    const name = productProfile.name
    const price = data.findUniqueProductContainer[productType].price ?? null
    const minimum_order_quantity =
      data.findUniqueProductContainer[productType]?.minimum_order_quantity ??
      null

    const isAdvancedProduct =
      data.findUniqueProductContainer.type === 'ADVANCED_PRODUCT'
    const actionButtonText = isAdvancedProduct
      ? t('configure-button')
      : t('add-to-cart-cta-button')

    return (
      <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
        <Card elevation={0} square={true} className={classes.root}>
          <div
            className={classes.cardActionAreaContainer}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <CardActionArea>
              <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
                <CardMedia
                  className={classes.media}
                  image={image}
                  title={name}
                />
              </Link>
            </CardActionArea>
            <div
              className={clsx(classes.removeIcon, !hover && classes.hide)}
              onClick={() => {
                removeFromWishlist(id)
              }}
            >
              <ClearIcon />
            </div>
          </div>
          <CardContent className={classes.cardContent}>
            <Link prefetch={false} href={`/${productSlug}/${permalink}`}>
              <Typography
                gutterBottom
                variant="body1"
                component="h2"
                className={classes.productName}
              >
                {name}
              </Typography>
            </Link>
            <Typography variant="body2">
              {' '}
              <ProductPrice price={price} />
            </Typography>
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {isAdvancedProduct ? (
              <Button
                href={`/${productSlug}/${permalink}`}
                variant="contained"
                color="primary"
              >
                {actionButtonText}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addToCart(
                    {
                      ...(wishlistItem as WishlistItem),
                      quantity: 1,
                      discount: data.findUniqueProductContainer.discount,
                      advanced_product_data: null,
                      product_container_id: data.findUniqueProductContainer.id,
                      minimum_order_quantity,
                    },
                    { snackbar, setSnackbar },
                    { nonCumulativeCouponWhenAddingProduct },
                  )
                  removeFromWishlist(id)
                  cartStateVar(true)
                }}
                className={classes.addToCartButton}
              >
                {actionButtonText}
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    )
  } else {
    return null
  }
}
