import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context/appContext'
import { addToCart } from '../../../operations/clientMutations/cartClient'
import {
  addToWishlist,
  removeFromWishlist,
} from '../../../operations/clientMutations/wishlistClient'
import { GET_PRODUCT_CONTAINER_FOR_REVALIDATION } from '../../../operations/queries'
import {
  cartStateVar,
  variantsDrawerStateVar,
  wishlistDrawerVar,
  wishlistItemsVar,
} from '../../../store/reactiveVars'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import {
  dispatchInformationDate,
  dispatchInformationDateExtended,
} from '../../../utilityFunctions/dispatchInformationDate'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { MobileDescriptionTab } from '../../ResponsiveElements/ResponsiveProductPageElements'
import { useSnackbarContext } from '../Utils'
import DeliveryInformationBox from '../Utils/DeliveryInformationBox'
import DiscountBadge from '../Utils/DiscountBadge'
import FastDeliveryBadge from '../Utils/FastDeliveryBadge'
import NewProductBadge from '../Utils/NewProductBadge'
import ReturnsInformationBox from '../Utils/ReturnsInformationBox'
import ShippingInformationBox from '../Utils/ShippingInformationBox'
import VariantsDrawer from './VariantsDrawer'
import { imageFallback } from '../../../constants/Images'
import CountryOfOriginBox from '../Utils/CountryOfOriginBox'
import { ProductPrice } from '../../UtilityComponents/price'
import { TrackingModuleButton } from '../Utils/TrackingModuleComponent'
import InfoLinkBox from '../Utils/InfoLinkBox'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      section: {
        padding: theme.spacing(3, 2),
        height: 'fit-content',
        position: 'sticky',
        top: '8.5rem',
        [theme.breakpoints.up(1440)]: {
          position: 'sticky',
          top: '8.5rem',
        },
        [theme.breakpoints.between(1175, 1440)]: {
          position: 'sticky',
          top: 'auto',
          bottom: 0,
          marginTop: 0,
        },
      },
      productNameContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(2),
        // alignItems: "center",
        // marginBottom: "1rem",
      },
      productName: {
        [theme.breakpoints.down('lg')]: {
          fontSize: '1.5rem',
        },
      },
      row: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        marginBottom: theme.spacing(4),
        backgroundColor: '#e7edf0',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(4),
        },
      },
      colorsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      colorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'fit-content',
        padding: theme.spacing(0.6, 0),
        cursor: 'pointer',
        marginRight: theme.spacing(1.5),
      },
      colorActive: {
        '&::after': {
          content: "''",
          display: 'block',
          width: '8px',
          height: '2px',
          margin: '4px auto 0px',
          backgroundColor: 'black',
          transition: 'background-color 0.3s ease 0s',
        },
      },
      color: {
        height: '20px',
        width: '20px',
        display: 'inline-block',
        borderRadius: '50%',
        transition: 'all 0.2s ease-in-out',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      },
      shortDescriptionContainer: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          marginBottom: theme.spacing(2),
        },
      },

      quantityContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
      },
      minimumQuantityContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: `-${theme.spacing(1)}`,
        marginBottom: theme.spacing(1),
      },

      quantityText: {
        // color: theme.palette.secondary.light,
        color: '#f44336',
      },
      minimumOrderText: {
        // color: theme.palette.secondary.light,
        color: '#f44336',
        fontStyle: 'italic',
      },

      priceContainer: {
        display: 'flex',
        marginBottom: '1rem',
      },

      drawer: {
        width: '450px',
      },
      topDrawerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
      },
      accordion: {
        marginBottom: theme.spacing(2),
        border: 0,
        backgroundColor: theme.palette.background.default,
        '& .MuiAccordionSummary-root': {
          padding: 0,
        },
      },
      wishlistIcon: {
        justifySelf: 'end',
      },
      wishlistIconFilled: {
        justifySelf: 'end',
        color: 'rgb(241, 88, 86)',
      },
      addToCartCaption: {
        marginTop: theme.spacing(4),
        display: 'block',
        opacity: '0.7',
      },
      addToCartButtonContainer: {
        // [theme.breakpoints.down("md")]: {
        //   background: theme.palette.background.default,
        //   paddingBottom: "1rem",
        //   position: "sticky",
        //   bottom: 0,
        // },
      },
      mobileDimensionsAccordion: {
        marginBottom: theme.spacing(2),
        border: 0,
        backgroundColor: theme.palette.background.default,
        '& .MuiAccordionSummary-root': {
          padding: 0,
        },
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      mobileAccordionSummary: {
        display: 'flex',
        flexDirection: 'column',
      },
      chevronRight: {
        cursor: 'pointer',
      },
      rowContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      pricesContainer: {
        display: 'flex',
      },
      discoutedPrice: {
        textDecoration: 'line-through',
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginLeft: '10px',
      },

      badgeBox: {
        position: 'absolute',
        top: 0,
        left: '-78px',
        zIndex: 20,
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    }),
  { name: 'MuiProductPanelComponent' },
)

const colors = ['#f9c901', '#333', '#E49A53']

export default function ProductPanel({
  product,
  productPermalink,
  className = null,
}) {
  const { t, i18n } = useTranslation(['common'])
  const nonCumulativeCouponWhenAddingProduct = t(
    'non-cumulative-coupon-when-adding-product',
  )
  const openColorVariantsCtaButton = t('open-color-variants-cta-button')
  const openFrameColorVariantsCtaButton = t(
    'open-frame-color-variants-cta-button',
  )
  const openSizeVariantsCtaButton = t('open-size-variants-cta-button')
  const mobileDimensionsDetailsTitle = t(
    'mobile-dimensions-details-accordion-title',
  )

  const addToCartCtaButton = t('add-to-cart-cta-button')
  const remainingStockString = t('remaining-suppliers-stock')
  const minimumOrderQuantityString = t('minimum-order-quantity')

  const classes = useStyles() as any
  const { locale } = useAppContext()
  const { snackbar, setSnackbar } = useSnackbarContext()
  const [isInWishlist, setIsInWishlist] = useState(false)
  const variantsDrawerState = useReactiveVar(variantsDrawerStateVar)
  const wishlistItems = useReactiveVar(wishlistItemsVar)
  let groupedByColorProducts = []
  let groupedByFrameColorProducts = []
  let groupedBySizeProducts = []

  const containerID = product?.id ?? null
  // ======== REAL TIME DATA REFETCH ========
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  // (We do this, after get StaticProps are implemented)
  // The goal is to revalidate Price, and product stock dynamically.
  const {
    loading,
    error,
    data: productRefetchData,
  } = useQuery(GET_PRODUCT_CONTAINER_FOR_REVALIDATION, {
    variables: { productContainerID: containerID },
  })

  const [discounted, setDiscounted] = useState(null)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discountPercentage, setDiscountPercentage] = useState(null)

  // ===== QUANTITY
  const [follow_product_stock, setFollow_product_stock] = useState(
    product.single_product.follow_product_stock,
  )
  const [quantity, setQuantity] = useState(product.single_product.quantity)
  const [minimum_order_quantity, setMinimum_order_quantity] = useState(
    product.single_product.minimum_order_quantity,
  )

  const [productDisabled, setProductDisabled] = useState(
    product.single_product.follow_product_stock
      ? product.single_product.follow_product_stock &&
        product.single_product.quantity > 0
        ? false
        : true
      : false,
  )

  // ===== PRICE
  const [productPrice, setProductPrice] = useState(product.single_product.price)

  // --- This hook is used to update product data
  useEffect(() => {
    if (productRefetchData) {
      const { single_product = {} } =
        productRefetchData?.findUniqueProductContainer

      const newQuantity = single_product.quantity ?? 0

      const newMinimum_order_quantity =
        single_product.minimum_order_quantity ?? null

      const newFollow_product_stock = single_product.follow_product_stock
      const newPrice = single_product.price

      const newDiscountObject =
        productRefetchData.findUniqueProductContainer.discount
      const { discounted, discountedPrice } = checkDiscountValidity(
        newDiscountObject,
        newPrice,
      )
      const newDiscountPercentage = newDiscountObject
        ? '-' + newDiscountObject.discount + '%'
        : 0

      setDiscountPercentage(newDiscountPercentage)
      setDiscounted(discounted)
      setDiscountedPrice(discountedPrice)

      setProductPrice(newPrice)
      setFollow_product_stock(newFollow_product_stock)
      setQuantity(newQuantity)
      setMinimum_order_quantity(newMinimum_order_quantity)

      setProductDisabled(
        newFollow_product_stock
          ? newFollow_product_stock && newQuantity > 0
            ? false
            : true
          : false,
      )
    }
  }, [productRefetchData])

  // ======== get the wishlist items from the cache at first render ========
  useEffect(() => {
    wishlistItems.forEach((wishlistItem) => {
      if (wishlistItem.id === product.id) {
        setIsInWishlist(true)
      }
    })

    return () => setIsInWishlist(false)
  }, [wishlistItems])

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  useEffect(() => {
    const { discounted, discountedPrice } = checkDiscountValidity(
      product.discount,
      productPrice,
    )
    const discountPercentage = product.discount
      ? '-' + product.discount.discount + '%'
      : 0
    setDiscounted(discounted)
    setDiscountedPrice(discountedPrice)
    setDiscountPercentage(discountPercentage)
  }, [])

  // ========== DISPATCH INFORMATION ===============
  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = product.manufacturer
    ? product.manufacturer.default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    product.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )

  const { total_delivery_time, manufacturing_time, shipping_time } =
    dispatchInformationDateExtended(
      product.dispatch_information,
      defaultDispatchInformation,
      manufacturerDefaultDispatch,
    )
  //---
  const pricesDisabled = appContext.pricesDisabled

  // console.log(" shipping_time ->", total_delivery_time);
  // console.log(" manufacturing_time ->", manufacturing_time);
  // console.log(" shipping_time ->", shipping_time);

  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(product.createdAt)

  // ============ RETURNS POLICIES ========================
  const standard_returns_policy_profiles = appContext
    ? appContext.shopSettings.standard_returns_policy_profiles
    : null
  const configurable_product_returns_policy_profiles = appContext
    ? appContext.shopSettings.configurable_product_returns_policy_profiles
    : null
  // If product has managed stock then standart ese configurable policy
  const returnsPolicy = product.single_product.follow_product_stock
    ? standard_returns_policy_profiles
    : configurable_product_returns_policy_profiles

  // ============ SHIPPING METHOD ========================
  // A shipping method can be set on product level
  // on category level
  // on manufacturer level
  // on shop setttings level
  // Currently we will only default shop settings method.
  // Also Filter - is enabled??
  // TODO query all others and apply dispatchInformationDate to pick correct one
  const shipping_method_in_appContext = appContext
    ? appContext.shopSettings.default_shipping_method
    : null

  const shipping_methods_in_single_product =
    product?.single_product?.shipping_methods
      ?.slice()
      .sort((a, b) => a.price_for_box - b.price_for_box)

  const shipping_method_filtered = shipping_methods_in_single_product[0]
  const shipping_method =
    shipping_method_filtered ?? shipping_method_in_appContext

  //--- Additional informational tabs
  const countryOfOrigin = product?.single_product?.country_of_origin ?? null
  const D3FileLink = product?.single_product?.product_details?.d3_model ?? null
  const assemblyInstructionsLink =
    product?.single_product?.product_details?.assembly_instructions ?? null

  //* since we can't filter out grouped products that don't have a profile matching
  //* the current locale, we have to loop through them here and do the checking
  //COLOR
  for (let groupedByColorProduct of product.single_product.grouped_by_color) {
    // ====================================================
    // we want to run the check and get this item's profile
    // ====================================================
    const productProfileIndex: number = getProfileIndexFromLanguage(
      groupedByColorProduct.product_profiles,
      locale,
    )

    if (groupedByColorProduct.product_profiles[productProfileIndex]) {
      groupedByColorProducts.push({
        ...groupedByColorProduct,
        productProfile:
          groupedByColorProduct.product_profiles[productProfileIndex],
      })
    }
  }

  //FRAME COLOR
  for (let groupedByFrameColorProduct of product.single_product
    .grouped_by_frame_color) {
    // ====================================================
    // we want to run the check and get this item's profile
    // ====================================================
    const productProfileIndex: number = getProfileIndexFromLanguage(
      groupedByFrameColorProduct.product_profiles,
      locale,
    )

    if (groupedByFrameColorProduct.product_profiles[productProfileIndex]) {
      groupedByFrameColorProducts.push({
        ...groupedByFrameColorProduct,
        productProfile:
          groupedByFrameColorProduct.product_profiles[productProfileIndex],
      })
    }
  }

  //SIZE
  for (let groupedBySizeProduct of product.single_product.grouped_by_size) {
    // ====================================================
    // we want to run the check and get this item's profile
    // ====================================================
    const productProfileIndex: number = getProfileIndexFromLanguage(
      groupedBySizeProduct.product_profiles,
      locale,
    )

    if (groupedBySizeProduct.product_profiles[productProfileIndex]) {
      groupedBySizeProducts.push({
        ...groupedBySizeProduct,
        productProfile:
          groupedBySizeProduct.product_profiles[productProfileIndex],
      })
    }
  }

  const images = product.single_product.images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
  const mainImage = images
    ? images[0]?.src_thumbnail ?? images[0]?.src ?? imageFallback
    : imageFallback

  const productProfile = product.single_product.product_profile
  return (
    <>
      <section className={clsx(classes.section, className && className)}>
        {/* PRODUCT NAME CONTAINER */}
        <div className={classes.productNameContainer}>
          <Typography
            variant="h5"
            component="h1"
            className={classes.productName}
          >
            {productProfile.name}
          </Typography>

          {!isInWishlist ? (
            <a className={classes.wishlistIcon}>
              <FavoriteBorderIcon
                aria-label="add to favorites"
                onClick={() => {
                  addToWishlist({
                    id: product.id,
                    name: productProfile.name,
                    price: productPrice,
                    sku: product.single_product.sku,
                    image: mainImage,
                    permalink: productPermalink,
                    product_type: product.type,
                    dispatchInformation: product.dispatch_information,
                    discount: product.discount,
                  })
                  wishlistDrawerVar(true)
                  setIsInWishlist(true)
                }}
              />
            </a>
          ) : (
            <a className={classes.wishlistIconFilled}>
              <FavoriteIcon
                aria-label="remove from favorites"
                onClick={() => {
                  removeFromWishlist(product.id)
                  setIsInWishlist(false)
                }}
              />
            </a>
          )}
        </div>

        {/* Long product name */}
        <div className={classes.shortDescriptionContainer}>
          <Typography variant="body1">
            {productProfile.long_product_name}
          </Typography>
        </div>

        {/* BADGES */}
        <div className={classes.badgeBox}>
          {discounted && <DiscountBadge content={discountPercentage} left />}
          {fastDelivery && <FastDeliveryBadge left />}
          {newProduct && <NewProductBadge left />}
        </div>

        {/* PRICE CONTAINER */}
        <div className={classes.pricesContainer}>
          <Typography variant="h6" component="p" gutterBottom>
            {' '}
            {discountedPrice && <ProductPrice price={discountedPrice} />}
          </Typography>
          <Typography
            variant={discounted ? 'h6' : 'h6'}
            component="p"
            gutterBottom
            className={clsx(discounted && classes.discoutedPrice)}
          >
            <ProductPrice price={productPrice} />
          </Typography>
        </div>

        {/* DESCRIPTION CONTAINER */}
        {/* // TODO ====> Change to short description and move description inside photo slider */}
        <div className={classes.shortDescriptionContainer}>
          {/* <Typography variant="body1">{product.description}</Typography> */}
          {productProfile?.description &&
            productProfile.description.split('\n').map((line, i) => {
              if (line) {
                return (
                  <Typography key={i} variant="body1">
                    <span dangerouslySetInnerHTML={{ __html: line }}></span>
                  </Typography>
                )
              } else {
                return <br key={i}></br>
              }
            })}
        </div>

        {/* //* ============= COLOR SELECTION */}
        <>
          {groupedByColorProducts.length !== 0 && (
            <div
              className={classes.row}
              onClick={() => {
                variantsDrawerStateVar({
                  open: true,
                  data: null,
                  component: 'COLOR_FORM',
                })
              }}
            >
              <section style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography component="p" variant="caption" display="block">
                  {`${openColorVariantsCtaButton} (${
                    groupedByColorProducts.length + 1
                  })`}
                </Typography>
                <div className={classes.colorsContainer}>
                  <div
                    className={clsx(
                      classes.colorActive,
                      classes.colorContainer,
                    )}
                  >
                    <span
                      className={classes.color}
                      style={{
                        background: `${
                          product.single_product.main_color?.background ??
                          product.single_product.main_color?.hex ??
                          ''
                        }`,
                      }}
                    />
                  </div>
                  {groupedByColorProducts.map((groupedProduct) => {
                    return (
                      <div
                        className={clsx(classes.colorContainer)}
                        key={groupedProduct.id}
                      >
                        <span
                          className={classes.color}
                          style={{
                            background: `${
                              groupedProduct.main_color?.background ??
                              groupedProduct.main_color?.hex ??
                              ''
                            }`,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </section>
              <ChevronRightIcon className={classes.chevronRight} />
            </div>
          )}
        </>

        {/* //* ============== FRAME SELECTION */}
        <>
          {groupedByFrameColorProducts.length !== 0 ? (
            <div
              className={classes.row}
              onClick={() => {
                variantsDrawerStateVar({
                  open: true,
                  data: null,
                  component: 'FRAME_COLOR_FORM',
                })
              }}
            >
              <div className={classes.rowContentContainer}>
                <Typography component="p" variant="caption" display="block">
                  {`${openFrameColorVariantsCtaButton} (${
                    groupedByFrameColorProducts.length + 1
                  })`}
                </Typography>
              </div>
              <ChevronRightIcon className={classes.chevronRight} />
            </div>
          ) : null}
        </>

        {/* //* ============== SIZE SELECTION */}
        <>
          {groupedBySizeProducts.length !== 0 ? (
            <div
              className={classes.row}
              onClick={() => {
                variantsDrawerStateVar({
                  open: true,
                  data: null,
                  component: 'SIZE_FORM',
                })
              }}
            >
              <div className={classes.rowContentContainer}>
                <Typography component="p" variant="caption" display="block">
                  {`${openSizeVariantsCtaButton} (${
                    groupedBySizeProducts.length + 1
                  })`}
                </Typography>
                <Typography component="p" variant="body2" display="block">
                  {product.size_name}
                </Typography>
              </div>
              <ChevronRightIcon className={classes.chevronRight} />
            </div>
          ) : null}
        </>

        {/* this "propless" div is not useless, it works as a limit to the sticky button  */}
        {/* <div> */}
        <Accordion
          elevation={0}
          className={classes.mobileDimensionsAccordion}
          defaultExpanded
          style={{ padding: 0 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="dimensions"
            id="dimensionsAccordion"
            style={{ padding: 0 }}
          >
            <div className={classes.mobileAccordionSummary}>
              <Typography variant="subtitle1" component="h2">
                {mobileDimensionsDetailsTitle}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <MobileDescriptionTab
              product={{
                dimensions: {
                  ...product.single_product.dimensions,
                },
                product_details: {
                  ...product.single_product.product_details,
                },
                packaging_information: [
                  ...product.single_product.packing_information_list,
                ],
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* QUANTITY */}
        {follow_product_stock && (
          <>
            <div className={classes.quantityContainer}>
              <Typography
                className={classes.quantityText}
                variant="body1"
                style={{ marginRight: 5 }}
              >
                {/* Likutis tiekėjo sandėlyje: */}
                {`${remainingStockString}:`}
              </Typography>
              <Typography className={classes.quantityText} variant="body2">
                {quantity}
              </Typography>
            </div>
            {minimum_order_quantity && (
              <div className={classes.minimumQuantityContainer}>
                <Typography
                  className={classes.minimumOrderText}
                  variant="body1"
                  style={{ marginRight: 5 }}
                >
                  {/* minimum_order_quantity: */}
                  {`${minimumOrderQuantityString}:`}
                </Typography>
                <Typography
                  className={classes.minimumOrderText}
                  variant="body2"
                >
                  {minimum_order_quantity}
                </Typography>
              </div>
            )}

            {quantity <= 0 ? (
              <div style={{ marginBottom: '10px' }}>
                <TrackingModuleButton
                  type={'when-new-stock-button'}
                  variant="contained"
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {!pricesDisabled && (
          <div className={classes.addToCartButtonContainer}>
            <Button
              variant="contained"
              disabled={productDisabled || loading}
              onClick={() => {
                addToCart(
                  {
                    id: product.id,
                    name: productProfile.name,
                    price: productPrice,
                    sku: product.single_product.sku,
                    image: mainImage,
                    quantity: minimum_order_quantity ?? 1,
                    minimum_order_quantity: minimum_order_quantity,
                    permalink: productPermalink,
                    product_type: product.type,
                    dispatchInformation: product.dispatch_information,
                    discount: product.discount,
                    advanced_product_data: null,
                    product_container_id: product.id,
                  },
                  { snackbar, setSnackbar },
                  { nonCumulativeCouponWhenAddingProduct },
                )
                cartStateVar(true)
              }}
            >
              {addToCartCtaButton}
            </Button>
          </div>
        )}

        {/* </div> */}

        {/* //* ============= DELIVERY INFORMATION SELECTION */}
        <DeliveryInformationBox
          total_delivery_time={total_delivery_time}
          manufacturing_time={manufacturing_time}
          shipping_time={shipping_time}
          locale={appContext.locale}
        />
        {/* //* ============= SHIPPING INFORMATION SELECTION */}
        <ShippingInformationBox
          shipping_method={shipping_method}
          locale={appContext.locale}
        />

        {/* //* ============= 3D Model SELECTION */}
        {D3FileLink && (
          <InfoLinkBox
            link={D3FileLink}
            title={t('3d-model')}
            icon={ThreeDRotationIcon}
          />
        )}

        {/* //* ============= ASSEMBLY INSTRUCTIONS DOWNLOAD SELECTION */}
        {assemblyInstructionsLink && (
          <InfoLinkBox
            link={assemblyInstructionsLink}
            title={t('assembly-instructions')}
          />
        )}

        {/* //* ============= RETURNS POLICY INFORMATION SELECTION */}
        <ReturnsInformationBox
          returns_policy={returnsPolicy}
          locale={appContext.locale}
        />

        {/* //* ============= countryOfOrigin SELECTION */}
        {countryOfOrigin && (
          <CountryOfOriginBox
            countryCode={countryOfOrigin}
            locale={appContext.locale}
          />
        )}

        {/* //* ============= TRACKING MODULE SECTION */}
        <TrackingModuleButton
          type={'consultation-button'}
          variant="contained"
        />
      </section>

      {/* //* ==================================================================================== */}
      {/* //* ================================= VARIANTS DRAWER ================================== */}
      {/* //* ==================================================================================== */}
      <VariantsDrawer
        groupedByColorProducts={[{ id: product.id }, ...groupedByColorProducts]}
        groupedBySizeProducts={[{ id: product.id }, ...groupedBySizeProducts]}
        groupedByFrameColor={[
          { id: product.id },
          ...groupedByFrameColorProducts,
        ]}
      />
    </>
  )
}
