import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAppContext } from '../../context/appContext'
import { getProfileIndexFromLanguage } from '../../utilityFunctions/getProfile'
import { Content } from '../DesktopElements/BlogPostPageElements'
import { Section } from '../DesktopElements/Utils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}))

const CompanyTerms = ({ content }): JSX.Element => {
  const { t } = useTranslation(['common'])
  const contactSlug = t('contact-slug')
  const classes = useStyles() as any
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const { locale } = useAppContext()
  const convertedContent = Object.values(content)

  const dataProfileIndex: number = getProfileIndexFromLanguage(
    convertedContent,
    locale,
  )

  const data =
    dataProfileIndex != null
      ? JSON.parse(content[dataProfileIndex].content)
      : null
  const title =
    dataProfileIndex != null
      ? content[dataProfileIndex].name
      : 'Page is not translated to this language'

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <>
          <Divider className={classes.divider} />
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h1">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {data && <Content content={data} />}
            </Grid>
          </Grid>
        </>
      </Section>
      <Divider />
    </div>
  )
}

export default CompanyTerms
