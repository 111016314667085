import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'next-i18next'
import { useAppContext } from '../../../../../context/appContext'
import {
  getProfileFromLanguage,
  getProfileIndexFromLanguage,
} from '../../../../../utilityFunctions/getProfile'
import { locale } from 'moment'

const useStylesSelectedFabric = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        marginBottom: '10px',
        maxWidth: '500px',
      },
      cardBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2px',
        width: '100%',
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',

        '&:last-child': {
          paddingBottom: 0,
        },
      },
      property: {
        width: '40%',
      },
      value: {
        width: '60%',
        textAlign: 'center',
      },
      title: {
        marginLeft: '10px',
        borderBottom: '1px solid #e2e1e0',
      },
    }),
  { name: 'MuiSofaPreviewSelectedFabric' },
)
// We make a block for each sofa combination, so they are separate as products
const PreviewSelectedFabric = ({
  selectedAdvancedProductFabric,
  elevation = 1,
  title = null,
  ...props
}) => {
  const classes = useStylesSelectedFabric() as any

  const appContext = useAppContext()
  const language = appContext ? appContext.locale : 'en'

  const fabricGroup = selectedAdvancedProductFabric.fabricGroupObject
  // Extract group profile
  const fabricGroupProfileIndex: number = getProfileIndexFromLanguage(
    fabricGroup.fabric_group_profiles,
    language,
  )
  const fabricGroupName =
    fabricGroup.fabric_group_profiles[fabricGroupProfileIndex]?.name ??
    'Not translated'

  const selectedFabric = selectedAdvancedProductFabric.fabricObject

  const fabricType = fabricGroup.type
  const { t, i18n } = useTranslation(['common'])
  const typeNameTranslated = t(fabricType)

  const fabricGroupString = t('fabric-group')
  const fabricCodeString = t('fabric-code')
  const fabricTypeString = t('fabric-type')
  const fabricString = t('fabric')

  return (
    <Card
      elevation={elevation}
      className={classes.card}
      sx={{ display: 'flex' }}
    >
      <CardMedia
        component="img"
        sx={{ width: 120 }}
        image={selectedFabric.image.src}
        alt="Live from space album cover"
      />
      <Box className={classes.cardBox}>
        <Box className={classes.title}>
          <Typography variant="subtitle1" component="p">
            {fabricString}
            {title && `: ${title}`}
          </Typography>
        </Box>

        <CardContent className={classes.row}>
          <Typography
            variant="body1"
            component="p"
            className={classes.property}
          >
            {fabricGroupString}
          </Typography>
          <Typography variant="body2" component="p" className={classes.value}>
            {fabricGroupName}
          </Typography>
        </CardContent>
        <CardContent className={classes.row}>
          <Typography
            variant="body1"
            component="p"
            className={classes.property}
          >
            {fabricCodeString}
          </Typography>
          <Typography variant="body2" component="p" className={classes.value}>
            {selectedFabric.code}
          </Typography>
        </CardContent>
        <CardContent className={classes.row}>
          <Typography
            variant="body1"
            component="p"
            className={classes.property}
          >
            {fabricTypeString}
          </Typography>
          <Typography variant="body2" component="p" className={classes.value}>
            {typeNameTranslated}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

const PreviewSelectedFabricWraper = (props) => {
  const { locale } = useAppContext()
  const combination = props.selectedAdvancedProductFabric.combinationFabrics
  if (combination) {
    return (
      <>
        {Object.keys(combination).map((key, index) => {
          const option = combination[key]

          const optionProfile = getProfileFromLanguage(
            option.option?.fabricCombinationOptionProfiles,
            locale,
          )
          return (
            <PreviewSelectedFabric
              key={index}
              title={optionProfile?.name}
              selectedAdvancedProductFabric={option}
            />
          )
        })}
      </>
    )
  }

  return <PreviewSelectedFabric {...props} />
}

export default PreviewSelectedFabricWraper
