import { Grid, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { Image, SectionHeader } from '../Utils'

const useStyles = makeStyles(
  (theme: Theme) => ({
    image: {
      maxWidth: 420,
      [theme.breakpoints.down('lg')]: {
        display: 'block',
        margin: 'auto',
      },
    },
  }),
  { name: 'MuiStoryComponent' },
)

const Story = ({
  block1Text,
  block1Title,
  block2Image,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const { t } = useTranslation(['common'])
  // const paragraphOneTitle = t('paragraph-one-title')
  // const paragraphOneContent = t('paragraph-one-content')

  const paragraphOneTitle = block1Title
  const paragraphOneContent = block1Text

  const classes = useStyles() as any

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justifyContent="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <div>
            <SectionHeader
              title={paragraphOneTitle}
              subtitle={paragraphOneContent}
              align="left"
              disableGutter
              subtitleProps={{
                color: 'textPrimary',
                variant: 'subtitle2',
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justifyContent={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          {block2Image && (
            <Image
              image={{ src: block2Image }}
              alt={paragraphOneTitle}
              className={classes.image}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Story
