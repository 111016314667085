import { Button, ImageList, ImageListItem, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import React from 'react'
import { Image } from '.'

const useStyles = makeStyles(
  (theme) => ({
    folioItem: {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      '&:hover': {
        '& .folio__image': {
          transform: 'scale(1.4)',
        },
        '& .folio__info-wrapper': {
          top: 0,
        },
      },
    },
    folioInfoWrapper: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: '100%',
      backgroundImage:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0) 2%, #000000)',
      padding: theme.spacing(4, 2),
      transition: 'top .6s',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
      },
    },
    image: {
      objectFit: 'cover',
      transitionDuration: '.6s',
      transform: 'scale(1.0)',
    },
    folioTitle: {
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    folioSubtitle: {
      color: 'white',
      textTransform: 'capitalize',
      margin: theme.spacing(1, 0),
    },
  }),
  { name: 'MuiGalleryComponent' },
)

const Gallery = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles() as any

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <div className={className} {...rest}>
      {/* <ImageList rowHeight={isMd ? 360 : 260} cols={3} spacing={isMd ? 24 : 8}> */}

      <ImageList rowHeight={isMd ? 360 : 260} cols={3}>
        {data.map((item: any, index: number) => (
          <ImageListItem key={index} cols={isMd ? item.cols : 3 || 1}>
            <div className={classes.folioItem} key={index}>
              <Image
                image={{ src: item.image.src }}
                alt={item.title}
                className={clsx('folio__image', classes.image)}
                lazyProps={{ width: '100%', height: '100%' }}
              />
              <div
                className={clsx(
                  'folio__info-wrapper',
                  classes.folioInfoWrapper,
                )}
              >
                <div>
                  <Button variant="contained" color="secondary">
                    View more
                  </Button>
                </div>
              </div>
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  )
}

export default Gallery
