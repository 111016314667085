import { gql } from "@apollo/client";

export const SEARCH_DROPDOWN_PRODUCT_CARD = gql`
  query SEARCH_DROPDOWN_PRODUCT_CARD($productID: Int!) {
    findUniqueProductContainer(where: { id: $productID }) {
      id
      discount {
        id
        discount
        active
        startDate
        expiryDate
      }
      single_product {
        id
        price
        images {
          id
          src
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }
    }
  }
`;
