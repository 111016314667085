import { useReactiveVar, useLazyQuery, useQuery } from '@apollo/client'
import {
  Collapse,
  List,
  ListItem,
  FormControl,
  FormGroup,
  Theme,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  expandedFilterVar,
  priceFilterVar,
  productTypeFilterVar,
} from '../../../../store/reactiveVars'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterDrawerItemTitle from '../FilterDrawerItemTitle'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      nested: {
        paddingLeft: theme.spacing(4),
      },
      MuiAccordionroot: {
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      MuiAccordionSummaryRoot: {
        marginBottom: -1,
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
        },
      },
      MuiAccordionSummaryContent: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    }),
  { name: 'MuiColorDrawerItemComponent' },
)

export default function ProductTypeDrawerItem({
  permalink,
  // categoryData,
  handleDrawerItemOpen,
}) {
  const { t, i18n } = useTranslation(['common'])
  const colorFilterString = t('filter-color')
  const classes = useStyles() as any
  const expandedFilter = useReactiveVar(expandedFilterVar)
  const categoryFilterString = t('filter-category')

  const productTypeFilterString = t('filter-product-type')
  const productTypeSimpleProductString = t('filter-product-type-simple-product')
  const productTypeAdvancedProductString = t(
    'filter-product-type-advanced-product',
  )
  const productTypeAdvancedProductVariantString = t(
    'filter-product-type-advanced-product-variant',
  )

  const productTypeFilter = useReactiveVar(productTypeFilterVar)

  //* =========================================================================
  //* ============================== handling the selection of a color filter
  //* =========================================================================
  const handleProductTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked === true) {
      productTypeFilterVar([...productTypeFilter, event.target.name])
    } else if (
      event.target.checked === false &&
      productTypeFilter.includes(event.target.name)
    ) {
      const filteredCategories = productTypeFilter.filter(
        (ID) => ID !== event.target.name,
      )
      productTypeFilterVar([...filteredCategories])
    }

    // setChecked(event.target.checked);
  }

  const optionsProductType = [
    'SIMPLE_PRODUCT',
    'ADVANCED_PRODUCT',
    // 'ADVANCED_PRODUCT_VARIANT',  // TO be added later
  ]

  const getOptionLabel = (productType) => {
    if (productType === 'SIMPLE_PRODUCT') {
      return productTypeSimpleProductString
    }
    if (productType === 'ADVANCED_PRODUCT') {
      return productTypeAdvancedProductString
    }
    if (productType === 'ADVANCED_PRODUCT_VARIANT') {
      return productTypeAdvancedProductVariantString
    }
  }

  return (
    <>
      {
        <>
          <Accordion
            expanded={expandedFilter === productTypeFilterString}
            onChange={(e, isExpanded) =>
              handleDrawerItemOpen(productTypeFilterString)(e, isExpanded)
            }
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            classes={{
              root: classes.MuiAccordionroot,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ColorsAccordion"
              id="ColorsAccordion"
              classes={{
                root: classes.MuiAccordionSummaryRoot,
                content: classes.MuiAccordionSummaryContent,
              }}
            >
              <Typography component="h6" variant="body2">
                {productTypeFilterString}
              </Typography>
            </AccordionSummary>
            <List component="div" disablePadding>
              <ListItem className={classes.nested}>
                <FormControl component="fieldset">
                  <FormGroup>
                    {optionsProductType.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={productTypeFilter.includes(option)}
                            onChange={handleProductTypeChange}
                            name={`${option}`}
                          />
                        }
                        label={`${getOptionLabel(option)}`}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </ListItem>
            </List>
          </Accordion>
        </>
      }
    </>
  )
}
