// -------
export const parseSofaCombination = (arrayOfStringifiedObjects) => {
  // console.log('arrayOfStringifiedObjects :>> ', arrayOfStringifiedObjects)
  // const array = Object.values(arrayOfStringifiedObjects) as any
  // console.log('array :>> ', array)
  //----
  let newArray = [] as any
  for (const combinations of arrayOfStringifiedObjects) {
    let newCombinationArray = [] as any
    for (const item of combinations) {
      let desstringifiedObj = JSON.parse(item)
      newCombinationArray.push(desstringifiedObj)
    }
    newArray.push(newCombinationArray)
  }
  return newArray
}

export function flatten(obj) {
  var result = Object.create(obj)
  for (var key in result) {
    result[key] = result[key]
  }
  return result
}
