import { gql, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Grid, Paper, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { recentlyViewedVar } from '../../store/reactiveVars'
import { SearchDropdownCard } from '../DesktopElements/SearchDropdownElements'
import RecentlyViewedSearchDropdownCard from '../DesktopElements/SearchDropdownElements/RecentlyViewedSearchDropdownCard'
import { useShopContext } from '../../context'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(2),
        minHeight: '50vh',
        borderRadius: 0,
        '&.MuiPaper-elevation2': {
          '-webkit-box-shadow': '0 15px 15px -3px rgba(0, 0, 0, 0.4)',
          '-moz-box-shadow': '1px 50px 50px 5px rgba(0, 0, 0, 0.4)',
          boxShadow: '1px 30px 50px 5px rgba(0, 0, 0, 0.4)',
        },
        [theme.breakpoints.down('lg')]: {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
        // backgroundColor: theme.palette.background.default,
      },
      gridContainer: {
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column-reverse',
        },
      },
      gridItem: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(2),
        },
      },
      helperLinkContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.only('md')]: {
          flexDirection: 'row',
        },
      },
      helpText: {
        border: ' 1px solid #d3d3d3',
        padding: theme.spacing(1, 2),
        borderRadius: '20px',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
          marginRight: theme.spacing(2),
        },
      },
      resultsHeaderContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
    }),
  { name: 'MuiSearchDropdownComponent' },
)

const GET_FIRST6_SEARCH_PRODUCTS = gql`
  query GET_FIRST6_SEARCH_PRODUCTS($searchValue: String!, $language: Language) {
    findManyProductContainer(
      orderBy: { SVP: desc }
      take: 6
      where: {
        OR: [
          {
            single_product: {is: {
              product_profiles: {
                some: {
                  AND: [
                    { name: { mode: insensitive, contains: $searchValue } }
                    { language: { equals: $language } }
                  ]
                }
              }
            }}
          }
          {
            advanced_product: { is: {
              advanced_product_profiles: {
                some: {
                  AND: [
                    { name: { mode: insensitive, contains: $searchValue } }
                    { language: { equals: $language } }
                  ]
                }
              }
            }}
          }
        ]
      }
    ) {
      id
      type
      single_product {
        id
        images {
          id
          src
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }
      advanced_product {
        id
        images {
          id
          src
        }
        advanced_product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }
    }
  }
`

const GET_FIRST6_BEST_SVP_PRODUCTS = gql`
  query GET_FIRST6_BEST_SVP_PRODUCTS($language: Language) {
    findManyProductContainer(
      orderBy: { SVP: desc }
      take: 6
      where: {
        OR: [
          {
            single_product: { is: {
              product_profiles: { some: { language: { equals: $language } } }
            }}
          }
          {
            advanced_product: { is: {
              advanced_product_profiles: {
                some: { language: { equals: $language } }
              }
            }}
          }
        ]
      }
    ) {
      id
      type
      single_product {
        id
        images {
          id
          src
        }
        product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }

      advanced_product {
        id
        images {
          id
          src
        }
        advanced_product_profiles {
          id
          name
          language
          meta_information {
            id
            permalink
          }
        }
      }
    }
  }
`

//TODO: debounce search query to when the user stops typing for 500ms

export default function SearchDropdown({ inputValue, searchValue, openState }) {
  const { t } = useTranslation(['common'])
  const recentlyViewedString = t('recently-viewed-carousel-header')
  const searchSlug = t('search-slug')
  const contactSlug = t('contact-slug')
  const trendingNowString = t('trending-now')
  const resultMatchingTermString = t('results-for-search-term')
  const noResultMatchingTermString = t('no-result-for-search-term')
  const helpBoxHeader = t('search-dropdown-help-box-header')
  const ourShowroom = t('our-showroom')
  const noProductsFoundString = t('no-products-found')
  const FAQString = t('F.A.Q.')
  const classes = useStyles() as any
  const { locale } = useRouter()
  const {
    data: SVPProductsData,
    loading: SVPProductsLoading,
    error: SVPProductsError,
  } = useQuery(GET_FIRST6_BEST_SVP_PRODUCTS, {
    variables: {
      language: locale,
    },
  })
  const [fetchSearch, { data, loading, error, called }] = useLazyQuery(
    GET_FIRST6_SEARCH_PRODUCTS,
    {
      fetchPolicy: 'cache-and-network',
    },
  )
  const [open, setOpen] = openState
  const recentlyViewed = useReactiveVar(recentlyViewedVar)

  useEffect(() => {
    let mounted = true
    if (searchValue !== '' && mounted) {
      fetchSearch({
        variables: {
          searchValue,
          language: locale,
        },
      })
    }

    return () => {
      mounted = false
    }
  }, [searchValue])

  const { shopPhoneNumber } = useShopContext()

  if (error) return <p>Something went wrong...</p>

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={3}
          className={classes.gridItem}
        >
          <Typography variant="body2" component="span" gutterBottom>
            {helpBoxHeader}
          </Typography>
          <div className={classes.helperLinkContainer}>
            <Link prefetch={false} href={`/${contactSlug}#contact`}>
              <a className={classes.helpText}>
                <Typography variant="caption" component="span">
                  {ourShowroom}
                </Typography>
              </a>
            </Link>

            <a className={classes.helpText}>
              <Typography variant="caption" component="span">
                {FAQString}
              </Typography>
            </a>

            <a className={classes.helpText} href={`tel:${shopPhoneNumber}`}>
              <Typography variant="caption" component="p">
                {shopPhoneNumber}
              </Typography>
            </a>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={9}
          xl={9}
          className={classes.gridItem}
        >
          {open &&
            ((called && loading) || SVPProductsLoading
              ? Loading()
              : inputValue === '' || searchValue === ''
                ? NoInput(
                  recentlyViewed,
                  setOpen,
                  { SVPProductsData, SVPProductsLoading, SVPProductsError },
                  {
                    recentlyViewedString,
                    trendingNowString,
                    noProductsFoundString,
                  },
                )
                : called &&
                  !loading &&
                  searchValue !== '' &&
                  data.findManyProductContainer.length !== 0
                  ? InputWithResult(classes, searchValue, setOpen, data, loading, {
                    searchSlug,
                    resultMatchingTermString,
                  })
                  : // <div>Results</div>
                  called &&
                  !loading &&
                  searchValue !== '' &&
                  data.findManyProductContainer.length === 0 &&
                  NoResult(
                    searchValue,
                    recentlyViewed,
                    setOpen,
                    { SVPProductsData, SVPProductsLoading, SVPProductsError },
                    {
                      recentlyViewedString,
                      trendingNowString,
                      noResultMatchingTermString,
                      noProductsFoundString,
                    },
                  ))}
          {/* {open && getComponentFromSwitch()} */}
        </Grid>
      </Grid>
    </Paper>
  )
}

function Loading() {
  return (
    <>
      <Skeleton height={100} />
      <Skeleton height={100} />
    </>
  )
}

//* =========================================================================
//* ==========================NO INPUT COMPONENT=============================
//* =========================================================================
function NoInput(recentlyViewed, setOpen, SVPQueryResult, translatedData) {
  const {
    recentlyViewedString,
    trendingNowString,
    noProductsFoundString,
  } = translatedData
  const {
    SVPProductsData,
    SVPProductsLoading,
    SVPProductsError,
  } = SVPQueryResult

  console.log('SVPProductsData :>> ', SVPProductsData)

  if (SVPProductsError) return <div>An error occurred loading products... </div>

  if (!SVPProductsLoading && SVPProductsData.findManyProductContainer.length === 0)
    return <div>{noProductsFoundString}</div>

  return (
    <>
      <Typography component="h2" variant="subtitle1" gutterBottom>
        {trendingNowString}
      </Typography>
      <Grid container spacing={1}>
        {recentlyViewed?.length > 0 && !SVPProductsLoading
          ? SVPProductsData.findManyProductContainer.map(
            (product, i) =>
              i < 3 && (
                <SearchDropdownCard
                  productContainer={product}
                  productID={product.id}
                  key={product?.id}
                  setState={setOpen}
                />
              ),
          )
          : !SVPProductsLoading &&
          SVPProductsData.findManyProductContainer.map((product, i) => (
            <SearchDropdownCard
              productContainer={product}
              productID={product.id}
              key={product?.id}
              setState={setOpen}
            />
          ))}
      </Grid>

      {recentlyViewed?.length > 0 && (
        <>
          <Typography
            component="h2"
            variant="subtitle1"
            gutterBottom
          // style={{ marginTop: "2rem" }}
          >
            {recentlyViewedString}
          </Typography>
          <Grid container spacing={1}>
            {recentlyViewed.map(
              (item, i) =>
                i < 3 && (
                  <RecentlyViewedSearchDropdownCard
                    recentlyViewedItem={item}
                    productID={item.id}
                    key={item.id}
                    setState={setOpen}
                  />
                ),
            )}
          </Grid>
        </>
      )}
    </>
  )
}

//* =========================================================================
//* ==========================WITH RESULT COMPONENT==========================
//* =========================================================================
function InputWithResult(
  classes,
  searchValue,
  setOpen,
  data,
  loading,
  translatedData,
) {
  const { searchSlug, resultMatchingTermString } = translatedData
  return (
    <>
      <div className={classes.resultsHeaderContainer}>
        <Typography component="h2" variant="subtitle1" gutterBottom>
          {`${resultMatchingTermString} '${searchValue}'`}
        </Typography>
        <Link prefetch={false} href={`/${searchSlug}/${searchValue}`}>
          <Typography
            component="a"
            variant="body1"
            style={{ textDecoration: 'underline' }}
            onClick={() => setOpen(false)}
          >
            View all
          </Typography>
        </Link>
      </div>
      <Grid container spacing={1}>
        {data?.findManyProductContainer.map((product) => {
          return (
            <SearchDropdownCard
              productContainer={product}
              productID={product.id}
              key={product?.id}
              setState={setOpen}
            />
          )
        })}
      </Grid>
    </>
  )
}

//* =========================================================================
//* ================================NO RESULT================================
//* =========================================================================
function NoResult(
  searchValue,
  recentlyViewed,
  setOpen,
  SVPQueryResult,
  translatedData,
) {
  const {
    recentlyViewedString,
    trendingNowString,
    noResultMatchingTermString,
    noProductsFoundString,
  } = translatedData
  const {
    SVPProductsData,
    SVPProductsLoading,
    SVPProductsError,
  } = SVPQueryResult

  if (SVPProductsError) return <div>An error occurred loading products... </div>

  if (!SVPProductsLoading && SVPProductsData.findManyProductContainer.length === 0)
    return <div>{noProductsFoundString}</div>

  return (
    <>
      <Typography component="h2" variant="subtitle1" gutterBottom>
        {`${noResultMatchingTermString} '${searchValue}'`}
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        gutterBottom
        style={{ marginTop: '2rem' }}
      >
        {recentlyViewed?.length > 0 ? recentlyViewedString : trendingNowString}
      </Typography>
      <Grid container spacing={1}>
        {recentlyViewed?.length > 0
          ? recentlyViewed.map(
            (item, i) =>
              i < 3 && (
                <RecentlyViewedSearchDropdownCard
                  recentlyViewedItem={item}
                  productID={item.id}
                  key={item.id}
                  setState={setOpen}
                />
              ),
          )
          : SVPProductsData.findManyProductContainer.map(
            (product, i) =>
              i < 3 && (
                <SearchDropdownCard
                  productContainer={product}
                  productID={product.id}
                  key={product?.id}
                  setState={setOpen}
                />
              ),
          )}
      </Grid>
    </>
  )
}
