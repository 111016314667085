import { useReactiveVar } from '@apollo/client'
import { CardMedia, Drawer, IconButton, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { Fragment, useState } from 'react'
import { parentFullPermalinkVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '& .MuiDrawer-paperAnchorRight': {
          right: 'auto',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0% 100%)',
        },
      },
      subMenu: {
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      subMenuTopContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: theme.spacing(27),
        padding: theme.spacing(2),
        backgroundSize: 'cover',
        color: '#fff',
      },
      subMenuSectionActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      closeIcon: {
        cursor: 'pointer',
      },
      backButtonContainer: {
        display: 'flex',
      },
      categoriesContainer: {
        overflowY: 'auto',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        height: '4rem',
        borderTop: '1px solid #d3d3d3',
      },
      leftRowContent: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      rightRowContent: {
        height: '100%',
        width: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      media: {
        height: '100%',
        backgroundSize: 'contain',
        width: '2.5rem',
        marginRight: '1rem',
      },
    }),
  { name: 'MuiResponsiveSubMenuComponent' },
)

export default function ResponsiveSubMenu({
  targetState,
  subMenuState,
  onClose,
  locale,
}) {
  const { t } = useTranslation(['common'])
  const categorySlug = t('category-slug')
  const { target, setTarget } = targetState
  const { category, category_profile } = target
  const { open, setOpen } = subMenuState
  const [recursiveMenuState, setRecursiveMenuState] = useState(false)
  const [recursiveTarget, setRecursiveTarget] = useState({
    category: null,
    category_profile: null,
  })
  const classes = useStyles() as any

  const seeAllText = t('see-all')

  // ==== FULL MENU PERMALINK (to perserve hierachy of the url for child categories) ====
  const parentFullPermalink = useReactiveVar(parentFullPermalinkVar)

  // useEffect(() => {
  //   console.log("We are in use effect (target changed)");
  //   console.log("target :>> ", target);

  //   setRecursiveTarget({
  //     ...recursiveTarget,
  //     parent_full_permalink: target.parent_full_permalink,
  //   });
  // }, [target]);

  // console.log("open (in recusrsive sub menu) :>> ", open);
  // console.log("target inside (Responsive sub menu) :>> ", target);

  // useEffect(() => {
  //   if (!recursiveMenuState) {
  //     //--- we closed submenu (Pressed back)
  //     console.log("Submenu Level 2 closed..");
  //     setRecursiveTarget({
  //       category: null,
  //       category_profile: null,
  //       parent_full_permalink: "",
  //     });
  //   }
  // }, [recursiveMenuState]);

  // We want to make some buttons invisible (show_in_menu === false)
  const childCategories = category?.child_categories
    ? category.child_categories
      .slice()
      .sort((a, b) =>
        a.menu_order > b.menu_order
          ? 1
          : b.menu_order > a.menu_order
            ? -1
            : 0,
      )
      .filter((item) => item.show_in_menu === true)
    : null

  return (
    <>
      <Drawer
        classes={{
          paper: classes.subMenu,
          root: classes.root,
        }}
        anchor="right"
        open={open}
        transitionDuration={{ enter: 600, exit: 400 }}
        onClose={() => {
          onClose()
        }}
      >
        {category && category_profile && (
          <>
            <CardMedia
              className={classes.subMenuTopContent}
              image={category.image?.src ?? `/fallbacks/imageNotFound.png`}
            >
              <div className={classes.subMenuSectionActions}>
                <div
                  className={classes.backButtonContainer}
                  onClick={() => {
                    // --- WE go back here
                    setOpen(false)
                    // We need to modify the original parent_full_permalink to remove last part
                    const newFullPermalink = parentFullPermalink
                      .split('/')
                      .slice(0, -1)
                      .join('/')
                    // Set it back to state
                    parentFullPermalinkVar(newFullPermalink)
                  }}
                >
                  <ChevronLeftIcon />
                  <Typography variant="body2" color="inherit">
                    MENU
                  </Typography>
                </div>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => {
                    onClose()
                  }}
                />
              </div>
              <Typography variant="h5">{category_profile.name}</Typography>
            </CardMedia>

            <Link
              prefetch={false}
              // href={`${categorySlug}/${category_profile.meta_information.permalink}/1`}
              href={`/${categorySlug}${parentFullPermalink}/1`}
            >
              <a>
                <div
                  className={classes.row}
                  onClick={() => {
                    onClose()
                  }}
                >
                  <Typography variant="body1">{seeAllText} </Typography>
                </div>
              </a>
            </Link>

            {childCategories?.map((child) => {
              const childCategory_profileIndex: number = getProfileIndexFromLanguage(
                child.category_profiles,
                locale,
              )

              if (child.child_categories.length > 0) {
                return (
                  <Fragment key={child.id}>
                    <div
                      className={classes.row}
                      onClick={(e) => {
                        setRecursiveTarget({
                          category: child,
                          category_profile:
                            child.category_profiles[childCategory_profileIndex],
                        })
                        setRecursiveMenuState(true)

                        parentFullPermalinkVar(
                          parentFullPermalink +
                          '/' +
                          child.category_profiles[childCategory_profileIndex]
                            .meta_information.permalink,
                        )
                      }}
                    >
                      <div className={classes.leftRowContent}>
                        <CardMedia
                          image={
                            child.image_icon?.src ??
                            child.image?.src ??
                            `/fallbacks/imageNotFound.png`
                          }
                          className={classes.media}
                        />
                        <Typography
                          variant="body1"
                          component="h2"
                          key={child.id}
                        >
                          {
                            child.category_profiles[childCategory_profileIndex]
                              ?.name
                          }
                        </Typography>
                      </div>
                      <IconButton size="large">
                        <ChevronRightIcon />
                      </IconButton>
                    </div>
                  </Fragment>
                )
              } else {
                return (
                  <Link
                    prefetch={false}
                    href={`/${categorySlug}${parentFullPermalink}/${child.category_profiles[childCategory_profileIndex]?.meta_information.permalink}/1`}
                    key={child.id}
                  >
                    <a>
                      <div
                        className={classes.row}
                        onClick={() => {
                          onClose()
                        }}
                      >
                        <div className={classes.leftRowContent}>
                          <CardMedia
                            image={
                              child.image_icon?.src ??
                              child.image?.src ??
                              `/fallbacks/imageNotFound.png`
                            }
                            className={classes.media}
                          />
                          <Typography
                            variant="body1"
                            component="h2"
                            key={child.id}
                          >
                            {
                              child.category_profiles[
                                childCategory_profileIndex
                              ]?.name
                            }
                          </Typography>
                        </div>
                        <IconButton size="large">
                          <ChevronRightIcon />
                        </IconButton>
                      </div>
                    </a>
                  </Link>
                )
              }
            })}

            <ResponsiveSubMenu
              subMenuState={{
                open: recursiveMenuState,
                setOpen: setRecursiveMenuState,
              }}
              onClose={() => {
                setOpen(false)
                onClose()
                parentFullPermalinkVar('')
              }}
              targetState={{
                target: recursiveTarget,
                setTarget: setRecursiveTarget,
              }}
              locale={locale}
            />
          </>
        )}
      </Drawer>
    </>
  )
}
