import useWindowDimensions from '../../Utils/WindowDimensionsHook'

const SofaVideoInstructions = () => {
  // Automatically resize for mobile or smaller screens
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  let originalWidth = 800
  let width = windowWidth < originalWidth ? windowWidth - 30 : originalWidth
  let height = (width * 9) / 16

  let widthString = `${width}`
  let heightString = `${height}`

  return (
    <iframe
      //   width="560"
      //   height="315"
      width={widthString}
      height={heightString}
      src="https://www.youtube-nocookie.com/embed/qS4gXwpGV5o?controls=0"
      title="Furnisystems sofa configuration Instructions"
      //   frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )
}

export default SofaVideoInstructions
