import { CardMedia, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      mediaContainer: {
        padding: theme.spacing(10),
        height: theme.spacing(55),
        width: theme.spacing(55),
        margin: '0 auto',
        [theme.breakpoints.down('lg')]: {
          // padding: 0,
          marginLeft: '-35px',
        },
      },
      media: {
        height: '100%',
        width: '100%',
        backgroundSize: 'contain',
      },
    }),
  { name: 'MuiNoDataFoundComponent' },
)

export default function NoDataFound({ image }) {
  const classes = useStyles() as any

  return (
    <div className={classes.mediaContainer}>
      <CardMedia image={image} className={classes.media} />
    </div>
  )
}
