// Applies conditions if exist, and filters the additional components that mets the conditions
export const getValidAdditionalComponents = (
  componentGroupToFilter,
  selectedComponents,
) =>
  componentGroupToFilter?.additional_components.filter((component) => {
    if (!component.conditions) {
      return true
    }

    const { onlyWithComponents, onlyWithComponentsGroup } = component.conditions

    if (onlyWithComponents) {
      const selected = selectedComponents.find(
        (item) => item.groupCode === onlyWithComponentsGroup,
      )

      if (onlyWithComponents.includes(selected?.code)) {
        return true
      }
      return false
    }

    return true
  })
