import { createContext, useContext } from "react";

const CheckoutContext = createContext(null);

export function CheckoutContextWrapper({ data, locale, children }) {
  return (
    <CheckoutContext.Provider value={{ data, locale }}>
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckoutContext() {
  return useContext(CheckoutContext);
}
