import { gql } from '@apollo/client'

export const GET_STYLE_BY_ID = gql`
  query GET_STYLE_BY_ID(
    $styleID: Int!
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $colors: [SingleProductWhereInput]
    $categories: [ProductContainerWhereInput]
    # $styles: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findUniqueStyle(where: { id: $styleID }) {
      id
      style_profiles {
        id
        name
        language
      }
    }
    findManyProductContainerCount(
      where: {
        AND: [
          { style:{ is: { id: { equals: $styleID } } }}
          # { categories: { some: { id: { equals: $categoryID } } } }
          { AND: $categories }
          # { OR: $styles }
          { OR: $productTypes }
          {
            OR: [
              {
                single_product: {is: {
                  AND: [
                    { OR: $colors }
                    { dimensions: { is: { AND: $dimensions }} }
                    {
                      product_profiles: {
                        some: { language: { equals: $language } }
                      }
                    }
                  ]
                }}
              }
              {
                advanced_product: { is: {
                  AND: [
                    # { dimensions: { AND: $dimensions } }
                    {
                      advanced_product_profiles: {
                        some: { language: { equals: $language } }
                      }
                    }
                  ]
                }}
              }
            ]
          }
          $price
        ]
      }
    )
  }
`

export const GET_FILTERED_STYLES = gql`
 query GET_FILTERED_STYLES(
    # $permalink: String
    $price: ProductContainerWhereInput
    $dimensions: [DimensionWhereInput]
    $language: Language
    $colors: [SingleProductWhereInput]
    $categories: [ProductContainerWhereInput]
    $productTypes: [ProductContainerWhereInput]
  ) {
    findManyStyle(
      where: {
        AND: [
          {
            # ----> Looks irrelevant
            product_containers:  {
              some: {
                AND: [
                  { OR: $categories }
                  { OR: $productTypes }
                  {
                     OR: [
                      {
                        single_product: { is: {
                          AND: [
                            { OR: $colors }
                            { dimensions: {is: { AND: $dimensions }} }
                            {
                              product_profiles: {
                                some: { language: { equals: $language } }
                              }
                            }
                          ]
                        }}
                        
                      }
                      {
                        advanced_product: {is: {
                          AND: [
                            # { dimensions: { AND: $dimensions } }
                            {
                              advanced_product_profiles: {
                                some: { language: { equals: $language } }
                              }
                            }
                          ]
                        }}
                      }
                     ]
                  }
                  $price
                ]
              }
            }
          }
        ]
      }
    ) {
      id
    }
  }
`
