import {
  Card,
  CardContent,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Stack from '@mui/material/Stack'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import GridOnIcon from '@mui/icons-material/GridOn'
import GridOffIcon from '@mui/icons-material/GridOff'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import DirectionsOffIcon from '@mui/icons-material/DirectionsOff'
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined'

import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'

import HeightIcon from '@mui/icons-material/Height'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import { sofaScaleVar } from '../../../../../store/reactiveVars'
import useWindowDimensions from '../../../Utils/WindowDimensionsHook'

import IconButton from '@mui/material/IconButton'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import CloseIcon from '@mui/icons-material/Close'

const NoSSRSofaDrawingStage = dynamic(() => import('./SofaDrawingStage'), {
  ssr: false,
})

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      buttonBox: {
        // width: '100%',
        // height: '100%',
        // minHeight: '600px',
        position: 'relative',
        borderRight: '1px solid #e7e9ea',

        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      },

      mainDrawingBox: {
        width: '100%',
        height: '100%',
        position: 'relative',

        // [theme.breakpoints.down('md')]: {
        //   marginRight: 0,
        //   marginBottom: theme.spacing(2),
        // },
      },

      exampleGifBoxContainer: {
        position: 'absolute',
        top: '25%',
        left: '38%',
        // width: '100%',
        textAlign: 'center',
        zIndex: '3',

        [theme.breakpoints.down('md')]: {
          // top: '20%',
          left: '5%',
        },
      },

      exampleGifBox: {
        zIndex: '1',
        border: '1px solid grey',
        padding: '30px',
        borderRadius: '25px',
        width: '400px',
        position: 'relative',

        [theme.breakpoints.down('md')]: {
          padding: '10px',
          width: '300px',
        },
      },

      closeButton: {
        zIndex: '3',
        position: 'absolute',
        right: 0,
        top: 0,
      },

      exampleGifImage: {
        width: '350px',
        [theme.breakpoints.down('md')]: {
          width: '250px',
        },
      },

      samplePersonBox: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        marginRight: '70px',
        zIndex: '1',
        pointerEvents: 'none',
      },

      samplePersonImage: {
        width: '250px',
        [theme.breakpoints.down('md')]: {
          width: '120px',
        },
      },
    }),
  { name: 'MuiStageContainer' },
)

const StageContainer = ({ sofaShapes, onSofaDelete, ...props }) => {
  const classes = useStyles() as any
  const stageCanvasRef = useRef(null)

  const { t, i18n } = useTranslation(['common'])
  // const pleaseAddSomeShapesText = t('sofa-shape-please-add-some-shapes')
  const dragShapeHereText = t('drag-sofa-text')

  // --- Stage controls (on the right)
  const [showButtons, setShowButtons] = useState(true)
  const [showArrows, setShowArrows] = useState(true)
  const [showGrid, setShowGrid] = useState(false)

  const [showInstructions, setShowInstructions] = useState(true)

  const [canvasWidth, setCanvasWidth] = useState(760)
  const [canvasHeight, setCanvasHeight] = useState(600)

  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const applyResize = windowHeight < 900 ? true : false

  useEffect(() => {
    //----
    if (stageCanvasRef.current) {
      const tmepCanvasWidth = stageCanvasRef.current.offsetWidth
      setCanvasWidth(tmepCanvasWidth)
      // console.log('canvasWidth :>> ', canvasWidth)
    }
  }, [stageCanvasRef])

  // ---- Scale controls
  const [scale, setScale] = useState(1)
  const scaleStep = 0.1
  const increaseScale = () => {
    if (scale < 2) {
      const newScale = scale + scaleStep
      setScale(newScale)
    }
  }
  const decreaseScale = () => {
    if (scale > 0.3) {
      const newScale = scale - scaleStep
      setScale(newScale)
    }
  }
  // console.log('scale :>> ', scale)

  // -------------
  const handleInstructionsClose = (e) => {
    setShowInstructions(false)
  }

  // == We handle resize for scrrens that have small height (like laptops with scaled windows display)
  useEffect(() => {
    //----
    if (applyResize) {
      setCanvasHeight(350)
      setScale(0.8)
    }
  }, [applyResize])

  // ======= MOBILE ADJUSTMENTS ========
  useEffect(() => {
    // ---
    if (canvasWidth < 700) {
      const newScale = 0.6
      const newHeight = 400
      setCanvasHeight(newHeight)
      setScale(newScale)
    }
  }, [canvasWidth])

  useEffect(() => {
    // We need to set scale to a reactive VAR
    // To be used in sofa preview
    sofaScaleVar(scale)
  }, [scale])

  // --- Decrease the scale so more shapes can fit on mobile, when new shapes are added.
  useEffect(() => {
    const numberOfSofaShapes = sofaShapes.length

    // console.log('scale -=-->', scale)
    // console.log('numberOfSofaShapes :>> ', numberOfSofaShapes)

    if (applyResize) {
      // We have mobile
      if (numberOfSofaShapes > 3) {
        if (scale > 0.5) {
          // console.log('decrease')
          decreaseScale()
        }
        if (numberOfSofaShapes > 5) {
          if (scale > 0.4) {
            // console.log('decrease')
            decreaseScale()
          }
        }
      }

      if (numberOfSofaShapes < 6) {
        if (scale < 0.5) {
          // console.log('increase')
          increaseScale()
        }
        if (numberOfSofaShapes < 4) {
          if (scale < 0.6) {
            // console.log('increase')
            increaseScale()
          }
        }
      }
    }
  }, [sofaShapes])

  return (
    <Grid container justifyContent="space-evenly" spacing={0}>
      <Grid item xs={2} lg={1} className={classes.buttonBox}>
        <Stack direction="column" alignItems="center" spacing={1}>
          {/* <IconButton aria-label="delete" size="large" disabled>
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton> */}
        </Stack>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Tooltip placement="right" title="Hide buttons">
            <IconButton
              color={showButtons ? 'primary' : 'error'}
              aria-label="hide shape buttons"
              size="large"
              onClick={(e) => setShowButtons(!showButtons)}
            >
              <RadioButtonCheckedOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip placement="right" title="Hide metrics">
            <IconButton
              color={showArrows ? 'primary' : 'error'}
              aria-label="show arrows"
              onClick={(e) => setShowArrows(!showArrows)}
              size="large"
            >
              <SquareFootIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip placement="right" title="Show grid">
            <IconButton
              color={showGrid ? 'primary' : 'error'}
              aria-label="show grid"
              size="large"
              onClick={(e) => setShowGrid(!showGrid)}
            >
              {showGrid ? (
                <GridOnIcon fontSize="inherit" />
              ) : (
                <GridOffIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
          <IconButton
            color="primary"
            aria-label="add an alarm"
            size="large"
            onClick={(e) => increaseScale()}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add an alarm"
            size="large"
            onClick={(e) => decreaseScale()}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Grid>

      <Grid item xs={10} lg={11}>
        <div
          className={classes.mainDrawingBox}
          ref={stageCanvasRef}
          // ref={div}
          style={{
            minHeight: `${canvasHeight}px`,
            position: 'relative',
            background: 'none',
          }}
        >
          <NoSSRSofaDrawingStage
            parentRef={stageCanvasRef}
            sofaShapes={sofaShapes}
            onSofaDelete={onSofaDelete}
            scale={scale}
            showButtons={showButtons}
            showArrows={showArrows}
            showGrid={showGrid}
          />

          {sofaShapes.length < 1 && (
            <div className={classes.exampleGifBoxContainer}>
              {showInstructions ? (
                <div className={classes.exampleGifBox}>
                  <IconButton
                    onClick={(e) => handleInstructionsClose(e)}
                    color="error"
                    className={classes.closeButton}
                    size="large"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>
                  <img
                    className={classes.exampleGifImage}
                    src="/sofa-example.gif"
                  ></img>
                  <Typography
                    variant="body2"
                    // color="textSecondary"
                    className={classes.summary}
                  >
                    {dragShapeHereText}
                  </Typography>
                </div>
              ) : (
                <Typography
                  variant="body2"
                  // color="textSecondary"
                  className={classes.summary}
                >
                  {dragShapeHereText}
                </Typography>
              )}
            </div>
          )}

          {sofaShapes.length > 0 && showButtons && (
            <div className={classes.samplePersonBox}>
              <img
                className={classes.samplePersonImage}
                src="/zmogeliukas.png"
              ></img>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default StageContainer
