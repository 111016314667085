import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import YouTube from 'react-youtube'
import ReactPlayer from 'react-player'
import { Media } from '../../../../lib/artsyFresnel/Media'

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: (props) => ({ ...props }),

    description: {
      color: 'rgb(68 67 67)',
      fontWeight: 300,
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 15,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    divider: {
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'MuiContentBlockComponent' },
)

export default function ContentBlock({ data, index }) {
  //---- Translations below
  // const { t } = useTranslation(['common'])
  // const contactSlug = t('contact-slug')

  // const appContext = useAppContext()
  // const language = appContext ? appContext.locale : 'en'
  const { locale } = useRouter()

  const contentBlockProfileIndex = data.content_block_profiles
    ? getProfileIndexFromLanguage(data.content_block_profiles, locale)
    : null
  const contentBlockProfile = data.content_block_profiles
    ? data.content_block_profiles[contentBlockProfileIndex]
    : null

  const type = data.type

  const defaultMargins = data.default_margins
  const maxHeight = data.max_height ?? null
  const maxWidth = data.max_width ?? null
  const minHeight = data.min_height ?? null
  const minWidth = data.min_width ?? null
  //--margins
  const marginRight = data.right_margin ?? null
  const marginLeft = data.left_margin ?? null
  const marginTop = data.top_margin ?? null
  const marginBottom = data.bottom_margin ?? null
  //-- background
  const backgroundColor = data.background_color ?? null
  const textColor = data.text_color ?? null

  // For pictures with TEXT
  const mediaMaxHeight = data.media_max_height ?? null
  const mediaMaxWidth = data.media_max_width ?? null
  const mediaMinHeight = data.media_min_height ?? null
  const mediaMinWidth = data.media_min_width ?? null
  const objectFitCover = data.object_fit_cover

  let extraCss
  try {
    extraCss = JSON.parse(data.extra_css ?? '{}')
  } catch (e) {
    //console.error('Error parsing extra_css', e)
    extraCss = {}
  }
  const classes = useStyles(extraCss) as any

  return (
    <section
      className={classes.wrapper}
      style={{
        ...(defaultMargins ? { margin: '40px 0' } : { margin: 'auto' }),
        ...(maxHeight && { maxHeight: maxHeight }),
        ...(maxWidth && { maxWidth: maxWidth }),
        ...(minHeight && { minHeight: minHeight }),
        ...(minWidth && { minWidth: minWidth }),
        ...(marginRight && { marginRight: marginRight }),
        ...(marginLeft && { marginLeft: marginLeft }),
        ...(marginTop && { marginTop: marginTop }),
        ...(marginBottom && { marginBottom: marginBottom }),
        ...extraCss,
      }}
    >
      {/* <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          This is content block...
        </Grid>
      </Grid> */}
      {type === 'text_and_image' && (
        <TextAndImage
          classes={classes}
          alignImage={index % 2 === 1 ? 'right' : 'left'}
          sectionImage={
            data?.main_image?.src_lg ?? data?.main_image?.src ?? null
          }
          sectionName={contentBlockProfile?.name ?? null}
          sectionDescription={contentBlockProfile?.description ?? null}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
        />
      )}

      {type === 'text_and_video' && (
        <TextAndVideo
          classes={classes}
          alignImage={index % 2 === 1 ? 'right' : 'left'}
          // sectionVideo={
          //   data?.main_image?.src_lg ?? data?.main_image?.src ?? null
          // }
          sectionName={contentBlockProfile?.name ?? null}
          sectionDescription={contentBlockProfile?.description ?? null}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
          videoType={data?.video_type ?? null}
          videoLink={data?.video_link ?? null}
          videoAutoplay={data?.video_autoplay ?? null}
          videoLoop={data?.video_loop ?? null}
        />
      )}

      {type === 'only_image' && (
        <OnlyImage
          classes={classes}
          sectionImage={
            data?.main_image?.src_lg ?? data?.main_image?.src ?? null
          }
        />
      )}

      {type === 'only_video' && (
        <OnlyVideo
          objectFitCover={objectFitCover}
          videoType={data?.video_type ?? null}
          videoLink={data?.video_link ?? null}
          videoAutoplay={data?.video_autoplay ?? null}
          videoLoop={data?.video_loop ?? null}
        />
      )}

      {type === 'only_text' && (
        <OnlyText
          classes={classes}
          sectionName={contentBlockProfile?.name ?? null}
          sectionDescription={contentBlockProfile?.description ?? null}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )}
    </section>
  )
}

// //=== CONTENT BLOCK TEXT + MEDIA
// const TextAndImage2 = ({
//   classes,
//   alignImage,
//   sectionImage,
//   sectionName,
//   sectionDescription,
// }) => {
//   const { t, i18n } = useTranslation(['common'])
//   // const allText = t('all')
//   // const viewAllText = t('similar-articles-cta-button')

//   console.log('sectionImage :>> ', sectionImage)

//   // const sectionImage = null
//   // const sectionName = 'aaaa'
//   // const sectionDescription = 'blablabvlaba'

//   return (
//     <Container maxWidth="xl" className={classes.container}>
//       {alignImage === 'left' && (
//         <CardMedia
//           className={classes.leftSideMedia}
//           image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
//         />
//       )}
//       <div className={classes.rightSide}>
//         <Paper
//           elevation={0}
//           className={clsx(
//             alignImage === 'left' && classes.translateTextSection,
//             alignImage === 'right' && classes.translateTextSectionRight,
//             classes.paper,
//           )}
//         >
//           {/* {alignImage === "right" && <Divider className={classes.divider} />} */}
//           <Typography variant="h3">{sectionName}</Typography>

//           {sectionDescription.split('\n').map((line) => (
//             <Typography
//               variant="body2"
//               component="p"
//               className={classes.description}
//             >
//               {line} xxxxxxxxx
//             </Typography>
//           ))}
//         </Paper>
//       </div>
//       {alignImage === 'right' && (
//         <CardMedia
//           className={classes.rightSideMedia}
//           image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
//         />
//       )}
//     </Container>
//   )
// }

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndImage = ({
  classes,
  alignImage,
  sectionImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,
}) => {
  return (
    <>
      <Media
        greaterThan="md"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <TextAndImageDesktop
          classes={classes}
          alignImage={alignImage}
          sectionImage={sectionImage}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
        />
      </Media>

      <Media
        lessThan="lg"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <TextAndImageMobile
          classes={classes}
          alignImage={alignImage}
          sectionImage={sectionImage}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
        />
      </Media>
    </>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndImageDesktop = ({
  classes,
  alignImage,
  sectionImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,
}) => {
  const { t, i18n } = useTranslation(['common'])
  // const allText = t('all')
  // const viewAllText = t('similar-articles-cta-button')
  // console.log('objectFitCover :>> ', objectFitCover)

  return (
    <Container maxWidth="xl" sx={{ minHeight: 'inherit' }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: alignImage === 'left' ? 'row' : 'row-reverse',
          width: '100%',
          // height: 'inherit',
          // minHeight: 500,
          minHeight: 'inherit',

          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            // padding: '50px',
            minHeight: 'inherit',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid item md={8} lg={8}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ ...(textColor ? { color: textColor } : {}) }}
                >
                  {sectionName}
                </Typography>
                {sectionDescription
                  ? sectionDescription.split('\n').map((line, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        component="p"
                        className={classes.description}
                        sx={{
                          ...(textColor
                            ? { color: textColor + ' !important' }
                            : {}),
                        }}
                      >
                        {line}
                      </Typography>
                    ))
                  : ''}
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            // maxHeight: '700px',
            // padding: '20px',
            // position: 'relative',
          }}
        >
          {objectFitCover ? (
            <Box
              sx={{
                ...(mediaMaxHeight && { maxHeight: mediaMaxHeight }),
                ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
                ...(mediaMinHeight && { minHeight: mediaMinHeight }),
                ...(mediaMinWidth && { minWidth: mediaMinWidth }),
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  // visibility: 'hidden',
                  // maxWidth: '100%',
                  // maxHeight: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={sectionImage ?? '/test-placeholder-barstools.jpeg'}
              ></img>
            </Box>
          ) : (
            <Box
              sx={{
                ...(mediaMaxHeight && { maxHeight: mediaMaxHeight }),
                ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
                ...(mediaMinHeight && { minHeight: mediaMinHeight }),
                ...(mediaMinWidth && { minWidth: mediaMinWidth }),
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                // height: '100%',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  // visibility: 'hidden',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                src={sectionImage ?? '/test-placeholder-barstools.jpeg'}
              ></img>
            </Box>
          )}
        </Box>
      </Card>
    </Container>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndImageMobile = ({
  classes,
  alignImage,
  sectionImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,
}) => {
  return (
    <Container maxWidth="xl" sx={{ minHeight: 'inherit' }}>
      <Card
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          minHeight: 'inherit',

          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // minHeight: 'inherit',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid item md={8} lg={8}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ ...(textColor ? { color: textColor } : {}) }}
                >
                  {sectionName}
                </Typography>
                {sectionDescription
                  ? sectionDescription.split('\n').map((line, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        component="p"
                        className={classes.description}
                        sx={{
                          ...(textColor
                            ? { color: textColor + ' !important' }
                            : {}),
                        }}
                      >
                        {line}
                      </Typography>
                    ))
                  : ''}
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {objectFitCover ? (
            <Box
              sx={{
                ...(mediaMaxHeight && {
                  maxHeight: mediaMaxHeight,
                }),
                ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
                ...(mediaMinHeight && {
                  minHeight: mediaMinHeight > 300 ? 300 : mediaMinHeight,
                }),
                ...(mediaMinWidth && { minWidth: mediaMinWidth }),
                display: 'flex',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  ...(mediaMaxHeight && {
                    maxHeight: mediaMaxHeight / 2,
                  }),
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={sectionImage ?? '/test-placeholder-barstools.jpeg'}
              ></img>
            </Box>
          ) : (
            <Box
              sx={{
                ...(mediaMaxHeight && {
                  maxHeight: mediaMaxHeight,
                }),
                ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
                ...(mediaMinHeight && {
                  minHeight: mediaMinHeight > 300 ? 300 : mediaMinHeight,
                }),
                ...(mediaMinWidth && { minWidth: mediaMinWidth }),
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                src={sectionImage ?? '/test-placeholder-barstools.jpeg'}
              ></img>
            </Box>
          )}
        </Box>
      </Card>
    </Container>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndVideo = ({
  classes,
  alignImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,

  videoType,
  videoLink,
  videoAutoplay,
  videoLoop,
}) => {
  return (
    <>
      <Media
        greaterThan="md"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <TextAndVideoDesktop
          classes={classes}
          alignImage={alignImage}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
          videoType={videoType}
          videoLink={videoLink}
          videoAutoplay={videoAutoplay}
          videoLoop={videoLoop}
        />
      </Media>

      <Media
        lessThan="lg"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <TextAndVideoMobile
          classes={classes}
          alignImage={alignImage}
          sectionName={sectionName}
          sectionDescription={sectionDescription}
          backgroundColor={backgroundColor}
          textColor={textColor}
          mediaMaxHeight={mediaMaxHeight}
          mediaMaxWidth={mediaMaxWidth}
          mediaMinHeight={mediaMinHeight}
          mediaMinWidth={mediaMinWidth}
          objectFitCover={objectFitCover}
          videoType={videoType}
          videoLink={videoLink}
          videoAutoplay={videoAutoplay}
          videoLoop={videoLoop}
        />
      </Media>
    </>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndVideoDesktop = ({
  classes,
  alignImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,

  videoType,
  videoLink,
  videoAutoplay,
  videoLoop,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 'inherit',
        maxHeight: 'inherit',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: alignImage === 'left' ? 'row' : 'row-reverse',
          width: '100%',
          minHeight: 'inherit',
          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            minHeight: 'inherit',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid item md={8} lg={8}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ ...(textColor ? { color: textColor } : {}) }}
                >
                  {sectionName}
                </Typography>
                {sectionDescription
                  ? sectionDescription.split('\n').map((line, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        component="p"
                        className={classes.description}
                        sx={{
                          ...(textColor
                            ? { color: textColor + ' !important' }
                            : {}),
                        }}
                      >
                        {line}
                      </Typography>
                    ))
                  : ''}
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <Box
            sx={{
              ...(mediaMaxHeight && { maxHeight: mediaMaxHeight }),
              ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
              ...(mediaMinHeight && { minHeight: mediaMinHeight }),
              ...(mediaMinWidth && { minWidth: mediaMinWidth }),
              display: 'block',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <VideoPlayerComponent
              videoType={videoType}
              videoLink={videoLink}
              videoAutoplay={videoAutoplay}
              videoLoop={videoLoop}
              objectFitCover={objectFitCover}
            />
          </Box>
        </Box>
      </Card>
    </Container>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const TextAndVideoMobile = ({
  classes,
  alignImage,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
  mediaMaxHeight,
  mediaMaxWidth,
  mediaMinHeight,
  mediaMinWidth,
  objectFitCover,

  videoType,
  videoLink,
  videoAutoplay,
  videoLoop,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 'inherit',
        maxHeight: 'inherit',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          // flexDirection: alignImage === 'left' ? 'row' : 'row-reverse',
          width: '100%',
          minHeight: 'inherit',
          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: 'inherit',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid item md={8} lg={8}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ ...(textColor ? { color: textColor } : {}) }}
                >
                  {sectionName}
                </Typography>
                {sectionDescription
                  ? sectionDescription.split('\n').map((line, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        component="p"
                        className={classes.description}
                        sx={{
                          ...(textColor
                            ? { color: textColor + ' !important' }
                            : {}),
                        }}
                      >
                        {line}
                      </Typography>
                    ))
                  : ''}
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...(mediaMaxHeight && { maxHeight: mediaMaxHeight }),
              ...(mediaMaxWidth && { maxWidth: mediaMaxWidth }),
              ...(mediaMinHeight && { minHeight: mediaMinHeight }),
              ...(mediaMinWidth && { minWidth: mediaMinWidth }),
              display: 'block',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <VideoPlayerComponent
              videoType={videoType}
              videoLink={videoLink}
              videoAutoplay={videoAutoplay}
              videoLoop={videoLoop}
              objectFitCover={objectFitCover}
              isMobile={true}
            />
          </Box>
        </Box>
      </Card>
    </Container>
  )
}

const VideoPlayerComponent = ({
  videoType,
  videoLink,
  videoAutoplay,
  videoLoop,
  objectFitCover,
  isMobile = false,
}) => {
  function getYouTubeVideoId(link) {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([\w-]{11})(?:\S+)?$/
    const match = link.match(regex)

    if (match && match[1]) {
      return match[1]
    }

    return null // Return null if the link is invalid or doesn't contain a video ID
  }

  const youtubeVideoID = getYouTubeVideoId(videoLink)
  const videoWrapperRef = useRef(null)

  //get screen width
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  return (
    <>
      {videoType === 'uploaded' && (
        <>
          {objectFitCover ? (
            <video
              style={{
                // visibility: 'hidden',
                // maxWidth: '100%',
                // maxHeight: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
              src={videoLink ?? '/test-placeholder-barstools.jpeg'}
              loop={videoLoop}
              autoPlay={videoAutoplay}
              muted={true}
              preload="auto"
              playsInline={true}
            ></video>
          ) : (
            <video
              style={{
                // visibility: 'hidden',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              src={videoLink ?? '/test-placeholder-barstools.jpeg'}
              loop={videoLoop}
              autoPlay={videoAutoplay}
              muted={true}
              preload="auto"
              playsInline={true}
            ></video>
          )}
        </>
      )}

      {videoType === 'youtube' && (
        <YouTube
          videoId={youtubeVideoID}
          opts={{
            height: isMobile ? ((screenWidth - 48) / 640) * 360 : '360',
            width: isMobile ? '100%' : '640',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: videoAutoplay ? 1 : 0,
              loop: videoLoop ? 1 : 0,
            },
          }}
        />
      )}

      {videoType === 'vimeo' && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ReactPlayer
            url={videoLink}
            controls={true}
            playing={videoAutoplay}
            loop={videoLoop}
            height={isMobile ? '180px' : '360px'}
            width={isMobile ? '320px' : '640px'}
          />
        </Box>
      )}
    </>
  )
}

//=== CONTENT TEXT
const OnlyText = ({
  classes,
  sectionName,
  sectionDescription,
  backgroundColor,
  textColor,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        textAlign: 'center',
        width: '100%',
        height: '100%',
        minHeight: 'inherit',
        maxHeight: 'inherit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          minHeight: 'inherit',
          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Grid item md={8} lg={8}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                component="div"
                variant="h5"
                sx={{ ...(textColor ? { color: textColor } : {}) }}
              >
                {sectionName}
              </Typography>
              {sectionDescription
                ? sectionDescription.split('\n').map((line, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      component="p"
                      className={classes.description}
                      sx={{
                        ...(textColor
                          ? { color: textColor + ' !important' }
                          : {}),
                      }}
                    >
                      {line}
                    </Typography>
                  ))
                : ''}
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const OnlyImage = ({ classes, sectionImage }) => {
  return (
    <Container maxWidth="xl" sx={{ textAlign: 'center' }}>
      {/* <Box
        sx={{
          background: `url(${
            sectionImage ?? '/test-placeholder-barstools.jpeg'
          })  no-repeat center`,
          backgroundSize: 'contain',
          height: '100%',
          width: '100%',
        }}
      >
      </Box> */}

      <img
        style={{
          // visibility: 'hidden',
          maxWidth: '100%',
          maxHeight: '100%',
          height: 'auto',
        }}
        src={sectionImage ?? '/test-placeholder-barstools.jpeg'}
      ></img>

      {/* <CardMedia
        sx={{ width: '100%', minHeight: 500 }}
        image={sectionImage ?? '/test-placeholder-barstools.jpeg'}
      /> */}
    </Container>
  )
}

//=== CONTENT BLOCK TEXT + MEDIA
const OnlyVideo = ({
  videoType,
  videoLink,
  videoAutoplay,
  videoLoop,
  objectFitCover,
}) => {
  return (
    <Container maxWidth="xl" sx={{ textAlign: 'center' }}>
      <Media
        greaterThan="md"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <VideoPlayerComponent
          videoType={videoType}
          videoLink={videoLink}
          videoAutoplay={videoAutoplay}
          videoLoop={videoLoop}
          objectFitCover={objectFitCover}
        />
      </Media>
      <Media
        lessThan="lg"
        style={{
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          maxHeight: 'inherit',
        }}
      >
        <VideoPlayerComponent
          videoType={videoType}
          videoLink={videoLink}
          videoAutoplay={videoAutoplay}
          videoLoop={videoLoop}
          objectFitCover={objectFitCover}
          isMobile={true}
        />
      </Media>
    </Container>
  )
}
