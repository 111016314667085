import { useReactiveVar, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionSummary,
  Collapse,
  List,
  ListItem,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import {
  expandedFilterVar,
  priceFilterVar,
} from '../../../../store/reactiveVars'
import FilterDrawerItemTitle from '../FilterDrawerItemTitle'
import FilterPriceRange from './FilterPriceRange'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      nested: {
        paddingLeft: theme.spacing(4),
      },
      MuiAccordionroot: {
        '&:before': {
          backgroundColor: 'white',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      MuiAccordionSummaryRoot: {
        marginBottom: -1,
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
        },
      },
      MuiAccordionSummaryContent: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    }),
  { name: 'MuiPriceDrawerItemComponent' },
)

export default function PriceDrawerItem({
  priceData,
  permalink,
  handleDrawerItemOpen,
}) {
  const { t, i18n } = useTranslation(['common'])
  const priceFilterString = t('filter-price')
  const classes = useStyles() as any
  const expandedFilter = useReactiveVar(expandedFilterVar)

  return (
    <>
      <Accordion
        expanded={expandedFilter === priceFilterString}
        onChange={(e, isExpanded) =>
          handleDrawerItemOpen(priceFilterString)(e, isExpanded)
        }
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="PriceAccordion"
          id="PriceAccordion"
          classes={{
            root: classes.MuiAccordionSummaryRoot,
            content: classes.MuiAccordionSummaryContent,
          }}
        >
          <Typography component="h6" variant="body2">
            {priceFilterString}
          </Typography>
        </AccordionSummary>
        <List component="div" disablePadding>
          <ListItem className={classes.nested}>
            <FilterPriceRange priceData={priceData} />
          </ListItem>
        </List>
      </Accordion>
    </>
  )
}
