import { CardMedia, Container, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      childCategory: {
        width: 'fit-content',
        color: '#333',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.5',
        },
      },
      paper: {
        left: 0,
        // top: "4.7rem",
        height: '470px',
        width: '100%',
        position: 'absolute',
        zIndex: 5,
        backgroundColor: '#fff',
        padding: '1rem',
        paddingBottom: '2rem',
      },
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '0.6rem',
        height: '480px',
        width: '100%',

        [theme.breakpoints.up(1285)]: {
          gridTemplateColumns: '1fr 1fr 1fr 2fr',
        },
      },
      media: {
        backgroundSize: 'contain',
        // width: "100%",
      },
      leftContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        height: 'inherit',
      },
      childrenContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        // height: "200px",
        gap: '0.5rem',
        listStyle: 'none',
        height: 'inherit',
        paddingBottom: '20px',
      },
      allText: {
        // fontStyle: "italic",
        textDecoration: 'underline',
      },
    }),
  { name: 'MuiCategoriesDropdownComponent' },
)

export default function CategoriesDropdown({
  openState,
  childCategoriesData,
  parentData,
  locale,
}) {
  const { t, i18n } = useTranslation(['common'])
  const categorySlug = t('category-slug')
  const styleSlug = t('style-slug')
  const allText = t('all')
  const byStyleText = t('by-style')

  const classes = useStyles() as any
  const {
    permalink: parentPermalink,
    image: parentImageSrc,
    name: parentName,
    styles,
  } = parentData
  const { open, setOpen } = openState

  const [image, setImage] = useState(parentImageSrc)
  const [childrenOfChild, setChildrenOfChild] = useState([])
  const [selectedChild, setSelectedChild] = useState(undefined)
  const [name, setName] = useState(parentName)
  const [permalink, setPermalink] = useState(parentPermalink)

  // RESET THE DATA
  useEffect(() => {
    if (parentImageSrc) {
      setImage(parentImageSrc)
    }
    setChildrenOfChild([])
    setName(parentName)
    setPermalink(parentPermalink)
    setSelectedChild(undefined)
  }, [parentData])

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.3,
      },
    },
  }

  const onChildCategoryEnter = (categoryObject) => {
    const childImageSrc = categoryObject.image
      ? categoryObject.image.src_xs ?? categoryObject.image.src
      : null
    if (childImageSrc) {
      setImage(childImageSrc)
    }
    const children = categoryObject.child_categories
    const sortedChildren = children
      .slice()
      .sort((a, b) =>
        a.menu_order > b.menu_order ? 1 : b.menu_order > a.menu_order ? -1 : 0,
      )
      .filter((item) => item.show_in_menu === true)

    if (children.length > 0) {
      setChildrenOfChild(sortedChildren)
      setSelectedChild(categoryObject)
    }
  }

  const onStyleEnter = (styleObject) => {
    // console.log("styleObject ->", styleObject);
    const childImageSrc = styleObject.main_image
      ? styleObject.main_image.src_xs ?? styleObject.main_image.src
      : null
    if (childImageSrc) {
      setImage(childImageSrc)
    }
  }

  const selectedChildProfileIndex: number = selectedChild
    ? getProfileIndexFromLanguage(selectedChild.category_profiles, locale)
    : 0

  const sortedCategories = childCategoriesData
    .slice()
    .sort((a, b) =>
      a.menu_order > b.menu_order ? 1 : b.menu_order > a.menu_order ? -1 : 0,
    )

  return (
    <>
      <motion.div
        className={classes.paper}
        onMouseEnter={(e) => setOpen((prev) => !prev)}
        onMouseLeave={(e) => setOpen((prev) => !prev)}
        initial="exit"
        animate={open ? 'enter' : 'exit'}
        variants={subMenuAnimate}
      >
        <Container maxWidth="xl" className={classes.container}>
          <div className={classes.leftContainer}>
            <ul className={classes.childrenContainer}>
              <Link
                prefetch={false}
                href={`/${categorySlug}/${parentPermalink}/1`}
              >
                <a
                  className={classes.childCategory}
                  onClick={() => setOpen(false)}
                // onMouseEnter={(e) => onChildCategoryEnter(category)}
                >
                  <Typography variant="body2">{name}</Typography>
                </a>
              </Link>

              {sortedCategories.map((category) => {
                const categoryProfileIndex: number = getProfileIndexFromLanguage(
                  category.category_profiles,
                  locale,
                )

                return (
                  <Link
                    prefetch={false}
                    href={`/${categorySlug}/${parentPermalink}/${category.category_profiles[categoryProfileIndex].meta_information.permalink}/1`}
                    key={category.category_profiles[categoryProfileIndex]?.id}
                  >
                    <a
                      className={classes.childCategory}
                      onClick={() => setOpen(false)}
                      onMouseEnter={(e) => onChildCategoryEnter(category)}
                    >
                      <Typography variant="body1">
                        {category.category_profiles[categoryProfileIndex]?.name}
                      </Typography>
                    </a>
                  </Link>
                )
              })}

              <Link
                prefetch={false}
                href={`/${categorySlug}/${parentPermalink}/1`}
                key={1000}
              >
                <a
                  className={classes.childCategory}
                  onClick={() => setOpen(false)}
                // onMouseEnter={(e) => onChildCategoryEnter(category)}
                >
                  <Typography
                    variant="body1"
                    className={classes.allText}
                  >{`${allText} ${name}`}</Typography>
                </a>
              </Link>
            </ul>
          </div>
          <div className={classes.leftContainer}>
            <ul className={classes.childrenContainer}>
              {selectedChild && (
                <Link
                  href={`/${categorySlug}/${parentPermalink}/${selectedChild.category_profiles[selectedChildProfileIndex].meta_information.permalink}/1`}
                  key={
                    selectedChild.category_profiles[selectedChildProfileIndex]
                      ?.id
                  }
                >
                  <a
                    className={classes.childCategory}
                    onClick={() => setOpen(false)}
                  >
                    <Typography variant="body2">
                      {
                        selectedChild.category_profiles[
                          selectedChildProfileIndex
                        ]?.name
                      }
                    </Typography>
                  </a>
                </Link>
              )}

              {childrenOfChild.map((category) => {
                const categoryProfileIndex: number = getProfileIndexFromLanguage(
                  category.category_profiles,
                  locale,
                )

                return (
                  <Link
                    href={`/${categorySlug}/${parentPermalink}/${selectedChild.category_profiles[selectedChildProfileIndex].meta_information.permalink}/${category.category_profiles[categoryProfileIndex].meta_information.permalink}/1`}
                    key={category.category_profiles[categoryProfileIndex]?.id}
                  >
                    <a
                      className={classes.childCategory}
                      onClick={() => setOpen(false)}
                    // onMouseEnter={(e) => onChildCategoryEnter(category)}
                    >
                      <Typography variant="body1">
                        {category.category_profiles[categoryProfileIndex]?.name}
                      </Typography>
                    </a>
                  </Link>
                )
              })}
              {selectedChild && (
                <Link
                  href={`/${categorySlug}/${parentPermalink}/${selectedChild.category_profiles[selectedChildProfileIndex].meta_information.permalink}/1`}
                >
                  <a
                    className={classes.childCategory}
                    onClick={() => setOpen(false)}
                  // onMouseEnter={(e) => onChildCategoryEnter(category)}
                  >
                    <Typography
                      variant="body1"
                      className={classes.allText}
                    >{`${allText} ${selectedChild.category_profiles[selectedChildProfileIndex]?.name}`}</Typography>
                  </a>
                </Link>
              )}
            </ul>
          </div>

          <div className={classes.leftContainer}>
            <ul className={classes.childrenContainer}>
              <li className={classes.childCategory}>{byStyleText}</li>

              {styles?.map((style) => {
                const styleProfileIndex: number = getProfileIndexFromLanguage(
                  style.style_profiles,
                  locale,
                )

                return (
                  <Link
                    href={`/${styleSlug}/${style.style_profiles[styleProfileIndex].meta_information.permalink}/1`}
                    key={style.style_profiles[styleProfileIndex]?.id}
                  >
                    <a
                      className={classes.childCategory}
                      onClick={() => setOpen(false)}
                      onMouseEnter={(e) => onStyleEnter(style)}
                    >
                      <Typography variant="body1">
                        {style.style_profiles[styleProfileIndex]?.name}
                      </Typography>
                    </a>
                  </Link>
                )
              })}
            </ul>
          </div>

          <CardMedia image={image} className={classes.media} />

          {/* <div className={classes.media}>
            <Image
              layout="fill"
              placeholder="blur"
              blurDataURL={imageLoadingPlaceholder}
              objectFit="contain"
              src={image}
            />
          </div> */}
        </Container>
      </motion.div>
    </>
  )
}
