import moment from "moment";
import { getDiscountedProductPrice } from "./priceUtils";

export const checkDiscountValidity = (discount, price) => {
  if (discount && discount.active) {
    if (discount.startDate) {
      if (discount.startDate && moment().isBefore(discount.startDate)) {
        return {
          discounted: false,
          discountedPrice: null,
        };
      }
    }

    // If its active we check if the end date has already passed.
    if (discount.expiryDate) {
      if (discount.expiryDate && moment().isAfter(discount.expiryDate)) {
        return {
          discounted: false,
          discountedPrice: null,
        };
      }
    }

    return {
      discounted: true,
      discountedPrice: getDiscountedProductPrice(price, discount.discount),
    };
  } else {
    return {
      discounted: false,
      discountedPrice: null,
    };
  }
};
