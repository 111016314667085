import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import DimensionsBlock from '../../DesktopElements/ProductPageElements/AdvancedProductElements/Components/DimensionsBlock'

const useStyles = makeStyles(
  (theme) => ({
    gridContainer: {
      // padding: "1rem",
      // backgroundColor: "#fff",
      maxWidth: '100%',
      margin: 0,
      padding: 0,
    },
    leftDataContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1.1rem',
    },
    property: {
      width: '50%',
    },
    value: {
      width: '50%',
    },
    gridItem: {
      borderBottom: '1px solid #cbcbcb',
    },
  }),
  { name: 'MuiMobileDescriptionTabComponent' },
)

export default function MobileDescriptionTab({ product }) {
  const { t } = useTranslation(['common'])
  const contactSlug = t('contact-slug')
  const yesString = t('yes')
  const noString = t('no')
  const dimensionsTitle = t('product-info-box-dimensions-title')
  const productDetailsTitle = t('product-info-box-productDetails-title')
  const noProductInfoFallback = t('no-product-info-fallback')
  const widthString = t('product-width')
  const heightString = t('product-height')
  const lengthString = t('product-length')
  const radiusString = t('product-radius')
  const weightString = t('product-weight')
  const seatWidthString = t('product-seat_width')
  const seatHeightString = t('product-seat_height')
  const seatDepthString = t('product-seat_depth')
  const shadeHeightString = t('product-shade_height')
  const shadeRadiusString = t('product-shade_radius')
  const bulbIncludedString = t('bulb-included')
  const cableLengthString = t('cable-length')
  const maxWattHalogenBulbString = t('max-watt-halogen-bulb')
  const maxWattLedBulbString = t('max-watt-led-bulb')
  const lightBulbFittingString = t('light-bulb-fitting')
  const bulbQuantityString = t('bulb-quantity')
  const threeDimensionalModelString = t('3d-model')
  const assemblyInstructionsString = t('assembly-instructions')
  //TODO: Important add this to UI and populate with correct data
  const assemblyString = t('assembly')
  const caringInstructionsString = t('caring-instructions')
  const materialString = t('material')
  const fabricCompositionString = t('fabric-composition')
  const foamTypeString = t('foam-type')
  const fillingString = t('filling-type')
  const classes = useStyles() as any
  const [dimensionsEmpty, setDimensionsEmpty] = useState(false)
  const [productDetailsEmpty, setProductDetailsEmpty] = useState(false)
  const { dimensions, product_details } = product
  const productDetails = product_details

  //* ========== CHECKING IF DIMESIONS VALUES ARE NULL (EXCLUDING ID AND __TYPENAME)
  //Because probably, during the import, dimensions are always created but not always populated with data.
  useEffect(() => {
    if (dimensions) {
      let dimensionsWithNoID = []

      Object.entries(dimensions).forEach(([key, value]) => {
        if (key === 'id' || key === '__typename') {
          return
        } else {
          dimensionsWithNoID.push(value)
        }
      })

      setDimensionsEmpty(dimensionsWithNoID.every((value) => value === null))
    }

    return
  }, [dimensions])

  //Because probably, during the import, product details are always created but not always populated with data.
  //* ============= CHECKING IF PRODUCT DETAILS VALUES ARE NULL (EXCLUDING ID AND __TYPENAME)
  useEffect(() => {
    if (productDetails) {
      let productDetailsWithNoID = []

      Object.entries(productDetails).forEach(([key, value]) => {
        if (key === 'id' || key === '__typename') {
          return
        } else {
          productDetailsWithNoID.push(value)
        }
      })

      setProductDetailsEmpty(
        productDetailsWithNoID.every((value) => value === null),
      )
    }

    return
  }, [productDetails])

  // TODO: IMPORTANT populate with data
  return (
    <>
      <Grid container spacing={0} className={classes.gridContainer}>
        {/* LEFT CONTAINER */}

        {dimensionsEmpty || !dimensions ? (
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
            {/* LEFT TITLE */}
            <Typography
              variant="h5"
              component="h4"
              style={{ marginBottom: '1rem' }}
            >
              {dimensionsTitle}
            </Typography>
            <Typography variant="body2" component="p">
              {noProductInfoFallback}
            </Typography>
            {/* <Typography variant="body2" component="p">
              No dimensions provided,
              <Link href={`/${contactSlug}`}>
                <Typography
                  component="a"
                  color="secondary"
                  display="inline"
                >{` contact `}</Typography>
              </Link>
              us if you need any additional information about this product.
            </Typography> */}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
            {/* LEFT TITLE */}
            <Typography
              variant="h5"
              component="h4"
              style={{ marginBottom: '1rem' }}
            >
              {dimensionsTitle}
            </Typography>
            {/* LEFT DATA CONTAINER */}
            <DimensionsBlock
              insideSingleProduct={true}
              dimensions={dimensions}
            />
          </Grid>
        )}

        {/* RIGHT CONTAINER */}
        {productDetailsEmpty || !productDetails ? (
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
            {/* LEFT TITLE */}
            {/* <Typography
              variant="h5"
              component="h4"
              style={{
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
            >
              {productDetailsTitle}
            </Typography>
            <Typography variant="body2" component="p">
              {noProductInfoFallback}
            </Typography> */}
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
            {/* RIGHT TITLE */}
            <Typography
              variant="h5"
              component="h4"
              style={{
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
            >
              {productDetailsTitle}
            </Typography>
            {/* RIGHT DATA CONTAINER */}
            <div className={classes.leftDataContainer}>
              {productDetails.bulb_included && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {bulbIncludedString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.bulb_included ? yesString : noString}
                  </Typography>
                </div>
              )}

              {productDetails.assembly_instructions && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {assemblyInstructionsString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.assembly_instructions}
                  </Typography>
                </div>
              )}

              {productDetails.cable_length && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {cableLengthString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.cable_length}
                  </Typography>
                </div>
              )}

              {productDetails.max_watt_halogen_bulb && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {maxWattHalogenBulbString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.max_watt_halogen_bulb}
                  </Typography>
                </div>
              )}

              {productDetails.max_watt_led_bulb && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {maxWattLedBulbString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.max_watt_led_bulb}
                  </Typography>
                </div>
              )}

              {productDetails.lightbulb_fitting && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {lightBulbFittingString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.lightbulb_fitting}
                  </Typography>
                </div>
              )}

              {productDetails.bulb_qty && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {bulbQuantityString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.bulb_qty}
                  </Typography>
                </div>
              )}

              {productDetails.d3_model && (
                <div className={classes.row}>
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.property}
                  >
                    {threeDimensionalModelString}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.value}
                  >
                    {productDetails.d3_model}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  )
}
