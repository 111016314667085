//* REACTIVE VARIABLES for storing local state outside of the Apollo Client cache

import { makeVar } from '@apollo/client'
import {
  CartItem,
  Order,
  OrderItem,
  RecentlyViewedItem,
  WishlistItem,
} from '../models'

//* ===============================================================================
//* ============================== SHOP SETTINGS VAR ==============================
//* ===============================================================================
export const shopSettingsVar = makeVar({} as any)

//* ===============================================================================
//* ================== CARTITEMS/WISHLIST/RECENTLYVIEWED ITEMS ====================
//* ===============================================================================
export const cartItemsVar = makeVar([] as CartItem[])
export const wishlistItemsVar = makeVar([] as WishlistItem[])
export const recentlyViewedVar = makeVar([] as RecentlyViewedItem[])

//* ===============================================================================
//* ===================== OPEN & CLOSING OF DRAWERS/DROPDOWNS =====================
//* ===============================================================================
//HOLDS THE STATE (OPEN/CLOSED) FOR THE CART DRAWER COMPONENT
export const cartStateVar = makeVar(false)
export const cartPageItems = makeVar([])
//HOLDS THE STATE (OPEN/CLOSED) FOR THE WISHLIST DRAWER COMPONENT
export const wishlistDrawerVar = makeVar(false)
//HOLDS THE STATE (OPEN/CLOSED) FOR THE TABLET/MOBILE MENU
export const responsiveMenuVar = makeVar(false)
//HOLDS THE STATE (OPEN/CLOSED) FOR THE TABLET/MOBILE MENU
export const responsiveSubMenuVar = makeVar(false)
//HOLDS THE STATE (OPEN/CLOSED) FOR THE DESKTOP CATEGORIES NAVIGATION
export const dropdownStateVar = makeVar(false)
// THIS HOLDS THE STRING OF ALL PARENT CATEGORIES BEFORE, forming a url -> dining-room/sofas/triple-sofas ...
// When going back url shrinks -> dining-room/sofas
export const parentFullPermalinkVar = makeVar('')

//* ================ using reactive var like context to set variants drawer content
//HOLDS THE STATE (OPEN/CLOSED) FOR THE SIZES DRAWER IN THE PRODUCT PAGE
// "COLOR_FORM"
// "SIZE_FORM"
// "FRAME_COLOR_FORM"
export const variantsDrawerStateVar = makeVar({
  open: false,
  data: null,
  component: '',
})

export const componentsDrawerStateVar = makeVar({
  open: false,
  data: null,
  componentGroup: null,
})

export const fabricsDrawerStateVar = makeVar({
  open: false,
  data: null,
  option: null,
})

export const fabricsCombinationDrawerStateVar = makeVar({
  open: false,
  data: null,
})

//* ===============================================================================
//* ============================ FILTERING & SORTING ==============================
//* ===============================================================================
// this variable listens for which filter chip is clicked so that the drawer will mount with right filter already opened
export const expandedFilterVar = makeVar('' as string | boolean)
//* =============== holds the value of the query variable used in the categoryProductList component
// export const sortByVar = makeVar(null as object | null);
export const sortByVar = makeVar({
  queryVar: null as object | null,
  value: '',
})
// export const filteredProductsExist = makeVar(false)
//* =============== reactive vars for colors filtering
export const colorsFilterVar = makeVar([])

//* =============== reactive vars for frame colors filtering
export const frameColorsFilterVar = makeVar([])

//* =============== reactive var for price filtering
export const priceFilterVar = makeVar({
  minPrice: null,
  maxPrice: null,
})

//* =============== reactive vars for category filtering
export const categoriesFilterVar = makeVar([])
//* =============== reactive vars for category filtering
export const stylesFilterVar = makeVar([])
//* =============== reactive vars for category filtering
export const productTypeFilterVar = makeVar([])

//* =============== reactive vars for dimensions filtering
export const widthFilterVar = makeVar({
  minWidth: null,
  maxWidth: null,
})
export const heightFilterVar = makeVar({
  minHeight: null,
  maxHeight: null,
})
export const lengthFilterVar = makeVar({
  minLength: null,
  maxLength: null,
})

//* ===============================================================================
//* ================================= ORDER =======================================
//* ===============================================================================
export const orderVar = makeVar({
  address1: null,
  billing_address1: null,
  address2: null,
  billing_address2: null,
  city: null,
  billing_city: null,
  country: null,
  billing_country: null,
  email: null,
  firstName: null,
  lastName: null,
  //TODO: make an interface for these guys
  order_items: [] as OrderItem[],
  phone: null,
  promo: false,
  state: null,
  postal_code: null,
  billing_postal_code: null,
  discount_applied: null,
  total_shipping_price: null,
  sub_total_price: null,
  total_price_without_VAT: null,
  total_price: null,
  buying_as_company: false,
  company_name: null,
  company_code: null,
  vat_code: null,
  zero_vat: false,
  is_lithuanian_company: null,
  coupon: null,
} as Order)

//* ===============================================================================
//* ================================= ADVANCED PRODUCT ============================
//* ===============================================================================

export const selectedAdvancedProductFabricVar = makeVar({
  fabricGroupObject: null,
  fabricObject: null,
  combinationFabrics: null,
})
export const selectedAdvancedProductFabricCombinationVar = makeVar({
  fabricCombination: null,
})
export const selectedSofaCombinationsVar = makeVar([])
// This var is used in sofa drawing stage + or - determines the size of grid
export const sofaScaleVar = makeVar(1)

// We have occasiosn like sofa, where we have several parts with different prices
// So we put them in an array
export const advancedProductTotalPriceVar = makeVar(null)

// ================================= ADDITIONAL COMPONENTS ============================
// This is an array, and we push all selected additional components to it
export const selectedAdditionalComponentsVar = makeVar([])

export const advancedProductVarsResetedVar = makeVar(null)
// To initiate a reset on another product page
export const resetAdvancedProductVars = () => {
  //---
  // -- Currently this breaks sofa shape slider (however on each load the first fabric is reselected, so should be no bugs)
  selectedAdvancedProductFabricVar({
    fabricGroupObject: null,
    fabricObject: null,
    combinationFabrics: null,
  })

  selectedSofaCombinationsVar([])
  sofaScaleVar(1)
  advancedProductTotalPriceVar(null)
  selectedAdditionalComponentsVar([])
  advancedProductVarsResetedVar(Date.now())
  console.log('Resetting VARs...')
}

//* ===============================================================================
//* ======= EXAMPLE FOR REACTIVE VARS THAT HAVE TO USE A TYPE DEFINITION =========
//* ===============================================================================
// import { VisibilityFilter, VisibilityFilters } from "./models/VisibilityFilter";
// const todosInitialValue: Todos = [
//     {
//         id: 0,
//         completed: false,
//         text: "Use Apollo Client 3"
//     }
// ];
// export const todosVar: ReactiveVar<Todos> = makeVar<Todos>(
//     todosInitialValue
// );
// export const visibilityFilterVar = makeVar<VisibilityFilter>(
//     VisibilityFilters.SHOW_ALL
// );
