//* ===============================================================================================
//*  this is used to create an array to pass in an AND statement where the parent is single product
//* TYPE: [SingleProductWhereInput]
//* ===============================================================================================
export const getMainColorQueryVariable = (colorsFilter) => {
  let colorOptions = []
  if (colorsFilter.length !== 0) {
    colorsFilter.forEach((color) => {
      let filter = { main_color: { is: { id: { equals: color } } } }

      colorOptions.push(filter)
    })
  }

  return colorOptions
}

export const getFrameColorQueryVariable = (colorsFilter) => {
  let colorOptions = []
  if (colorsFilter.length !== 0) {
    colorsFilter.forEach((color) => {
      let filter = { frame_color: { is: { id: { equals: color } } } }

      colorOptions.push(filter)
    })
  }

  return colorOptions
}

//* ===============================================================================================
export const getCategoriesQueryVariable = (categoriesFilter) => {
  let categoryOptions = []
  if (categoriesFilter.length !== 0) {
    categoriesFilter.forEach((category) => {
      let filter = { categories: { some: { id: { equals: category } } } }
      categoryOptions.push(filter)
    })
  }
  return categoryOptions
}

export const getStylesQueryVariable = (stylesFilter) => {
  let styleOptions = []
  if (stylesFilter.length !== 0) {
    stylesFilter.forEach((style) => {
      let filter = { style: { is: { id: { equals: style } } } }
      styleOptions.push(filter)
    })
  }
  return styleOptions
}

export const getProductTypeQueryVariable = (productTypeFilter) => {
  let productTypeOptions = []
  if (productTypeFilter.length !== 0) {
    productTypeFilter.forEach((type) => {
      let filter = { type: { equals: type } }
      productTypeOptions.push(filter)
    })
  }
  return productTypeOptions
}

//* ===============================================================================================
//*  this is used to create an array to pass in an AND statement where the parent is single product
//* TYPE: [DimensionWhereInput]
//* ===============================================================================================
export const getDimensionsQueryVariable = (
  widthFilter,
  heightFilter,
  lengthFilter,
) => {
  let dimensionOptions = []

  if (widthFilter.minWidth || widthFilter.maxWidth) {
    let filter = [
      { width: { gte: widthFilter.minWidth } },
      { width: { lte: widthFilter.maxWidth } },
    ]

    dimensionOptions = [...dimensionOptions, ...filter]
  }
  if (heightFilter.minHeight || heightFilter.maxHeight) {
    let filter = [
      { height: { gte: heightFilter.minHeight } },
      { height: { lte: heightFilter.maxHeight } },
    ]

    dimensionOptions = [...dimensionOptions, ...filter]
  }
  if (lengthFilter.minLength || lengthFilter.maxLength) {
    let filter = [
      { length: { gte: lengthFilter.minLength } },
      { length: { lte: lengthFilter.maxLength } },
    ]

    dimensionOptions = [...dimensionOptions, ...filter]
  }

  return dimensionOptions
}

//* ==============================================================================================
//* this is used to create an array to pass in an AND statement where the parent is single product
//* TYPE: [DimensionWhereInput]
//* ==============================================================================================
