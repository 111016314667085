import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Media } from '../../../../../lib/artsyFresnel/Media'
import { useAppContext } from '../../../../context/appContext'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'
import FabricGroupsSlider from './FabricsGroupsSlider'
import FabricsSlider from './FabricsSlider'
import {
  advancedProductVarsResetedVar,
  selectedAdvancedProductFabricVar,
} from '../../../../store/reactiveVars'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper'

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { textAlign } from '@mui/system'
import { useReactiveVar } from '@apollo/client'
import { TrackingModuleButton } from '../../Utils/TrackingModuleComponent'
import SectionTitle from './SectionTitle'

const allFabricIcons = [
  {
    name: 'water_repelant',
    text: 'Water repelant',
    image: '/fabric_icons/water_repelant.png',
  },
  {
    name: 'virus_protection',
    text: 'Virus protection',
    image: '/fabric_icons/virus_protection.png',
  },
  {
    name: 'non_flammable',
    text: 'Non flammable',
    image: '/fabric_icons/non_flammable.png',
  },
  {
    name: 'pet_friendly',
    text: 'Pet friendly',
    image: '/fabric_icons/pet_friendly.png',
  },
  {
    name: 'environment_friendly',
    text: 'Environment friendly',
    image: '/fabric_icons/environment_friendly.png',
  },
  {
    name: 'easy_water_cleaning',
    text: 'Easy water cleaning',
    image: '/fabric_icons/easy_water_cleaning.png',
  },
]

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      fabricCategoryTitle: {
        textAlign: 'center',
        fontWeight: 400,
        // marginTop: '5px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        marginLeft: '25px',
        marginRight: '30px',
        // border: `1px solid ${theme.palette.secondary.main}`,
      },

      fabricSliderInfo: {
        textAlign: 'center',
        fontWeight: 400,
        position: 'absolute',
        top: '-100px',
        width: '100%',
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        [theme.breakpoints.down(1550)]: {
          fontSize: '15px',
          top: '-85px',
        },
      },

      fabricSelectionRow: {
        marginTop: '10px',
        marginBottom: '5px', // was 50px
      },

      groupDescriptionCard: {
        display: 'flex',
        height: '560px',
        width: '100%',

        // [theme.breakpoints.down('lg')]: {
        //   height: '500px',
        // },

        [theme.breakpoints.down(1650)]: {
          height: '520px',
        },

        [theme.breakpoints.down(1500)]: {
          height: '480px',
        },

        [theme.breakpoints.down(1400)]: {
          height: '465px',
        },

        [theme.breakpoints.down(1350)]: {
          height: '450px',
        },

        [theme.breakpoints.down(1250)]: {
          height: '420px',
        },

        [theme.breakpoints.down(1100)]: {
          height: '400px',
        },
      },

      cardDescription: {
        marginTop: '10px',
        marginBottom: '10px',
        maxHeight: '250px',
        overflow: 'clip',
        wordWrap: 'break-word',
      },

      cardContent: {
        // flexGrow: 1,
        // height: '100%',
        width: '50%',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'space-between',

        // '& h2': {
        //   width: '100%',
        // },
        '& p': {
          width: '100%',
        },
      },

      cardTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      priceRangeString: {
        fontSize: '20px',
        color: '#8d8d8d',
      },

      fabricImageBox: {
        width: '50%',
        position: 'relative',
      },

      fabricImage: {
        height: '100%',
      },

      types: {
        marginTop: '10px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
          marginTop: '10px',
          padding: 0,
        },
      },

      fabricTypeBox: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',

        textAlign: 'center',
        margin: '0 20px',
        cursor: 'pointer',
        maxWidth: '140px',

        [theme.breakpoints.down('md')]: {
          margin: 0,
          fontSize: '12px',
          width: '100px',
          textAlign: 'left',

          '& p': {
            // padding: '8px 0 !important',
            textAlign: 'center',
          },
        },

        [theme.breakpoints.down(1550)]: {
          margin: '0',
          fontSize: '12px',
          width: '100px',
          textAlign: 'left',

          '& p': {
            // padding: '8px 0 !important',
            textAlign: 'center',
          },
        },
      },
      fabricTypeImage: {
        height: '100px',
        width: '100px',
        margin: '0 20px',
        opacity: 0.6,
        [theme.breakpoints.down('md')]: {
          height: '80px',
          width: '80px',
          margin: 0,
        },

        [theme.breakpoints.down(1550)]: {
          height: '80px',
          width: '80px',
          margin: 0,
        },
      },

      fabricTypeImageSelected: {
        opacity: 1,
        border: '5px solid',
        borderColor: theme.palette.secondary.main,
      },

      fabricCode: {
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        padding: '5px',
        width: '100%',
        background: 'rgb(0 0 0 / 26%)',
        color: '#fff',
      },

      cardContentFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'flex-end',
      },
    }),
  { name: 'MuiSizeDrawerCardComponent' },
)

export default function FabricsSelect({ fabricGroups }) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])

  // Pasirinkite audini
  const chooseFabricText = t('choose-fabric-text')
  // Audinio konfiguravimas
  const fabricConfigurationText = t('fabric-configuration-text')
  // Pasirinkti audinio kategorija
  const chooseFabricCategoryText = t('choose-fabric-category-text')
  // Pasirinkite audinio palete
  const chooseFabricPalletText = t('choose-fabric-pallet-text')

  // fabricConfigurationText
  // chooseFabricCategoryText
  // chooseFabricPalletText
  // chooseFabricText

  // === EXTRACT FABRIC TYPES ==== //
  const fabricTypes = [] as any
  for (const group of fabricGroups) {
    if (fabricTypes.filter((item) => item === group.type).length === 0) {
      fabricTypes.push(group.type)
    }
  }

  // ------ STATE -----
  const [selectedFabricGroup, setSelectedFabricGroup] = useState(
    fabricGroups[0] ?? null,
  )
  const [selectedFabric, setSelectedFabric] = useState(
    fabricGroups[0]?.fabrics[0] ?? null,
  )

  const [selectedFabricType, setSelectedFabricType] = useState(
    fabricTypes[0] ?? null,
  )

  const [filteredFabricGroups, setFilteredFabricGroups] = useState(fabricGroups)

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()

  // We need to follow this, as Var resets happens strangely after render...
  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )
  // -- HANDLE RESETING OF FIRST FABRIC AFTER VARS RESET
  useEffect(() => {
    //----
    // const filteredGroups = fabricGroups.filter(
    //   (group) => group.type === selectedFabricType,
    // )
    // setSelectedFabricType(fabricTypes[0] ?? null)
    // setSelectedFabricGroup(filteredGroups[0])
    // setSelectedFabric(filteredGroups[0]?.fabrics[0] ?? null)

    selectedAdvancedProductFabricVar({
      fabricGroupObject: selectedFabricGroup,
      fabricObject: selectedFabric,
      combinationFabrics: null,
    })
  }, [advancedProductVarsResetedVar, []])

  useEffect(() => {
    // --- When selected fabric Group canges
    // console.log('selectedFabricGroup changed! ->', selectedFabricGroup)
    setSelectedFabric(selectedFabricGroup.fabrics[0])
  }, [selectedFabricGroup])

  // ==== We need to filter all fabricGroups acordinglyt to selected type
  useEffect(() => {
    // ---
    const filteredGroups = fabricGroups.filter(
      (group) => group.type === selectedFabricType,
    )
    // console.log('filteredGroups :>> ', filteredGroups)
    setFilteredFabricGroups(filteredGroups)
    setSelectedFabricGroup(filteredGroups[0])
    setSelectedFabric(filteredGroups[0]?.fabrics[0] ?? null)
  }, [selectedFabricType])

  const handleTypeChange = (type) => {
    // ---
    setSelectedFabricType(type)
  }

  // --- SET REACTIVE VAR -----
  useEffect(() => {
    selectedAdvancedProductFabricVar({
      fabricGroupObject: selectedFabricGroup,
      fabricObject: selectedFabric,
      combinationFabrics: null,
    })
  }, [selectedFabric])

  return (
    <Grid container justifyContent="space-evenly" spacing={1}>
      <SectionTitle title={fabricConfigurationText} />

      <Grid item xs={12} lg={12}>
        <Typography
          variant="h6"
          component="h4"
          className={classes.fabricCategoryTitle}
          // sx={{
          //   textAlign: 'center',
          //   fontWeight: 400,
          //   marginTop: '10px',
          // }}
        >
          {chooseFabricCategoryText}
        </Typography>

        <div className={classes.types}>
          {fabricTypes.map((type, i) => {
            let selected = false
            if (type === selectedFabricType) {
              selected = true
            }
            return (
              <FabricTypeButton
                key={i}
                type={type}
                handleTypeChange={handleTypeChange}
                selected={selected}
              />
            )
          })}
        </div>
      </Grid>

      <Media greaterThan="md" style={{ width: '100%' }}>
        <FabricsSelectDesktop
          filteredFabricGroups={filteredFabricGroups}
          selectedFabricGroup={selectedFabricGroup}
          setSelectedFabricGroup={setSelectedFabricGroup}
          selectedFabric={selectedFabric}
          setSelectedFabric={setSelectedFabric}
        />
      </Media>

      <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
        <Media lessThan="lg">
          <FabricsSelectMobile
            filteredFabricGroups={filteredFabricGroups}
            selectedFabricGroup={selectedFabricGroup}
            setSelectedFabricGroup={setSelectedFabricGroup}
            selectedFabric={selectedFabric}
            setSelectedFabric={setSelectedFabric}
          />
        </Media>
      </div>
    </Grid>
  )
}

const useStylesFabricsSelectMobile = makeStyles(
  (theme: Theme) =>
    createStyles({
      fabricSelectionRow: {},

      fabricSliderMobileInfo: {
        textAlign: 'center',
        fontWeight: 400,
        // position: 'absolute',
        // top: '-100px',
        width: '100%',
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        margin: '10px',
        lineHeight: '40px',
      },
    }),
  { name: 'MuiFabricsSelectMobileComponent' },
)

const FabricsSelectMobile = ({
  filteredFabricGroups,
  selectedFabricGroup,
  setSelectedFabricGroup,
  selectedFabric,
  setSelectedFabric,
}) => {
  const classes = useStylesFabricsSelectMobile() as any

  const { t, i18n } = useTranslation(['common'])
  // Pasirinkite audini
  const chooseFabricText = t('choose-fabric-text')
  // Pasirinkite audinio palete
  const chooseFabricPalletText = t('choose-fabric-pallet-text')

  //  Width in the intiial div solves mobile bugs, related to viewport scale.
  return (
    <div>
      <Typography
        variant="h6"
        component="h4"
        className={classes.fabricSliderMobileInfo}
      >
        {chooseFabricPalletText}
      </Typography>

      <FabricsGroupSelectMobile
        filteredFabricGroups={filteredFabricGroups}
        setSelectedFabricGroup={setSelectedFabricGroup}
      />
      <Typography
        variant="h6"
        component="h4"
        className={classes.fabricSliderMobileInfo}
      >
        {chooseFabricText}
      </Typography>
      <FabricSelectMobile
        items={selectedFabricGroup.fabrics}
        selectedItem={selectedFabric}
        setSelectedItem={setSelectedFabric}
      />
    </div>
  )
}

const useStylesFabricSelectMobile = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        margin: '20px 30px',
      },
      card: {
        position: 'relative',
        // display: 'inline',
        opacity: 0.6,
        // margin: '3px',
      },

      cardSelected: {
        opacity: 1,
        border: '5px solid',
        borderColor: theme.palette.secondary.main,
        margin: 0,
      },

      fabricImage: {
        minHeight: '100px',
        minWidth: '100px',
      },

      fabricCodeBox: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: 'rgb(0 0 0 / 26%)',
        color: '#fff',
        textAlign: 'center',
      },
    }),
  { name: 'MuiFFabricSelectMobileComponent' },
)

const FabricSelectMobile = ({
  className = null,
  items,
  selectedItem,
  setSelectedItem,
  ...rest
}: ProductSliderProps): JSX.Element => {
  const classes = useStylesFabricSelectMobile() as any
  // const [currentSlide, setCurrentSlide] = useState(0)
  // const [isLastSlide, setIsLastSlide] = useState(false)
  // console.log('groupedItems :>> ', groupedItems)

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="flex-start" spacing={1}>
        {items.map((item, i) => {
          let selected = false
          if (selectedItem && selectedItem.id === item.id) {
            selected = true
          }
          return (
            <Grid key={i} item xs={4}>
              <ItemCard
                item={item}
                classes={classes}
                selected={selected}
                onItemChange={setSelectedItem}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

const ItemCard = ({ item, classes, selected, onItemChange }) => {
  // ----
  const { locale } = useRouter()
  const handleChange = (e) => {
    // Trying to change selected item
    onItemChange(item)
  }

  const { t, i18n } = useTranslation(['common'])
  const fabricCodeText = t('fabric-code-text')

  const fabricImage = item?.image?.src_md ?? item?.image?.src

  return (
    <Card
      elevation={0}
      square={true}
      className={clsx(selected && classes.cardSelected, classes.card)}
      onClick={(e) => handleChange(e)}
    >
      <CardMedia
        component="img"
        image={fabricImage}
        alt={item.code}
        className={classes.fabricImage}
      />
      <div className={classes.fabricCodeBox}>
        <Typography variant="caption">
          {`${fabricCodeText} `}
          <strong>{item.code}</strong>
        </Typography>
      </div>
    </Card>
  )
}

const useStylesFabricsGroupSelectMobile = makeStyles(
  (theme: Theme) =>
    createStyles({
      fabricSelectionRow: {
        marginTop: '10px',
        marginBottom: '5px', // was 50px
      },

      sliderContainer: {
        position: 'relative',
        width: '100vw',
        padding: '0 8%',
        zIndex: 2,
        marginLeft: '10px',
        // marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
          // marginTop: theme.spacing(1),
          // padding: theme.spacing(0, 0, 0, 2),
          // padding: '0 10%',
        },
      },

      // ==== SWIPER SLIDER ======
      slider: {
        display: 'block',
        width: '100%',
        overflow: 'visible',
        // overflowX: 'hidden',
        // background: 'none',
        // touchAction: 'none',
        '--swiper-pagination-color': 'black',

        '& .swiper-slide': {
          // background: 'none !important',
        },

        '& .swiper-button-prev': {
          padding: '25px',
          // background: '#e7e9ea',
          color: '#000',
          marginLeft: '-50px',
        },
        '& .swiper-button-next': {
          padding: '25px',
          // background: '#e7e9ea',
          color: '#000',
          marginRight: '-50px',
        },
      },
    }),
  { name: 'MuiuseStylesFabricsGroupSelectMobileComponent' },
)

const FabricsGroupSelectMobile = ({
  filteredFabricGroups,
  setSelectedFabricGroup,
}) => {
  const classes = useStylesFabricsGroupSelectMobile() as any

  const handleSlideChange = (e) => {
    //----
    const activeIndex = e.activeIndex
    const selectedGroup = filteredFabricGroups[activeIndex]
    setSelectedFabricGroup(selectedGroup)
    // console.log('activeIndex :>> ', activeIndex)
  }

  let modifiedElements = [] as any
  let n = 0
  for (const group of filteredFabricGroups) {
    modifiedElements.push(
      // <p style={{ background: 'red', height: '100px' }}>sssss</p>,
      <GroupDescriptionMobileCard key={n} fabricGroup={group} />,
    )
    n++
  }

  return (
    <Box className={clsx(classes.sliderContainer)}>
      <Swiper
        id="fabricGroupSlider"
        modules={[Navigation]}
        tag="section"
        wrapperTag="ul"
        navigation={true}
        autoplay={false}
        loop={false}
        pagination={true}
        freeMode={false}
        cssMode={true}
        slidesPerView={1}
        // spaceBetween={10}
        // initialSlide={0}
        onSlideChange={handleSlideChange}
        className={clsx(classes.slider)}
      >
        {modifiedElements.map((element, i) => {
          return (
            <SwiperSlide key={i} tag="li">
              {element}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

const useStylesMobileCard = makeStyles(
  (theme: Theme) =>
    createStyles({
      groupDescriptionCard: {
        display: 'flex',
        minHeight: '450px',
        margin: '5px',

        // [theme.breakpoints.down('lg')]: {
        //   height: '500px',
        // },
      },

      cardDescription: {
        marginTop: '10px',
        marginBottom: '10px',
        maxHeight: '300px',
        overflow: 'hidden',
        wordWrap: 'break-word',
      },

      cardContent: {
        // flexGrow: 1,
        // height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'space-between',

        // '& h2': {
        //   width: '100%',
        // },
        '& p': {
          width: '100%',
        },
      },

      cardTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      priceRangeString: {
        fontSize: '20px',
        color: '#8d8d8d',
      },

      fabricImageBox: {
        width: '50%',
        position: 'relative',
      },

      cardContentFooter: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
      },
    }),
  { name: 'MuiSizeGroupDescriptionMobileCardComponent' },
)

const GroupDescriptionMobileCard = ({ fabricGroup }) => {
  const classes = useStylesMobileCard() as any
  const { locale } = useRouter()
  const { t, i18n } = useTranslation(['common'])
  const fabricCodeText = t('fabric-code-text')

  // TODO Translate --> Audinio kodas
  // console.log('fabricGroup :>> ', fabricGroup)

  const priceRangeString = '€'.repeat(fabricGroup.priceMultiplier)

  const fabricGroupProfileIndex: number = getProfileIndexFromLanguage(
    fabricGroup.fabric_group_profiles,
    locale,
  )
  const name =
    fabricGroup.fabric_group_profiles[fabricGroupProfileIndex]?.name ??
    'Not translated'
  const description =
    fabricGroup.fabric_group_profiles[fabricGroupProfileIndex]?.description ??
    'Not translated'

  const filteredIcons = allFabricIcons.filter((item) => {
    if (
      fabricGroup.easy_water_cleaning &&
      item.name === 'easy_water_cleaning'
    ) {
      return item
    } else if (fabricGroup.water_repelant && item.name === 'water_repelant') {
      return item
    } else if (
      fabricGroup.virus_protection &&
      item.name === 'virus_protection'
    ) {
      return item
    } else if (fabricGroup.non_flammable && item.name === 'non_flammable') {
      return item
    } else if (fabricGroup.pet_friendly && item.name === 'pet_friendly') {
      return item
    } else if (
      fabricGroup.environment_friendly &&
      item.name === 'environment_friendly'
    ) {
      return item
    }
  })

  const manufacturerLogoSrc =
    fabricGroup.manufacturer?.logo_image?.src_md ??
    fabricGroup.manufacturer?.logo_image?.src

  const manufacturerProfileIndex: number = getProfileIndexFromLanguage(
    fabricGroup?.manufacturer?.manufacturer_profiles,
    locale,
  )
  const manufacturerName =
    fabricGroup?.manufacturer?.manufacturer_profiles[manufacturerProfileIndex]
      ?.name ?? 'Not translated'

  let manufacturerLogo
  if (manufacturerLogoSrc) {
    manufacturerLogo = (
      <img
        src={manufacturerLogoSrc}
        style={{ maxHeight: '150px', maxWidth: '150px' }}
      />
    )
  } else {
    manufacturerLogo = (
      <Typography variant="h6" component="h3">
        {manufacturerName}
      </Typography>
    )
  }

  return (
    <Card
      elevation={1}
      square={true}
      className={clsx(classes.groupDescriptionCard)}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.cardTitle}>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
          <Typography
            variant="body1"
            component="h2"
            className={classes.priceRangeString}
          >
            {priceRangeString}
          </Typography>
        </div>

        <div className={classes.cardDescription}>
          {description.split('\n').map((line, i) => {
            return (
              <Typography key={i} variant="body1" component="p">
                {line}
              </Typography>
            )
          })}
        </div>

        <div className={classes.cardContentFooter}>
          {filteredIcons.map((iconObject, i) => (
            <FabricAttributeImage
              key={i}
              src={iconObject.image}
              alt={iconObject.text}
            />
          ))}
        </div>
        <div className={classes.cardContentFooter}> {manufacturerLogo}</div>
        <TrackingModuleButton
          type={'order-a-fabric-or-material-sample-button'}
          variant="contained"
        />
      </CardContent>
    </Card>
  )
}

const FabricsSelectDesktop = ({
  filteredFabricGroups,
  selectedFabricGroup,
  setSelectedFabricGroup,
  selectedFabric,
  setSelectedFabric,
}) => {
  const classes = useStyles() as any

  const { t, i18n } = useTranslation(['common'])
  // Pasirinkite audini
  const chooseFabricText = t('choose-fabric-text')
  // Pasirinkite audinio palete
  const chooseFabricPalletText = t('choose-fabric-pallet-text')

  return (
    <Grid
      container
      justifyContent="space-evenly"
      spacing={0}
      // className={classes.fabricSelectionRow}
      sx={{ marginTop: '100px' }}
    >
      <Grid item lg={2} sx={{ position: 'relative' }}>
        <Typography
          variant="h6"
          component="h4"
          className={classes.fabricSliderInfo}
        >
          {chooseFabricPalletText}
        </Typography>

        <FabricGroupsSlider
          items={filteredFabricGroups}
          selectedItem={selectedFabricGroup}
          setSelectedItem={setSelectedFabricGroup}
        />
      </Grid>

      <Grid item lg={7}>
        <GroupDescription
          fabricGroup={selectedFabricGroup}
          selectedFabric={selectedFabric}
        />
      </Grid>

      <Grid item lg={3} sx={{ position: 'relative' }}>
        <Typography
          variant="h6"
          component="h4"
          className={classes.fabricSliderInfo}
        >
          {chooseFabricText}
        </Typography>
        <FabricsSlider
          items={selectedFabricGroup?.fabrics}
          selectedItem={selectedFabric}
          setSelectedItem={setSelectedFabric}
        />
      </Grid>
    </Grid>
  )
}

const FabricTypeButton = ({ type, handleTypeChange, selected }) => {
  // return <p>{type}</p>
  const classes = useStyles() as any
  const fabricImage = `/fabric_type_images/${type}.jpg`

  const { t, i18n } = useTranslation(['common'])
  const typeNameTranslated = t(type)

  return (
    <Box
      className={classes.fabricTypeBox}
      onClick={(e) => handleTypeChange(type)}
    >
      <Card
        elevation={0}
        square={true}
        // className={clsx(selected && classes.cardSelected, classes.card)}
        className={clsx(
          selected && classes.fabricTypeImageSelected,
          classes.fabricTypeImage,
        )}
      >
        <CardMedia component="img" image={fabricImage} alt={type} />
      </Card>
      <p
        style={{
          textTransform: 'uppercase',
          padding: '10px',
          fontWeight: 500,
        }}
      >
        {typeNameTranslated}
      </p>
    </Box>
  )
}

const GroupDescription = ({ fabricGroup, selectedFabric }) => {
  const classes = useStyles() as any
  const { locale } = useRouter()
  const { t, i18n } = useTranslation(['common'])
  const fabricCodeText = t('fabric-code-text')

  const priceRangeString = '€'.repeat(fabricGroup.priceMultiplier)

  const fabricGroupProfileIndex: number = getProfileIndexFromLanguage(
    fabricGroup.fabric_group_profiles,
    locale,
  )
  const name =
    fabricGroup.fabric_group_profiles[fabricGroupProfileIndex]?.name ??
    'Not translated'
  const description =
    fabricGroup.fabric_group_profiles[fabricGroupProfileIndex]?.description ??
    'Not translated'

  // console.log('description :>> ', description)

  const filteredIcons = allFabricIcons.filter((item) => {
    if (
      fabricGroup.easy_water_cleaning &&
      item.name === 'easy_water_cleaning'
    ) {
      return item
    } else if (fabricGroup.water_repelant && item.name === 'water_repelant') {
      return item
    } else if (
      fabricGroup.virus_protection &&
      item.name === 'virus_protection'
    ) {
      return item
    } else if (fabricGroup.non_flammable && item.name === 'non_flammable') {
      return item
    } else if (fabricGroup.pet_friendly && item.name === 'pet_friendly') {
      return item
    } else if (
      fabricGroup.environment_friendly &&
      item.name === 'environment_friendly'
    ) {
      return item
    }
  })

  const fabricImage = selectedFabric?.image.src_md ?? selectedFabric?.image.src
  const manufacturerLogoSrc =
    fabricGroup.manufacturer?.logo_image?.src_md ??
    fabricGroup.manufacturer?.logo_image?.src

  const manufacturerProfileIndex: number = getProfileIndexFromLanguage(
    fabricGroup?.manufacturer?.manufacturer_profiles,
    locale,
  )
  const manufacturerName =
    fabricGroup?.manufacturer?.manufacturer_profiles[manufacturerProfileIndex]
      ?.name ?? 'Not translated'

  let manufacturerLogo
  if (manufacturerLogoSrc) {
    manufacturerLogo = (
      <img
        src={manufacturerLogoSrc}
        style={{ maxHeight: '150px', maxWidth: '150px' }}
      />
    )
  } else {
    manufacturerLogo = (
      <Typography variant="h6" component="h3">
        {manufacturerName}
      </Typography>
    )
  }

  return (
    <Card
      elevation={0}
      square={true}
      className={clsx(classes.groupDescriptionCard)}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.cardTitle}>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
          <Typography
            variant="body1"
            component="h2"
            className={classes.priceRangeString}
          >
            {priceRangeString}
          </Typography>
        </div>

        <div className={classes.cardDescription}>
          {description.split('\n').map((line, i) => {
            return (
              <Typography
                variant="body1"
                component="p"
                key={i}
                // className={classes.cardDescription}
              >
                {line}
              </Typography>
            )
          })}
        </div>

        <div style={{ width: '100%' }}>
          <div className={classes.cardContentFooter}>
            <div>
              {filteredIcons.map((iconObject, i) => (
                <FabricAttributeImage
                  key={i}
                  src={iconObject.image}
                  alt={iconObject.text}
                />
              ))}
            </div>
            <div> {manufacturerLogo}</div>
          </div>
          <TrackingModuleButton
            type={'order-a-fabric-or-material-sample-button'}
            variant="contained"
          />
        </div>
      </CardContent>

      <div className={classes.fabricImageBox}>
        <CardMedia
          component="img"
          image={fabricImage}
          alt={name}
          className={classes.fabricImage}
        />
        <div className={classes.fabricCode}>
          <p>
            {fabricCodeText}: <strong>{selectedFabric?.code}</strong>{' '}
          </p>
        </div>
      </div>
    </Card>
  )
}

const FabricAttributeImage = ({ src, alt = '' }) => {
  return (
    <Tooltip title={alt}>
      <img
        src={src}
        alt={alt}
        style={{
          width: 50,
          height: 50,
          marginRight: 20,
          display: 'inline',
        }}
      ></img>
    </Tooltip>
  )
}
